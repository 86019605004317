import {postJSON as post} from '../../api';
import {ADD_LOCATION_SERVICE_START, ADD_LOCATION_SERVICE_SUCCESS, ADD_LOCATION_SERVICE_FAILURE} from './types';

const addServiceStart = () => ({
  type: ADD_LOCATION_SERVICE_START,
});

const addServiceSuccess = () => ({
  type: ADD_LOCATION_SERVICE_SUCCESS,
});

const addServiceFailure = error => ({
  type: ADD_LOCATION_SERVICE_FAILURE,
  error,
});

const addService = (authorization, locationId, data, callback) => {
  return dispatch => {
    dispatch(addServiceStart());
    post(`locations/${locationId}/services`, {authorization}, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(addServiceSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(addServiceFailure(error));
      });
  };
};

export default addService;
