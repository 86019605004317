import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';

const optionItems = (options, selectedOptionIndex, name, handleChange) => {
  const handleClick = id => {
    handleChange({target: { name, value: id}});
  };
  const items = options.map((option, i) => {
    return (
      <Option key={i}>
        <SelectOption selected={i === selectedOptionIndex}
          onClick={() => handleClick(option.id)}>
          <div className="circle" />
        </SelectOption>
        {option.name}
      </Option>
    );
  });
  return items;
};

const RadioField = ({label, name, options, selectedOptionIndex, handleChange}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <OptionContainer>
        {optionItems(options, selectedOptionIndex, name, handleChange)}
      </OptionContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: ${props => props.width ? 'inline-block': 'block'};
  min-width: ${props => props.width || '100%'};
  max-width: ${props => props.width || '100%'};
  margin: 24px 0 20px;
  padding: 0;
  float: ${props => props.float || 'none'};
  text-align: left;
`;

const Label = styled.label`
  display: block;
  margin: 0.25rem 0;
  font-size: 14px;
  color: ${colors.TEXT_INPUT};
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Option = styled.div`
  display: inline-flex;
  align-items: center;
  min-height: 32px; max-height: 32px;
  margin: 6px 0; margin-right: 32px;
`;

const SelectOption = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  margin-right: 6px;
  border: 2px solid ${props => props.selected ? colors.BLUE_MAIN : colors.TEXT_MINOR};
  border-radius: 50%;
  cursor: pointer;
  & > div.circle {
    min-width: 14px; max-width: 14px;
    min-height: 14px; max-height: 14px;
    border-radius: 50%;
    background-color: ${props => props.selected ? colors.BLUE_MAIN : 'white'};
  }
`;

export default RadioField;
