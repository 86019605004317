import React from 'react';
import styled from 'styled-components';

import {PasswordField, Section, Text, TextField, Title} from '../common/Form';
import Button from '../common/Button';
import {ErrorAlert} from '../common/Alerts';

export const FirstSection = ({fields, handleChange, handleSectionBack, error}) => {
  const allFieldsValid = Object.values(fields)
    .filter(field => !field.valid).length === 0;

  const {email, first_name, last_name, password, confirm_password} = fields;

  return (
    <Section>
      <Title>Sign Up</Title>
      <TextField
        name="email" value={email.value} valid={email.valid}
        warning="This field is required."
        label="Email" required
        handleChange={handleChange} width="100%"
      />
      <TextField
        name="first_name" value={first_name.value} valid={first_name.valid}
        warning="This field is required."
        label="First name" required
        handleChange={handleChange} width="48.2%"
        margin="0 14px 0 0"
      />
      <TextField
        name="last_name" value={last_name.value} valid={last_name.valid}
        warning="This field is required."
        label="Last name" required
        handleChange={handleChange} width="48.2%"
      />
      <PasswordField
        name="password" value={password.value} valid={password.valid}
        warning="Must contain at least 8 characters and 1 digit."
        label="Password" required
        handleChange={handleChange} width="100%"
      />
      <PasswordField
        name="confirm_password" value={confirm_password.value} valid={confirm_password.valid}
        warning="Must be equal to password field."
        label="Confirm password" required
        handleChange={handleChange} width="100%"
      />
      <Text textAlign="left" position="absolute">
        {"By pressing continue, you agree to Drive Energy's "}
        <a href="https://ms.drivechargers.ru/privacy">Privacy Policy</a>.
      </Text>
      <ButtonContainer>
        <Button type="submit" width="100%" theme="blue" float="left" margin="0"
          disabled={!allFieldsValid}>
          Sign Up
        </Button>
        {(error && error.error && <div className="error-alert"><ErrorAlert noTriangle>{error.error}</ErrorAlert></div>)}
      </ButtonContainer>
    </Section>
  );
};

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    position: relative;
  }
  @media screen and (min-width: 576px) {
    position: absolute; right: 0; bottom: 0; left: 0;
  }
  & > div.error-alert {
    display: inline;
    position: relative;
    width: 200px;
    margin-top: 10px;
    top: 20px;
  }
`;
