import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import addPhoto from '../../../redux/actions/addPhoto';
import getPhotos from '../../../redux/actions/getPhotos';
import Modal from '../../common/Modal';
import AddPhotoForm from './AddPhotoForm';

class AddPhoto extends Component {
  componentDidMount() {
    const {loggedIn, history} = this.props;

    if (!loggedIn) {
      history.push(`/`);
      return false;
    }
  }

  render() {
    const {authorization, locationId, addPhoto, getPhotos} = this.props;

    return (
      <Modal minHeight="200px">
        <AddPhotoForm
          authorization={authorization}
          locationId={locationId}
          addPhoto={addPhoto}
          getPhotos={getPhotos}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({manager}, {match}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
});

const mapDispatchToProps = dispatch => ({
  addPhoto: (authorization, locationId, data, callback) =>
    dispatch(addPhoto(authorization, locationId, data, callback)),
  getPhotos: (locationId, params, callback) =>
    dispatch(getPhotos(locationId, params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPhoto));
