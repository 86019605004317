import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../common/Button';
import {TopBarBlock, LeftSide, RightSide} from '../common/MainBlocks';
import TabLinkGroup from '../common/TabLinkGroup';
import TabLink from '../common/TabLink';

import SearchForm from './SearchForm';

const Topbar = () => {
  const {t} = useTranslation();

  return (
    <TopBarBlock fixed white>
      <LeftSide>
        <TabLinkGroup>
          <TabLink
            to="/locations">
            {t('allLocations')}
          </TabLink>
        </TabLinkGroup>
      </LeftSide>
      <RightSide>
        <SearchForm/>
        <Button
          to="/locations/add"
          theme="blue"
          minWidth="180px"
          maxWidth="none"
          height="40px"
          margin="0">
          {t('addNewLocation')}
        </Button>
      </RightSide>
    </TopBarBlock>
  );
};

export default Topbar;