import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getServices from '../../../redux/actions/getServices';
import deleteService from '../../../redux/actions/deleteService';
import LoadingSpinner from '../../common/LoadingSpinner';

import MiddleBar from '../MiddleBar';
import ServiceContainer from './ServiceContainer';
import UnavailableContainer from './UnavailableContainer';

class Services extends Component {
  componentDidMount() {
    const {locationId, getServices} = this.props;

    getServices(locationId);
  }

  render() {
    const {authorization, locationId, data, loading,
      deleteService, getServices, t, serviceTypes} = this.props;

    if (loading) {
      return <LoadingSpinner/>;
    }

    const status = <>{t('services')} <span>({data.length})</span></>;

    const buttonText = t('addService');
    const buttonLink = `/locations/${locationId}/services/add`;
    const tooltipTxt = t('disabledServiceAdd');

    if (this.props.manager.subscription_id) {
      return (
        <>
          <MiddleBar
            status={status}
            tooltipText={tooltipTxt}
            disabled={(data.length >= serviceTypes.length)}
            buttonText={buttonText}
            buttonLink={buttonLink}
          />

          <ServiceContainer
            authorization={authorization}
            locationId={locationId}
            data={data}
            deleteService={deleteService}
            getServices={getServices}
          />
        </>
      );
    }

    return (
      <UnavailableContainer
        history={this.props.history}
      />
    );
  }
}

const mapStateToProps = ({manager, location, types}, {match}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  data: location.services,
  loading: location.servicesLoading,
  manager: manager.data,
  serviceTypes: types.services,
});

const mapDispatchToProps = dispatch => ({
  getServices: locationId => dispatch(getServices(locationId)),
  deleteService: (authorization, locationId, data, callback) =>
    dispatch(deleteService(authorization, locationId, data, callback)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Services);
