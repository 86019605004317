import styled from 'styled-components';

import bp from './breakpoints';
import * as colors from '../../util/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  margin: 0 auto;
  min-height: 100%;

  @media screen and (max-width: ${bp.desktop}) {
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
  }
`;
Wrapper.defaultProps = {
  direction: 'row',
};

export const WrapperBlock = styled.div`
  display: block;
  padding-left: 260px;
  background-color: ${colors.BG};
  height: 100%;
  position: relative;
  overflow: hidden;
  min-width: 700px;

  @media screen and (max-width: ${bp.tablet}) {
    padding-left: 0px;
  }
`;

export const SidebarBlock = styled.div`
  width: 260px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba(217, 226, 232, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  @media screen and (max-width: ${bp.tablet}) {
    transform: translateX(-100%);
  }
`;

export const PageBlock = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0 60px;

  @media screen and (max-width: ${bp.tablet}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
PageBlock.defaultProps = {
  id: 'PageBlock',
};

export const PageInnerBlock = styled.div`
  display: block;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
PageInnerBlock.defaultProps = {
  id: 'PageInnerBlock',
};

export const TopBarBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.height ? props.height : '80px'};
  padding: 0 60px;
  background-color: ${props => props.white ? '#fff' : 'transparent'};
  box-shadow: ${props => props.white ? '0 1px 15px rgba(217,226,232,0.8)' : 'none'};

  ${props => props.fixed ? 'position: sticky; top: 0; z-index: 10;' : null}
  ${props => props.white ? 'margin-bottom: 32px;' : null}
  ${props => props.noMargin ? null : 'margin-left: -60px; margin-right: -60px;'};

  @media screen and (max-width: ${bp.tablet}) {
    padding-left: 30px;
    padding-right: 30px;
    ${props => props.noMargin ? null : 'margin-left: -30px; margin-right: -30px;'};
  }
`;

export const PageTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
