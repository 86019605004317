import React from 'react';
import {useTranslation} from 'react-i18next';

import {TextField, SelectField} from '../../common/Form';

export const Bank = ({data, handleChange}) => {
  const {t} = useTranslation();

  return (
    <div>
      <TextField
        name="bank_account_id"
        value={data.bank_account_id.value}
        valid={data.bank_account_id.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('bankAccountId')} required
        width="100%"
      />
      <TextField
        name="bank_name"
        value={data.bank_name.value}
        valid={data.bank_name.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('bankName')} required
        width="100%"
      />
      <TextField
        name="bank_id"
        value={data.bank_id.value}
        valid={data.bank_id.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('bankId')} required
        width="100%"
      />
      <SelectField
        name="bank_country"
        selectedOption={
          data.bank_country.options[data.bank_country.selectedOptionIndex]
        }
        options={data.bank_country.options}
        handleChange={handleChange}
        label={t('country')} required
        width="100%"
      />
      <TextField
        name="bank_city"
        value={data.bank_city.value}
        valid={data.bank_city.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('city')} required
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="bank_postal_code"
        value={data.bank_postal_code.value}
        valid={data.bank_postal_code.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('postalCode')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <TextField
        name="bank_address"
        value={data.bank_address.value}
        valid={data.bank_address.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('address')} required
        width="100%"
      />
      <TextField
        name="name_on_account"
        value={data.name_on_account.value}
        valid={data.name_on_account.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('nameOnAccount')} required
        width="100%"
      />
    </div>
  );
};
