import React, {Component} from 'react';
import * as colors from '../../../util/colors';

import Avatar from '../Avatar';

import {AvatarWrapper, Name, Balance} from './Profile.styled';

class Profile extends Component {
  componentWillMount() {
    const {getManagerBalance, authorization, id} = this.props;
    getManagerBalance(authorization, id);
  };

  render() {
    const {
      avatar_url,
      first_name,
      last_name,
      balance,
      role,
    } = this.props;

    return (
      <>
        <AvatarWrapper>
          <Avatar
            rounded
            src={avatar_url}
            style={role === 'administrator' ? {border: `2px solid ${colors.RED_MAIN}`} : null}
          />
        </AvatarWrapper>
        <Name>
          {`${first_name} ${last_name}`}
        </Name>
        <Balance>
          {balance && balance.available && balance.available.amount ? (
            balance.available.amount / 100
          ) : 0}
          {' '}
          ₽
        </Balance>
      </>
    );
  };
}

export default Profile;
