import React from 'react';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';

import Sidebar from '../common/Sidebar';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import MyPlan from './MyPlan';
import PaymentMethod from './PaymentMethod';
import PaymentAccount from './PaymentAccount';
import PaymentBank from './PaymentBank';
import SubscriptionConfiguration from './SubscriptionConfiguration';

import {WrapperBlock, PageBlock} from '../common/MainBlocks';
import TopbarMobile from "../common/TopbarMobile";

const Settings = ({loggedIn, history}) => {
  if (!loggedIn) {
    history.push('/');
    return false;
  }

  return (
    <WrapperBlock>
      <TopbarMobile />
      <Sidebar/>
      <PageBlock>
        <Route exact path="/settings/profile" component={Profile} />
        <Route exact path="/settings/change-password" component={ChangePassword} />
        <Route exact path="/settings/payments" component={MyPlan} />
        <Route exact path="/settings/payments/method" component={PaymentMethod} />
        <Route exact path="/settings/payments/account" component={PaymentAccount} />
        <Route exact path="/settings/payments/bank" component={PaymentBank} />
        <Route exact path="/settings/payments/subscription" component={SubscriptionConfiguration} />
      </PageBlock>
    </WrapperBlock>
  );
};

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
});

export default connect(mapStateToProps, null)(withRouter(Settings));
