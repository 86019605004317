import React from 'react';
import {withRouter} from 'react-router-dom';
import {Container, Row} from 'reactstrap';
import styled from 'styled-components';

import Logo from '../common/Logo';
import bp from '../common/breakpoints';
import AddLocationForm from './AddLocationForm';
import MapContainer from './MapContainer';

const AddLocation = ({history}) => (
  <Container fluid={true}>
    <Row>
      <LogoWrap><Logo fillColor="blue" /></LogoWrap>
      <BackLink onClick={() => history.push('/locations')}>
        <i className="fa fa-arrow-left" /> Back
      </BackLink>
      <AddLocationForm />
      <MapContainer />
    </Row>
  </Container>
);

const BackLink = styled.button`
  position: absolute;
  min-height: 2rem; max-height: 2rem;
  font-size: 16px;
  color: black; background-color: transparent;
  border: 0 solid transparent;
  cursor: pointer;
  z-index: 3;
  &:focus { outline: none; }
  @media screen and (max-width: 992px) {
    top: 5vw; left: 1.9vw;
  }
  @media screen and (min-width: 992px) {
    top: 6vw; left: 12.5vw;
  }
`;

const LogoWrap = styled.div`
  position: absolute; top: 1.5vw; left: 5vw;
  min-width: 7vw; max-width: 7vw;
  min-height: 2vw; max-width: 2vw;
  cursor: pointer;
  z-index: 3;
  @media screen and (max-width: 768px) {
    top: 15px;
    min-width: 65px; max-width: 14vw;
    min-height: 65px; max-width: 4vw;
  }
  @media screen and (max-width: ${bp.phone}) {
    left: 40%;
  }
`;

export default withRouter(AddLocation);
