import {postJSON as post} from '../../api';
import {
  CREATE_PAYMENT_METHOD_START, CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
} from './types';

const createPaymentMethodStart = () => ({
  type: CREATE_PAYMENT_METHOD_START,
});

const createPaymentMethodSuccess = () => ({
  type: CREATE_PAYMENT_METHOD_SUCCESS,
});

const createPaymentMethodFailure = error => ({
  type: CREATE_PAYMENT_METHOD_FAILURE,
  error,
});

const createPaymentMethod = (authorization, data, callback) => {
  return dispatch => {
    dispatch(createPaymentMethodStart());
    post('payment-methods', {authorization}, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(createPaymentMethodSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(createPaymentMethodFailure(error));
      });
  };
};

export default createPaymentMethod;
