import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import getCurrentManager from '../../../redux/actions/getCurrentManager';
import logOutManager from '../../../redux/actions/logOutManager';
import SettingsTopbar from '../Topbar';
import ProfileContainer from './ProfileContainer';

const Profile = props => {
  if (!props.loggedIn) {
    props.history.push('/');
    return false;
  }

  return (
    <>
      <SettingsTopbar />
      <ProfileContainer
        authorization={props.authorization}
        data={props.data}
        loading={props.loading}
        getCurrentManager={props.getCurrentManager}
        logOutManager={props.logOutManager}
      />
    </>
  );
};

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  data: manager.data,
  loading: manager.loading,
  error: manager.error,
});

const mapDispatchToProps = dispatch => ({
  getCurrentManager: (authorization, callback) => {
    return dispatch(getCurrentManager(authorization, callback));
  },
  logOutManager: callback => dispatch(logOutManager(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
