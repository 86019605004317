import React from 'react';
import {useTranslation} from 'react-i18next';

import TabLinkGroup from '../common/TabLinkGroup';
import TabLink from '../common/TabLink';
import Paper from '../common/Paper';
import LocationInfo from './LocationInfo';

import { Navigation } from './LocationCard.styled';


const LocationCard = props => {
  const {
    authorization,
    locationId,
    data,
    deleteLocation,
  } = props;

  const {t} = useTranslation();

  return (
    <Paper padding="0" id="LocationCard">
      <LocationInfo
        authorization={authorization}
        locationId={locationId}
        data={data}
        deleteLocation={deleteLocation}
      />
      <Navigation>
        <TabLinkGroup>
          <TabLink to={`/locations/${locationId}/stations`}>{t('stations')}</TabLink>
          <TabLink to={`/locations/${locationId}/photos`}>{t('photos')}</TabLink>
          <TabLink to={`/locations/${locationId}/services`}>{t('services')}</TabLink>
          <TabLink to={`/locations/${locationId}/feedback`}>{t('feedback')}</TabLink>
        </TabLinkGroup>
      </Navigation>
    </Paper>
  );
};

export default LocationCard;
