import React from 'react';
import styled from 'styled-components';

const CheckboxField = ({width, name, value, valid, handleChange, text, warning}) => (
  <Container width={width}>
    <Text valid={valid !== undefined && !valid}>
      <Field onClick={() => handleChange({ target: { type: 'checkbox', name, value }})}>
        <Input name={name} checked={value} onChange={handleChange}
          type="checkbox" />
        <Label checked={value} />
      </Field>
      {text}
    </Text>
    <Warning visible={valid !== undefined && !valid}>{warning}</Warning>
  </Container>
);

const Container = styled.div`
  position: relative;
  min-height: 2rem; max-height: 2rem;
  min-width: ${props => props.width || '100%'};
  max-width: ${props => props.width || '100%'};
  margin: 0.5rem ${props => props.width ? '0.5rem' : 'auto'};
  padding: 0;
`;

const Field = styled.div`
  width: 25px;
  margin-right: 6px;
  position: relative;
`;

const Label = styled.label`
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: #eee;
  border: 1px solid #ddd;

  &::after {
    opacity: ${props => props.checked ? '1' : '0.2'};
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 4px;
    left: 5px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
`;

const Input = styled.input`
  outline: none;
  &:focus ~ ${Label}::after {
    opacity: ${props => props.checked ? '1' : '0.5'};
    width: 10px;
    height: 6px;
  }
`;

const Text = styled.div`
  display: inline-flex;
  align-items: center;
  min-width: 100%;
  font-size: 14px;
  border-bottom: ${props => props.valid ? '1px solid red' : 'transparent'};
`;

const Warning = styled.div`
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  text-align: right;
  font-size: 12px;
  color: red;
`;

export default CheckboxField;
