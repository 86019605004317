import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getLocationStations from '../../../redux/actions/getLocationStations';
import deleteStation from '../../../redux/actions/deleteStation';
import getStation from '../../../redux/actions/getStation';

import LoadingSpinner from '../../common/LoadingSpinner';

import MiddleBar, { Title, Counter } from '../MiddleBar';
import { StationsWrapper } from './Stations.styled';
import StationCard from './StationCard';

class Stations extends Component {
  intervalId = 0;
  updateInterval = 30000;
  offset = 0;
  limit = 100;
  stationHeight = 490;

  componentDidMount() {
    const {
      authorization,
      locationId,
      getLocationStations,
    } = this.props;

    this.setLimitForLoading();

    getLocationStations(
      authorization,
      locationId,
      { order_by: 'number:asc', limit: this.limit, offset: this.offset },
      () => { this.offset = this.offset + this.limit },
      false,
    );

    document
      .getElementById('PageBlock')
      .addEventListener('scroll', this.handleScroll);

    window.addEventListener('resize', this.handleWindowResize);

    this.intervalId = setInterval(() => this.updateLocationStations(), this.updateInterval);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    document
      .getElementById('PageBlock')
      .removeEventListener('scroll', this.handleScroll);
    window
      .removeEventListener('resize', this.handleWindowResize);
  }

  calcStationsNumberInRow = () => {
    const width = document.body.offsetWidth;
    if (width <= 1224) {
      return 1
    } else if (width > 1224 && width <= 1824) {
      return 2;
    } else {
      return 3;
    }
  };

  calcRowsNumber = () => {
    return Math.floor(this.limit / this.calcStationsNumberInRow());
  };

  setLimitForLoading = () => {
    // // Высота карточки станции + отступ снизу
    // const locationCardHeight = document.getElementById('LocationCard').offsetHeight + 24;
    //
    // // Высота блока с кнопкой "добавить станцию" + отступ снизу
    // const middleBarHeight = 40 + 16;
    //
    // // 80 - шапка, 32 - отступ снизу: снизу от шапки и внизу экрана
    // const wrapHeight = document.body.offsetHeight - 80 - 32 - locationCardHeight - middleBarHeight;
    //
    // // Устанавливаем limit - по сколько локаций будем грузить
    // if (this.stationHeight * this.calcRowsNumber() < wrapHeight) {
    //   do {
    //     this.limit = this.limit + 10;
    //   } while (this.stationHeight * this.calcRowsNumber() < wrapHeight);
    // }
  };

  handleWindowResize = () => {
    const { authorization, getLocationStations } = this.props;

    this.limit = 100;
    this.offset = 0;

    this.setLimitForLoading();

    // getLocationStations(
    //   authorization,
    //   { order_by: 'number:asc', limit: this.limit, offset: this.offset },
    //   () => { this.offset = this.offset + this.limit },
    //   false,
    //   false,
    // );
  };

  handleScroll = () => {
    const { data, totalCount, loadingMore } = this.props;
    const pageBlockHeight = document.getElementById('PageBlock').offsetHeight;
    const pageBlockScrollTop = document.getElementById('PageBlock').scrollTop;
    const pageInnerBlockHeight = document.getElementById('PageInnerBlock').offsetHeight - 1;

    if (pageBlockHeight + pageBlockScrollTop >= pageInnerBlockHeight && !loadingMore && !(data.length >= totalCount)) {
      console.log('Loading more...');
      console.log(data.length >= totalCount);
      this.loadMore();
    }
  };

  loadMore = () => {
    const {
      authorization,
      locationId,
      getLocationStations,
    } = this.props;

    getLocationStations(
      authorization,
      locationId,
      { order_by: 'number:asc', limit: this.limit, offset: this.offset},
      () => { this.offset = this.offset + this.limit },
      true,
      true
    );
  };

  updateLocationStations = () => {
    const {
      authorization,
      locationId,
      getLocationStations,
    } = this.props;

    getLocationStations(
      authorization,
      locationId,
      { order_by: 'number:asc', limit: this.offset, offset: 0 },
      null,
      true,
      false
    );
  }

  render() {
    const {
      authorization,
      data,
      totalCount,
      loading,
      loadingMore,
      getLocationStations,
      deleteStation,
      t,
      getStation,
      match: {
        params: {
          locationId,
        },
      },
    } = this.props;

    if (loading) {
      return <LoadingSpinner/>;
    }

    const status = (
      <Title>
        {t('stations')}
        {' '}
        <Counter>({totalCount})</Counter>
      </Title>
    );

    return (
      <>
        <MiddleBar
          status={status}
          buttonText={t('addStation')}
          buttonLink={`/locations/${locationId}/stations/add`}
        />

        <StationsWrapper id="StationContainer">
          {data && data
            .sort((station1, station2) => station1.number - station2.number)
            .map((station, i) => (
              <StationCard
                key={i}
                {...station}
                authorization={authorization}
                locationId={locationId}
                getLocationStations={getLocationStations}
                deleteStation={deleteStation}
                getStation={getStation}
              />
            )
          )}
        </StationsWrapper>

        {loadingMore && (
          <div style={{ height: '100px', position: 'relative' }}>
            <LoadingSpinner />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({manager, location}, {match}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  data: location.stations,
  totalCount: location.stationsTotalCount,
  loading: location.stationsLoading,
  loadingMore: location.stationsLoadingMore,
});

const mapDispatchToProps = dispatch => ({
  getLocationStations: (...params) => dispatch(getLocationStations(...params)),
  deleteStation: (...params) => dispatch(deleteStation(...params)),
  getStation: (...params) => dispatch(getStation(...params)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Stations);
