import React from 'react';

const TabLinkGroup = props => {
  const { children } = props;

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { marginRight: true, })
  );

  return childrenWithProps;
};

export default TabLinkGroup;