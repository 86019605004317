import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import * as _ from 'lodash';

import addStation from '../../../redux/actions/addStation';
import getStations from '../../../redux/actions/getStations';
import getLocationStations from '../../../redux/actions/getLocationStations';
import clearLocationErrors from '../../../redux/actions/clearErrors';
import clearStation from '../../../redux/actions/clearStation';

import {TopBarBlock} from '../../common/MainBlocks';
import TopbarBackButton from '../../common/TopbarBackButton';

import {ChooseStationForm} from './ChooseStationForm';
import CreateModelForm from './CreateModelForm';
import ModelSelectedForm from './ModelSelectedForm';
import {ModelCreationFinish} from './ModelCreationFinish';

export const CHOOSE_STATION_FORM = 0;
export const CREATE_NEW_STATION_FORM = 1;
export const STATION_SELECTED_FORM = 99;
export const MODEL_CREATED_FINISH = 3;

class AddStation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      newStation: {},
      stations: [],
      filteredStations: [],
      search: '',
    };
  };

  componentDidMount() {
    const {authorization, getStations, station} = this.props;

    if (station) {
      this.setState({step: MODEL_CREATED_FINISH, newStation: station});
      return;
    }

    getStations(authorization, {search: this.state.search, group_by: 'model'}, data => {
      this.setState({newStation: data[0], stations: data, filteredStations: data});
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.data, this.state.stations)) {
      this.setState({stations: nextProps.data, filteredStations: this.props.data});
    }
  }

  changeStep = step => this.setState({ step });

  saveStation = station => {
    this.setState({
      newStation: station,
      step: STATION_SELECTED_FORM,
    });
  };

  selectModelBtnClick = () => {
    this.changeStep(STATION_SELECTED_FORM);
  }

  modelSelectedNext = station => {
    const {authorization, locationId, addStation} = this.props;

    addStation(authorization, locationId, station, () => {
      this.setState({
        newStation: station,
        step: MODEL_CREATED_FINISH,
      });
    });
  };

  handleSearch = event => {
    const { value } = event.target;
    const { getStations, authorization } = this.props;

    getStations(authorization, {search: value, group_by: 'model'}, data => {
      if (data.length !== 0 ) {
        this.setState({
          filteredStations: data,
          search: value,
        });
      }
    });
  }

  renderPage = () => {
    const { step, search, newStation, filteredStations } = this.state;
    const { error, clearStation, locationId, history } = this.props;

    switch (step) {

      case CHOOSE_STATION_FORM:
        return (
          <ChooseStationForm
            selectModelBtnClick={this.selectModelBtnClick}
            stations={filteredStations}
            handleSearch={this.handleSearch}
            search={search}
            changeStep={this.changeStep}
            saveStation={station => this.setState({ newStation: station })}
          />
        );

      case CREATE_NEW_STATION_FORM:
        return (
          <CreateModelForm
            saveStation={this.saveStation}
          />
        );

      case STATION_SELECTED_FORM:
        return (
          <ModelSelectedForm
            error={error}
            station={newStation}
            modelSelectedNextClick={this.modelSelectedNext}
          />
        );

      case MODEL_CREATED_FINISH:
        return (
          <ModelCreationFinish
            clearStation={clearStation}
            locationId={locationId}
            history={history}
            station={newStation}
          />
        );

      default:
        return <ChooseStationForm/>;
    }
  }

  render() {
    const {t, locationId} = this.props;
    const {step} = this.state;

    return (
      <>
        {step !== MODEL_CREATED_FINISH  && 
          <TopBarBlock fixed white>
            {this.state.step === 0 ? (
              <TopbarBackButton
                to={`/locations/${locationId}/stations`}
                label={t('stations')}
              />
            ) : (
              <TopbarBackButton
                onClick={() => {
                  this.setState({
                    step: (step === STATION_SELECTED_FORM) ? CHOOSE_STATION_FORM : step - 1,
                  });
                }}
                label={t('back')}
              />
            )}
          </TopBarBlock>
        }
        
        {this.renderPage()}
      </>
    );
  }
}
const mapStateToProps = ({manager, stations, location, station }, {match}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  ...stations,
  station: station.data,
  error: location.error,
});

const mapDispatchToProps = dispatch => ({
  addStation: (authorization, locationId, data, callback) =>
    dispatch(addStation(authorization, locationId, data, callback)),
  getStations: (authorization, params, callback) =>
    dispatch(getStations(authorization, params, callback)),
  getLocationStations: (authorization, locationId, params, callback) =>
    dispatch(getLocationStations(authorization, locationId, params, callback)),
  clearLocationErrors: () => dispatch(clearLocationErrors()),
  clearStation: () => dispatch(clearStation())
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withTranslation(), withRouter)(AddStation));