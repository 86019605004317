import React from "react";
import moment from "moment";
import {MONTH_BEFORE, WEEK_BEFORE, MONTHS, YEARS} from "../../util/time";
import {covertOrdersToStyledSheetData, getOrdersForExportToXlsx, loadOrders} from "./ChargingHistoryData";
import {exportToXLSX} from "../../util/xlsx";
import {useTranslation} from "react-i18next";

import {DownloadItem, IntervalContainer, IntervalItem} from "./StatisticsContainer.styled";
import LocationsFilter from "./LocationsFilter";

const WEEK_INTERVAL = {
  startDay: {id: WEEK_BEFORE.date(), name: WEEK_BEFORE.date()},
  startMonth: MONTHS[WEEK_BEFORE.month()],
  startYear: {id: WEEK_BEFORE.year(), name: WEEK_BEFORE.year()},

  endDay: {id: moment().date(), name: moment().date()},
  endMonth: MONTHS[moment().month()],
  endYear: YEARS[YEARS.length - 1],
};

export const MONTHS_INTERVAL = {
  startDay: {id: MONTH_BEFORE.date(), name: MONTH_BEFORE.date()},
  startMonth: MONTHS[MONTH_BEFORE.month()],
  startYear: {id: MONTH_BEFORE.year(), name: MONTH_BEFORE.year()},

  endDay: {id: moment().date(), name: moment().date()},
  endMonth: MONTHS[moment().month()],
  endYear: YEARS[YEARS.length - 1],
};

export default function StatisticsInterval({ onStaticsIntervalChange, onLocationsFilterChange, interval, getFromDate, getToDate, locationIds, authorization }) {
  const {t} = useTranslation();

  const setStatisticsInterval = ({interval}) => {
    let from;
    let dates;

    switch (true) {
      case (interval === 'week'): {
        from = moment().subtract(7, 'days').toISOString();
        dates = WEEK_INTERVAL;
        break;
      }
      case (interval === 'day'): {
        from = moment().subtract(1, 'days').toISOString();
        break;
      }
      case (interval === 'month'):
      default: {
        from = moment().subtract(30, 'days').toISOString();
        dates = MONTHS_INTERVAL;
        break;
      }
    }

    onStaticsIntervalChange({ interval, from, ...dates });
  }

  const onExportExcelClick = async () => {
    const fromDate = getFromDate();
    const toDate = getToDate();
    const fromDateFormatted = moment(fromDate).format( 'DD-MM-YY');
    const toDateFormatted = moment(toDate ).format('DD-MM-YY');
    const toStartOfNextDayDate = toDate.add(1, 'day').startOf('day');

    const orders = await loadOrders({
      from: fromDate.toISOString(),
      to: toStartOfNextDayDate.toISOString(),
      limit: 10000,
      locationIds: locationIds.join(','),
      authorization,
    });

    const csvData = getOrdersForExportToXlsx(orders);
    const sheetData = covertOrdersToStyledSheetData(csvData);

    exportToXLSX({ Sheets: { 'Статистика': sheetData }, fileName: `Статистика с ${fromDateFormatted} по ${toDateFormatted}` });
  }

  return (
    <IntervalContainer>
      {/* TODO <IntervalItem
              active={this.state.interval === 'day'}
              onClick={() => { setStatisticsInterval({interval: 'day'}); }}
            >
              {t('day')}
            </IntervalItem> */}
      <IntervalItem
        active={interval === 'week'}
        onClick={() => setStatisticsInterval({interval: 'week'})}
      >
        {t('week')}
      </IntervalItem>

      <IntervalItem
        active={interval === 'month'}
        onClick={() => setStatisticsInterval({interval: 'month'})}
      >
        {t('month')}
      </IntervalItem>

      <LocationsFilter
        onChange={onLocationsFilterChange}
      />

      <IntervalItem onClick={onExportExcelClick}>
        <DownloadItem>
          <span>{t('exportStatistic')}</span>
          <img src="/downloadIcon.svg" alt="download icon" height='15'/>
        </DownloadItem>
      </IntervalItem>
    </IntervalContainer>
  )
}