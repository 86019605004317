import styled from 'styled-components';

import * as colors from '../../../util/colors';

export const Wrapper = styled.div`
  display: block;
  width: 116px;
  position: absolute;
  top: calc(100% + 3px);
  right: 0;
  z-index: 2;
  overflow: hidden;
  border: 1px solid ${colors.LINE};
  border-radius: 5px;
  background-color: #fff;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  color: #222;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.LINE};
  }

  &:hover {
    background-color: ${colors.BG};
  }

  ${props => props.active ? (`
    background-color: #f3f4f6 !important;
  `) : null}
`;

export const Icon = styled.div`
  margin-right: 8px;
`;

export const Text = styled.div`
  font-size: 16px;
  margin-right: 4px;
`;

export const ActiveIcon = styled.div`
  padding: 0 1px;
  margin-left: 2px;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;