import {postJSON as post} from '../../api';
import {CHANGE_MONETIZE_START, CHANGE_MONETIZE_SUCCESS, CHANGE_MONETIZE_FAILURE} from './types';
import setServerTime from "./setServerTime";

const changeMonetizeStart = () => ({
  type: CHANGE_MONETIZE_START,
});

const changeMonetizeSuccess = (data) => ({
  type: CHANGE_MONETIZE_SUCCESS,
  data,
});

const changeMonetizeFailure = error => ({
  type: CHANGE_MONETIZE_FAILURE,
  error,
});

const changeMonetize = (authorization, stationId, data, callback) => {
  return dispatch => {
    dispatch(changeMonetizeStart());
    post(`stations/${stationId}/monetize`, {authorization}, data)
      .then(async response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(setServerTime(response.headers.get('Date')));
          return response.json();
        }
      })
      .then((data) => {
        dispatch(changeMonetizeSuccess(data));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(changeMonetizeFailure(error));
      });
  };
};

export default changeMonetize;
