import styled from 'styled-components';
import {Link as RouteLink} from 'react-router-dom';

import * as colors from '../../../util/colors';

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px auto 32px;
`;

export const LinkContainer = styled.div`
`;

export const Link = styled(RouteLink)`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  font-size: 16px;
  border-left: 4px solid ${props => (window.location.pathname.split('/')[1] === props.to.split('/')[1]) ? colors.BLUE_MAIN : 'transparent'};
  color: ${props => (window.location.pathname.split('/')[1] === props.to.split('/')[1]) ? colors.BLUE_MAIN : 'black'};
  background-color: ${props => (window.location.pathname.split('/')[1] === props.to.split('/')[1]) ? colors.BG : 'white'};
  cursor: pointer;
  &:hover {
    color: ${props => (window.location.pathname.split('/')[1] === props.to.split('/')[1]) ? colors.BLUE_MAIN : colors.TEXT_MINOR};
    text-decoration: none;
  }
`;