import React from 'react';
import { node, string, object } from 'prop-types';

import { Wrapper } from './Paper.styled';

const propTypes = {
  padding: string,
  children: node,
  other: object,
};

const Paper = props => {
  const {
    padding,
    children,
    ...other
  } = props;

  return (
    <Wrapper
      padding={padding}
      {...other}
    >
      {children}
    </Wrapper>
  )
};

Paper.propTypes = propTypes;

export default Paper;