import {getJSON as get} from '../../api';
import {
  GET_MANAGER_SUBSCRIPTION_START, GET_MANAGER_SUBSCRIPTION_SUCCESS,
  GET_MANAGER_SUBSCRIPTION_FAILURE,
} from './types';

const getManagerSubscriptionStart = () => ({
  type: GET_MANAGER_SUBSCRIPTION_START,
});

const getManagerSubscriptionSuccess = data => ({
  type: GET_MANAGER_SUBSCRIPTION_SUCCESS,
  data,
});

const getManagerSubscriptionFailure = error => ({
  type: GET_MANAGER_SUBSCRIPTION_FAILURE,
  error,
});

const getManagerSubscription = (authorization, managerId, callback) => {
  return dispatch => {
    dispatch(getManagerSubscriptionStart());
    get(`managers/${managerId}/subscription`, { authorization })
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getManagerSubscriptionSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getManagerSubscriptionFailure(error));
      });
  };
};

export default getManagerSubscription;
