import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import getLocation from '../../../redux/actions/getLocation';
import updateLocation from '../../../redux/actions/updateLocation';
import setLocationOpeningHours from '../../../redux/actions/setLocationOpeningHours';
import Modal from '../../common/Modal';
import LocationSettingsForm from './LocationSettingsForm';

const Settings = props => {
  if (!props.loggedIn) {
    props.history.push('/');
    return false;
  }

  return (
    <Modal width="75vw">
      <LocationSettingsForm
        authorization={props.authorization}
        role={props.role}
        locationId={props.locationId}
        data={props.data}
        loading={props.loading}
        error={props.error}
        setLocationOpeningHours={props.setLocationOpeningHours}
        updateLocation={props.updateLocation}
        getLocation={props.getLocation}
      />
    </Modal>
  );
};

const mapStateToProps = ({manager, location}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  role: manager.data?.role,
  authorization: `Bearer ${manager.accessToken}`,
  locationId: location.data && location.data.id,
  ...location,
});

const mapDispatchToProps = dispatch => ({
  setLocationOpeningHours: (authorization, locationId, data, callback) =>
    dispatch(setLocationOpeningHours(authorization, locationId, data, callback)),
  updateLocation: (authorization, locationId, data, callback) =>
    dispatch(updateLocation(authorization, locationId, data, callback)),
  getLocation: (locationId, callback) =>
    dispatch(getLocation(locationId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings));
