import React from 'react';
import {connect} from 'react-redux';
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {Title} from "../Location/LocationInfo.styled";

import Paper from "../common/Paper";
import moment from "moment";
import styled from "styled-components";
import * as colors from "../../util/colors";

const STATIC_SERVER_OFFSET = '3 hours';

function OrderHistory(props) {
    const { order, logs } = props;

    return (
        <>
            <Paper>
                <Title>Хронология</Title>
                <div>
                    {logs && logs[0] && logs[0].created_at && (<Date date={logs[0].created_at} />)}
                </div>
                <div>
                    {logs && logs
                        .map((log, i) => (
                            <LogRow key={i} log={log} order={order} dateStart={logs && logs[0] && logs[0].created_at} />
                        ))}
                </div>
            </Paper>
        </>
    );
}

const Date = ({ date }) => {
    const [relativeDate, fullDate] = getRelativeAndFullDatesWithServerOffset(date, {STATIC_SERVER_OFFSET});
    return (<div style={{ margin: '15px' }}>{fullDate} ({relativeDate})</div>);
};

const LogRow = ({ log, dateStart, order }) => {
    const kind = log.kind;
    const time = moment(log.created_at).add(STATIC_SERVER_OFFSET).format('HH:mm:ss.SSS');
    const relativeTime = moment(log.created_at).add(STATIC_SERVER_OFFSET).from(dateStart);
    return (<LogRowContainer>
        <LogKind kind={kind}>{kind}</LogKind>
        <LogTime>{time}</LogTime>
        <LogWrapper log={log} order={order} />
    </LogRowContainer>);

};

const LogWrapper = ({ log, order }) => {
    const kind = log.kind;
    if (kind === 'ocpp') { return <LogOcpp log={log} />}
    if (kind === 'payment') { return <LogPayment log={log} />}
    if (kind === 'order') { return <LogOrder log={log} order={order} />}
    if (kind === 'api') { return <LogApi log={log} />}
    if (kind === 'logs') { return <LogError log={log} />}
    return <div />;
}

const LogPayment = ({ log }) => {
    let event = log.event;
    if (event === 'holded_amount') { event = 'Захолдировано'; }
    if (event === 'succeeded_amount') { event = 'Оплачено'; }
    if (event === 'canceled_amount') { event = 'Отмена оплаты'; }
    if (event === 'refunded_amount') { event = 'Возврат оплаты'; }
    return (<>
        <LogDescription>{event}</LogDescription>
        <PreLog>{log.value/100} р.</PreLog>
        <span style={{color: '#CCC'}}>{event !== 'holded_amount' && log.holded_value ? ` (из ${log.holded_value/100}  р.)`: ''}</span>
    </>);
}

const LogOrder = ({ log, order }) => {
    let event = log.event;
    let isMinor = false;
    if (event === 'created_at') { event = 'Создан заказ'; }
    if (event === 'reservation_start') { event = 'Начало резервирования'; isMinor = true; }
    if (event === 'charging_start') { event = 'Зарядная сессия началась'; }
    if (event === 'countdown_finish') { event = 'Отсчет времени остановлен'; isMinor = true; }
    if (event === 'start_tx_status_at') { event = `Изменен статус операции startTx (${order.start_tx_status})`; isMinor = true; }
    if (event === 'transaction_start') { event = 'Транзакция начата'; isMinor = true; }
    if (event === 'ev_charging_start') { event = 'Заряд пошел (Charging)'; isMinor = true; }
    if (event === 'stop_tx_status_at') { event = `Изменен статус операции stopTx (${order.stop_tx_status})`; isMinor = true; }
    if (event === 'transaction_finish') { event = 'Транзакция завершена'; isMinor = true; }
    if (event === 'reservation_finish') { event = 'Окончание резервирования'; isMinor = true; }
    if (event === 'charging_finish') { event = 'Зарядка окончена'; }
    if (event === 'ev_disconnected_at') { event = 'Авто отключено'; }

    return (<LogDescription isMinor={isMinor}>{event}</LogDescription>);
}

const LogApi = ({ log }) => {
    const regexp = /v1(\/[A-Za-z0-9_]+)/g;
    const matches = [...log.request_url.matchAll(regexp)];
    let endpoint = matches && matches[0] && matches[0][1]
        ? `${matches[0][1]}${log.request_endpoint}`
        : log.request_endpoint;
    if (endpoint === '/orders/') {
        return (<>
            <LogDescription>Клиент создал заказ</LogDescription>
            <PreLog>{`${log.app_type}(${log.app_version})`}</PreLog>
            <LogCode code={log.response_code}>{log.response_code}</LogCode>
        </>);
    }
    if (endpoint === '/orders/:order_id') { endpoint = 'Клиент изменил заказ'; }
    if (endpoint === '/orders/:order_id/complete') { endpoint = 'Клиент отправил команду окончания зарядки'; }
    if (endpoint === '/orders/:order_id/cancel') { endpoint = 'Клиент отправил команду отмены зарядки'; }
    if (endpoint === '/users/:user_id/cloud-messaging-token') { endpoint = 'Обновлен токен для push сообщений'; }
    if (endpoint === '/locations/:location_id/feedback') {
        const post = JSON.parse(log.request_post);
        return (<>
            <LogDescription>Клиент поставил оценку</LogDescription>
            <PreLog>{post.rating}</PreLog>
            <PreLog>{post.comment !== '' ? ` (${post.comment})` : ''}</PreLog>
        </>);
    }
    return (<LogDescription>{endpoint} <LogCode code={log.response_code}>{log.response_code}</LogCode></LogDescription>);
}

const LogError = ({ log }) => {
    const maxLen = 250;
    let text = log.text.length > maxLen
        ? log.text.substr(0, 250) + '..'
        : log.text;
    return (<PreLog>{text}</PreLog>);
}

const LogOcpp = ({ log }) => {
    return (<LogOcppDesc log={log} />);
};

const LogOcppDesc = ({ log }) => {
    if (log.event === 'connector_status') {
        return (<LogOcppConnectorStatus log={log} />);
    }
    if (log.event === 'remote_start_tx_request') {
        return (<LogOcppRemoteStartTxReq log={log} />);
    }
    if (log.event === 'remote_start_tx_response') {
        return (<LogOcppRemoteStartTxRes log={log} />);
    }
    if (log.event === 'start_tx_request') {
        return (<LogOcppStartTxReq log={log} />);
    }
    if (log.event === 'start_tx_response') {
        return (<LogOcppStartTxRes log={log} />);
    }
    if (log.event === 'remote_stop_tx_request') {
        return (<LogOcppRemoteStopTxReq log={log} />);
    }
    if (log.event === 'remote_stop_tx_response') {
        return (<LogOcppRemoteStopTxRes log={log} />);
    }
    if (log.event === 'stop_tx_request') {
        return (<LogOcppStopTxReq log={log} />);
    }
    if (log.event === 'stop_tx_response') {
        return (<LogOcppStopTxRes log={log} />);
    }
    if (log.event === 'meter_values') {
        return (<LogOcppMeterValues log={log} />);
    }
    if (log.event === 'boot_notification') {
        return (<LogOcppBootNotification log={log} />);
    }

    return (<LogDescription>{log.event}</LogDescription>);
};

const LogOcppConnectorStatus = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Статус коннектора </LogDescription>
            <LogConnectorStatus status={log.status}>{log.status}</LogConnectorStatus>
        </LogDescriptionWrapper>);
};

const LogOcppRemoteStartTxReq = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Запрос старта транзакции </LogDescription>
        </LogDescriptionWrapper>);
};

const LogOcppRemoteStartTxRes = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Подтверждение готовности старта </LogDescription>
            <LogTxStatus status={log.status}>{log.status}</LogTxStatus>
        </LogDescriptionWrapper>);
};

const LogOcppStartTxReq = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Старт транзакции </LogDescription>
            <PreLog>Счетчик {log.meter_start}</PreLog>
        </LogDescriptionWrapper>);
};

const LogOcppStartTxRes = ({ log }) => {
    return (<LogDescriptionWrapper>
        <LogDescription>Старт транзакции (ответ сервера) </LogDescription>
        <PreLog>Tx {log.transaction_id}</PreLog>
    </LogDescriptionWrapper>);
};

const LogOcppRemoteStopTxReq = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Запрос завершения транзакции </LogDescription>
        </LogDescriptionWrapper>);
};

const LogOcppRemoteStopTxRes = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Подтверждение завершения транзакции </LogDescription>
            <LogTxStatus status={log.status}>{log.status}</LogTxStatus>
        </LogDescriptionWrapper>);
};

const LogOcppStopTxReq = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Завершение транзакции </LogDescription>
            <PreLog>Счетчик {log.meter_stop} (причина: {log.reason})</PreLog>
        </LogDescriptionWrapper>);
};

const LogOcppStopTxRes = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Завершение транзакции (ответ) </LogDescription>
        </LogDescriptionWrapper>);
};

const LogOcppMeterValues = ({ log }) => {
    return (<LogDescriptionWrapper>
            <LogDescription>Метрики </LogDescription>
            <MetersFormatted meters={log.meters} />
        </LogDescriptionWrapper>);
};

export const MetersFormatted = ({ meters }) => {
    const measurands = ['energy', 'power', 'soc', 'current', 'voltage'];
    const results = [];
    for (let measurand of measurands) {
        if (meters && meters[measurand] && meters[measurand].value && meters[measurand].unit) {
            let value = meters[measurand].value;
            let unit = meters[measurand].unit;

            if (measurand === 'energy' && unit === 'Wh') {
                unit = 'kWh';
                value = Math.round(value) / 1000;
            }
            if (measurand === 'power' && unit === 'W') {
                unit = 'kW';
                value = Math.round(value) / 1000;
            }
            if (measurand === 'soc') {
                unit = '%';
                value = Math.round(value * 10) / 10;
            }

            results.push(`${value} ${unit}`);
        }
    }

    return (<PreLog>{results.join(' / ')}</PreLog>);
};

const LogOcppBootNotification = ({ log }) => {
    return (<span>
            <LogDescription>Станция перезагружена</LogDescription>
        </span>);
};

const LogRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: nowrap;
    font-size: 15px;
`;

const LogTime = styled.div`
  display: inline-flex;
  padding-right: 10px;
  color: #AAA;
  font-size: 13px;
`;

const LogKind = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    min-width: 47px;
    height: 19px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 12px;
    color: #fff;
    padding: 1px;
    margin: 5px 16px 5px 24px;
    background-color: ${props => kindColorByValue(props.kind)};
`;

const LogDescriptionWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const LogDescription = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${props => (props.isMinor ? '#CCC' : 'inherit')};
`;

export const PreLog = styled.div`
    display: inline-flex;
    font-family: monospace;
    font-size: 12px;
    background-color: #F1F1F1;
    padding: 1px 5px;
    margin: 0 5px;
    align-items: center;
`;


const kindColorByValue = kind => {
    switch (kind) {
        case 'payment':
            return '#9c27b0';
        case 'api':
            return '#2e7d32';
        case 'ocpp':
            return '#bdbdbd';
        case 'order':
            return '#1976d2';
        case 'logs':
            return '#757575';
        default:
            return '#bc146b';
    }
};

const LogCode = styled.div`
    margin-left: 4px;
    font-size: 13px;
    display: ${props => (props.code >= 400 ? 'inline' : 'none')};
    color: ${props => (props.code >= 400 ? colors.ERROR : colors.ICON_GREEN)};
`;

const LogTxStatus = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
    font-weight: bold;
    border-radius: 12px;
    color: #fff;
    padding: 5px;
    height: 20px;
    font-size: 13px;
    margin: 5px 5px 5px 8px;
    background-color: ${props => TxStatusColorByValue(props.status)};
`;

const TxStatusColorByValue = status => {
    switch (status) {
        case 'Invalid':
        case 'Rejected':
            return colors.ERROR;
        case 'Accepted':
            return colors.ICON_GREEN;
        default:
            return colors.ERROR;
    }
};

const LogConnectorStatus = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
    font-weight: bold;
    border-radius: 12px;
    color: #fff;
    padding: 5px;
    height: 20px;
    font-size: 13px;
    margin: 5px 5px 5px 8px;
    background-color: ${props => ConnectorStatusColorByValue(props.status)};
`;

const ConnectorStatusColorByValue = status => {
    switch (status) {
        case 'Available':
            return colors.ICON_GREEN;
        case 'Preparing':
            return colors.ICON_ORANGE;
        case 'Occupied':
        case 'Charging':
        case 'SuspendedEVSE':
        case 'SuspendedEV':
        case 'Finishing':
        case 'Reserved':
            return colors.ICON_BLUE;
        case 'Faulted':
            return colors.ERROR;
        case 'Unavailable':
        default:
            return 'gray';
    }
};

const mapStateToProps = ({manager}, {match}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
});


export default compose(
    connect(mapStateToProps),
    withTranslation()
)(OrderHistory);
