import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import Button from '../../common/Button';

class PlanContainer extends Component {
  clickUpgradeButton = () => {
    const {data: {default_payment_method_id, account_id}, paymentAccount} = this.props;

    if (!default_payment_method_id) {
      this.props.history.push('/settings/payments/method');
    } else if (!account_id) {
      this.props.history.push('/settings/payments/account');
    } else if (!paymentAccount.bank_account || !paymentAccount.bank_account.bank_name) {
      this.props.history.push('/settings/payments/bank');
    } else {
      this.props.history.push('/settings/payments/subscription');
    }
  }

  render() {
    const {
      t,
      subscription,
      data: {
        default_payment_method_id, account_id, subscription_id,
      },
      paymentAccount,
    } = this.props;

    const planFee = parseFloat((subscription.fee / 100).toFixed(2)); // Original value is in cents.
    const currentPeriodEnd = subscription && subscription.current_period_end;

    const subscriptionFrequency = Object.keys(subscription).length && subscription.frequency.toLowerCase();

    return (
      <Container>
        <h3 className="title">{t('myPlan')}</h3>
        <CurrentPlan>
          <div className="image-container">
            <img
              src={subscription_id ? '/proPlan.svg' : '/freePlan.svg'}
              alt={subscription_id ? 'Pro plan' : 'free plan'}
              className="plan"
            />
          </div>
          <div className="info-container">
            <h3 className="title">{subscription_id ? 'Pro' : 'Free'}</h3>
            <div className="fee">
              <span className="amount">
                {planFee ? planFee : 0}₽
              </span>
                /{t((subscriptionFrequency === 'monthly') ? 'month' : 'year')} {t('per')} <span className='amount'>{subscription.quantity}</span> {t('stationMyPlan')}
            </div>
            <div className={!subscription_id ? 'monetization-free' : 'monetization'}>
              {!subscription_id ? t('noMonetization') : `${t('nextPayment')}: ${moment(currentPeriodEnd).format('DD MMMM YYYY')}`}
            </div>
          </div>
          <UpgradeButton
            onClick={this.clickUpgradeButton}
            style={subscription_id ? {visibility: 'hidden'} : {}}
          >{t('upgradeToPro')}</UpgradeButton>
        </CurrentPlan>
        {!subscription_id && <UpgradeInstructions>
          <img src="/infoIcon.svg" alt="info icon" className="info-icon"/>
          <span>{t('upgradeTo')} <span className="link"><a href="/#greeting">Pro</a></span>{' '}
            {t('toMonetize')}</span>

          <div className="instruction-list">
            <img src="/greenCheck.svg" alt="check icon" className="check-icon"
              style={default_payment_method_id ? {} : {visibility: 'hidden'}}
            />
            <span className="instruction-item">1. {t('addPaymentMethod')}</span>

            <br/>

            <img src="/greenCheck.svg" alt="check icon" className="check-icon"
              style={account_id ? {} : {visibility: 'hidden'}}
            />
            <span className="instruction-item">2. {t('addBusinessInfo')}</span>

            <br/>

            <img src="/greenCheck.svg" alt="check icon" className="check-icon"
              style={(paymentAccount.bank_account && paymentAccount.bank_account.bank_name) ? {} : {visibility: 'hidden'}}
            />
            <span className="instruction-item">3. {t('addBankInfo')}</span>

            <br/>

            <img src="/greenCheck.svg" alt="check icon" className="check-icon"
              style={subscription_id ? {} : {visibility: 'hidden'}}
            />
            <span className="instruction-item">4. {t('configureSubscription')}</span>
          </div>
        </UpgradeInstructions>}
      </Container>
    );
  }
}

const Container = styled.div`
  display: block;
  max-width: 740px;
  margin: 0 auto;
  padding: 24px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px ${colors.LINE};
  & > h3.title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: bold;
  }
`;

const CurrentPlan = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  min-width: 100%; max-width: 100%;
  margin: 0 auto;
  & > div.image-container {
    display: inline-block;
    min-width: 125px; max-width: 125px;
    margin-right: 14px;
    & > img.plan {
      width: 100%;
      height: 100%;
      display: inline-block;
      margin: auto;
      vertical-align: middle;
    }
  }
  & > div.info-container {
    display: inline-block;
    padding-top: 18px;
    & > h3.title {
      font-size: 18px;
      font-weight: bold;
    }
    & > div.fee > span.amount {
      font-weight: bold;
      color: ${colors.BLUE_MAIN};
    }
    & > div.monetization-free {
      margin: 6px 0;
      color: ${colors.ICON_GRAY};
    }
    & > div.monetization {
      margin: 6px 0;
    }
  }
`;

const UpgradeInstructions = styled.div`
  min-width: 100%; max-width: 100%;
  margin: 0 auto;
  margin-top: 24px;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 5px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: #f0f6fa;
  & > img.info-icon {
    display: inline;
    margin-right: 12px;
  }
  & > span.link {
    cursor: pointer;
    color: ${colors.TEXT_BLUE};
  }
  & > div.instruction-list {
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 13px;
    & > img.check-icon {
      display: inline;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
`;

const UpgradeButton = styled(Button)`
  position: absolute; top: 0; right: 0;
  min-width: auto; max-width: auto;
  margin: 0;
  font-size: 14px;
  background-color: #E1EEFF;
  color: #2A79EE;
  &:hover {
    cursor: pointer;
  }
  border: 0;
  border-radius: 4px;
`;

export default compose(withTranslation(), withRouter)(PlanContainer);
