import {postJSON as post} from '../../api';
import {ADD_LOCATION_STATION_START, ADD_LOCATION_STATION_SUCCESS, ADD_LOCATION_STATION_FAILURE} from './types';

const addStationStart = () => ({
  type: ADD_LOCATION_STATION_START,
});

const addStationSuccess = () => ({
  type: ADD_LOCATION_STATION_SUCCESS,
});

const addStationFailure = error => ({
  type: ADD_LOCATION_STATION_FAILURE,
  error,
});

const addStation = (authorization, locationId, body, callback) => {
  return dispatch => {
    dispatch(addStationStart());
    post(`locations/${locationId}/stations`, {authorization}, body)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(addStationSuccess());
          if (callback) { callback(); }
        } else if ((response.status >= 400) && (response.status < 500)) {
          return response.json();
        } else {
          dispatch(addStationFailure(response));
        }
      })
      .then(data => {
        if (data && data.error) {
          dispatch(addStationFailure(data));
        }
      })
      .catch(error => {
        dispatch(addStationFailure(error));
      });
  };
};

export default addStation;
