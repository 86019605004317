import {postJSON as post} from '../../api';
import {
  CREATE_MANAGER_SUBSCRIPTION_START, CREATE_MANAGER_SUBSCRIPTION_SUCCESS,
  CREATE_MANAGER_SUBSCRIPTION_FAILURE,
} from './types';

const createManagerSubscriptionStart = () => ({
  type: CREATE_MANAGER_SUBSCRIPTION_START,
});

const createManagerSubscriptionSuccess = () => ({
  type: CREATE_MANAGER_SUBSCRIPTION_SUCCESS,
});

const createManagerSubscriptionFailure = error => ({
  type: CREATE_MANAGER_SUBSCRIPTION_FAILURE,
  error,
});

const createManagerSubscription = (authorization, managerId, data, callback) => {
  return dispatch => {
    dispatch(createManagerSubscriptionStart());
    post(`managers/${managerId}/subscription`, {authorization}, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(createManagerSubscriptionSuccess());
          if (callback) { callback(); }
        } else {
          dispatch(createManagerSubscriptionFailure(response));
        }
      })
      .catch(error => {
        dispatch(createManagerSubscriptionFailure(error));
      });
  };
};

export default createManagerSubscription;
