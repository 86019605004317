import React from 'react';
import {compose} from "redux";
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import ReactTooltip from 'react-tooltip';
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import * as colors from '../../util/colors';

import {Tr, Td} from './../ChargePoints/StationsTable.styled';
import styled from "styled-components";

const AntiSanctionProtectionRow = ({t, history, ...props}) => {
    const {
        android_v,
        ios_v,
        manager_first_name,
        manager_last_name,
        manager_email,
        created_at,
        serverTimeOffset,
    } = props;

    const manager_name = [manager_first_name, manager_last_name];
    const manager = manager_name.join('') ? manager_name.join(' ') : manager_email;

    const [createdAtRelativeDate, createdAtFullDate] = getRelativeAndFullDatesWithServerOffset(created_at, serverTimeOffset);

    return (
        <>
            <Tr>
                <Td>
                    {android_v}
                </Td>

                <Td>
                    {ios_v}
                </Td>

                <Td data-tip={createdAtFullDate} data-place="left">
                    <ReactTooltip effect='solid' />
                    {createdAtRelativeDate}
                </Td>

                <Td>
                    {manager}
                </Td>
            </Tr>
        </>
    );
};

const Wifi = ({ signal }) => {
  if (!signal) {
    return null;
  }

  let color;

  if (signal <= 12) color = colors.ERROR;
  if (signal >= 13 && signal <= 20) color = colors.ORANGE_MAIN;
  if (signal > 20) color = colors.ICON_GREEN;

  return (
    <>
      <i className="fa fa-wifi" style={{ color }}/>
    </>
  );
}

const Warning = ({data_transfer}) => {
  let error;

  if (data_transfer?.autoenterprise?.miscInfo?.tamper === 'open') {
    error = 'Tamper open!';
  }

  if (!error) {
    return null;
  }

  return (
    <i
      className="fas fa-exclamation-circle"
      style={{ color: colors.ERROR }}
      data-tip={error}
    />
  )
}

export const OptionsContainer = styled.div`
  position: relative;
  min-height: 24px;
`;

export default compose(withTranslation(), withRouter)(AntiSanctionProtectionRow);
