import {getJSON as get} from '../../api';
import {GET_ORDER_DETAILS_START, GET_ORDER_DETAILS_SUCCESS, GET_ORDER_DETAILS_FAILURE} from './types';
import setServerTime from "./setServerTime";

const getOrderDetailsStart = () => ({
  type: GET_ORDER_DETAILS_START,
});

const getOrderDetailsSuccess = (data) => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  data,
});

const getOrderDetailsFailure = error => ({
  type: GET_ORDER_DETAILS_FAILURE,
  error,
});

const getOrderDetails = (authorization, orderId, callback) => {
  return dispatch => {
    dispatch(getOrderDetailsStart());
    get(`orders/${orderId}/details`, {authorization})
      .then(async response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(setServerTime(response.headers.get('Date')));
          return response.json();
        }
      })
      .then((data) => {
        dispatch(getOrderDetailsSuccess(data));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(getOrderDetailsFailure(error));
      });
  };
};

export default getOrderDetails;
