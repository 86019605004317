import React from 'react';
import styled from "styled-components";
import * as colors from "../../../util/colors";

import Evse from './Evse';
import {compose} from "redux";
import {connect} from "react-redux";

const Evses = ({evses, noTitle, ...props}) => (
    <>
        {!noTitle && (
            <EvseStatusContainer>
                <EvseStatusNumber>
                    EVSE <span className="number">({evses.length})</span>
                    {/* <span className="question">
                      <i className="fas fa-question"></i>
                    </span> */}
                </EvseStatusNumber>
            </EvseStatusContainer>
        )}
        <EvseContainer expandable={props.full}>
            {evses.map((evse, i) =>
                <Evse key={i} {...evse} {...props} />
            )}
        </EvseContainer>
    </>
);

const EvseStatusContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  border-bottom: 1px solid ${colors.LINE};
`;

const EvseStatus = styled.div`
  position: relative;
  font-size: calc(8px + 0.3vw);
  margin-right: 16px;
  color: ${colors.TEXT_INPUT};
`;

const EvseStatusNumber = styled(EvseStatus)`
  padding-right: 3px;
  font-weight: bold;
  color: black;
  margin-right: 24px;
  & > span.number {
    font-weight: normal;
    color: ${colors.TEXT_INPUT};
  }
  & > span.question {
    position: absolute; top: 0; right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 14px; max-width: 14px;
    min-height: 14px; max-height: 14px;
    font-size: 12px;
    font-weight: normal;
    border-radius: 50%;
    color: white; background-color: ${colors.TEXT_INPUT};
  }
`;

const EvseContainer = styled.div`
  ${props => !props.expandable && `
    overflow-y: auto;
    max-height: 165px;
    min-height: 165px;
  `}
`;

const mapStateToProps = ({manager}, {match}) => ({
    isAdministrator: manager.data?.role === 'administrator',
});

export default compose(
    connect(mapStateToProps),
)(Evses);

