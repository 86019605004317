/* global BlueSnap */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import createPaymentMethod from '../../../redux/actions/createPaymentMethod';
import {getJSON as get} from '../../../api';
import LoadingSpinner from '../../common/LoadingSpinner';
import Form, {Title, Text, TextField} from '../../common/Form';
import Button from '../../common/Button';
import {TopBarBlock} from '../../common/MainBlocks';
import TopbarBackButton from '../../common/TopbarBackButton';

const FORM_ID = 'bluesnap';
const BS_CREDIT_CARD_KEY = 'encryptedCreditCard';
const BS_CVV_KEY = 'encryptedCvv';

class PaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        card_number: {value: '', valid: undefined},
        expiration_date: {value: '', valid: undefined},
        security_code: {value: '', valid: undefined},
      }
    };
  }

  componentWillMount = () => {
    get('help/config')
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(config => {
        this.bluesnap = new BlueSnap(config.BLUESNAP_PUBLIC_KEY, true);
      });
  }

  fieldIsValid = (name, value) => {
    if (name === 'card_number') {
      return ((value.length >= 15) && (value.length <= 17) && !isNaN(value));
    }

    if (name === 'expiration_date') {
      return ((value.length === 5) && (value[2] === '/'));
    }

    if (name === 'security_code') {
      return ((value.length >= 3) && (value.length <= 4) && !isNaN(value));
    }

    return false;
  }

  handleChange = event => {
    event.preventDefault();

    const {name, value} = event.target;
    const data = {...this.state.data};

    data[name].value = value;
    data[name].valid = this.fieldIsValid(name, value);

    this.setState({data});
  }

  createHiddenInput = (bluesnapName, value) => {
    const $input = document.createElement('input');

    $input.setAttribute('type', 'hidden');
    $input.setAttribute('data-bluesnap', bluesnapName);
    $input.value = value;

    return $input;
  }

  createForm = (params) => {
    const $form = document.createElement('form');
    const $creditCardNumber = this.createHiddenInput(BS_CREDIT_CARD_KEY, params.creditCardNumber);
    const $cvvNumber = this.createHiddenInput(BS_CVV_KEY, params.cvvNumber);

    $form.id = FORM_ID;

    $form.appendChild($creditCardNumber);
    $form.appendChild($cvvNumber);

    document.body.appendChild($form);
  }

  encrypt = (params) => {
    this.createForm(params);

    this.bluesnap.encrypt(FORM_ID);

    const encryptedCreditCard = document.querySelector("input[name='" + BS_CREDIT_CARD_KEY + "']").value;
    const encryptedCvv = document.querySelector("input[name='" + BS_CVV_KEY + "']").value;

    const $form = document.querySelector('#' + FORM_ID);

    $form.parentNode.removeChild($form);

    return {
      encryptedCreditCard,
      encryptedCvv,
    };
  }

  handleSubmit = event => {
    event.preventDefault();

    const {card_number, security_code, expiration_date} = this.state.data;

    // Encrypt fileds.

    const encrypted = this.encrypt({creditCardNumber: card_number.value, cvvNumber: security_code.value});

    const [expiration_month, expiration_year] = expiration_date.value.split('/');

    const body = {
      credit_card: {
        expiration_year: ['20', expiration_year].join(''),
        expiration_month,
        encrypted_card_number: encrypted.encryptedCreditCard,
        encrypted_security_code: encrypted.encryptedCvv,
      }
    };

    this.props.createPaymentMethod(this.props.authorization, body, () => this.props.history.push('/settings/payments/account'));
  }

  render() {
    const {t, loading} = this.props;
    const {card_number, expiration_date, security_code} = this.state.data;

    if (loading) {
      return (
        <>
          <TopBarBlock fixed white>
            <TopbarBackButton
              to="/settings/payments"
              label={t('payments')}
            />
          </TopBarBlock>
          <LoadingSpinner />
        </>
      );
    }

    return (
      <>
        <TopBarBlock fixed white>
          <TopbarBackButton
            to="/settings/payments"
            label={t('payments')}
          />
        </TopBarBlock>
        <Container>
          <img src="/paymentsProgressBar/progressBar0.svg" alt="progress-bar" className="progress-bar-icon" />
          <Form id='checkout-form' onSubmit={this.handleSubmit}>
            <Title size="24px">{t('paymentMethod')}</Title>
            <Text margin="16px 0">{t('addPaymentMethodTo')}</Text>
            <InputContainer>
              <TextField
                name='card_number'
                label={t('cardNumber')}
                value={card_number.value}
                valid={card_number.valid}
                handleChange={this.handleChange}
              />
              <Row>
                <TextField
                  name='expiration_date'
                  label={t('expirationDate')}
                  value={expiration_date.value}
                  valid={expiration_date.valid}
                  handleChange={this.handleChange}
                  width="182px"
                  placeholder='MM/YY'
                />
                <TextField
                  name='security_code'
                  label={t('securityCode')}
                  value={security_code.value}
                  valid={security_code.valid}
                  handleChange={this.handleChange}
                  width="182px"
                />
              </Row>
            </InputContainer>
            <Info>
              <img src="/infoIcon.svg" alt="info icon" className="info-icon"/>
              <Text color="#818286" size="14px">{t('deDoesNotStoreCardInfo')}</Text>
            </Info>
            <ButtonContainer>
              <div className="button-container">
                <Button
                  /* disabled={!allFieldsValid} */
                  type='submit'
                  margin='0'
                  width='auto'
                >
                  {t('addPaymentMethod')}
                </Button>
                {/* error && error.message && <div className="error-alert"><ErrorAlert>{error.message}</ErrorAlert></div> */}
              </div>
            </ButtonContainer>
          </Form>
        </Container>
      </>
    );
  }
};

const Container = styled.div`
  position: relative;
  // min-width: 582px;
  max-width: 582px;
  margin: 0 auto;
  margin-bottom: 32px;
  padding: 24px 100px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px ${colors.LINE};
  & > h3.title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: bold;
  }
  & > img.progress-bar-icon {
    display: block;
    margin: 0 auto 24px;
  }
  @media screen and (max-width: 940px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; min-width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; min-width: 100%;
  & > div:last-child {
    margin-top: 16px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; min-width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  & > span {
    padding-bottom: 5px;
  }
  & > img {
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  & > div.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%; max-width: 100%;
    position: relative;
    & > div.error-alert {
      margin-left: 9px;
    }
  }
`;

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && Object.values(manager.data).length > 0,
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  ...manager,
});

const mapDispatchToProps = dispatch => ({
  createPaymentMethod: (authorization, data, callback) =>
    dispatch(createPaymentMethod(authorization, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withTranslation(), withRouter)(PaymentMethod));
