// app
export const SET_SERVER_TIME_OFFSET = 'SET_SERVER_TIME_OFFSET';

// manager
export const SIGN_UP_MANAGER_START = 'SIGN_UP_MANAGER_START';
export const SIGN_UP_MANAGER_SUCCESS = 'SIGN_UP_MANAGER_SUCCESS';
export const SIGN_UP_MANAGER_FAILURE = 'SIGN_UP_MANAGER_FAILURE';

export const LOG_IN_MANAGER_START = 'LOG_IN_MANAGER_START';
export const LOG_IN_MANAGER_SUCCESS = 'LOG_IN_MANAGER_SUCCESS';
export const LOG_IN_MANAGER_FAILURE = 'LOG_IN_MANAGER_FAILURE';

export const GET_CURRENT_MANAGER_START = 'GET_CURRENT_MANAGER_START';
export const GET_CURRENT_MANAGER_SUCCESS = 'GET_CURRENT_MANAGER_SUCCESS';
export const GET_CURRENT_MANAGER_FAILURE = 'GET_CURRENT_MANAGER_FAILURE';

export const GET_MANAGER_ACCESS_TOKEN_START = 'GET_MANAGER_ACCESS_TOKEN_START';
export const GET_MANAGER_ACCESS_TOKEN_SUCCESS = 'GET_MANAGER_ACCESS_TOKEN_SUCCESS';
export const GET_MANAGER_ACCESS_TOKEN_FAILURE = 'GET_MANAGER_ACCESS_TOKEN_FAILURE';

export const EDIT_MANAGER_START = 'EDIT_MANAGER_START';
export const EDIT_MANAGER_SUCCESS = 'EDIT_MANAGER_SUCCESS';
export const EDIT_MANAGER_FAILURE = 'EDIT_MANAGER_FAILURE';

export const SET_MANAGER_SETTINGS_START = 'SET_MANAGER_SETTINGS_START';
export const SET_MANAGER_SETTINGS_SUCCESS = 'SET_MANAGER_SETTINGS_SUCCESS';
export const SET_MANAGER_SETTINGS_FAILURE = 'SET_MANAGER_SETTINGS_FAILURE';

export const SET_MANAGER_PASSWORD_START = 'SET_MANAGER_PASSWORD_START';
export const SET_MANAGER_PASSWORD_SUCCESS = 'SET_MANAGER_PASSWORD_SUCCESS';
export const SET_MANAGER_PASSWORD_FAILURE = 'SET_MANAGER_PASSWORD_FAILURE';

export const SET_MANAGER_AVATAR_START = 'SET_MANAGER_AVATAR_START';
export const SET_MANAGER_AVATAR_SUCCESS = 'SET_MANAGER_AVATAR_SUCCESS';
export const SET_MANAGER_AVATAR_FAILURE = 'SET_MANAGER_AVATAR_FAILURE';

export const CREATE_PAYMENT_ACCOUNT_START = 'CREATE_PAYMENT_ACCOUNT_START';
export const CREATE_PAYMENT_ACCOUNT_SUCCESS = 'CREATE_PAYMENT_ACCOUNT_SUCCESS';
export const CREATE_PAYMENT_ACCOUNT_FAILURE = 'CREATE_PAYMENT_ACCOUNT_FAILURE';

export const GET_PAYMENT_ACCOUNT_START = 'GET_PAYMENT_ACCOUNT_START';
export const GET_PAYMENT_ACCOUNT_SUCCESS = 'GET_PAYMENT_ACCOUNT_SUCCESS';
export const GET_PAYMENT_ACCOUNT_FAILURE = 'GET_PAYMENT_ACCOUNT_FAILURE';

export const CREATE_PAYMENT_METHOD_START = 'CREATE_PAYMENT_METHOD_START';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_FAILURE = 'CREATE_PAYMENT_METHOD_FAILURE';

export const GET_PAYMENT_METHOD_START = 'GET_PAYMENT_METHOD_START';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';

export const CREATE_MANAGER_SUBSCRIPTION_START = 'CREATE_MANAGER_SUBSCRIPTION_START';
export const CREATE_MANAGER_SUBSCRIPTION_SUCCESS = 'CREATE_MANAGER_SUBSCRIPTION_SUCCESS';
export const CREATE_MANAGER_SUBSCRIPTION_FAILURE = 'CREATE_MANAGER_SUBSCRIPTION_FAILURE';

export const GET_MANAGER_SUBSCRIPTION_START = 'GET_MANAGER_SUBSCRIPTION_START';
export const GET_MANAGER_SUBSCRIPTION_SUCCESS = 'GET_MANAGER_SUBSCRIPTION_SUCCESS';
export const GET_MANAGER_SUBSCRIPTION_FAILURE = 'GET_MANAGER_SUBSCRIPTION_FAILURE';

export const GET_MANAGER_BALANCE_START = 'GET_MANAGER_BALANCE_START';
export const GET_MANAGER_BALANCE_SUCCESS = 'GET_MANAGER_BALANCE_SUCCESS';
export const GET_MANAGER_BALANCE_FAILURE = 'GET_MANAGER_BALANCE_FAILURE';

export const LOG_OUT_MANAGER = 'LOG_OUT_MANAGER';

// map
export const SET_MAP_POSITION = 'SET_MAP_POSITION';
export const SET_MAP_ADDRESS = 'SET_MAP_ADDRESS';

// locations
export const ADD_LOCATION_START = 'ADD_LOCATION_START';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAILURE = 'ADD_LOCATION_FAILURE';

export const GET_LOCATIONS_START = 'GET_LOCATIONS_START';
export const GET_LOCATIONS_START_MORE = 'GET_LOCATIONS_START_MORE';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';

export const GET_LOCATION_START = 'GET_LOCATION_START';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';

export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

export const SET_LOCATION_OPENING_HOURS_START = 'SET_LOCATION_OPENING_HOURS_START';
export const SET_LOCATION_OPENING_HOURS_SUCCESS = 'SET_LOCATION_OPENING_HOURS_SUCCESS';
export const SET_LOCATION_OPENING_HOURS_FAILURE = 'SET_LOCATION_OPENING_HOURS_FAILURE';

export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';

// location stations
export const ADD_LOCATION_STATION_START = 'ADD_LOCATION_STATION_START';
export const ADD_LOCATION_STATION_SUCCESS = 'ADD_LOCATION_STATION_SUCCESS';
export const ADD_LOCATION_STATION_FAILURE = 'ADD_LOCATION_STATION_FAILURE';

export const GET_LOCATION_STATIONS_START = 'GET_LOCATION_STATIONS_START';
export const GET_LOCATION_STATIONS_START_MORE = 'GET_LOCATION_STATIONS_START_MORE';
export const GET_LOCATION_STATIONS_SUCCESS = 'GET_LOCATION_STATIONS_SUCCESS';
export const GET_LOCATION_STATIONS_FAILURE = 'GET_LOCATION_STATIONS_FAILURE';

export const DELETE_LOCATION_STATION_START = 'DELETE_LOCATION_STATION_START';
export const DELETE_LOCATION_STATION_SUCCESS = 'DELETE_LOCATION_STATION_SUCCESS';
export const DELETE_LOCATION_STATION_FAILURE = 'DELETE_LOCATION_STATION_FAILURE';

// station
export const GET_STATION_START = 'GET_STATION_START';
export const GET_STATION_SUCCESS = 'GET_STATION_SUCCESS';
export const GET_STATION_FAILURE = 'GET_STATION_FAILURE';

export const CHANGE_STATION_STATUS_START = 'CHANGE_STATION_STATUS_START';
export const CHANGE_STATION_STATUS_SUCCESS = 'CHANGE_STATION_STATUS_SUCCESS';
export const CHANGE_STATION_STATUS_FAILURE = 'CHANGE_STATION_STATUS_FAILURE';

export const CHANGE_MONETIZE_START = 'CHANGE_MONETIZE_START';
export const CHANGE_MONETIZE_SUCCESS = 'CHANGE_MONETIZE_SUCCESS';
export const CHANGE_MONETIZE_FAILURE = 'CHANGE_MONETIZE_FAILURE';

export const UPDATE_STATION_START = 'UPDATE_STATION_START';
export const UPDATE_STATION_SUCCESS = 'UPDATE_STATION_SUCCESS';
export const UPDATE_STATION_FAILURE = 'UPDATE_STATION_FAILURE';

// station
export const GET_ORDER_DETAILS_START = 'GET_ORDER_DETAILS_START';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILURE = 'GET_ORDER_DETAILS_FAILURE';

// location services
export const ADD_LOCATION_SERVICE_START = 'ADD_LOCATION_SERVICE_START';
export const ADD_LOCATION_SERVICE_SUCCESS = 'ADD_LOCATION_SERVICE_SUCCESS';
export const ADD_LOCATION_SERVICE_FAILURE = 'ADD_LOCATION_SERVICE_FAILURE';

export const GET_LOCATION_SERVICES_START = 'GET_LOCATION_SERVICES_START';
export const GET_LOCATION_SERVICES_SUCCESS = 'GET_LOCATION_SERVICES_SUCCESS';
export const GET_LOCATION_SERVICES_FAILURE = 'GET_LOCATION_SERVICES_FAILURE';

export const GET_LOCATION_FEEDBACK_START = 'GET_LOCATION_FEEDBACK_START';
export const GET_LOCATION_FEEDBACK_SUCCESS = 'GET_LOCATION_FEEDBACK_SUCCESS';
export const GET_LOCATION_FEEDBACK_FAILURE = 'GET_LOCATION_FEEDBACK_FAILURE';

export const EDIT_LOCATION_SERVICE_START = 'EDIT_LOCATION_SERVICE_START';
export const EDIT_LOCATION_SERVICE_SUCCESS = 'EDIT_LOCATION_SERVICE_SUCCESS';
export const EDIT_LOCATION_SERVICE_FAILURE = 'EDIT_LOCATION_SERVICE_FAILURE';

export const DELETE_LOCATION_SERVICE_START = 'DELETE_LOCATION_SERVICE_START';
export const DELETE_LOCATION_SERVICE_SUCCESS = 'DELETE_LOCATION_SERVICE_SUCCESS';
export const DELETE_LOCATION_SERVICE_FAILURE = 'DELETE_LOCATION_SERVICE_FAILURE';

export const GET_SERVICE_TYPES_START = 'GET_SERVICE_TYPES_START';
export const GET_SERVICE_TYPES_SUCCESS = 'GET_SERVICE_TYPES_SUCCESS';
export const GET_SERVICE_TYPES_FAILURE = 'GET_SERVICE_TYPES_FAILURE';

// location photos
export const ADD_LOCATION_PHOTO_START = 'ADD_LOCATION_PHOTO_START';
export const ADD_LOCATION_PHOTO_SUCCESS = 'ADD_LOCATION_PHOTO_SUCCESS';
export const ADD_LOCATION_PHOTO_FAILURE = 'ADD_LOCATION_PHOTO_FAILURE';

export const GET_LOCATION_PHOTOS_START = 'GET_LOCATION_PHOTOS_START';
export const GET_LOCATION_PHOTOS_SUCCESS = 'GET_LOCATION_PHOTOS_SUCCESS';
export const GET_LOCATION_PHOTOS_FAILURE = 'GET_LOCATION_PHOTOS_FAILURE';

export const DELETE_LOCATION_PHOTO_START = 'DELETE_LOCATION_PHOTO_START';
export const DELETE_LOCATION_PHOTO_SUCCESS = 'DELETE_LOCATION_PHOTO_SUCCESS';
export const DELETE_LOCATION_PHOTO_FAILURE = 'DELETE_LOCATION_PHOTO_FAILURE';

// stations
export const GET_STATIONS_START = 'GET_STATIONS_START';
export const GET_STATIONS_SUCCESS = 'GET_STATIONS_SUCCESS';
export const GET_STATIONS_FAILURE = 'GET_STATIONS_FAILURE';

// orders
export const GET_ORDERS_START = 'GET_ORDERS_START';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

// user
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

// connector
export const GET_CONNECTOR_START = 'GET_CONNECTOR_START';
export const GET_CONNECTOR_SUCCESS = 'GET_CONNECTOR_SUCCESS';
export const GET_CONNECTOR_FAILURE = 'GET_CONNECTOR_FAILURE';

// statistics
export const GET_STATISTICS_START = 'GET_STATISTICS_START';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_FAILURE = 'GET_STATISTICS_FAILURE';

// plans
export const GET_PLANS_START = 'GET_PLANS_START';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE';

export const CLEAR_LOCATION_ERRORS = 'CLEAR_LOCATION_ERRORS';

export const CLEAR_STATION_REQUEST = 'CLEAR_STATION_REQUEST';

export const CLEAR_ORDER_DETAILS_REQUEST = 'CLEAR_STATION_REQUEST';
