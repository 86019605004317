import {LineChart} from "react-chartkick";
import * as colors from "../../util/colors";
import {ChartContainer} from "./StatisticsContainer.styled";
import React from "react";

export default function StatisticsChart({data, type}) {
  const maxValue = Math.max(...Object.values(data));

  return (
    <ChartContainer>
      <LineChart
        data={data}
        colors={[colors.ICON_BLUE]}
        dataset={{ borderWidth: 4, pointRadius: 6, pointBackgroundColor: 'white', pointHoverRadius: 10 }}
        messages={{ empty: 'No data' }}
        prefix={['charging_revenue'].includes(type) ? '₽' : ''}
        max={Math.ceil(maxValue * 1.1)}
      />
    </ChartContainer>
  )
}