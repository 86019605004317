import {GET_USER_START, GET_USER_SUCCESS, GET_USER_FAILURE} from '../actions/types';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    // get user
    case GET_USER_START:
      return {...state, loading: true};
    case GET_USER_SUCCESS:
      return {...state, data: action.data, error: null, loading: false};
    case GET_USER_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default user;
