import React from 'react';

import TabLinkGroup from '../common/TabLinkGroup';
import TabLink from '../common/TabLink';
import Paper from '../common/Paper';
import {Navigation} from "../Location/LocationCard.styled";


const OrderTabs = props => {
    const {
        orderId,
    } = props;

    return (
        <Paper padding="0">
            <Navigation>
                <TabLinkGroup>
                    <TabLink to={`/orders/${orderId}/information`}>Информация</TabLink>
                    <TabLink to={`/orders/${orderId}/console`}>Ocpp логи</TabLink>
                </TabLinkGroup>
            </Navigation>
        </Paper>
    );
};

export default OrderTabs;
