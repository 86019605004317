import styled from 'styled-components';

import bp from '../breakpoints';

export const Backdrop = styled.div`
  @media screen and (max-width: ${bp.tablet}) {
    position: fixed; top: 0; right: 0; bottom: 0; left: 0;
    display: ${props => props.visible ? 'block' : 'none'};
    background: rgb(0, 0, 0, 0.5);
    z-index: 100;
  }
  @media screen and (min-width: ${bp.tablet}) {
    display: none;
  }
`;

export const Container = styled.div`
  position: fixed; top: 0; right: 0; bottom: 0; left: 25vw;
  background-color: white;
  z-index: 100;
`;

export const LinkContainer = styled.div`
  display: block;
  min-width: 100%; max-width: 100%;
  margin-top: 56px;
`;

export const Times = styled.span`
  position: absolute; top: 0; right: 12px;
  z-index: 10;
  font-size: 36px;
  color: black;
`;
