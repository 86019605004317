import {getJSON as get} from '../../api';
import {GET_LOCATION_START, GET_LOCATION_SUCCESS, GET_LOCATION_FAILURE} from './types';

const getLocationStart = () => ({
  type: GET_LOCATION_START,
});

const getLocationSuccess = data => ({
  type: GET_LOCATION_SUCCESS,
  data,
});

const getLocationFailure = error => ({
  type: GET_LOCATION_FAILURE,
  error,
});

const getLocation = (locationId, callback) => {
  return dispatch => {
    dispatch(getLocationStart());
    get(`locations/${locationId}`)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getLocationSuccess(data));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(getLocationFailure(error));
      });
  };
};

export default getLocation;
