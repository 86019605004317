import {deleteJSON} from '../../api';
import {
  DELETE_LOCATION_PHOTO_START, DELETE_LOCATION_PHOTO_SUCCESS, DELETE_LOCATION_PHOTO_FAILURE,
} from './types';

const deletePhotoStart = () => ({
  type: DELETE_LOCATION_PHOTO_START,
});

const deletePhotoSuccess = () => ({
  type: DELETE_LOCATION_PHOTO_SUCCESS,
});

const deletePhotoFailure = error => ({
  type: DELETE_LOCATION_PHOTO_FAILURE,
  error,
});

const deletePhoto = (authorization, locationId, photoId, callback) => {
  return dispatch => {
    dispatch(deletePhotoStart());
    deleteJSON(`locations/${locationId}/photos/${photoId}`, {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(deletePhotoSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(deletePhotoFailure(error));
      });
  };
};

export default deletePhoto;
