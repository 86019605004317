import React from 'react';
import styled from 'styled-components';

import PhotoCard from './PhotoCard';

const PhotoContainer = ({authorization, locationId, data, getPhotos}) => (
  <Container>
    {photoItems(data, authorization, locationId, getPhotos)}
  </Container>
);

const Container = styled.div`
  position: relative;
  display: grid;
  @media screen and (max-width: 1315px) {
    grid-template-columns: repeat(2, 49%);
    column-gap: 2%;
  }
  @media screen and (min-width: 1315px) {
    grid-template-columns: repeat(3, 32%);
    column-gap: 2%;
  }
`;

const photoItems = (data, authorization, locationId, deletePhoto, getPhotos) => {
  const items = data.map((photo, i) =>
    <PhotoCard key={i}
      {...photo}
      authorization={authorization}
      locationId={locationId}
      getPhotos={getPhotos}
    />
  );

  return items;
};

export default PhotoContainer;
