import styled from 'styled-components';

import * as colors from '../../../util/colors';
import bp from '../../common/breakpoints';

export const Wrapper = styled.div`
  display: block;
  width: 33.3333%;
  padding: 0 12px;
  margin-bottom: 24px;

  @media screen and (max-width: ${bp.largeDesktop}) {
    width: 50%;
  }

  @media screen and (max-width: ${bp.desktop}) {
    width: 100%;
  }
`;

export const Inner = styled.div`
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 15px rgba(217,226,232,0.8);
`;

export const Top = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const LeftSide = styled.div`
  width: 170px;
  margin-right: 24px;
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const Number = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-right: 9px;
`;

export const Parking = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg:not(:last-child) {
    margin-right 8px;
  }
`;

export const ParkingLabel = styled.div`
  display: block;
  color: #202020;
  margin-right: 16px;
  font-size: 18px;
  font-weight: 700;
`;

export const OptionsToggle = styled.span`
  position: absolute;
  right: 0;
  right: 0;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.TEXT_MINOR};
  cursor: pointer;
  z-index: 2;
`;

export const OptionContainer = styled.div`
  position: absolute;
  ${props => props.place === 'top' && 'bottom: 30px;'}
  ${props => !props.place && 'top: 48px;'}
  right: -12px;
  display: ${props => props.visible ? 'block' : 'none'};
  transform: translateX(12px) translateY(-6px);
  border-radius: 10px;
  background-color: ${colors.OPTION_BG};
  z-index: 5;
`;

export const Triangle = styled.div`
  position: absolute;
  ${props => props.place === 'bottom' && 'bottom: -20px;'}
  ${props => !props.place && 'top: -20px;'}
  right: 15px;
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  border-top: 0 solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  ${props => props.place === 'bottom' && `border-top: 10px solid ${colors.OPTION_BG};`}
  ${props => !props.place && `border-bottom: 10px solid ${colors.OPTION_BG};`}
`;

export const TriangleBottom = styled.div`
  position: absolute; bottom: -20px; right: 15px;
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  border-bottom: 0 solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${colors.OPTION_BG};
`;

export const Option = styled.span`
  display: block;
  padding: 12px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  white-space: nowrap; 
  &:nth-child(2) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:hover { background-color: ${colors.OPTION_BG_ACTIVE}; }
  & > i {
    width: 1em;
    height: 1em;
    text-align: center;
    margin-right: 12px;
  }
`;

export const Middle = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  min-height: 170px;
  margin-top: 12px;
`;

export const Image = styled.img`
  display: block;
  width: 170px;
  height: 170px;
  margin-right: 24px;
  object-fit: contain;
  object-position: center;
`;

export const Info = styled.div`
  display: inline-block;
  min-height: 100%; max-height: 100%;
  padding: 0 3px;
`;

export const InfoBox = styled.div`
  display: block;
  margin-bottom: 24px;
  & > label {
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: ${colors.TEXT_INPUT};
    margin: 0;
  }
  & > div:last-child {
    display: block;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
  }
  & > div.id {
    & > img.icon-copy {
      display: inline-block;
      margin-left: 8px;
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: #F3F5F6;
    }
  }
`;

export const Bottom = styled.div`
  display: block;
`;
