import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {oneOf, bool, object} from 'prop-types';

import Logo from '../Logo';
import Burger from '../Burger';
import {Wrapper} from '../MainBlocks';
import Sidebar from './Sidebar';

import {TopbarSubstrate, TopbarBlock, ChangeLanguagePlaceholder} from './TopbarMobile.styled';

const propTypes = {
  theme: oneOf(['black', 'white']),
  fixed: bool,
  history: object,
};

const TopbarMobile = props => {
  const {
    theme = 'white',
    fixed,
    history,
  } = props;

  const {t} = useTranslation();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const invertTheme = theme === 'white' ? 'black' : 'white';

  return (
    <TopbarSubstrate fixed={fixed}>
      <TopbarBlock theme={theme} fixed={fixed}>
        <Wrapper>
          <Burger
            theme={invertTheme}
            onClick={() => setSidebarVisible(!sidebarVisible)}
          />
          <Logo
            fillColor={invertTheme}
            onClick={() => history.push('/')}
            className={'small-logo'}
          />
          <Sidebar
            visible={sidebarVisible}
            hide={() => setSidebarVisible(false)}
          />
          <ChangeLanguagePlaceholder />

        </Wrapper>
      </TopbarBlock>
    </TopbarSubstrate>
  );
};

TopbarMobile.propTypes = propTypes;

export default withRouter(TopbarMobile);
