import {deleteJSON} from '../../api';
import {
  DELETE_LOCATION_STATION_START, DELETE_LOCATION_STATION_SUCCESS, DELETE_LOCATION_STATION_FAILURE,
} from './types';

const deleteStationStart = () => ({
  type: DELETE_LOCATION_STATION_START,
});

const deleteStationSuccess = () => ({
  type: DELETE_LOCATION_STATION_SUCCESS,
});

const deleteStationFailure = error => ({
  type: DELETE_LOCATION_STATION_FAILURE,
  error,
});

const deleteStation = (authorization, stationId, callback) => {
  return dispatch => {
    dispatch(deleteStationStart());
    deleteJSON(`stations/${stationId}`, {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(deleteStationSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(deleteStationFailure(error));
      });
  };
};

export default deleteStation;
