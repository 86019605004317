import React from 'react';
import styled from 'styled-components';

import ServiceCard from './ServiceCard';

const ServiceContainer = ({authorization, locationId, data,
  deleteService, getServices}) => (
  <Container>
    {serviceItems(data, authorization, locationId, deleteService, getServices)}
  </Container>
);

const Container = styled.div`
  position: relative;
  display: grid;
  column-gap: 2%;
  @media screen and (max-width: 1315px) {
    grid-template-columns: repeat(4, 23.5%);
  }
  @media screen and (min-width: 1315px) {
    grid-template-columns: repeat(6, 17%);
  }
`;

const serviceItems = (data, authorization, locationId, deleteService, getServices) => {
  const items = data.map((service, i) =>
    <ServiceCard key={i}
      {...service}
      authorization={authorization}
      locationId={locationId}
      deleteService={deleteService}
      getServices={getServices}
    />
  );

  return items;
};

export default ServiceContainer;
