import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import getLocations from '../../../redux/actions/getLocations';
import getLocationStations from '../../../redux/actions/getLocationStations';
import createManagerSubscription from '../../../redux/actions/createManagerSubscription';
import updateStation from '../../../redux/actions/updateStation';

import LoadingSpinner from '../../common/LoadingSpinner';
import {TopBarBlock} from '../../common/MainBlocks';
import TopbarBackButton from '../../common/TopbarBackButton';

import SubscriptionConfigurationForm from './SubscriptionConfigurationForm';

class SubscriptionConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
    };
  }

  componentWillMount() {
    this.props.getLocations(this.props.authorization, {mode: 'full'}, null, locations => {
      locations.forEach(location => {
        // REVIEW Limit.
        this.props.getLocationStations(this.props.authorization, location.id, {limit: 100}, locationStations => {
          const sortedLocationStations = locationStations.sort((a, b) => a.number - b.number);
          location.stations = sortedLocationStations;

          this.setState({locations: [...this.state.locations, location]});
        });
      });
    });
  }

  render() {
    const {t, loading} = this.props;

    if (loading) {
      return (
        <>
          <TopBarBlock fixed white>
            <TopbarBackButton
              to="/settings/payments"
              label={t('payments')}
            />
          </TopBarBlock>
          <LoadingSpinner />
        </>
      );
    }

    return (
      <Container>
        <TopBarBlock fixed white>
          <TopbarBackButton
            to="/settings/payments"
            label={t('payments')}
          />
        </TopBarBlock>

        <SubscriptionConfigurationForm
          locations={this.state.locations}
          authorization={this.props.authorization}
          manager={this.props.data}
          createManagerSubscription={this.props.createManagerSubscription}
          updateStation={this.props.updateStation}
        />

      </Container>
    );
  }
}

const Container = styled.div`
`;

const mapStateToProps = ({manager, stations}) => ({
  loggedIn: manager.accessToken && Object.values(manager.data).length > 0,
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  stations: stations.data,
  ...manager,
});

const mapDispatchToProps = dispatch => ({
  getLocations: (authorization, params, excludeLoading, callback) =>
    dispatch(getLocations(authorization, params, excludeLoading, callback)),
  getLocationStations: (authorization, locationId, params, callback) =>
    dispatch(getLocationStations(authorization, locationId, params, callback)),
  createManagerSubscription: (authorization, managerId, data, callback) =>
    dispatch(createManagerSubscription(authorization, managerId, data, callback)),
  updateStation: (authorization, stationId, data, callback) =>
    dispatch(updateStation(authorization, stationId, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withTranslation(), withRouter)(SubscriptionConfiguration));
