import {postJSON as post} from '../../api';
import {CHANGE_STATION_STATUS_START, CHANGE_STATION_STATUS_SUCCESS, CHANGE_STATION_STATUS_FAILURE} from './types';
import setServerTime from "./setServerTime";

const changeStationStatusStart = () => ({
  type: CHANGE_STATION_STATUS_START,
});

const changeStationStatusSuccess = (data) => ({
  type: CHANGE_STATION_STATUS_SUCCESS,
  data,
});

const changeStationStatusFailure = error => ({
  type: CHANGE_STATION_STATUS_FAILURE,
  error,
});

const changeStationStatus = (authorization, stationId, data, callback) => {
  return dispatch => {
    dispatch(changeStationStatusStart());
    post(`stations/${stationId}/status`, {authorization}, data)
      .then(async response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(setServerTime(response.headers.get('Date')));
          return response.json();
        }
      })
      .then((data) => {
        dispatch(changeStationStatusSuccess(data));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(changeStationStatusFailure(error));
      });
  };
};

export default changeStationStatus;
