import React from 'react';
import {useTranslation} from "react-i18next";

import Agreement from '../common/Agreement';

const Terms = () => {
  const {t} = useTranslation();

  return (
    <Agreement
        title={t('privacyPolicy')}
        url="/privacy-policy"
        links={[]}
    />
  );
};

export default Terms;
