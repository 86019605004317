import React from "react";
import {connect} from "react-redux";
import * as colors from '../../util/colors';

import {getConnectorMeterValues} from "../../api";
import styled from "styled-components";
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";

const EvseMeterValues = ({stationId, number, authorization, serverTimeOffset}) => {
    const meterValues = useGetConnectorMeterValuesEffect(stationId, number, authorization);

    const getMultiplierSymb = (multiplier) => ({
        1000: 'k',
        1000000: 'M',
        1000000000: 'G',
    }[multiplier] || '');

    let lastContext = '';

    return (
        <EvseMeterValuesContainer>
            {meterValues.map((meter, i) => {
                const [meterRelativeDate, meterFullDate] = getRelativeAndFullDatesWithServerOffset(meter.timestamp, serverTimeOffset);
                const context = lastContext !== meter.context ? meter.context : '';
                console.log(`meter.context=${meter.context}, lastContext=${lastContext} => context=${context}`);
                if (context) { lastContext = context; }
                return (
                    <EvseMeterValuesStyled key={i}>
                        <EvseMeterValuesContextStyled>
                            {context && context}
                        </EvseMeterValuesContextStyled>
                        {meter.measurand}
                        {meter.phase ? `(${meter.phase})` : ''}
                        {meter.location ? `, ${meter.location}` : ''}
                        {`: ${meter.value}`}
                        {' '}
                        {meter.multiplier && getMultiplierSymb(meter.multiplier)}
                        {meter.unit}
                        {!meter.multiplier || getMultiplierSymb(meter.multiplier) ? '' : `(*${meter.multiplier})`}
                        {' '}
                        <EvseMeterTimestamp>
                            {meterRelativeDate}
                            ({meterFullDate})
                        </EvseMeterTimestamp>
                        <br />
                    </EvseMeterValuesStyled>
                )
            })}
        </EvseMeterValuesContainer>
    )
}

const useGetConnectorMeterValuesEffect = (stationId, number, authorization) => {
    const [meterValues, setMeterValues] = React.useState([]);

    React.useEffect(() => {
        getConnectorMeterValues(stationId, number, {authorization})
            .then(response => response.json())
            .then(data => {
                data = data.sort((a,b) =>
                    `${a.context}${a.measurand}${a.phase}`.localeCompare(`${b.context}${b.measurand}${b.phase}`)
                );
                setMeterValues(data);
            });
    }, [stationId, number, authorization]);

    return meterValues;
}

const EvseMeterValuesContainer = styled.div`
  margin: -16px 0px 16px 8px;
`;

export const EvseMeterTimestamp = styled.span`
  display: none;
  color: ${colors.MARKER_GRAY};
`;

const EvseMeterValuesStyled = styled.div`
  margin-left: 64px;
  font-family: monospace;
  font-size: 0.8rem;
  &:hover ${EvseMeterTimestamp} {
    display: inline;
  }
`;

const EvseMeterValuesContextStyled = styled.div`
  font-size: 1rem;
  margin-left: 16px;
  margin-left: -32px;
  font-family: 'Open Sans', sans-serif;
`;

const mapStateToProps = ({manager, app}) => ({
    authorization: `Bearer ${manager.accessToken}`,
    serverTimeOffset: app.serverTimeOffset,
});

export default connect(mapStateToProps)(EvseMeterValues);
