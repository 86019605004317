import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import * as colors from '../../../util/colors';
import Form, {Title, Text} from '../../common/Form';
import Button from '../../common/Button';
import {ErrorAlert} from '../../common/Alerts';
import {BusinessType, Individual, Company} from './sections';

const COUNTRY_OPTIONS = [
  {id: 'DE', name: 'Germany'},
  {id: 'RU', name: 'Russia'},
];

class PaymentAccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        business_type: {
          value: 'individual', valid: true,
          options: [
            {id: 'individual', name: 'Individual'},
            {id: 'company', name: 'Company'}
          ],
          selectedOptionIndex: 0,
        },
        // Individual.
        individual_first_name: {value: '', valid: undefined},
        individual_last_name: {value: '', valid: undefined},
        individual_email: {value: '', valid: undefined},
        individual_phone: {value: '', valid: undefined},
        individual_birth_date: {value: '', valid: undefined},
        individual_tax_id: {value: '', valid: undefined},
        individual_country: {
          value: COUNTRY_OPTIONS[0].id, valid: true,
          options: COUNTRY_OPTIONS,
          selectedOptionIndex: 0,
        },
        individual_city: {value: '', valid: undefined},
        individual_address: {value: '', valid: undefined},
        individual_postal_code: {value: '', valid: undefined},
        // Company.
        company_name: {value: '', valid: undefined},
        company_email: {value: '', valid: undefined},
        company_phone: {value: '', valid: undefined},
        company_tax_id: {value: '', valid: undefined},
        company_country: {
          value: COUNTRY_OPTIONS[0].id, valid: true,
          options: COUNTRY_OPTIONS,
          selectedOptionIndex: 0,
        },
        company_city: {value: '', valid: undefined},
        company_address: {value: '', valid: undefined},
        company_postal_code: {value: '', valid: undefined},
        // Principal.
        principal_first_name: {value: '', valid: undefined},
        principal_last_name: {value: '', valid: undefined},
        principal_email: {value: '', valid: undefined},
        principal_birth_date: {value: '', valid: undefined},
        principal_tax_id: {value: '', valid: undefined},
        principal_country: {
          value: COUNTRY_OPTIONS[0].id, valid: true,
          options: COUNTRY_OPTIONS,
          selectedOptionIndex: 0,
        },
        principal_city: {value: '', valid: undefined},
        principal_address: {value: '', valid: undefined},
        principal_postal_code: {value: '', valid: undefined},
        principal_passport_number: {value: '', valid: undefined},
      }
    };
  }

  handleSubmit = event => {
    event.preventDefault();

    const {authorization, managerId} = this.props;
    const {data} = this.state;
    const businessType = data.business_type.value;

    let body;

    if (businessType === 'individual') {
      body = {
        email: data.individual_email.value,
        firstName: data.individual_first_name.value,
        lastName: data.individual_last_name.value,
        phone: data.individual_phone.value,
        country: data.individual_country.value,
        city: data.individual_city.value,
        address: data.individual_address.value,
        zip: data.individual_postal_code.value,
        defaultPayoutCurrency: 'EUR',
        vendorPrincipal: {
          firstName: data.individual_first_name.value,
          lastName: data.individual_last_name.value,
          country: data.individual_country.value,
          city: data.individual_city.value,
          address: data.individual_address.value,
          zip: data.individual_postal_code.value,
          dob: data.individual_birth_date.value,
          email: data.individual_email.value,
          personalIdentificationNumber: data.individual_tax_id.value,
          passportNumber: data.principal_passport_number.value,
        },
        vendorAgreement: {
          commissionPercent: 0
        },
      };
    } else {
      body = {
        name: data.company_name.value,
        email: data.company_email.value,
        phone: data.company_phone.value,
        country: data.company_country.value,
        city: data.company_city.value,
        address: data.company_address.value,
        zip: data.company_postal_code.value,
        taxId: data.company_tax_id.value,
        defaultPayoutCurrency: 'EUR',
        vendorPrincipal: {
          firstName: data.principal_first_name.value,
          lastName: data.principal_last_name.value,
          country: data.principal_country.value,
          city: data.principal_city.value,
          address: data.principal_address.value,
          zip: data.principal_postal_code.value,
          dob: data.principal_birth_date.value,
          email: data.principal_email.value,
          personalIdentificationNumber: data.principal_tax_id.value,
          passportNumber: data.principal_passport_number.value,
        },
        vendorAgreement: {
          commissionPercent: 0
        },
      };
    }

    this.props.updatePaymentAccount(authorization, managerId, body, () => this.props.history.push('/settings/payments/bank'));
  };

  handleChange = event => {
    const {name, value} = event.target;

    if (!this.fieldIsPermissible(name, value)) { return false; }

    const data = {...this.state.data};

    // for select fields
    if (data[name].options) {
      const selectedOption = data[name].options.find(option => option.id === value);
      data[name].selectedOptionIndex = data[name].options.indexOf(selectedOption);
    }

    data[name].valid = this.fieldIsValid(name, value);

    if (name === 'birth_date') {
      const previousValue = data[name].value;
      const dashIsNeeded =
        previousValue.length < value.length &&
        (value.length === 2 || value.length === 5);

      if (dashIsNeeded) {
        data[name].value = value + '-';
      }
      else {
        data[name].value = value;
      }
    }
    else {
      data[name].value = value;
    }

    this.setState({data});
  };

  fieldIsPermissible = (name, value) => {
    switch (name) {
      case 'birth_date':
        return value.length <= 10;
      default:
        return true;
    }
  };

  fieldIsValid = (name, value) => {
    switch (name) {
      case 'birth_date':
        return value.length === 10;
      default:
        return value !== '';
    }
  };

  fieldsAreValid = () => {
    const {data} = this.state;
    const businessType = data.business_type.value;

    return !Object.entries(data).find(([key, value]) => (((businessType === 'individual') ? key.includes('individual') : !key.includes('individual')) && !value.valid));
  }

  filterData = filter => {
    const {data} = this.state;
    const filteredData = {};

    for (let fieldName in data) {
      if (filter(fieldName)) {
        filteredData[fieldName] = data[fieldName];
      }
    }

    return filteredData;
  };

  render() {
    const {error, t} = this.props;

    return (
      <Container>
        <img src="/paymentsProgressBar/progressBar1.svg" alt="progress-bar" className="progress-bar-icon" />
        <Form onSubmit={this.handleSubmit}>
          <Title size="24px">{t('businessInfo')}</Title>
          <Text margin="16px 0">{t('addBusinessInfoTo')}</Text>

          <BusinessType data={this.state.data} handleChange={this.handleChange} />

          {(this.state.data.business_type.value === 'individual')
            ? <Individual data={this.state.data} handleChange={this.handleChange} />
            : <Company data={this.state.data} handleChange={this.handleChange} />}

          <Info>
            <img src="/infoIcon.svg" alt="info icon" className="info-icon"/>
            <Text color="#818286" size="14px">{t('deDoesNotStoreAccountInfo')}</Text>
          </Info>

          <ButtonContainer>
            <div className="button-container">
              <Button
                disabled={!this.fieldsAreValid()}
                type='submit'
                margin='0'
                width='auto'
              >
                {t('addBusinessInfo')}
              </Button>
              {error.message && <div className="error-alert"><ErrorAlert>{error.message}</ErrorAlert></div>}
            </div>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: 582px; max-width: 582px;
  min-height: 120px;
  margin: 0 auto; margin-bottom: 32px;
  padding: 24px 100px; padding-bottom: 68px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px ${colors.LINE};
  & > h3.title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: bold;
  }
  & > img.progress-bar-icon {
    display: block;
    margin: 0 auto 24px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; min-width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  & > span {
    padding-bottom: 5px;
  }
  & > img {
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute; right: 0; bottom: 0; left: 0;
  min-height: 68px; max-height: 68px;
  & > div.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%; max-width: 100%;
    position: relative;
    & > div.error-alert {
      margin-left: 9px;
    }
  }
`;

export default compose(withTranslation(), withRouter)(PaymentAccountForm);
