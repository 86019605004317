import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

const MenuItem = props => {
  const {
    children,
    active,
    startIcon,
    history,
    to,
    ...other
  } = props;

  if (!startIcon && !children) {
    return null;
  }

  return (
    <MenuItemWrap
      active={active}
      onClick={() => to && history.push(to)}
      {...other}>

      {startIcon &&
        <MenuItemIcon>
          {startIcon}
        </MenuItemIcon>
      }

      {children && 
        <MenuItemText>
          {children}
        </MenuItemText>
      }
    </MenuItemWrap>
  );
};

const MenuItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // border-bottom: 1px solid #E6EAED;
  border-left: 4px solid transparent;
  padding: 18px 20px;
  font-weight: 500;
  color: #2F3033;
  cursor: pointer;

  color: ${props => props.active ? '#2E83FF' : null};
  border-left-color: ${props => props.active ? '#2E83FF' : null};
  background-color: ${props => props.active ? '#F5F7F9' : null};
`;

const MenuItemIcon = styled.div`
  display: flex;
  margin-right: 20px;
`;

const MenuItemText = styled.div``;

MenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  startIcon: PropTypes.node,
};

export default withRouter(MenuItem);