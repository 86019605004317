import {getJSON as get} from '../../api';
import {GET_USER_START, GET_USER_SUCCESS, GET_USER_FAILURE} from './types';

const getUserStart = () => ({
  type: GET_USER_START,
});

const getUserSuccess = data => ({
  type: GET_USER_SUCCESS,
  data,
});

const getUserFailure = error => ({
  type: GET_USER_FAILURE,
  error,
});

const getUser = (userId, callback) => {
  return dispatch => {
    dispatch(getUserStart());
    get(`users/${userId}`)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getUserSuccess(data));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(getUserFailure(error));
      });
  };
};

export default getUser;
