import styled from 'styled-components';

import * as colors from '../../../util/colors';
import bp from '../breakpoints';

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
Container.defaultProps = {
  action: 'https://gmail.us20.list-manage.com/subscribe/post',
  method: 'POST',
};

export const TextField = styled.input`
  display: block;
  flex-grow: 1;
  height: 48px;
  border: 1px solid ${colors.INPUT_STROKE};
  border-right: 0;
  border-radius: 8px 0 0 8px;
  padding: 0 12px;
  background-color: #fff;
  color: black;
  outline: none;
  font-size: 14px;

  &::placeholder { color: #83868F; }

  @media screen and (max-width: ${bp.tablet}) {
    font-size: 16px;
  }
`;
TextField.defaultProps = {
  type: 'email',
  autocapitalize: 'off',
  autocorrect: 'off',
};

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  border: 0;
  border-radius: 0 8px 8px 0;
  color: #fff;
  background-color: #68A5FC;
  cursor: pointer;

  &:hover {
    background-color: ${colors.TEXT_BLUE};
  }

  @media screen and (max-width: ${bp.tablet}) {
    font-size: 16px;
  }
`;
SubmitButton.defaultProps = {
  type: 'submit'
};