import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getLocations from '../../redux/actions/getLocations';
import LoadingSpinner from '../common/LoadingSpinner';
import Paper from '../common/Paper';
import {ErrorAlert} from '../common/Alerts';
import LocationRow from './LocationRow';

import { TableWrapper, Table, Thead, TheadTr, Th } from './LocationTable.styled';


class LocationTable extends Component {
  intervalId = 0;
  updateInterval = 60000;
  offset = 0;
  limit = 0;
  rowHeight = 65;
  lastLoad = false;

  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      columns: [
        { name: 'name', label: t('name'), width: '300' },
        { name: 'address', label: t('address'), width: '300' },
        { name: 'openingHours', label: t('openingHours'), width: '250' },
        { name: 'rating', label: t('rating'), width: '90' },
        { name: 'services', label: t('services'), width: '90' },
        { name: 'stations', label: t('stations'), width: '90' },
        { name: 'evse', label: 'EVSE', width: '90' },
        { name: 'evseInUse', label: t('evseInUse'), width: '90' },
      ],
    };
  }

  componentDidMount() {
    const { authorization, getLocations } = this.props;

    this.setLimitForLoading();

    getLocations(
      authorization,
      { mode: 'full', limit: this.limit, offset: this.offset },
      () => { this.offset = this.offset + this.limit },
      false,
    );

    document
      .getElementById('PageBlock')
      .addEventListener('scroll', this.handleScroll);

    window.addEventListener('resize', this.handleWindowResize);

    // this.intervalId = setInterval(() => this.updateLocations(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    document
      .getElementById('PageBlock')
      .removeEventListener('scroll', this.handleScroll);
    window
      .removeEventListener('resize', this.handleWindowResize);
  }

  setLimitForLoading = () => {
    // // 80 - шапка, 32 - отступы: снизу от шапки и внизу экрана
    // const wrapHeight = document.body.offsetHeight - 80 - 32 - 32;
    //
    // // Устанавливаем limit - по сколько локаций будем грузить
    // if (this.limit * this.rowHeight < wrapHeight) {
    //   do {
    //     this.limit = this.limit + 50;
    //   } while ((this.limit * this.rowHeight) < wrapHeight);
    // }
  };

  handleWindowResize = () => {
    const { authorization, getLocations } = this.props;

    this.limit = 150;
    this.offset = 0;
    this.lastLoad = false;

    // this.setLimitForLoading();

    getLocations(
      authorization,
      { mode: 'full', limit: this.limit, offset: this.offset },
      () => { this.offset = this.offset + this.limit },
      false,
      false,
    );
  };

  handleScroll = () => {
    const { loadingMore } = this.props;
    const pageBlockHeight = document.getElementById('PageBlock').offsetHeight;
    const pageBlockScrollTop = document.getElementById('PageBlock').scrollTop;
    const pageInnerBlockHeight = document.getElementById('PageInnerBlock').offsetHeight - 1;

    if (pageBlockHeight + pageBlockScrollTop >= pageInnerBlockHeight && !loadingMore && !this.lastLoad) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { authorization, getLocations } = this.props;

    getLocations(
      authorization,
      { mode: 'full', limit: this.limit, offset: this.offset },
      (response) => {
        this.offset = this.offset + this.limit;
        this.lastLoad = response.length < this.limit;
      },
      true,
      true,
    );
  };

  updateLocations = () => {
    const {
      authorization,
      getLocations,
    } = this.props;

    getLocations(
      authorization,
      { mode: 'full', limit: this.offset, offset: 0 },
      null,
      true,
      false,
    );
  };

  render() {
    const { columns } = this.state;
    const { data, loading, loadingMore, error } = this.props;

    if (loading) {
      return (
        <div style={{ flexGrow: 1 }}>
          <LoadingSpinner />
        </div>
      );
    }

    if (error && error.message) {
      return <ErrorAlert>{error.message}</ErrorAlert>;
    }

    const allColumnsWidth = columns.reduce((accumulator, currentValue) => accumulator + +currentValue.width, 0);

    return (
      <>
        <Paper padding="0">
          <TableWrapper style={{ width: `${allColumnsWidth}px` }}>
            <Table>
              <colgroup>
                {columns.map(col => <col key={col.name} width={col.width} />)}
              </colgroup>
              <Thead>
                <TheadTr>
                  {columns.map(col => <Th key={col.name}>{col.label}</Th>)}
                </TheadTr>
              </Thead>
              <tbody>
                {data && data.map((location, i) =>
                  <LocationRow key={i} {...location} />
                )}
              </tbody>
            </Table>
          </TableWrapper>
        </Paper>

        {loadingMore && (
          <div style={{ height: '100px', position: 'relative' }}>
            <LoadingSpinner />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ manager, locations: { data, loading, loadingMore, error } }) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  data,
  loading,
  loadingMore,
  error,
});

const mapDispatchToProps = dispatch => ({
  getLocations: (...params) => dispatch(getLocations(...params)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LocationTable);
