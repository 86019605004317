import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioButton = props => {
  const { name, items } = props;

  if (!items.length) return null;

  return (
    <RadioWrap>
      <RadioSet>
        {items.map(({ label, ...other}, i) => (
          <RadioItem key={i}>
            <RadioInput
              type='radio'
              name={name}
              {...other}
            />
            <RadioText>{label}</RadioText>
          </RadioItem>
        ))}
      </RadioSet>
    </RadioWrap>
  );
};

const RadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-center: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const RadioSet = styled.div`
  display: block;
  border: 1px solid #CAD0D9;
  box-sizing: border-box;
  border-radius: 30px;
`;

const RadioItem = styled.label`
  margin: 0;
  &:hover { cursor: pointer; }
`;

const RadioInput = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;

  &:checked + span {
    background-color: #E1EEFF;
    border-color: #2A79EE;
    color: #2A79EE;
  }
`;

const RadioText = styled.span`
  display: block;
  border-radius: 30px;
  padding: 10px 36px;
  border: 1px solid transparent;
`;

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RadioButton;