import React from 'react';
import {Col} from 'reactstrap';
import styled from 'styled-components';

const IllustrationContainer = () => (
  <Container>
    <Illustration src="/main.svg" />
  </Container>
);

const Container = styled(Col)``;
Container.defaultProps = {
  xs: 12, sm: 12,
  md: {size: 5, offset: -1},
  lg: {size: 5, offset: -1},
};

const Illustration = styled.img`
  display: block;
  margin: 23vh auto 0;
  min-width: 100%; max-width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
`;

export default IllustrationContainer;
