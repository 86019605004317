import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import logInManager from '../../redux/actions/logInManager';
import getCurrentManager from '../../redux/actions/getCurrentManager';
import LoadingSpinner from '../common/LoadingSpinner';
import Form, {PasswordField, TextField, Title} from '../common/Form';
import {Close} from '../common/Modal';
import Button from '../common/Button';
import {ErrorAlert} from '../common/Alerts';

// import * as colors from '../../util/colors';

class LogInForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: '',
        password: '',
      },
      loading: false,
    };
  }

  componentWillMount() {
    if (this.props.error) {
      this.props.error.error = null;
    }

    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
  }

  handleChange = event => {
    const {name, value} = event.target;

    this.setState({data: { ...this.state.data, [name]: value}});
  };

  handleSubmit = event => {
    event.preventDefault();

    const formData = { ...this.state.data };
    const {logInManager, getCurrentManager, history} = this.props;

    this.setState({
      loading: true,
    });

    logInManager(formData, accessToken => {
      getCurrentManager(`Bearer ${accessToken}`, () => history.push('/dashboard'));
    });
  };

  render() {
    const {
      history,
      error,
      // setForm
    } = this.props;
    const {email, password} = this.state.data;
    const {handleChange, handleSubmit} = this;

    if (this.state.loading) {
      return (
        <Container>
          <LoadingSpinner/>
        </Container>
      );
    }

    return (
      <Container>
        <Form onSubmit={handleSubmit}>
          <Close onClick={() => history.push('/')}>
            <img src="/cross.svg" alt="Close" />
          </Close>
          <Title>Log In</Title>
          <TextField
            name="email" value={email} valid={true} handleChange={handleChange}
            label="Email"
            type="email"
            width="100%"
          />
          <PasswordField
            name="password" value={password} handleChange={handleChange}
            label="Password"
            type="password" width="100%"
          />
          <ButtonContainer>
            {/* <ForgotPasswordText onClick={() => setForm('reset_password')}>
              Forgot password?
            </ForgotPasswordText> */}
            <Button type="submit" width="100%" margin="0" theme="blue" warning="hi" // REVIEW
              disabled={!email.length || !password.length}>
              Log In
            </Button>
            {(error && error.error && <ErrorAlert top="20px" right={0} left={0} bottom={0} noTriangle>{error.error}</ErrorAlert>)}
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 40px;
  height: auto;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  margin-top: 48px;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {

  }
  & > div.error-alert {
    display: inline;
    position: absolute;
    width: 200px;
    margin-top: -3px;
    margin-left: 9px;
  }
`;

// const ForgotPasswordText = styled(Text)`
//   color: ${colors.TEXT_BLUE};
//   cursor: pointer;
//   margin-bottom: 18px;
// `;

const mapStateToProps = ({manager}) => ({
  ...manager,
});

const mapDispatchToProps = dispatch => ({
  logInManager: (data, callback) => dispatch(logInManager(data, callback)),
  getCurrentManager: (authorization, callback) =>
    dispatch(getCurrentManager(authorization, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogInForm));
