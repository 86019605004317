import styled from 'styled-components';

import * as colors from '../../util/colors';

export const AvatarAndNameCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const OpeningHours = styled.div`
  display: inline-block;
  min-width: 50%; max-width: 50%;
`;

export const Name = styled.div``;

export const Address = styled.div``;

export const Rating = styled.span`
  color: ${colors.ORANGE_MAIN};
`;

export const Services = styled.span`
  color: black;
`;

export const Available = styled.span`
  color: black;
`;

export const Busy = styled.span`
  color: ${colors.BLUE_MAIN};
`;