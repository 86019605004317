import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {TextField} from '../../common/Form';
import Button from '../../common/Button';

export const ModelCreationFinish = ({station, history, locationId, clearStation}) => {
  const {t} = useTranslation();

  const id = station ? station.id : 'station id is required';

  const copyValue = elementId => {
    const element = window.document.getElementById(elementId);
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('Copy');
  };

  const handleOk = () => {
    clearStation();
    history.push(`/locations/${locationId}/stations`)
  };

  return (
    <Container>
      <Title><span>{t('yourStationHasBeenAdded')}</span></Title>
      <Image src="/stationConnectedToLaptop.svg"/>
      <Section>
        <span>1. {t('accessWebUserInterface')}</span>
        <div className="border"/>
      </Section>
      <Section>
        <span>2.1. {t('ifStationAllowsExplicitly')}</span>
        <TextField
          className="model_creation_text_field"
          rightIcon={<RightIcon src="/copyIcon.svg" onClick={() => copyValue('id')}/>}
          fieldStyle="background-color: #E1EEFF; opacity: 0.7; border-radius: 4px;"
          name="id"
          id="id"
          value={id}
          valid
          required={false}
          handleChange={() => false} handleBlur={() => false}
          removeIconVisible={false}
        />
        <span>{t('thenSetBackendUrl')}</span>
        <TextField
          rightIcon={<RightIcon src="/copyIcon.svg" onClick={() => copyValue('backendUrl')}/>}
          className="model_creation_text_field"
          name="backendUrl"
          id="backendUrl"
          value="wss://csms.drivechargers.ru"
          valid
          required={false}
          handleChange={() => false}
          removeIconVisible={false}
        />
        <div className="border"/>
      </Section>
      <Section>
        <span>2.2 {t('ifStationDoesntAllowExplicitly')}</span>
        <TextField
          rightIcon={<RightIcon src="/copyIcon.svg" onClick={() => copyValue('backendUrlWithId')}/>}
          className="model_creation_text_field"
          name="backendUrlWithId"
          id="backendUrlWithId"
          value={`wss://csms.drivechargers.ru/${id}`}
          valid
          required={false}
          handleChange={() => false}
          removeIconVisible={false}
        />
      </Section>
      <ButtonContainer>
        <Button theme="blue" width="100%" margin="40px 0" onClick={() => handleOk()}>
          {t('ok')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  background-color: white;
  min-width: 700px; max-width: 50%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 8px;
`;

const Title = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 32px;
  & > span {
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    color: #202020;
  }
`;

const Image = styled.img`
  width: 500px;
  height: 156px;
  padding: 0 100px;
  margin: 32px 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 100px;
  & > span {
    font-weight: bold;
    font-size: 16px;
    color: #2F3033;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  & > div.border {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    border: 1px solid #E6EAED;
  }
`;

const RightIcon = styled.img`
  position: absolute; top: 25%; right: 6px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  padding: 0 100px;
  width: 100%;
`;
