import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import {geocode} from '../../api';
import addLocation from '../../redux/actions/addLocation';
import setMapPosition from '../../redux/actions/setMapPosition';
import setMapAddress from '../../redux/actions/setMapAddress';
import SubmitForm, {Title, Text, TextField} from '../common/Form';
import Button from '../common/Button';
import LoadingSpinner from '../common/LoadingSpinner';
import {ErrorAlert} from '../common/Alerts';

class AddLocationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name:        {value: 'Charging location', valid: undefined},
        address:     {value: '', valid: undefined},
        country:     {value: '', valid: undefined},
        city:        {value: '', valid: undefined},
        street:      {value: '', valid: undefined},
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const data = {...this.state.data};

    if (nextProps.address) {
      const {country, city, street} = nextProps.address;

      data.street.value      = street      || '';
      data.city.value        = city        || '';
      data.country.value     = country     || '';

      this.setState({data});
    }
  }

  setData = (name, value, valid) => {
    const data = {...this.state.data};

    data[name].value = value;
    data[name].valid = valid;

    this.setState({ data });
  };

  fieldIsValid = (name, value) => {
    return value.length !== 0;
  };

  handleChange = event => {
    const {name, value} = event.target;

    const valid = this.fieldIsValid(name, value);

    this.setData(name, value, valid);

    const address = this.state.data.address.value;

    if (address.length > 3) {
      geocode(this.props.authorization, {address})
        .then(addressObject => {
          if (addressObject && addressObject.location) {
            this.props.setMapPosition({
              lat: addressObject.location.lat,
              lng: addressObject.location.lng,
            });
            this.props.setMapAddress(addressObject);
          }
        });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const {data} = this.state;
    const formData = {};

    for (let name in data) {
      formData[name] = data[name].value;
    }

    formData.coordinates = this.props.coordinates;

    const {addLocation, authorization, history} = this.props;

    if (authorization) {
      addLocation({ authorization }, formData, () => history.push('/locations'));
    }
  };

  validation() {
    if (!this.state.data?.name?.value) {
      return false;
    }

    return true;
  }

  render() {
    const {handleChange, handleSubmit, props} = this;
    const {loading, error, t} = props;
    const {name, address} = this.state.data;
    const allFieldsValid = this.validation();

    if (loading) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      );
    }

    return (
      <Container>
        <Form onSubmit={handleSubmit} width="50%">
          <Title textAlign="left">{t('addChargingLocation')}</Title>
          <Text textAlign="left">
            {t('locationAddInfo')}
          </Text>
          <TextField
            name="name" value={name.value} valid={name.valid}
            label={t('locationName')}
            handleChange={handleChange} width="100%"
          />
          <TextField
            name="address" value={address.value} valid={(props.address && props.address.country && (props.address.country.length > 0)) || address.valid}
            label={t('address')}
            handleChange={handleChange} width="100%"
          />
          <Text textAlign="left">
            {props.address && [props.address.country, props.address.city, props.address.street, props.address.postal_code].filter(Boolean).join(', ')}
          </Text>
          <ButtonContainer>
            {error && error.message && <ErrorAlert>{error.message}</ErrorAlert>}
            <Button type="submit" disabled={!allFieldsValid}
              theme="blue" width="100%" margin="0 6px 0 0" float="left">
              {t('addLocation')}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  @media screen and (max-width: 992px) {
    display: block;
    min-width: 50%; max-width: 50%;
    padding: 6.8vw 0;
  }
  @media screen and (min-width: 992px) {
    display: inline-flex;
    align-items: stretch;
    min-width: 50%; max-width: 50%;
    padding: 6.8vw 0;
    margin: auto;
  }
`;

const Form = styled(SubmitForm)`
  position: relative;
  height: auto;
  @media screen and (max-width: 992px) {
    min-width: 90%; max-width: 90%;
  }
  @media screen and (min-width: 992px) {
    min-width: 50%; max-width: 50%;
  }
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
`;

const mapStateToProps = ({manager, map, locations}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  coordinates: map.position,
  address: map.address,
  ...locations,
});

const mapDispatchToProps = dispatch => ({
  addLocation: (authorization, data, callbcak) =>
    dispatch(addLocation(authorization, data, callbcak)),
  setMapPosition: position => dispatch(setMapPosition(position)),
  setMapAddress: position => dispatch(setMapAddress(position)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter
)(AddLocationForm);
