import React from 'react';
import {useTranslation} from "react-i18next";
import MenuItem from "./MenuItem";
import Icon from "./Sidebar/SidebarIcon";

export const DashboardMenuItem = ({ pathName }) => {
    const {t} = useTranslation();

    return (
        <MenuItem
            to="/dashboard"
            active={pathName === 'dashboard'}
            startIcon={<Icon type={pathName === 'dashboard' ? 'homeActive' : 'home'} />}
        >
            {t('dashboard')}
        </MenuItem>
    )
}

export const ChargePointsMenuItem = ({ pathName }) => (
    <MenuItem
        to="/charge-points"
        active={pathName === 'charge-points'}
        startIcon={<Icon type={pathName === 'charge-points' ? 'stationsActive' : 'stations'} />}
    >
        Зарядные станции
    </MenuItem>
)

export const LocationsMenuItem = ({ pathName }) => {
    const {t} = useTranslation();

    return (
        <MenuItem
            to="/locations"
            active={pathName === 'locations'}
            startIcon={<Icon type={pathName === 'locations' ? 'mapMarkerActive' : 'mapMarker'} />}
        >
            {t('locations')}
        </MenuItem>
    )
}

export const MapMenuItem = ({ pathName }) => {
    const {t} = useTranslation();

    return (
        <MenuItem
            to="/map"
            active={pathName === 'map'}
            startIcon={<Icon type={pathName === 'map' ? 'mapActive' : 'map'} />}
        >
            {t('map')}
        </MenuItem>
    )
}

export const AspMenuItem = ({ pathName }) => (
    <MenuItem
        to="/anti-sanction-protection"
        active={pathName === 'anti-sanction-protection'}
        startIcon={<Icon type={pathName === 'anti-sanction-protection' ? 'antiSanctionProtectionActive' : 'antiSanctionProtection'} />}
    >
        Антисанкции. Версии
    </MenuItem>
)

export const ProfileMenuItem = ({ pathName }) => {
    const {t} = useTranslation();

    return (
        <MenuItem
            to="/settings/profile"
            active={pathName === 'settings'}
            startIcon={<Icon type={pathName === 'settings' ? 'settingsActive' : 'settings'} />}
        >
            {t('settings')}
        </MenuItem>
    )
}

export const StatisticsByDayMenuItem = ({ pathName }) => (
    <MenuItem
        to="/statistics-by-day"
        active={pathName === 'statistics-by-day'}
        startIcon={<Icon type={pathName === 'statistics-by-day' ? 'homeActive' : 'home'} />}
    >
        Статистика по дням
    </MenuItem>
)

export const StatisticsReportsMenuItem = ({ pathName }) => (
    <MenuItem
        to="/statistics-reports"
        active={pathName === 'statistics-reports'}
        startIcon={<Icon type={pathName === 'statistics-reports' ? 'statisticActive' : 'statistic'} />}
    >
        Отчеты
    </MenuItem>
)
