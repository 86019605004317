import {getJSON as get} from '../../api';
import {GET_PAYMENT_METHOD_START, GET_PAYMENT_METHOD_SUCCESS, GET_PAYMENT_METHOD_FAILURE} from './types';

const getPaymentMethodStart = () => ({
  type: GET_PAYMENT_METHOD_START,
});

const getPaymentMethodSuccess = data => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  data,
});

const getPaymentMethodFailure = error => ({
  type: GET_PAYMENT_METHOD_FAILURE,
  error,
});

const getPaymentMethod = (authorization, paymentMethodId, callback) => {
  return dispatch => {
    dispatch(getPaymentMethodStart());
    get(`payment-methods/${paymentMethodId}`, {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getPaymentMethodSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getPaymentMethodFailure(error));
      });
  };
};

export default getPaymentMethod;
