import {SummaryAmount, SummaryContainer, SummaryItem} from "./StatisticsContainer.styled";
import React from "react";
import {useTranslation} from "react-i18next";

export default function StatisticsSummary({ chargingRevenue, numberOfCharges, energyUsage, chartData, onChange }) {
  const {t} = useTranslation();

  return (
    <SummaryContainer>
      <SummaryItem
        active={chartData === 'charging_revenue'}
        onClick={() => onChange({chartData: 'charging_revenue'})}
      >
        {t('chargingRevenue')}
        <SummaryAmount>
          {chargingRevenue.toFixed(2)} ₽
        </SummaryAmount>
      </SummaryItem>
      <SummaryItem
        active={chartData === 'charges'}
        onClick={() => onChange({chartData: 'charges'})}
      >
        {t('charges')}
        <SummaryAmount>
          {numberOfCharges}
        </SummaryAmount>
      </SummaryItem>
      <SummaryItem
        active={chartData === 'energy_usage'}
        onClick={() => onChange({chartData: 'energy_usage'})}
      >
        {t('consumption')}, {t('kWh')}
        <SummaryAmount>
          {parseFloat((energyUsage / 1000).toFixed(3))}
        </SummaryAmount>
      </SummaryItem>
    </SummaryContainer>
  )
}
