import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';

import clearStation from '../../redux/actions/clearStation';
import {WrapperBlock, PageBlock, PageInnerBlock} from '../common/MainBlocks';
import LocationsTopBar from './Topbar';
import Sidebar from '../common/Sidebar';
import LocationTable from './LocationTable';
import TopbarMobile from "../common/TopbarMobile";

class Locations extends Component {
  componentWillMount() {
    const {loggedIn, role, history} = this.props;

    if (!loggedIn || role !== 'administrator') {
      history.push('/');
    }
  }

  componentDidMount() {
    this.props.clearStation();
  }

  render() {
    return (
      <WrapperBlock>
        <TopbarMobile />
        <Sidebar />
        <PageBlock>
          <PageInnerBlock>
            <LocationsTopBar />
            <LocationTable/>
          </PageInnerBlock>
        </PageBlock>
      </WrapperBlock>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  clearStation: () => dispatch(clearStation()),
});

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  role: manager.data?.role,
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Locations);
