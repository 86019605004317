import {GET_MANAGER_BALANCE_START, GET_MANAGER_BALANCE_SUCCESS, GET_MANAGER_BALANCE_FAILURE} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const balance = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_BALANCE_START:
      return {...state, loading: false};
    case GET_MANAGER_BALANCE_SUCCESS:
      return {...state, data: action.data, error: null, loading: false};
    case GET_MANAGER_BALANCE_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default balance;
