import {combineReducers} from 'redux';

import app from './app';
import manager from './manager';
import map from './map';
import locations from './locations';
import location from './location';
import station from './station';
import types from './types';
import orders from './orders';
import user from './user';
import connector from './connector';
import stations from './stations';
import plans from './plans';
import balance from './balance';
import statistics from './statistics';
import orderDetails from './orderDetails';

const initialState = {};

const rootReducer = combineReducers({
  app,
  manager,
  map,
  locations,
  location,
  station,
  user,
  connector,
  stations,
  types,
  orders,
  plans,
  balance,
  statistics,
  orderDetails,
});

export {initialState, rootReducer};
