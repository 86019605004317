import {
  // location
  ADD_LOCATION_START, ADD_LOCATION_SUCCESS, ADD_LOCATION_FAILURE,
  GET_LOCATION_START, GET_LOCATION_SUCCESS, GET_LOCATION_FAILURE,
  UPDATE_LOCATION_START, UPDATE_LOCATION_SUCCESS, UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION_START, DELETE_LOCATION_SUCCESS, DELETE_LOCATION_FAILURE,
  // stations
  ADD_LOCATION_STATION_START, ADD_LOCATION_STATION_SUCCESS, ADD_LOCATION_STATION_FAILURE,
  GET_LOCATION_STATIONS_START, GET_LOCATION_STATIONS_START_MORE, GET_LOCATION_STATIONS_SUCCESS, GET_LOCATION_STATIONS_FAILURE,
  DELETE_LOCATION_STATION_START, DELETE_LOCATION_STATION_SUCCESS, DELETE_LOCATION_STATION_FAILURE,
  // services
  ADD_LOCATION_SERVICE_START, ADD_LOCATION_SERVICE_SUCCESS, ADD_LOCATION_SERVICE_FAILURE,
  GET_LOCATION_SERVICES_START, GET_LOCATION_SERVICES_SUCCESS, GET_LOCATION_SERVICES_FAILURE,
  EDIT_LOCATION_SERVICE_START, EDIT_LOCATION_SERVICE_SUCCESS, EDIT_LOCATION_SERVICE_FAILURE,
  DELETE_LOCATION_SERVICE_START, DELETE_LOCATION_SERVICE_SUCCESS, DELETE_LOCATION_SERVICE_FAILURE,
  // photos
  ADD_LOCATION_PHOTO_START, ADD_LOCATION_PHOTO_SUCCESS, ADD_LOCATION_PHOTO_FAILURE,
  GET_LOCATION_PHOTOS_START, GET_LOCATION_PHOTOS_SUCCESS, GET_LOCATION_PHOTOS_FAILURE,
  DELETE_LOCATION_PHOTO_START, DELETE_LOCATION_PHOTO_SUCCESS, DELETE_LOCATION_PHOTO_FAILURE,
  // feedback
  GET_LOCATION_FEEDBACK_START, GET_LOCATION_FEEDBACK_SUCCESS, GET_LOCATION_FEEDBACK_FAILURE,
  // opening hours
  SET_LOCATION_OPENING_HOURS_START, SET_LOCATION_OPENING_HOURS_SUCCESS,
  SET_LOCATION_OPENING_HOURS_FAILURE, CLEAR_LOCATION_ERRORS,
} from '../actions/types';

const initialState = {
  data: {},
  loading: false,
  error: null,
  // stations
  stations: [],
  stationsTotalCount: null,
  stationsLoading: false,
  stationsLoadingMore: false,
  // services
  services: [],
  servicesLoading: false,
  // photos
  photos: [],
  photosLoading: false,
  // feedback
  feedback: [],
  feedbackLoading: false,
};

const location = (state = initialState, action) => {
  switch (action.type) {
    // add location
    case ADD_LOCATION_START:
      return {...state, loading: true};
    case ADD_LOCATION_SUCCESS:
      return {...state, loading: false};
    case ADD_LOCATION_FAILURE:
      return {...state, error: action.error, loading: false};
    // get location
    case GET_LOCATION_START:
      return {...state, loading: true};
    case GET_LOCATION_SUCCESS:
      return {...state, data: action.data, loading: false};
    case GET_LOCATION_FAILURE:
      return {...state, error: action.error, loading: false};
    // update location
    case UPDATE_LOCATION_START:
      return {...state, loading: true};
    case UPDATE_LOCATION_SUCCESS:
      return {...state, loading: false};
    case UPDATE_LOCATION_FAILURE:
      return {...state, error: action.error, loading: false};
    // delete location
    case DELETE_LOCATION_START:
      return {...state, loading: true};
    case DELETE_LOCATION_SUCCESS:
      return {...state, loading: false};
    case DELETE_LOCATION_FAILURE:
      return {...state, error: action.error, loading: false};
    // add station
    case ADD_LOCATION_STATION_START:
      return {...state, stationsLoading: true};
    case ADD_LOCATION_STATION_SUCCESS:
      return {...state, stationsLoading: false};
    case ADD_LOCATION_STATION_FAILURE:
      return {...state, error: action.error, stationsLoading: false};
    // get stations
    case GET_LOCATION_STATIONS_START:
      return { ...state, stationsLoading: true, stationsLoadingMore: false, stationsTotalCount: null };
    case GET_LOCATION_STATIONS_START_MORE:
      return { ...state, stationsLoading: false, stationsLoadingMore: true };
    case GET_LOCATION_STATIONS_SUCCESS:
      if (action.loadMore) {
        return { ...state, stations: [ ...state.stations, ...action.data ], stationsLoading: false, stationsLoadingMore: false }
      }
      return { ...state, stations: action.data, stationsTotalCount: action.totalCount, stationsLoading: false, stationsLoadingMore: false };
    case GET_LOCATION_STATIONS_FAILURE:
      return {...state, error: action.error, stationsLoading: false};
    // delete station
    case DELETE_LOCATION_STATION_START:
      return {...state, stationsLoading: true};
    case DELETE_LOCATION_STATION_SUCCESS:
      return {...state, error: null, stationsLoading: false};
    case DELETE_LOCATION_STATION_FAILURE:
      return {...state, error: action.error, stationsLoading: false};
    // add service
    case ADD_LOCATION_SERVICE_START:
      return {...state, servicesLoading: true};
    case ADD_LOCATION_SERVICE_SUCCESS:
      return {...state, servicesLoading: false};
    case ADD_LOCATION_SERVICE_FAILURE:
      return {...state, error: action.error, servicesLoading: false};
    // get services
    case GET_LOCATION_SERVICES_START:
      return {...state, servicesLoading: true};
    case GET_LOCATION_SERVICES_SUCCESS:
      return {...state, services: action.data, error: null, servicesLoading: false};
    case GET_LOCATION_SERVICES_FAILURE:
      return {...state, error: action.error, servicesLoading: false};
    // edit service
    case EDIT_LOCATION_SERVICE_START:
      return {...state, servicesLoading: true};
    case EDIT_LOCATION_SERVICE_SUCCESS:
      return {...state, error: null, servicesLoading: false};
    case EDIT_LOCATION_SERVICE_FAILURE:
      return {...state, error: action.error, servicesLoading: false};
    // delete service
    case DELETE_LOCATION_SERVICE_START:
      return {...state, servicesLoading: true};
    case DELETE_LOCATION_SERVICE_SUCCESS:
      return {...state, error: null, servicesLoading: false};
    case DELETE_LOCATION_SERVICE_FAILURE:
      return {...state, error: action.error, servicesLoading: false};
    // add photo
    case ADD_LOCATION_PHOTO_START:
      return {...state, photosLoading: true};
    case ADD_LOCATION_PHOTO_SUCCESS:
      return {...state, photosLoading: false};
    case ADD_LOCATION_PHOTO_FAILURE:
      return {...state, error: action.error, photosLoading: false};
    // get photos
    case GET_LOCATION_PHOTOS_START:
      return {...state, photosLoading: true};
    case GET_LOCATION_PHOTOS_SUCCESS:
      return {...state, photos: action.data, error: null, photosLoading: false};
    case GET_LOCATION_PHOTOS_FAILURE:
      return {...state, error: action.error, photosLoading: false};
    // delete photo
    case DELETE_LOCATION_PHOTO_START:
      return {...state, photosLoading: true};
    case DELETE_LOCATION_PHOTO_SUCCESS:
      return {...state, error: null, photosLoading: false};
    case DELETE_LOCATION_PHOTO_FAILURE:
      return {...state, error: action.error, photosLoading: false};
    // get feedback
    case GET_LOCATION_FEEDBACK_START:
      return {...state, feedbackLoading: true};
    case GET_LOCATION_FEEDBACK_SUCCESS:
      return {...state, feedback: action.data, error: null, feedbackLoading: false};
    case GET_LOCATION_FEEDBACK_FAILURE:
      return {...state, error: action.error, feedbackLoading: false};
    // set location opening hours
    case SET_LOCATION_OPENING_HOURS_START:
      return {...state, loading: true};
    case SET_LOCATION_OPENING_HOURS_SUCCESS:
      return {...state, loading: false};
    case SET_LOCATION_OPENING_HOURS_FAILURE:
      return {...state, error: action.error, loading: false};
    case CLEAR_LOCATION_ERRORS: {
      return {...state, error: null};
    }
    default:
      return state;
  }
};

export default location;
