import {compose} from "redux";
import moment from "moment";
import {postGraphql} from "../../api";

export const loadPaymentsGroupedByLocationsInDay = async (options, authorization) => {
  const { payments, paymentsTotalCount } = await paymentsData(options, authorization);

  return {
    paymentsTotalCount,
    payments: formatLoadedPayments(payments),
  }
}

const createGetOrdersQuery = (args) => {

  return {
    query: `{
       payments(from: "${args.from}", to: "${args.to}", payment_method_type: "${args.paymentTypesIds}", locationIds: "${args.locationIds}", limit: ${args.limit}, offset: ${args.offset}, search: "${args.search}") {
         total_count
         edges {
           node {
             id
             location {
               id
               name
               country
               city
               street
             }
             connector {
               id
               type
               evse_number
             }
             status
             reservation_start
             reservation_finish
             charging_start
             charging_finish
             meter_start
             meter_stop
             service_orders {
               id
             }
             charging_price
             services_price
             captured_amount
             payment_method_type
          }
        }
      }
    }`
  };
};

const paymentsData = async (options, authorization) => {
  const queryOpts = {
    offset: 0,
    ...options,
  };

  const getOrdersQuery = createGetOrdersQuery(queryOpts);

  const result = await postGraphql({authorization}, getOrdersQuery).then(res => res.json());

  if (!result.data?.payments?.edges) {
    return;
  }

  return {
    paymentsTotalCount: result.data.payments.total_count,
    payments: result.data.payments.edges.map(edge => edge.node),
  }
};

const formatLoadedPayments = (orders) => {
  return compose(
    withDayTitle,
    groupByLocationInDay,
  )(orders)
}

const groupByLocationInDay = (payments) => {
  const groupedByDayOrders = groupByDay(payments);
  const groupedByLocation = [];

  for (let dayOrder of groupedByDayOrders) {
    const dayLocationsObject = {};

    for (let order of dayOrder.orders) {
      const { location } = order;
      const locationId = order.location.id;
      const groupId = `${order.location.id}:${dayOrder.orderDay}`;

      const dayLocationOrder = dayLocationsObject[locationId] || {
        captured_amount: 0,
        meter_start: 0,
        meter_stop: 0,
        payments_count: 0,
        payments: [],
      };

      dayLocationsObject[locationId] = {
        location,
        groupId,
        captured_amount: dayLocationOrder.captured_amount + order.captured_amount,
        meter_start: dayLocationOrder.meter_start + order.meter_start,
        meter_stop: dayLocationOrder.meter_stop + order.meter_stop,
        payments_count: dayLocationOrder.payments_count + 1,
        charging_finish: order.charging_finish || order.reservation_finish,
        payments: [...dayLocationOrder.payments, {...order, parentId: groupId}],
        payment_method_type: order.payment_method_type,
      }
    }

    groupedByLocation.push(...Object.values(dayLocationsObject));
    // groupedByLocation.push(...groupedByLocation[groupedByLocation.length-1].payments);
  }

  return groupedByLocation;
}

const withDayTitle = (orders) => {
  const withDayTitleOrders = [];

  let lastDay;

  for (let order of orders) {
    if (order.title) {
      continue;
    }

    const orderTimestamp = order.charging_finish || order.reservation_finish;
    const orderDay = moment(orderTimestamp).format('Y-MM-DD');

    if (lastDay != orderDay) {
      withDayTitleOrders.push({ title: orderDay })
      lastDay = orderDay;
    }

    withDayTitleOrders.push(order);
  }

  return withDayTitleOrders;
}

const groupByDay = (orders) => {
  const groupedByDayOrders = [];

  for (let order of orders) {
    if (!order) {
      continue;
    }
    const orderTimestamp = order.charging_finish || order.reservation_finish;
    const orderDay = moment(orderTimestamp).format('YMMD');

    if (!groupedByDayOrders.length) {
      groupedByDayOrders.push({ orderDay, orders: [] });
    }

    const lastDay = groupedByDayOrders[groupedByDayOrders.length - 1];

    if (orderDay === lastDay.orderDay) {
      lastDay.orders.push(order);
    } else {
      groupedByDayOrders.push({
        orderDay,
        orders: [order],
      })
    }
  }

  return groupedByDayOrders;
}
