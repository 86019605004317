import React from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import getStation from '../../redux/actions/getStation';

import {
    Bottom,
    Image, Info, InfoBox,
    Inner,
    LeftSide, Middle,
    Number,
    Parking,
    ParkingLabel,
    RightSide,
    Top,
    Wrapper
} from "../Location/Stations/StationCard.styled";
import StationMonetizedIcon from "../Location/Stations/StationMonetizedIcon";
import ReactTooltip from "react-tooltip";
import OperativeStatus from "../common/OperativeStatus";
import CarIcon from "../icons/CarIcon";
import BikeIcon from "../icons/BikeIcon";
import Evses from "../Location/Stations/Evses";
import DescriptionField from './DescriptionField';

function StationDescriptionTab(props) {
    const {
        stationId,
        authorization,
        data,
        getStation,
        isAdministrator,
        history,
        t,
    } = props;

    React.useEffect(() => {
        getStation(authorization, stationId);
    }, [authorization, stationId]);


    if (!data?.id) {
        return null;
    }

    const operative = (data.evses.filter(evse => evse.operational).length !== 0);

    return (
        <Wrapper className={'station-description'} style={{width: '100%'}}>
            <Inner>

                <Top>
                    <LeftSide>
                        <Number>#{data.number}</Number>
                        <StationMonetizedIcon monetized={data.monetized} />
                        <ReactTooltip effect='solid' />
                        <OperativeStatus operative={operative} />
                    </LeftSide>

                    <RightSide>
                        <Parking>
                            <ParkingLabel>{t('parking')}: {' '}</ParkingLabel>
                            {data.parking.car && <CarIcon/>}
                            {data.parking.bike && <BikeIcon/>}
                        </Parking>
                    </RightSide>
                </Top>

                <Middle>
                    <Image
                        src={validImageUrl(data.image) ? data.image : '/defaultStationPicture.jpg'}
                    />
                    <Info>
                        <InfoBox>
                            <label>{t('manufacturer')}</label>
                            <div>{data.vendor_name}</div>
                            <DescriptionField
                                vendor_name={data.vendor_name}
                                model={null}
                                connectors={null}
                                station_id={null}
                                value={data.descriptions.desc1}
                            />
                        </InfoBox>
                        <InfoBox>
                            <label>{t('model')}</label>
                            <div>{data.model}</div>
                            <DescriptionField
                                vendor_name={data.vendor_name}
                                model={data.model}
                                connectors={null}
                                station_id={null}
                                value={data.descriptions.desc2}
                            />
                        </InfoBox>
                        <InfoBox>
                            <Evses evses={data.evses} />
                            <DescriptionField
                                vendor_name={data.vendor_name}
                                model={data.model}
                                connectors={data.connectors_descriptor}
                                station_id={null}
                                value={data.descriptions.desc3}
                            />
                        </InfoBox>
                        <InfoBox>
                            <label>{t('stationId')}</label>
                            <div className="id"
                                 onClick={() => history.push(`/charge-points/${encodeURIComponent(data.id)}/information`)}
                            > {data.id}
                            </div>
                            <DescriptionField
                                vendor_name={data.vendor_name}
                                model={data.model}
                                connectors={data.connectors_descriptor}
                                station_id={data.id}
                                value={data.descriptions.desc4}
                            />
                        </InfoBox>
                    </Info>
                </Middle>
            </Inner>
        </Wrapper>
    );
}


const validImageUrl = url => {
    if (!url) {
        return false;
    }
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
    return url.match(re) !== null;
};

const mapStateToProps = ({manager, station, app}, {match}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    stationId: match.params.stationId,
    loading: station.stationsLoading,
    data: station.data,
    serverTimeOffset: app.serverTimeOffset,
    isAdministrator: manager.data?.role === 'administrator',
});

const mapDispatchToProps = dispatch => ({
    getStation: (...params) => dispatch(getStation(...params)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StationDescriptionTab);
