import {GET_ORDERS_START, GET_ORDERS_SUCCESS, GET_ORDERS_FAILURE} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_START:
      return {...state, loading: true};
    case GET_ORDERS_SUCCESS:
      return {...state, data: action.data, error: null, loading: false};
    case GET_ORDERS_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default orders;
