import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import Form, {Title, PasswordField} from '../../common/Form';
import {Close} from '../../common/Modal';
import Button from '../../common/Button';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        password: {value: '', valid: undefined},
        confirm_password: {value: '', valid: undefined},
      },
    };
  }

  handleSubmit = event => {
    event.preventDefault();

    const data = {...this.state.data};
    const formData = {};

    for (let name in data) {
      formData[name] = data[name].value;
    }

    const {authorization, managerId,
      editManager, getCurrentManager, history} = this.props;

    editManager(authorization, managerId, formData, () => {
      getCurrentManager(authorization, () => {
        history.push('/settings/profile');
      });
    });
  };

  handleChange = event => {
    const {name, value} = event.target;
    const data = {...this.state.data};

    data[name].value = value;
    data[name].valid = this.fieldIsValid(name, value);

    this.setState({data});
  };

  fieldIsValid = (name, value) => {
    const validPassword = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;

    switch (name) {
      case 'password':
        return validPassword.test(value);
      case 'confirm_password':
        return validPassword.test(value) && value === this.state.data.password.value;
      default:
        return value.length !== 0;
    }
  };

  render() {
    const {history, t} = this.props;
    const {password, confirm_password} = this.state.data;
    const allFieldsAreValid = password.valid && confirm_password.valid;

    return (
      <Container>
        <Form onSubmit={this.handleSubmit}>
          <Close onClick={() => history.push('/settings/profile')}>
            <img src="/cross.svg" alt="Close" />
          </Close>
          <Title>{t('changePassword')}</Title>
          <PasswordField
            name="password"
            value={password.value}
            valid={password.valid}
            handleChange={this.handleChange}
            label={t('newPassword')} required
            warning="This field is required"
            type="password" width="100%"
          />
          <PasswordField
            name="confirm_password"
            value={confirm_password.value}
            valid={confirm_password.valid}
            handleChange={this.handleChange}
            label={t('confirmPassword')} required
            warning="This field must match the password field."
            type="password" width="100%"
          />
          <ButtonContainer>
            <Button type="submit" width="100%" margin="0" theme="blue"
              disabled={!allFieldsAreValid}>
              {t('changePassword')}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px);
  max-width: calc(100% - 80px);
  margin: 32px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export default compose(withTranslation(), withRouter)(ChangePasswordForm);
