import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';

import {
    YMaps,
    Map,
    Placemark,
} from 'react-yandex-maps';

import getLocations from '../../../redux/actions/getLocations';
import getLocation from '../../../redux/actions/getLocation';
import availableLocationMarker from './markers/availableLocationMarker.svg';
import busyLocationMarker from './markers/busyLocationMarker.svg';
import offlineLocationMarker from './markers/offlineLocationMarker.svg';

class EvseMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icons: null,
            testIcon: null,
        };
    }

    componentWillMount() {
        this.props.getLocations(null, {limit: 100}); // FIXME Use coordinates.
    }

    onMapLoad = (ymaps) => {
        this.setState({
            icons: {
                availableIcon: ymaps.templateLayoutFactory.createClass(
                    `<img src='${availableLocationMarker}' />`
                ),
                busyIcon: ymaps.templateLayoutFactory.createClass(
                    `<img src='${busyLocationMarker}' />`
                ),
                offlineIcon: ymaps.templateLayoutFactory.createClass(
                    `<img src='${offlineLocationMarker}' />`
                ),
            },
            testIcon: ymaps.templateLayoutFactory.createClass(
                `<img src='${availableLocationMarker}' />`
            ),
        })
    }

    render() {
        const {data: locations} = this.props;

        return (
            <MapWrapper onClick={() => this.props.setDescription(null)}>
                <YMaps>
                    <Map
                        modules={[
                            "templateLayoutFactory",
                        ]}
                        defaultState={{center: [49.02753, 31.482771], zoom: 4}}
                        width="100%"
                        height="100%"
                        onLoad={this.onMapLoad}
                    >
                        {locations?.map(location => (
                            <EvsePlacemark
                                key={location.id}
                                setDescription={this.props.setDescription}
                                getLocation={this.props.getLocation}
                                icons={this.state.icons}
                                testIcon={this.state.testIcon}
                                {...location}
                            />
                        ))}
                    </Map>
                </YMaps>
            </MapWrapper>
        )
    }
}

const EvsePlacemark = (props) => {

    const {
        id,
        coordinates,
        evse_statuses,
        icons,
    } = props;

    const getIconByEvseStatuses = statuses => {
        if (statuses.available > 0) {
            return icons?.availableIcon;
        }
        if (statuses.busy > 0) {
            return icons?.busyIcon;
        }
        return icons?.offlineIcon;
    };

    return (
        <Placemark
            geometry={[coordinates.lat, coordinates.lng]}
            onClick={() => {
                props.getLocation(id, res => props.setDescription(res));
            }}
            options={{
                iconLayout: getIconByEvseStatuses(evse_statuses),
                iconOffset: [-12,-30],
            }}
        />
    )
}

const MapWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 80px);
  top: 80px;
`;

const mapStateToProps = ({manager, locations}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    ...locations,
});

const mapDispatchToProps = dispatch => ({
    getLocations: (authorization, params) =>
        dispatch(getLocations(authorization, params)),
    getLocation: (locationId, callback) =>
        dispatch(getLocation(locationId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvseMap);
