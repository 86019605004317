const scrollToSection = (sectionId, block) => {
  if (!sectionId || !document.getElementById(sectionId)) {
    return false;
  }
  document.getElementById(sectionId).scrollIntoView({
    behavior: 'smooth',
    block: block || 'center',
  });
};

export default scrollToSection;
