import styled from 'styled-components';

import bp from '../breakpoints';
import * as colors from '../../../util/colors';

export const Wrapper = styled.div`
  position: relative;
`;

export const ViewSelected = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 3px;
  padding: 8px;
  ${props => (props.bordered ? `border: 1px solid ${colors.LINE};` : null)}
`;

export const ViewSelectedText = styled.div`
  padding: 0 8px;
  line-height: 1;
`;

export const ViewSelectedTriangle = styled.div`
  color: inherit;
  width: 20px;
  height: 20px;
  position: relative;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: inherit;
    position: absolute;
    bottom: 2px;
    right: 2px;
  }

  @media screen and (max-width: ${bp.tablet}) {
    display: none;
  }
`;