import {postGraphql} from '../../api';

export const loadOrders = async ({ authorization, ...queryOpts }) => {
  const getOrdersQuery = createGetOrdersQuery({
    search: '',
    offset: 0,
    locationIds: '',
    ...queryOpts
  });

  const result = await postGraphql({authorization}, getOrdersQuery).then(res => res.json());
  const edges = result.data.orders?.edges || [];

  return edges.map(edge => edge.node);
}

const createGetOrdersQuery = (args) => {
  return {
    query: `{
       orders(from: "${args.from}", to: "${args.to}", statuses: "wait_payment,active,countdown,starting,started", locationIds: "", limit: ${args.limit}, offset: 0, search: "") {
         total_count
         edges {
           node {
             id
             location {
               id
               name
               country
               city
               street
             }
             station {
               id
               number
             }
             connector {
               id
               type
               evse_number
             }
             user {
               id
               first_name
               last_name
               avatar_url
               phone
             }
             status
             reservation_start
             reservation_finish
             charging_start
             charging_finish
             meter_start
             meter_stop
             service_orders {
               id
             }
             charging_price
             services_price
             captured_amount
             transaction_start
             transaction_finish
             payment_method_type
             meter_soc_start
             meter_soc_stop
             rate
             current_charging_amount
             current_wh
             current_w
             rfid
             reservation_price
          }
        }
      }
    }`
  };
};
