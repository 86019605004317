import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getStatistics from '../../redux/actions/getStatistics';
import clearStation from '../../redux/actions/clearStation';
import Sidebar from '../common/Sidebar';
import {WrapperBlock, PageBlock, TopBarBlock, PageTitle} from '../common/MainBlocks';
import ChargingHistoryByDay from "./PaymentsHistory";
import TopbarMobile from "../common/TopbarMobile";

class StatisticsByDay extends Component {
  componentWillMount() {
    // document.querySelector('meta[name="viewport"]').setAttribute('content', '');
  }

  componentDidMount() {
    const {loggedIn, history} = this.props;

    this.props.clearStation();

    if (!loggedIn) {
      history.push('/');

      return false;
    }
  };

  render() {
    const {
      authorization,
    } = this.props;

    return (
      <WrapperBlock>
        <TopbarMobile />
        <Sidebar/>
        <PageBlock>
          <TopBarBlock>
            <PageTitle>Статистика по дням</PageTitle>
          </TopBarBlock>
          <ChargingHistoryByDay
            authorization={authorization}
          />
        </PageBlock>
      </WrapperBlock>
    );
  };
}

const mapStateToProps = ({manager, statistics}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  statisticsData: statistics.data,
  statisticsLoading: statistics.loading,
  statisticsError: statistics.error,
});

const mapDispatchToProps = dispatch => ({
  getStatistics: (authorization, params, callback, excludeLoading = false) =>
    dispatch(getStatistics(authorization, params, callback, excludeLoading)),
  clearStation: () => dispatch(clearStation()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(StatisticsByDay);
