import React from 'react';
import {Location, Price} from "../Dashboard/ChargingHistoryContainer";
import styled from "styled-components";

const isRowExpanded = (rowData) => !rowData.groupId && !rowData.title

const formatWithSpace = (value) => {
  var parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

export const renderFirstColumn = (rowData) => {
  if (rowData.summary) {
    return <SummaryCell />
  }

  if (!isRowExpanded(rowData)) {
    return <PaymentMethodColumn {...rowData} />
  }

  return <LocationCell {...rowData} />
};

export const render4dColumn = (rowData) => {
  return (
      <Data>
        <div>{rowData.count}</div>
      </Data>
  )
};

export const renderKwh = rowData => {
  return (
    <Data>
      <div>{toKWh(rowData)}</div>
    </Data>
  );
}

export const renderAmount= amount => {
  amount = amount / 100
  return (
    <Data>
      <div>{formatWithSpace(amount)}₽</div>
    </Data>
  );
}

export const renderGrayAmount= amount => {
  amount = Math.round(amount) / 100
  return (
    <Data>
      <Gray>{formatWithSpace(amount)}₽</Gray>
    </Data>
  );
}

export const renderToCompensationAmount= (rowData) => {
  const amount = Math.round(rowData.amount - rowData.agent_fee - rowData.bank_commission - rowData.compensation) / 100;
  return (
    <Data>
      <div>{formatWithSpace(amount)}₽</div>
    </Data>
  );
}

export const capturedAmount = (cellData, rowData) => {
  if (rowData.summary) {
    return (
        <Data>
          <div>{toCapturedAmount(rowData)}₽</div>
        </Data>
    );
  }
  return (
    <Data><Price>{toCapturedAmount(rowData)}₽</Price></Data>
  );
}


export const toKWh = (rowData) => parseFloat(rowData.kwh).toFixed(3)

export const toCapturedAmount = (rowData) => {
  if (rowData.payment_method_type === 'cash') { return 0; }
  return formatWithSpace(rowData.amount / 100);
}


const SummaryCell = () => (
    <Location>
      <div className="name" style={{paddingLeft: '16px'}}>Итого:</div>
    </Location>
);

const Data = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Gray = styled.span`
  color: #b9b9b9;
  font-weight: normal;
  font-size: 0.9rem;
`;

const LocationCell = ({ payment_method_type, payment_provider, market_shop_id }) => {
  let title = payment_method_type;
  switch (payment_method_type) {
    case 'account':
      title = 'Личный счет';
      break;
    case 'terminal':
      title = 'Терминал';
      break;
    case 'sbp':
      title = 'СБП';
      break;
    case 'cash':
      title = 'Наличные оператору';
      break;
    case 'compensation':
      title = 'Компенсация предыдущих сессий';
      break;
    case 'card':
      title = `Карта (${payment_provider === 'tinkoff' ? 'Тиньков' : 'Юмани'})`;
      break;
  }

  return (
    <Data>
      <Location>
        <div className="name" style={{paddingLeft: '16px'}}>{title}</div>
        {payment_method_type === 'card' && market_shop_id && <a className="address" style={{paddingLeft: '16px', color: '#d0d0d1'}}>shop {market_shop_id}</a>}
      </Location>
    </Data>
  )
};

const PaymentMethodColumn = ({ day }) => (
  <Data>
    <Location>
      <div className="address" style={{paddingLeft: '16px'}}>{day}</div>
    </Location>
  </Data>
);
