import {SET_SERVER_TIME_OFFSET} from '../actions/types';

const initialState = {
    serverTimeOffset: 0,
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case SET_SERVER_TIME_OFFSET:
            return {...state, serverTimeOffset: action.payload};
        default:
            return state;
    }
};

export default app;
