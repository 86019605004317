import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import editManager from '../../../redux/actions/editManager';
import getCurrentManager from '../../../redux/actions/getCurrentManager';
import Modal from '../../common/Modal';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = props => {
  const {
    loggedIn,
    history,
    authorization,
    data,
    editManager,
    getCurrentManager,
  } = props;

  if (!loggedIn) {
    history.push('/');
  }

  return (
    <Modal minHeight="360px">
      <ChangePasswordForm
        authorization={authorization}
        managerId={data.id}
        editManager={editManager}
        getCurrentManager={getCurrentManager}
      />
    </Modal>
  );
};

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  data: manager.data,
  loading: manager.loading,
  error: manager.error,
});

const mapDispatchToProps = dispatch => ({
  getCurrentManager: (authorization, callback) =>
    dispatch(getCurrentManager(authorization, callback)),
  editManager: (authorization, managerId, data, callback) =>
    dispatch(editManager(authorization, managerId, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword));
