import React from "react";

const CarIcon = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.8624 5.76312C23.745 5.61994 23.5698 5.53637 23.3861 5.53637H21.0503C20.4739 4.01605 19.723 2.52647 18.8694 1.96419C15.9167 0.0217033 8.08467 0.0217033 5.13192 1.96419C4.27775 2.52708 3.52988 4.01851 2.95039 5.53637H0.614621C0.429037 5.53637 0.254515 5.61994 0.138371 5.76312C0.0216135 5.90569 -0.0250896 6.09435 0.0130103 6.27563L0.358982 7.95203C0.417976 8.23778 0.669927 8.44179 0.960593 8.44179H1.65254C0.984559 9.2124 0.66071 10.1827 0.656408 11.1537C0.652106 12.3532 1.10746 13.4372 1.94013 14.2041C1.94935 14.2121 1.95857 14.2176 1.96717 14.2256V16.5712C1.96717 17.0794 2.38012 17.493 2.88894 17.493H5.04343C5.55225 17.493 5.96521 17.0794 5.96521 16.5712V15.6286H18.0349V16.5712C18.0349 17.0794 18.4479 17.493 18.9567 17.493H21.1112C21.6188 17.493 22.0329 17.0794 22.0329 16.5712V14.2717C22.9166 13.4624 23.3388 12.3489 23.3443 11.2372C23.348 10.2331 23.0094 9.22715 22.3021 8.44057H23.0401C23.332 8.44057 23.584 8.23655 23.6417 7.95018L23.9883 6.2744C24.024 6.09435 23.9791 5.90692 23.8624 5.76312ZM6.14403 3.50355C8.49762 1.95435 15.5025 1.95435 17.8548 3.50355C18.312 3.80343 18.9001 5.01587 19.4391 6.50054H4.56104C5.09874 5.01649 5.68683 3.80466 6.14403 3.50355ZM3.38731 11.3847C3.38731 10.383 4.1997 9.57189 5.20075 9.57189C6.20302 9.57189 7.01418 10.383 7.01418 11.3847C7.01418 12.3864 6.20302 13.1981 5.20075 13.1981C4.1997 13.1981 3.38731 12.3864 3.38731 11.3847ZM18.8233 13.1981C17.8223 13.1981 17.0099 12.3864 17.0099 11.3847C17.0099 10.383 17.8223 9.57189 18.8233 9.57189C19.8256 9.57189 20.6368 10.383 20.6368 11.3847C20.6368 12.3864 19.8244 13.1981 18.8233 13.1981Z" 
        fill="#83868F"
      />
    </svg>
  );
};

export default CarIcon;