import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

import {
    Option,
    OptionContainer,
    OptionsToggle,
    Triangle,
    TriangleBottom
} from "../Location/Stations/StationCard.styled";
import {OptionsContainer} from "../ChargePoints/StationRow";

const OptionsMenu = (props) => {
    const iconRef = React.useRef();
    const [menuPlace, setMenuPlace] = React.useState('');

    const detectPlace = () => {
        const {top} = iconRef.current.getBoundingClientRect();
        setMenuPlace(top > document.documentElement.clientHeight / 2 ? 'top' : '');
        console.log(top, document.documentElement.clientHeight);
    }

    const onToggleClick = (e) => {
        detectPlace();
        props.onToggleClick(e);
    }

    return (
        <OptionsContainer>
            <OptionsToggle onClick={onToggleClick}>
                <i className="fa fa-ellipsis-v" ref={iconRef}/>
            </OptionsToggle>
            <OptionContainer visible={props.isMenuVisible} place={menuPlace}>
                <Triangle place={menuPlace === 'top' && 'bottom'}/>
                {props.children}
            </OptionContainer>
        </OptionsContainer>
    )
}

export default compose(withTranslation())(OptionsMenu);
