import React from 'react';
import {withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Wrapper} from '../MainBlocks';
import Logo from '../Logo';
import Link from './Link';
import Form from './Form';
import {
  FooterWrap,
  FooterInner,
  BrandInfo,

  Links,
  LinkSection,
  Title,
  SocialLinkWrap,
  SocialLink,
  Copyright,
  Join,
  Newsletter,
} from './Footer.styled';

const Footer = () => {
  const {t} = useTranslation();

  return (
    <FooterWrap>
      <Wrapper>
        <FooterInner>

          <BrandInfo>
            <Logo />
            <SocialLinkWrap>
              <SocialLink href="#">
                <i className="fab fa-facebook" />
              </SocialLink>
              <SocialLink href="#">
                <i className="fab fa-linkedin" />
              </SocialLink>
              <SocialLink href="#">
                <i className="fab fa-telegram" />
              </SocialLink>
            </SocialLinkWrap>
            <Copyright>
              &copy; 2020 Drive Energy. All rights reserved.
            </Copyright>
          </BrandInfo>

          <Links>
            <LinkSection>
              <Title>{t('products')}</Title>
              <Link target="_blank" to="https://driveenergychargers.com/%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0%D0%BC/" sectionId="mobileApp" block="center">{t('mobileApp')}</Link><br/>
              <Link to="/">{t('managementSystem')}</Link><br/>
              <Link target="_blank" to="https://driveenergychargers.com/product-category/stancii/">{t('chargingStation')}</Link><br/>
            </LinkSection>
            <LinkSection>
              <Title>{t('about')}</Title>
              <Link target="_blank" to="https://driveenergychargers.com/contact/">{t('contactUs')}</Link><br/>
            </LinkSection>
            <LinkSection>
              <Title>{t('legal')}</Title>
              <Link to="/privacy">{t('privacyPolicy')}</Link><br/>
              <Link to="/terms">{t('termsAndConditions')}</Link><br/>
            </LinkSection>
          </Links>
        </FooterInner>
      </Wrapper>
    </FooterWrap>
  );
};

export default withRouter(Footer);
