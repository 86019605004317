import {store} from "../redux/store";
import getManagerAccessToken from "../redux/actions/getManagerAccessToken";
import logOutManager from "../redux/actions/logOutManager";

let refreshTokenPromise;

export default function ajax (url, options) {
    return fetch(url, options)
        .then((response) => {
            return checkResponseAuth(response, url, options);
        })
}

const checkResponseAuth = async (response, url, options) => {
    const unauthorizedStatus = 401;

    if (response.status === unauthorizedStatus) {
        if (!refreshTokenPromise) {
            refreshTokenPromise = refreshAuthToken();
        }

        await refreshTokenPromise;

        refreshTokenPromise = null;

        const responseAfterRefreshToken = await requestAgainWithRefreshedToken(url, options);

        if (responseAfterRefreshToken.status === unauthorizedStatus) {
            logOut();
        } else {
            return responseAfterRefreshToken;
        }
    }

    return response;
}

const refreshAuthToken = () => {
    const {data, refreshToken} = store.getState().manager;
    const email = data?.email;

    return new Promise((resolve, reject) => {
        store.dispatch(getManagerAccessToken(email, refreshToken, resolve, reject));
    });
}

const logOut = () => {
    store.dispatch(logOutManager());

    setTimeout(() => {
        window.location = '/'
    }, 1000);
}

const requestAgainWithRefreshedToken = (url, options) => {
    const refreshedAccessToken = store.getState().manager.accessToken;
    const authorization = options.headers?.authorization && `Bearer ${refreshedAccessToken}`;

    return fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            authorization,
        }
    })
}
