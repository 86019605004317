import React from 'react';
import PropTypes from 'prop-types';

const BurgerIcon = props => {
  const { theme = 'black' } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="4" width="20" height="2" rx="1" fill={theme} />
      <rect x="2" y="11" width="20" height="2" rx="1" fill={theme} />
      <rect x="2" y="18" width="20" height="2" rx="1" fill={theme} />
    </svg>
  );
};

BurgerIcon.propTypes = {
  theme: PropTypes.oneOf(['black', 'white']),
};

export default BurgerIcon;