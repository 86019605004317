import React from 'react';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import * as colors from '../../../util/colors';

function UnavailableContainer(props) {
  const {t} = props;

  return (
    <Container>
      <img src="/lock.svg" alt="lock icon" className="lock-icon" />
      <div className="text">{t('availableIn')} <span className="link"><a href="/#plans">Pro</a></span> {t('version')}</div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  width: 300px;
  height: 70%;
  & > div.text {
    margin-left: 18px;
    font-size: 20px;
    color: ${colors.TEXT_MINOR};
    & > span.link {
      cursor: pointer;
      color: ${colors.TEXT_BLUE};
      font-weight: 800;
    }
  }
`;

export default compose(withTranslation())(UnavailableContainer);
