import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';

function TextField(props) {
  return (
    <Container width={props.width} margin={props.margin} float={props.float}>
      <Label>{props.label} <Required>{props.required && '*'}</Required></Label>
      {props.multiline && (
        <MultilineField
          className={props.className}
          id={props.id}
          name={props.name} value={props.value}
          valid={props.valid} disabled={props.disabled}
          onChange={props.handleChange} onBlur={props.handleBlur || props.handleChange}
          height={props.height} noHighlight={props.noHighlight}
          onFocus={props.onFocus} autoFocus={props.autoFocus || false}
          placeholder={props.placeholder}
        />
      )}
      {!props.multiline && (
        <Field
          className={props.className}
          id={props.id}
          name={props.name} value={props.value}
          valid={props.valid} disabled={props.disabled}
          onChange={props.handleChange} onBlur={props.handleBlur || props.handleChange}
          height={props.height} noHighlight={props.noHighlight}
          onFocus={props.onFocus} autoFocus={props.autoFocus || false}
          onClick={props.onClick}
          placeholder={props.placeholder}
        />
      )}
      {props.rightIcon && props.rightIcon}
      {props.removeIconVisible && props.value.length !== 0 &&
        <RemoveIcon
          onClick={() => props.handleChange({ target: { name: `${props.name}`, value: '' } })}>
          &times;
        </RemoveIcon>}
      {props.warning &&
        <Warning visible={props.valid !== undefined && !props.valid}>{props.warning}</Warning>}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: ${props => props.width ? 'inline-block': 'block'};
  min-width: ${props => props.width || '100%'};
  max-width: ${props => props.width || '100%'};
  margin: ${props => props.margin || '0'};
  padding: 0;
  float: ${props => props.float || 'none'};
  text-align: left;
`;

const Label = styled.label`
  display: block;
  margin: 0.25rem 0;
  font-size: 14px;
  color: ${colors.TEXT_INPUT};
`;

const Required = styled.span`
  color: ${colors.BUTTON_BLUE};
`;

const Field = styled.input`
  display: block;
  min-width: 100%; max-width: 100%;
  min-height: ${props => props.height || '40px'};
  max-height: ${props => props.height || '40px'};
  margin: 0.25rem 0;
  padding-left: 0.5rem;
  font-size: 16px;
  border: 1px solid ${colors.TEXT_INPUT}; border-radius: 3px;
  color: black;
  background-color: white;
  outline: none;
  ${props => !props.noHighlight &&
  `
    box-shadow: 0 0 3px ${props.valid === false ? 'red' : 'transparent'};
    &:focus {
      box-shadow: 0 0 3px ${props.valid === false ? 'red' : colors.BUTTON_BLUE};
    }
  `}
  &:disabled { background-color: ${colors.BG} }
  &:placeholder { padding-left: 0 };
`;
Field.defaultProps = {
  type: 'text',
};

const MultilineField = styled.textarea`
  display: block;
  min-width: 100%; max-width: 100%;
  min-height: ${props => props.height || '40px'};
  max-height: ${props => props.height || '40px'};
  margin: 0.25rem 0;
  padding-left: 0.5rem;
  font-size: 16px;
  border: 1px solid ${colors.TEXT_INPUT}; border-radius: 3px;
  color: black;
  background-color: white;
  outline: none;
  ${props => !props.noHighlight &&
    `
    box-shadow: 0 0 3px ${props.valid === false ? 'red' : 'transparent'};
    &:focus {
      box-shadow: 0 0 3px ${props.valid === false ? 'red' : colors.BUTTON_BLUE};
    }
  `}
  &:disabled { background-color: ${colors.BG} }
  &:placeholder { padding-left: 0 };
`;

const Warning = styled.div`
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  text-align: right;
  font-size: 12px;
  color: red;
`;

const RemoveIcon = styled.span`
  position: absolute; top: 25px; right: 6px;
  font-size: 32px;
  color: ${colors.ICON_GRAY};
  cursor: pointer;
`;

export default TextField;
