import React, { Component } from 'react';
import {bool} from 'prop-types';

import i18n from '../../../i18n';

import SwitchDesktop from './SwitchDesktop';
import SwitchMobile from './SwitchMobile';

import {
  Wrapper,
  ViewSelected,
  ViewSelectedText,
  ViewSelectedTriangle,
} from './ChangeLanguage.styled';

import FlagGe from './icons/FlagGe';
import FlagRu from './icons/FlagRu';
import FlagUs from './icons/FlagUs';

export const LANGUAGE_OPTIONS = [
  {id: 0, name: 'English', shorthand: 'en', icon: <FlagUs />},
  {id: 1, name: 'German', shorthand: 'de', icon: <FlagGe />},
  {id: 2, name: 'Russian', shorthand: 'ru', icon: <FlagRu />},
];

const propTypes = {
  withText: bool,
  bordered: bool,
};

class ChangeLanguage extends Component {

  constructor(props) {
    super(props);

    let { language } = i18n;

    // Если еще ни разу не заходил, то i18n вернет локальный язык
    if (language.indexOf('-') !== -1) {
      language = language.split('-')[0];
    }
    let langs = {ru: 1, en: 1, de: 1};
    if (langs[language] !== 1) {
      language = 'en';
    }

    this.state = {
      desktopSwitchOpened: false,
      mobileSwitchOpened: false,
      selectedLang: language,
    };
  }

  isMobileCheck = () => window.innerWidth <= 768;

  openLangSwitch = () => {
    if (this.isMobileCheck()) {
      this.setState({ mobileSwitchOpened: true });
    } else {
      this.setState(prevState => {
        return { desktopSwitchOpened: !prevState.desktopSwitchOpened };
      });
    }
  }

  closeLangSwitch = () => {
    if (this.isMobileCheck()) {
      this.setState({ mobileSwitchOpened: false });
    } else {
      this.setState({ desktopSwitchOpened: false });
    }
  }

  changeLanguage = (langShortName) => {
    this.setState({ selectedLang: langShortName });
    i18n.changeLanguage(langShortName);
    this.closeLangSwitch();
  };

  render() {
    const {
      desktopSwitchOpened,
      mobileSwitchOpened,
      selectedLang,
    } = this.state;

    const {
      withText = false,
      bordered = false,
    } = this.props;

    const isMobile = this.isMobileCheck();

    const selectedLangInfo = LANGUAGE_OPTIONS.find(l => l.shorthand === selectedLang);

    return (
      <Wrapper>

        <ViewSelected
          id="selectLangNode"
          onClick={() => this.openLangSwitch()}
          bordered={bordered}
        >
          {selectedLangInfo.icon}
          {withText && <ViewSelectedText>{selectedLangInfo.name}</ViewSelectedText>}
          <ViewSelectedTriangle />
        </ViewSelected>

        {isMobile ? (
          <SwitchMobile
            isVisible={mobileSwitchOpened}
            closeLangSwitch={this.closeLangSwitch}
            options={LANGUAGE_OPTIONS}
            selectedOption={selectedLangInfo}
            changeLanguage={this.changeLanguage}
          />
        ) : (
          <SwitchDesktop
            isVisible={desktopSwitchOpened}
            closeLangSwitch={this.closeLangSwitch}
            options={LANGUAGE_OPTIONS}
            selectedOption={selectedLangInfo}
            changeLanguage={this.changeLanguage}

            checkDesktopSwitch={this.checkDesktopSwitch}
          />
        )}

      </Wrapper>
    );
  }
};

ChangeLanguage.propTypes = propTypes;

export default ChangeLanguage;
