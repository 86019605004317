import styled from 'styled-components';

import * as colors from '../../../util/colors';

export const LangChooser = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
`;

export const LangOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  background-color: ${props => props.selected ? colors.BG : '#FFFFFF'}
`;

export const LangTitle = styled.span`
  height: 64px;
  font-weight: bold;
`;

export const Icon = styled.div`
  margin-left: 24px;
`;

export const Name = styled.span`
  margin-left: 16px;
  color: inherit;
`;

export const Dot = styled.div`
  border: 2px solid #DADADA;
  border-radius: 100%;
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 23px;
`;

export const FilledDot = styled(Dot)`
  border: 2px solid #2E83FF;

  &:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2E83FF;
    border-radius: 50%;
  }
`;