import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import getServices from '../../../redux/actions/getServices';
import editService from '../../../redux/actions/editService';
import Modal from '../../common/Modal';
import EditServiceForm from './EditServiceForm';

class EditService extends Component {
  componentWillMount() {
    const {loggedIn, history} = this.props;

    if (!loggedIn) {
      history.push('/');
      return false;
    }
  }

  render() {
    const {props} = this;

    return (
      <Modal>
        <EditServiceForm
          authorization={props.authorization}
          locationId={props.locationId}
          serviceId={props.serviceId}
          data={props.data}
          loading={props.loading}
          error={props.error}
          editService={props.editService}
          getServices={props.getServices}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({manager, location, types}, {match}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  serviceId: match.params.serviceId,
  serviceTypes: types.services,
  data: location.services.find(service => service.id === match.params.serviceId),
  loading: types.loading || location.servicesLoading,
  error: location.error || types.error,
});

const mapDispatchToProps = dispatch => ({
  editService: (authorization, serviceId, data, callback) =>
    dispatch(editService(authorization, serviceId, data, callback)),
  getServices: (locationId, params, callback) =>
    dispatch(getServices(locationId, params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditService));
