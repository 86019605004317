import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import LoadingSpinner from '../common/LoadingSpinner';
import Paper from '../common/Paper';
import {ErrorAlert} from '../common/Alerts';
import StationRow from './AntiSanctionProtectionRow';

import {TableWrapper, Table, Thead, TheadTr, Th} from './../ChargePoints/StationsTable.styled';


class AntiSanctionProtectionTable extends Component {
    intervalId = 0;
    updateInterval = 10000;
    offset = 0;
    limit = 0;
    rowHeight = 65;
    lastLoad = false;

    constructor(props) {
        super(props);

        const {t} = this.props;

        this.state = {
            columns: [
                {name: 'model', label: 'Версия Андроид', width: '100'},
                {name: 'vendor_name', label: 'Версия iOS', width: '100'},
                {name: 'ping_timestamp', label: 'Дата/время установки', width: '90'},
                {name: 'owner', label: 'Кто поменял(пользователь)', width: '90'},
            ],
        };
    }

    componentDidMount() {
        const {getVersions} = this.props;

        getVersions();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        document
            .getElementById('PageBlock')
            .removeEventListener('scroll', this.handleScroll);
        window
            .removeEventListener('resize', this.handleWindowResize);
    }

    render() {
        const {columns} = this.state;
        const {serverTimeOffset, loading, loadingMore, data, error} = this.props;

        if (loading) {
            return (
                <div style={{flexGrow: 1}}>
                    <LoadingSpinner/>
                </div>
            );
        }

        if (error && error.message) {
            return <ErrorAlert>{error.message}</ErrorAlert>;
        }

        const allColumnsWidth = columns.reduce((accumulator, currentValue) => accumulator + +currentValue.width, 0);

        return (
            <>
                <Paper padding="0">
                    <TableWrapper style={{width: `${allColumnsWidth}px`}}>
                        <Table>
                            <colgroup>
                                {columns.map(col => <col key={col.name} width={col.width}/>)}
                            </colgroup>
                            <Thead>
                                <TheadTr>
                                    {columns.map(col => <Th key={col.name}>{col.label}</Th>)}
                                </TheadTr>
                            </Thead>
                            <tbody>
                            {data && data.map((station, i) =>
                                <StationRow
                                    key={station.id}
                                    serverTimeOffset={serverTimeOffset}
                                    {...station}
                                />
                            )}
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Paper>

                {loadingMore && (
                    <div style={{height: '100px', position: 'relative'}}>
                        <LoadingSpinner/>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({stations: {params, error}, app}) => ({
    serverTimeOffset: app.serverTimeOffset,
    params,
    error,
});

export default compose(connect(mapStateToProps, null), withTranslation())(AntiSanctionProtectionTable);
