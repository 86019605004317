import {keyframes} from 'styled-components';

export const fadeIn = () => keyframes`
    from { opacity: 0 }
    to { opacity: 1 }
`;

export const spin = deg => keyframes`
  from { transform: rotate(0) }
  to { transform: rotate(${deg}deg) }
`;

export const slideRight = () => keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
`;
