import React, {useState} from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';

const PasswordField = ({name, value, valid, handleChange, warning, label, required}) => {
  const [showPassword, setShowPassword] = useState(false);
  const fieldType = showPassword ? 'text' : 'password';
  return (
    <Container>
      <Label>{label} <Required>{required && '*'}</Required></Label>
      <Field
        name={name} value={value} valid={valid}
        onChange={handleChange} onBlur={handleChange}
        type={fieldType}
      />
      <FieldIcon onClick={() => setShowPassword(!showPassword)}>
        {showPassword
          ? <i className="far fa-eye-slash" />
          : <i className="far fa-eye" />}
      </FieldIcon>
      <Warning visible={(valid !== undefined) && !valid}>{warning}</Warning>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: ${props => props.width ? 'inline-block': 'block'};
  min-width: ${props => props.width || '100%'};
  max-width: ${props => props.width || '100%'};
  margin: 0.25rem ${props => props.width ? '0.5rem' : 'auto'};
  padding: 0;
  text-align: left;
`;

const Label = styled.label`
  display: block;
  margin: 0.25rem 0;
  font-size: 14px;
  color: ${colors.TEXT_INPUT};
`;

const Required = styled.span`
  color: ${colors.BUTTON_BLUE};
`;

const Field = styled.input`
  display: block;
  min-width: 100%; max-width: 100%;
  min-height: ${props => props.height || '40px'};
  max-height: ${props => props.height || '40px'};
  margin: 0.25rem 0;
  padding-left: 0.5rem;
  border: 1px solid ${colors.TEXT_INPUT}; border-radius: 3px;
  color: black; background-color: white;
  outline: none;
  z-index: 0;
  &::placeholder { padding-left: 0.5rem };
  box-shadow: 0 0 3px ${props => props.valid === false ? 'red' : 'transparent'};
  &:focus {
    box-shadow: 0 0 3px ${props => props.valid === false ? 'red' : colors.BUTTON_BLUE};
  }
`;

const FieldIcon = styled.span`
  position: absolute; top: 25px; right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px; max-width: 40px;
  min-height: 40px; max-height: 40px;
  font-size: 18px;
  color: ${colors.ICON_NON_ACTIVE};
  cursor: pointer;
  z-index: 1;
`;

const Warning = styled.div`
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  text-align: right;
  font-size: 12px;
  color: red;
`;

export default PasswordField;
