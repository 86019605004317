import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import Sidebar from './Sidebar';

const mapStateToProps = ({ manager }) => ({
  loggedIn: manager.accessToken && Object.values(manager.data).length > 0,
  role: manager.data?.role,
});

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(Sidebar);