import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import {postJSON as post} from '../../api';
import Form, {TextField, Title, Text} from '../common/Form';
import {Close} from '../common/Modal';
import Button from '../common/Button';

const SendPasswordForm = ({history, setForm}) => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    await post('auth/manager/password-change-requests', '', {email});
    setForm('info_recovery');
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Close onClick={() => history.push('/')}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Title>Forgot password</Title>
        <Text textAlign="left" position="absolute">
          {'Enter your email address and we will send you a link to reset your password.'}
        </Text>
        <TextField value={email} handleChange={event => setEmail(event.target.value)}
          name="email"
          label="Email"
          type="email"
          width="100%"
        />
        <ButtonContainer>
          <Button type="submit" width="100%" margin="0" theme="blue" warning="hi" // REVIEW
            disabled={!email.length}>
            Recover password
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 40px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {
    position: absolute; right: 0; bottom: 0; left: 0;
  }
  & > div.error-alert {
    display: inline;
    position: absolute;
    width: 200px;
    margin-top: -3px;
    margin-left: 9px;
  }
`;

export default withRouter(SendPasswordForm);
