import {compose} from "redux";
import moment from "moment";
import {postGraphql} from "../../api";

export const loadPaymentsGroupedByPaymentMethodInDay = async (options, authorization) => {
  const { payments, paymentsTotalCount } = await paymentsData(options, authorization);

  return {
    paymentsTotalCount,
    payments: formatLoadedPayments(payments),
  }
}

const createGetOrdersQuery = (args) => {

  return {
    query: `{
      groupedPayments(from: "${args.from}", to: "${args.to}", locationIds: "${args.locationIds}", limit: ${args.limit}, offset: ${args.offset}) {
         total_count
         edges {
           node {
             day
             payment_method_type
             payment_provider
             kwh
             amount
             bank_commission
             agent_fee
             compensation
             count
             market_shop_id
          }
        }
      }
    }`
  };
};

const paymentsData = async (options, authorization) => {
  const queryOpts = {
    offset: 0,
    ...options,
  };

  const getOrdersQuery = createGetOrdersQuery(queryOpts);

  const result = await postGraphql({authorization}, getOrdersQuery).then(res => res.json());

  if (!result.data?.groupedPayments?.edges) {
    return;
  }

  return {
    paymentsTotalCount: result.data.groupedPayments?.total_count,
    payments: result.data.groupedPayments?.edges.map(edge => edge.node),
  }
};

const formatLoadedPayments = (payments) => {
  return compose(
    groupPaymentsInDay,
    // withSummaryRow,
  )(payments)
}

const groupPaymentsInDay = (payments) => {
  const summaryRow = {
    kwh: 0,
    amount: 0,
    bank_commission: 0,
    agent_fee: 0,
    compensation: 0,
    count: 0,
    day: 'Итого:',
    summary: true,
  };

  for (let groupedPayment of payments) {
    if (groupedPayment.payments) { // grouped by day payments
      continue;
    }

    summaryRow.kwh += groupedPayment.kwh
    summaryRow.amount += groupedPayment.amount
    summaryRow.bank_commission += groupedPayment.bank_commission
    summaryRow.agent_fee += groupedPayment.agent_fee
    summaryRow.compensation += groupedPayment.compensation
    summaryRow.count += groupedPayment.count
  }

  const groupedByDayOrders = groupByDay(payments);
  const groupByPaymentMethod = [];
  groupByPaymentMethod.push(summaryRow);

  for (let dayOrder of groupedByDayOrders) {
    const dayPaymentMethodObject = {};

    for (let order of dayOrder.orders) {
      const dayId = `${order.day}`;
      const groupId = `${order.day}:${dayOrder.orderDay}`;

      const dayPaymentMethodOrder = dayPaymentMethodObject[dayId] || {
        kwh: 0,
        amount: 0,
        bank_commission: 0,
        agent_fee: 0,
        compensation: 0,
        count: 0,
        payments: [],
        day: order.day,
      };

      dayPaymentMethodObject[dayId] = {
        groupId,
        kwh: dayPaymentMethodOrder.kwh + order.kwh,
        amount: dayPaymentMethodOrder.amount + order.amount,
        bank_commission: dayPaymentMethodOrder.bank_commission + order.bank_commission,
        agent_fee: dayPaymentMethodOrder.agent_fee + order.agent_fee,
        compensation: dayPaymentMethodOrder.compensation + order.compensation,
        count: dayPaymentMethodOrder.count + order.count,
        day: order.day,
        payments: [...dayPaymentMethodOrder.payments, {...order, parentId: groupId}],
      }
    }

    groupByPaymentMethod.push(...Object.values(dayPaymentMethodObject));
  }

  return groupByPaymentMethod;
}

const withSummaryRow = (groupedPayments) => {
  const withSummaryRowPayments = [];

  const summaryRow = {
    kwh: 0,
    amount: 0,
    bank_commission: 0,
    agent_fee: 0,
    compensation: 0,
    count: 0,
    day: 'Итого:',
    summary: true,
  };

  for (let groupedPayment of groupedPayments) {
    if (groupedPayment.payments) { // grouped by day payments
      continue;
    }

    summaryRow.kwh += groupedPayment.kwh
    summaryRow.amount += groupedPayment.amount
    summaryRow.bank_commission += groupedPayment.bank_commission
    summaryRow.agent_fee += groupedPayment.agent_fee
    summaryRow.compensation += groupedPayment.compensation
    summaryRow.count += groupedPayment.count
  }

  withSummaryRowPayments.push(summaryRow);
  withSummaryRowPayments.push(...groupedPayments);
  return withSummaryRowPayments;
}

const groupByDay = (orders) => {
  const groupedByDayOrders = [];

  for (let order of orders) {
    if (!order) {
      continue;
    }

    const orderDay = moment(order.day).format('YMMD');

    if (!groupedByDayOrders.length) {
      groupedByDayOrders.push({ orderDay, orders: [] });
    }

    const lastDay = groupedByDayOrders[groupedByDayOrders.length - 1];

    if (orderDay === lastDay.orderDay) {
      lastDay.orders.push(order);
    } else {
      groupedByDayOrders.push({
        orderDay,
        orders: [order],
      })
    }
  }

  return groupedByDayOrders;
}
