import React from 'react';
import {useTranslation} from 'react-i18next';
import {func, bool} from 'prop-types';

import Link from './Link';

import {Backdrop, Container, LinkContainer, Times} from './Sidebar.styled';

const propTypes = {
  visible: bool,
  hide: func,
};

const Sidebar = props => {
  const {visible, hide} = props;
  const {t} = useTranslation();

  return (
    <Backdrop visible={visible}>
      <Container>
        <Times onClick={hide}>&times;</Times>
        <LinkContainer>
          <Link onClick={hide} to="https://ms.drivechargers.ru/#mobileApp" sectionId="mobileApp" block="start">{t('mobileApp')}</Link>
          <Link onClick={hide} to="/" block="start">{t('managementSystem')}</Link>
          <Link onClick={hide} to="https://ms.drivechargers.ru/station" block="start">{t('chargingStation')}</Link>
          <Link onClick={hide} to="https://ms.drivechargers.ru/blog">{t('blog')}</Link>
          <Link onClick={hide} to="https://ms.drivechargers.ru/team">{t('team')}</Link>
          <Link onClick={hide} to="https://ms.drivechargers.ru/contact">{t('contactUs')}</Link>
        </LinkContainer>
      </Container>
    </Backdrop>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
