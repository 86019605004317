import React from 'react';
import {getDays, MONTHS, YEARS} from "../../util/time";

import {Dates, RowTitle} from "./StatisticsContainer.styled";
import {SelectField} from "../common/Form";
import {withTranslation} from "react-i18next";

function StatisticsDates({t, data= {}, handleChange}) {
  return (
    <>
      <Dates>
        <RowTitle>{t('exportStartDate')}: </RowTitle>
        <div>
          <SelectField
            noDot
            selectedFieldStyle={{border: '1px solid #CAD0D9'}}
            optionHoverColor="#E1EEFF"
            name="startDays"
            width='64px'
            selectedOption={data.startDay}
            options={getDays(data.startMonth.id, data.startYear.id)}
            handleChange={option => handleChange({
              startDay: {
                id: option.target.value,
                name: option.target.value
              }
            })}
          />
          <SelectField
            noDot
            optionHoverColor="#E1EEFF"
            name="startMonths"
            selectedFieldStyle={{border: '1px solid #CAD0D9'}}
            width='132px'
            selectedOption={data.startMonth}
            handleChange={option => handleChange({startMonth: MONTHS[option.target.value]})}
            options={MONTHS}
          />
          <SelectField
            noDot
            optionHoverColor="#E1EEFF"
            name="startYears"
            selectedFieldStyle={{border: '1px solid #CAD0D9'}}
            width='80px'
            selectedOption={data.startYear}
            options={YEARS}
            handleChange={option => handleChange({
              startYear: {
                id: option.target.value,
                name: option.target.value
              }
            })}
          />
        </div>
      </Dates>
      <Dates>
        <RowTitle>{t('exportEndDate')}: </RowTitle>
        <div>
          <SelectField
            noDot
            optionHoverColor="#E1EEFF"
            selectedFieldStyle={{border: '1px solid #CAD0D9'}}
            name="endDays"
            width='64px'
            selectedOption={data.endDay}
            options={getDays(data.endMonth.id, data.endYear.id)}
            handleChange={option => handleChange({
              endDay: {
                id: option.target.value,
                name: option.target.value
              }
            })}
          />
          <SelectField
            noDot
            optionHoverColor="#E1EEFF"
            selectedFieldStyle={{border: '1px solid #CAD0D9'}}
            name="endMonths"
            width='132px'
            selectedOption={data.endMonth}
            handleChange={option => handleChange({endMonth: MONTHS[option.target.value]})}
            options={MONTHS}
          />
          <SelectField
            noDot
            optionHoverColor="#E1EEFF"
            selectedFieldStyle={{border: '1px solid #CAD0D9'}}
            name="endYears"
            width='80px'
            selectedOption={data.endYear}
            options={YEARS}
            handleChange={option => handleChange({
              endYear: {
                id: option.target.value,
                name: option.target.value
              }
            })}
          />
        </div>
      </Dates>
    </>
  )
}

export default withTranslation()(StatisticsDates);
