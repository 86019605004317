import {postJSON as post} from '../../api';
import {ADD_LOCATION_START, ADD_LOCATION_SUCCESS, ADD_LOCATION_FAILURE} from './types';

const addLocationStart = () => ({
  type: ADD_LOCATION_START,
});

const addLocationSuccess = () => ({
  type: ADD_LOCATION_SUCCESS,
});

const addLocationFailure = error => ({
  type: ADD_LOCATION_FAILURE,
  error,
});

const addLocation = (authorization, data, callback) => {
  return dispatch => {
    dispatch(addLocationStart());
    post('locations', authorization, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(addLocationSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(addLocationFailure(error));
      });
  };
};

export default addLocation;
