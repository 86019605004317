import styled from 'styled-components';

import bp from '../breakpoints';

const setWrapperTheme = theme => {
  switch (theme) {
    case 'black':
      return `
        background-color: #000;
        color: #fff;
      `;
    case 'white':
      return `
        background-color: #fff;
        color: #1F1F1F;
        box-shadow: 0 1px 10px rgba(0,0,0,.1);
      `;
    case 'blue':
    default:
      return `
        background-color: #0192ff;
        color: #fff;
      `;
  }
};

export const TopbarSubstrate = styled.div`
  display: none;
  ${props => props.fixed ? 'height: 80px;' : null}
  
  @media screen and (max-width: ${bp.tablet}) {
    display: block;
  }

`;


export const TopbarBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 80px;
  ${props => setWrapperTheme(props.theme)};
  ${props => props.fixed ? (`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  `) : (`
    position: static;
  `)}
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;
  max-height: 100%;

`;

export const ChangeLanguagePlaceholder = styled.div`
  display: block;
`;
