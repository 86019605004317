import styled from 'styled-components';
import {Col} from 'reactstrap';

export const Container = styled(Col)`
  @media screen and (max-width: 768px) {
    margin-top: 4rem;
    padding: 1rem;
    padding-bottom: 0;
  }
  @media screen and (min-width: 768px) {
    margin-top: 15vh;
    padding: 0 5.4rem; padding-top: calc(12.1875vw - 4rem);
  }
`;
Container.defaultProps = {
  xs: 12, sm: 12,
  md: {size: 5, offset: 1},
  lg: {size: 5, offset: 1},
};

export const Title = styled.h1`
  font-family: 'Open Sans Extrabold', sans-serif;
  font-weight: bold;
  color: black;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 10vw;
    font-size: 32px;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 2.916vw;
    font-size: 2.91vw;
  }
`;

export const ButtonContainer = styled.div`
  display: block;
  @media screen and (max-width: 768px) {
    margin-bottom: 32px;
  }
`;