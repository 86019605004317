import React from 'react';
import {connect} from 'react-redux';
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import getOrderDetails from '../../redux/actions/getOrderDetails';
import {Title} from "../Location/LocationInfo.styled";
import {statusColorByValue} from "../Dashboard/ChargingHistoryContainer";
import OrderHistory from "./OrderHistory";
import {PreLog, MetersFormatted} from "./OrderHistory";

import Paper from "../common/Paper";
import TitleField from "../common/TitleField";
import styled from "styled-components";
import * as colors from "../../util/colors";

const STATIC_SERVER_OFFSET = '3 hours';

function OrderInformationTab(props) {
    const {
        orderId,
        authorization,
        data,
        serverTimeOffset,
        getOrderDetails,
    } = props;

    React.useEffect(() => {
        getOrderDetails(authorization, orderId);
    }, [authorization, orderId]);

    if (!data?.order?.id) {
        return null;
    }

    const {
        order,
        logs,
        ocpp,
    } = data;

    // const owner_name = getOwnerNameByData(data);

    const [createdRelativeDate, createdFullDate] = getRelativeAndFullDatesWithServerOffset(order.created_at, STATIC_SERVER_OFFSET);
    const [txStartRelativeDate, txStartFullDate] = getRelativeAndFullDatesWithServerOffset(order.transaction_start, STATIC_SERVER_OFFSET);
    const [txFinishRelativeDate, txFinishFullDate] = getRelativeAndFullDatesWithServerOffset(order.transaction_finish, STATIC_SERVER_OFFSET);

    const limits = [];
    if (order.limit_amount) { limits.push(`${order.limit_amount/100} р.`);}
    if (order.limit_kwh) { limits.push(`${order.limit_kwh} кВтч`);}
    if (order.limit_percents) { limits.push(`${order.limit_percents} %`);}

    return (
        <>
            <Paper>
                <Title>Зарядная сессия</Title>

                <TitleField>ID</TitleField>{order.id}<IconCopy value={order.id} /><br/>
                <TitleField>Статус</TitleField><OrderStatus status={order.status}>{order.status}</OrderStatus><br/>
                <TitleField>User</TitleField>{order.user_id}{order.rfid ? (<Rfid>RFID</Rfid>) : ''} <IconCopy value={order.user_id} /><br/>
                <TitleField/><span style={{fontSize: '14px'}}>{order.user.phone}, {order.user.first_name} {order.user.last_name} {order.user.email}</span><br/>
                <TitleField>Станция</TitleField>{order.station_id}<br/>
                <TitleField>Коннектор</TitleField>{order.connector_type} ({order.connector_power} кВт)<br/>
                <TitleField>Локация</TitleField>{order.location.name}<br/>
                <TitleField/><span style={{fontSize: '14px'}}>{order.location.country}, {order.location.city}, {order.location.street}</span><br/>

                <TitleField>Заказ создан</TitleField>{createdRelativeDate} ({createdFullDate})<br/>
                <TitleField>Тип оплаты</TitleField>{order.payment_method_type}<br/>
                <TitleField>Тариф</TitleField>{order.rate/100} / {order.tariff}<br/>
                <TitleField>Лимиты</TitleField>{limits.join(', ')}<br/>
                <TitleField>Статус предоплаты</TitleField><PaidStatus status={order.payment_intent_status}>{order.payment_intent_status}</PaidStatus><br/>
                <TitleField>Оплачено</TitleField><PaidAmount order={order} /><br/>
                <TitleField>Промежуточная сумма</TitleField>{order.current_charging_amount/100} р.<br/>
                <TitleField>Последние метрики</TitleField><MetersFormatted meters={order.meters} /><br/>
                <TitleField>Начальные метрики</TitleField><MetersFormatted meters={order.meters_begin} /><br/>
                <TitleField>Длительность заряда</TitleField>{order.duration}<br/>
                <TitleField>Причина остановки</TitleField><PreLog>{order.stop_reason}</PreLog><br/>
                <br/>

                <TitleField>Транзакция</TitleField><PreLog>{order.transaction_id}</PreLog>
                    {txStartFullDate || txFinishFullDate ? ` (${txStartFullDate} - ${txFinishFullDate})` : ''} <br/>
                <TitleField>Счетчик</TitleField><MeterCounter order={order} /><br/>
                <TitleField>Команда старта</TitleField><StartCmdStatus order={order} serverTimeOffset={STATIC_SERVER_OFFSET} /><br/>
                <TitleField>Команда остановки</TitleField><StopCmdStatus order={order} serverTimeOffset={STATIC_SERVER_OFFSET} /><br/>
                <br/>
                <TitleField>Авто подключено?</TitleField>{order.ev_connected ? 'Да' : 'Нет'}<br/>
                <TitleField>Простой с подкл. коннектором</TitleField>{Math.floor(order.parking_time/60)} мин. ({order.parking_time} сек)<br/>
                <TitleField>Оплачено за простой</TitleField>{order.parking_captured_amount/100} р. из {order.parking_amount/100} р.<br/>

                {order.warning && (<><br/><TitleField>Внимание!</TitleField><PreLog style={{color: '#d65454', marginLeft: '18px'}}>{order.warning}</PreLog><br/></>)}
            </Paper>

            {logs && (<OrderHistory logs={logs} order={order} />)}
        </>
    );
}

const StartCmdStatus = ({ order, serverTimeOffset }) => {
    const [txStartCmdRelativeDate, txStartCmdFullDate] = getRelativeAndFullDatesWithServerOffset(order.start_tx_status_at, serverTimeOffset);

    if (!order.start_tx_status) { return ''}

    return (
        <>
            <TxShortStatus status={order.start_tx_status}>{order.start_tx_status}</TxShortStatus>
            <>{ txStartCmdFullDate ? ` (${txStartCmdFullDate})` : ''} </>
        </>
    );
};

const StopCmdStatus = ({ order, serverTimeOffset }) => {
    const [txFinishCmdRelativeDate, txFinishCmdFullDate] = getRelativeAndFullDatesWithServerOffset(order.stop_tx_status_at, serverTimeOffset);

    if (!order.stop_tx_status) { return ''}

    return (
        <>
            <TxShortStatus status={order.stop_tx_status}>{order.stop_tx_status}</TxShortStatus>
            <>
                { txFinishCmdFullDate ? ` (${txFinishCmdFullDate})` : ''}
                { (order.stop_tx_retry_count && order.stop_tx_retry_count > 1) ? `, попытка ${order.stop_tx_retry_count}` : ''}
            </>
        </>
    );
};

const MeterCounter = ({ order }) => {
    return (<>
        <PreLog>{order.meter_start}</PreLog> - <PreLog>{order.meter_stop}</PreLog>
        {order.meter_start && order.meter_stop ? ` (${(order.meter_stop - order.meter_start)/1000} кВтч)` : ''}
        {order.meter_start_offset ? ` (задвоение!, + ${order.meter_start_offset} )` : ''}
        </>);
};

const PaidAmount = ({ order }) => {
    let kWh = (order.captured_amount / order.rate).toFixed(3);
    if (order.reservation_price) {
        kWh = ((order.captured_amount - order.reservation_price) / order.rate).toFixed(3);
        return (<>{order.captured_amount/100} р. из {(order.charging_price + order.reservation_price)/100} р.
            <span style={{color: '#AAA'}}> ({kWh + ' кВтч'} + {order.reservation_price/100} р. бронь)</span>
        </>);
    }
    return (<>{order.captured_amount/100} р. из {order.charging_price/100} р.
        <span style={{color: '#AAA'}}> ({kWh + ' кВтч'})</span>
    </>);
};

const IconCopy = ({ value }) => {
    return (<IconCopyWrapper>
            <img src="/copyIcon.svg" alt="copy icon" className="icon-copy" onClick={() => {navigator.clipboard.writeText(value)}} />
        </IconCopyWrapper>);
};

const IconCopyWrapper = styled.span`
 img.icon-copy {
      display: inline-block;
      margin-left: 8px;
      &:hover {
        cursor: pointer;
      }
    }
`;

const OrderStatus = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
    font-weight: bold;
    border-radius: 12px;
    color: #fff;
    padding: 5px;
    height: 20px;
    font-size: 13px;
    background-color: ${props => statusColorByValue(props.status)};
`;

const PaidStatus = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
    font-weight: bold;
    border-radius: 12px;
    color: #fff;
    padding: 5px;
    height: 20px;
    font-size: 13px;
    background-color: ${props => PaidColorByValue(props.status)};
`;

const PaidColorByValue = status => {
    switch (status) {
        case 'canceled':
            return colors.ICON_GRAY;
        case 'captured':
            return colors.ICON_GREEN;
        case 'created':
            return colors.ICON_ORANGE;
        default:
            return colors.ERROR;
    }
};

const TxShortStatus = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
    font-weight: bold;
    border-radius: 12px;
    color: #fff;
    padding: 5px;
    height: 20px;
    font-size: 13px;
    background-color: ${props => TxShortStatusColorByValue(props.status)};
`;

const TxShortStatusColorByValue = status => {
    switch (status) {
        case 'accepted_has':
            return colors.ICON_YELLOW;
        case 'rejected_only':
            return colors.ERROR;
        case 'started':
        case 'stopped':
            return colors.ICON_GREEN;
        case 'wait':
            return colors.ICON_ORANGE;
        default:
            return colors.ERROR;
    }
};

const Rfid = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    min-width: 35px;
    height: 13px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    padding: 1px;
    background-color: red;
`;

const mapStateToProps = ({manager, orderDetails, app}, {match}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    orderId: match.params.orderId,
    // loading: orderDetails.stationsLoading,
    data: orderDetails.data,
    serverTimeOffset: app.serverTimeOffset,
});

const mapDispatchToProps = dispatch => ({
    getOrderDetails: (...params) => dispatch(getOrderDetails(...params)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(OrderInformationTab);
