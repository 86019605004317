import React from 'react';
import {withRouter} from 'react-router-dom';
import {object, string, func} from 'prop-types';

import backIcon from './backIcon.svg';

import {Button} from './TopbarBackButton.styled';

const propTypes = {
  label: string,
  history: object,
  to: string,
  onClick: func,
  other: object,
};

const TopbarBackButton = props => {
  const {
    label = 'Back',
    history,
    to,
    onClick,
    ...other
  } = props;
  
  return (
    <Button
      onClick={() => {
        to && history.push(to);
        onClick && onClick();
      }}
      {...other}>
      <img 
        src={backIcon} 
        alt={'Back'}
      />
      {label}
    </Button>
  );
};

TopbarBackButton.propTypes = propTypes;

export default withRouter(TopbarBackButton);