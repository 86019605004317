import styled from 'styled-components';

const DescriptionCard = styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  bottom: 0;
  z-index: 2;
  width: 480px;
  background-color: white;
`;

const PlaceProperties = styled.div`
  padding: 16px 32px 8px 32px;
`;

const PhotoOfPlace = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
`;

const PlaceRating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Rating = styled.span`
  margin-left: 8px;
  color: #f69b0f;
`;

const PlaceTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 20px;
`;

const InfoText = styled.span`
  font-weight: normal;
  font-size: 16px;
  margin-left: 8px;
  line-height: 130%;
  color: #818286;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export {
  DescriptionCard,
  PhotoOfPlace,
  PlaceProperties,
  PlaceRating,
  Rating,
  PlaceTitle,
  InfoText,
  InfoBlock
};
