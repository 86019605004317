import styled from 'styled-components';
import { ModalHeader } from 'reactstrap';

import * as colors from '../../util/colors';
import bp from '../common/breakpoints';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  min-height: 25rem;
`;

export const Title = styled.div`
  padding: 24px 24px 16px;
  font-size: 20px;
  color: ${colors.TEXT_MAIN};
  font-weight: bold;
`;

export const IntervalContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 24px 0 24px 0;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.TEXT_TITLE};
  width: auto;

  @media screen and (max-width: ${bp.laptop}) {
    position: static;
    padding: 0 24px;
  }
`;

export const IntervalItem = styled.div`
  display: inline-block;
  margin-right: 24px;
  border-bottom: ${props => (props.active ? `4px solid ${colors.BLUE_MAIN}` : 'none')};
  color: ${props => (props.active ? colors.TEXT_TITLE : colors.TEXT_MINOR)};
  &:hover {
    cursor: pointer;
  }
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 107px;
`;

export const SummaryItem = styled.div`
  width: 210px;
  height: 100%;
  overflow: hidden;
  padding: 28px 24px;
  color: ${colors.TEXT_MINOR};
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: ${props => (props.active ? 'rgb(245,247,249, 0.5)' : 'none')}; // colors.BG
  cursor: pointer;

  @media screen and (max-width: ${bp.desktop}) {
    width: 33.3333%;
  }
`;

export const SummaryAmount = styled.div`
  color: ${colors.TEXT_MAIN};
  font-weight: bold;
  font-size: 20px;
`;

export const ChartContainer = styled.div`
  min-width: 80%;
  margin: 40px 32px 32px 32px;
`;

export const DownloadItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 40px;
  width: auto;
  background-color: #E1EEFF;
  align-items: center;
  border-radius: 4px;
  padding: 0 16px;
  & > span {
    color: #2A79EE;
    font-weight: 500;
    font-size: 14px;
  }
  & > img {
    padding-left: 8px;
  }
`;

export const ExportContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(ModalHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0px;
  padding-top: 32px;
`;

export const ModalTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
`;

export const RowTitle = styled.span`
  align-self: ${props => props.align || 'center'};
  max-width: 30%;
  color: #2F3033;
  font-size: 16px;
`;

export const Dates = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > div {
    & > div {
      margin-left: 4px;
      margin-right: 4px;
      & :last-child {
        margin-right: 0px;
      }
    }
  }
  padding: 24px 24px 16px;
  padding-top: 16px;
  border-bottom: 1px solid #E6EAED;
`;

export const ExportFormat = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #E6EAED;
`;

export const DataTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
`;

export const DataTypes = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  div div { margin-left: 35px; }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: space-around;
  padding-top: 16px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${props => props.color || '#4792FF'};
  color: ${props => props.textColor || '#4792FF'};
  &:hover {
    cursor: pointer;
  }
  border: 0;
  margin-right: 5px;
  margin-left: 5px;
  &:disabled {
    background-color: ${props => props.color || 'rgba(54,115,203,0.87)'};
  }
  outline: none;
  &:focus { outline: none; };
`;