import React, {Component} from 'react';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import {Container, Row, Col} from 'reactstrap';

import {postJSON as post} from '../../api';
import Form, {Title, PasswordField} from '../common/Form';
import Button from '../common/Button';
import Topbar from '../common/Topbar';


class PasswordRecovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        password: { value: '', valid: undefined },
        confirm_password: { value: '', valid: undefined },
      },
    };
  };

      handleSubmit = async (event) => {
        event.preventDefault();
        const data = { ...this.state.data };
        const formData = {};

        for (let name in data) {
          formData[name] = data[name].value;
        }

        await post(`auth/manager/password-change-requests/${this.props.match.params.token}`, '', {'new_password': data.password.value});

        this.props.history.push('/');
      };

      handleChange = event => {
        const {name, value} = event.target;
        const data = {...this.state.data};

        data[name].value = value;
        data[name].valid = this.fieldIsValid(name, value);

        this.setState({data});
      };

      fieldIsValid = (name, value) => {
        const validPassword = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;

        switch (name) {
          case 'password':
            return validPassword.test(value);
          case 'confirm_password':
            return validPassword.test(value) && value === this.state.data.password.value;
          default:
            return value.length !== 0;
        }
      };

      render() {
        const {t} = this.props;
        const {password, confirm_password} = this.state.data;
        const allFieldsAreValid = password.valid && confirm_password.valid;

        return (
          <Container fluid>
            <RowContainer>
              <Topbar />
              <Container>
                <FormContainer>
                  <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <Form marginTop="20px" onSubmit={this.handleSubmit}>
                      <Title>{t('changePassword')}</Title>
                      <PasswordField
                        name="password"
                        value={password.value}
                        valid={password.valid}
                        handleChange={this.handleChange}
                        label={t('newPassword')} required
                        warning="This field is required"
                        type="password" width="100%"
                      />
                      <PasswordField
                        name="confirm_password"
                        value={confirm_password.value}
                        valid={confirm_password.valid}
                        handleChange={this.handleChange}
                        label={t('confirmPassword')} required
                        warning="This field must match the password field."
                        type="password" width="100%"
                      />
                      <ButtonContainer>
                        <Button type="submit" width="100%" margin="0" theme="blue"
                          disabled={!allFieldsAreValid}>
                          {t('changePassword')}
                        </Button>
                      </ButtonContainer>
                    </Form>
                  </Col>
                </FormContainer>
              </Container>
            </RowContainer>
          </Container>
        );
      }
}

const RowContainer = styled(Row)`
  min-height: 100vh;
  width: 100vw;
`;

const FormContainer = styled(Row)`
  margin-top: 166px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export default compose(withTranslation())(PasswordRecovery);
