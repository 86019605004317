import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';

const ErrorAlert = ({top, right, bottom, left, width, children, noTriangle}) => (
  <Container top={top} right={right} bottom={bottom} left={left} width={width}>
    {!noTriangle && <Triangle />}
    {children}
  </Container>
);

const Container = styled.div`
  position: ${({top, right, bottom, left}) => (top && right && bottom && left) ? 'fixed' : 'relative'};
  ${props => props.top && `top: ${props.top};`}
  ${props => props.right && `right: ${props.right};`}
  ${props => props.bottom && `bottom: ${props.bottom};`}
  ${props => props.left && `left: ${props.left};`}
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${props => props.width || '100%'};
  max-width: ${props => props.width || '100%'};
  min-height: 48px;
  padding: 12px 24px;
  border: 1px solid transparent; border-radius: 5px;
  font-weight: bold;
  color: white; background-color: ${colors.ERROR};
  z-index: 20;
`;

const Triangle = styled.div`
  position: absolute; left: -24px;
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  border-top: 10px solid transparent;
  border-right: 10px solid ${colors.ERROR};
  border-left: 0 solid transparent;
  border-bottom: 10px solid transparent;
`;

export default ErrorAlert;
