import {CLEAR_LOCATION_ERRORS} from './types';

const clearLocationErrorsRequest = () => ({
  type: CLEAR_LOCATION_ERRORS
});

const clearLocationErrors = () => {
  return dispatch => {
    dispatch(clearLocationErrorsRequest());
  };
};

export default clearLocationErrors;
