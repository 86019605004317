import React from 'react';
import {useTranslation} from 'react-i18next';

import Connectors from './Connectors';
import {
  DescriptionCard,
  PhotoOfPlace,
  PlaceProperties,
  PlaceRating,
  Rating,
  PlaceTitle,
  InfoText,
  InfoBlock
} from './styled';

function sortConnectorsByStatus(connectors) {
  const available = [];
  const busy = [];
  const offline = [];

  for (const connector of connectors) {
    switch (connector.status) {
      case 'available': available.push(connector); break;
      case 'busy': busy.push(connector); break;
      default: offline.push(connector);
    }
  }

  return [...available, ...busy, ...offline];
}

const LocationCard = ({description}) => {
  const {
    name,
    country,
    city,
    rating,
    street,
    opening_hours,
    connectors,
    photos
  } = description || {};

  const {t} = useTranslation();

  const currentDate = new Date();

  const getWeekDay = date => {
    const days = [0, 1, 2, 3, 4, 5, 6];

    return days[date.getDay()];
  };

  return (
    <>
      {description && (
        <DescriptionCard>
          {photos[0] ? (
            <PhotoOfPlace src={`${photos[0].url}`} alt="place-photo" />
          ) : (
            <PhotoOfPlace src="./defaultLocationImage.svg" alt="location image" />
          )}

          <PlaceProperties>
            <PlaceRating>
              <img src="/ratingStarIcon.svg" alt="rating-star" />
              <Rating>{parseFloat(rating.toFixed(1))} </Rating>
            </PlaceRating>
            <PlaceTitle>{name}</PlaceTitle>

            <InfoBlock>
              <img src="/geolocationIcon.svg" alt="geolocation" />
              <InfoText>{` ${street}, ${city}, ${country} `}</InfoText>
            </InfoBlock>

            <InfoBlock>
              <img src="/openingHoursIcon.svg" alt="opening-hours" />
              {opening_hours.open_now ? (
                <InfoText>{`${t('openNow')}: ${`${opening_hours.periods[
                  getWeekDay(currentDate)
                ].opens_at.slice(0, 2)}:${opening_hours.periods[
                  getWeekDay(currentDate)
                ].opens_at.slice(-2)}`}-${`${opening_hours.periods[
                  getWeekDay(currentDate)
                ].closes_at.slice(0, 2)}:${opening_hours.periods[
                  getWeekDay(currentDate)
                ].closes_at.slice(-2)}`}`}</InfoText>
              ) : (
                <InfoText> {t('closed')} </InfoText>
              )}
            </InfoBlock>
          </PlaceProperties>
          <Connectors connectors={sortConnectorsByStatus(connectors)} />
        </DescriptionCard>
      )}
    </>
  );
};

export default LocationCard;
