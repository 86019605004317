import styled from 'styled-components';

import * as colors from '../../../util/colors';
import bp from '../breakpoints';

export const FooterWrap = styled.div`
  color: ${colors.TEXT};
  background-color: #fff;
  border-top: 1px solid ${colors.LINE};
`;

export const FooterInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  padding: 64px 0;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${bp.tablet}) {
    flex-wrap: nowrap;
    flex-direction: column;
    position: relative;
    padding-bottom: 104px;
  }
`;

export const BrandInfo = styled.div`
  width: 230px;

  @media screen and (max-width: ${bp.tablet}) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const SocialLinkWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const SocialLink = styled.a`
  display: block;
  color: ${colors.BLUE_MAIN};
  text-decoration: none;
  font-size: 24px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:not(:last-child) {
    margin-right: 24px;
  }
`;
SocialLink.defaultProps = {
  target: 'blank',
};

export const Copyright = styled.div`
  color: ${colors.TEXT};
  opacity: 0.7;
  font-size: 14px;
  margin-top: 20px;

  @media screen and (max-width: ${bp.tablet}) {
    font-size: 16px;
    position: absolute;
    bottom: 40px;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    width: calc(100% - 230px);
  }

  @media screen and (max-width: ${bp.tablet}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const LinkSection = styled.div`
  min-width: 160px;
  margin-right: 40px;

  &:nth-child(2) {
    min-width: 100px;
  }
  
  @media screen and (max-width: ${bp.tablet}) {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
    font-size: 16px;
  }
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 20px;
  color: #83868F;
  font-size: 14px;

  @media screen and (max-width: ${bp.tablet}) {
    font-size: 16px;
  }
`;

export const Newsletter = styled.div`
  width: 270px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 40px;
    padding-left: 230px;
  }

  @media screen and (max-width: ${bp.tablet}) {
    padding-left: 0;
    padding-top: 0;
  }
`;

export const Join = styled.span`
  display: block;
  margin-bottom: 16px;
  color: #626262;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: ${bp.tablet}) {
    margin-bottom: 12px;
  }
`;