import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import getCurrentManager from '../../../redux/actions/getCurrentManager';
import getManagerSubscription from '../../../redux/actions/getManagerSubscription';
import getPaymentAccount from '../../../redux/actions/getPaymentAccount';
import getPaymentMethod from '../../../redux/actions/getPaymentMethod';
import SettingsTopbar from '../Topbar';
import PlanContainer from './PlanContainer';
import PaymentDetails from './PaymentDetails';

class MyPlan extends Component {
  componentWillMount() {
    const {authorization, data: {id: managerId}} = this.props;

    this.props.getCurrentManager(authorization);
    this.props.getPaymentAccount(authorization, managerId);
    this.props.getManagerSubscription(authorization, managerId);
  }

  componentDidUpdate() {
    const managerId = this.props.data.id;
    const paymentMethodId = this.props.data.default_payment_method_id;

    if (this.props.data.plan_id && (Object.keys(this.props.subscription).length === 0)) {
      Promise.race([
        this.props.getManagerSubscription(this.props.authorization, managerId),
        setTimeout(3000)
      ]);
    }

    if (paymentMethodId && (Object.keys(this.props.paymentMethod).length === 0)) {
      this.props.getPaymentMethod(this.props.authorization, paymentMethodId);
    }
  }

  render() {
    if (!this.props.loggedIn) { this.props.history.push('/'); }

    const paymentMethodId = this.props.data.default_payment_method_id;

    return (
      <>
        <SettingsTopbar />
        <PlanContainer
          authorization={this.props.authorization}
          data={this.props.data}
          subscription={this.props.subscription}
          plans={this.props.plans.data}
          getCurrentManager={this.props.getCurrentManager}
          getManagerSubscription={this.props.getManagerSubscription}
          createManagerSubscription={this.props.createManagerSubscription}
          getPaymentAccount={this.props.getPaymentAccount}
          paymentAccount={this.props.paymentAccount}
        />
        {(paymentMethodId &&
          <PaymentDetails
            paymentAccount={this.props.paymentAccount}
            paymentMethod={this.props.paymentMethod}
          />
        )}
      </>
    );
  }
};

const mapStateToProps = ({manager, plans}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  plans,
  ...manager,
});

const mapDispatchToProps = dispatch => ({
  getCurrentManager: (authorization, callback) =>
    dispatch(getCurrentManager(authorization, callback)),
  getManagerSubscription: (authorization, managerId, callback) =>
    dispatch(getManagerSubscription(authorization, managerId, callback)),
  getPaymentAccount: (authorization, managerId, callback) =>
    dispatch(getPaymentAccount(authorization, managerId, callback)),
  getPaymentMethod: (authorization, paymentMethodId, callback) =>
    dispatch(getPaymentMethod(authorization, paymentMethodId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyPlan));
