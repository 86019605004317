import React from 'react';
import {connect} from 'react-redux';
import styled from "styled-components";
import getOrderDetails from '../../redux/actions/getOrderDetails';

import Paper from "../common/Paper";
import Button from "../common/Button";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function OrderConsoleTab(props) {
    const {
        orderId,
        authorization,
        data,
        serverTimeOffset,
        getOrderDetails,
    } = props;

    React.useEffect(() => {
        getOrderDetails(authorization, orderId);
    }, [authorization, orderId]);

    if (!data?.order?.id) {
        return null;
    }

    const {
        order,
        logs,
        ocpp,
    } = data;

    return (<><ConsoleMessages consoleMessages={ocpp} /></>);
}

const ConsoleMessages = ({consoleMessages}) => {
  const [isFullscreen, setFullscreen] = React.useState(false);
  const toggleFullscreen = function () {
    setFullscreen(!isFullscreen);
  };

  const ref = useScrollToBottomEffect(consoleMessages);
  // const consoleMessagesReversed = [...consoleMessages].reverse();

  const formatMessage = ({
                           message_from,
                           message_type_id,
                           action,
                           payload,
                           created_at,
                         }) => (
    <div style={{
      3: {color: '#9acaff'},
      4: {color: '#dc75a5'},
    }[message_type_id]}>
        <CreatedAt title={message_from === 'station' ? 'входящее(от станции)' : 'исходящее(от сервера)'}>{message_from === 'station' ? '≫' : '≪'} </CreatedAt>
        <CreatedAt>{created_at}; </CreatedAt>
        <Action>{action} </Action>
        <Payload>{JSON.stringify(payload, null, ' ').replace(/(;)/gi,'$1 ')}</Payload>
    </div>
  );

  return (
    <Paper className={isFullscreen ? 'fullscreen' : ''}>
      <div
        ref={ref}
        className="model_creation_text_field default-scrollbar"
        style={{
          padding: '8px',
          height: '250px',
          overflowY: 'scroll',
          opacity: 0.7,
          flex: 'auto',
        }}
      >
        {consoleMessages.map((message) => (
            <>
              {formatMessage(message)}
            </>
          )
        )}
      </div>
      <ButtonsRow>
        <Button onClick={toggleFullscreen} style={{marginLeft: 'auto', minWidth: 'auto', width: '20px'}}>⤢</Button>
      </ButtonsRow>
    </Paper>
  )
}

const useScrollToBottomEffect = (consoleMessages) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [consoleMessages])

  return ref;
}

// const mapStateToProps = ({manager}, {match}) => ({
//   authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
//   orderId: match.params.orderId,
// });

// export default connect(mapStateToProps)(OrderConsoleTab);

const mapStateToProps = ({manager, orderDetails, app}, {match}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    orderId: match.params.orderId,
    // loading: orderDetails.stationsLoading,
    data: orderDetails.data,
    // serverTimeOffset: app.serverTimeOffset,
});

const mapDispatchToProps = dispatch => ({
    getOrderDetails: (...params) => dispatch(getOrderDetails(...params)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(OrderConsoleTab);




export const CreatedAt = styled.span`
  color: #9acaff;
`;

export const Action = styled.span`
  color: inherit;
`;

export const Payload = styled.span`
  color: inherit;
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
