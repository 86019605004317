import * as FileSaver from 'file-saver';
import * as XLSX from 'sheetjs-style';

export const exportToXLSX = ({
  Sheets,
  fileName,
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  fileExtension = '.xlsx'
}) => {
  const wb = { Sheets, SheetNames: Object.keys(Sheets) };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});

  FileSaver.saveAs(data, fileName + fileExtension);
}

export const convertJsonToSheetData = (csvData, { headCellStyle } = {}) => {
  const sheetData = XLSX.utils.json_to_sheet(csvData);

  if (headCellStyle) {
    setSheetHeadersStyle(sheetData, headCellStyle);
  }

  return sheetData;
}

const setSheetHeadersStyle = (sheetData, headCellStyle) => {
  for (const key of (Object.keys(sheetData))) {
    const cellNumber = key.replace(/[a-zA-Z]+/gi,'')

    if (cellNumber === '1') {
      sheetData[key].s = headCellStyle;
    }
  }
}

