import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getStations from '../../redux/actions/getStations';
import LoadingSpinner from '../common/LoadingSpinner';
import Paper from '../common/Paper';
import {ErrorAlert} from '../common/Alerts';
import StationRow from './StationRow';

import {TableWrapper, Table, Thead, TheadTr, Th} from './StationsTable.styled';


class StationsTable extends Component {
    intervalId = 0;
    updateInterval = 60000;
    offset = 0;
    limit = 0;
    lazyIncrement = 100;
    rowHeight = 65;
    lastLoad = false;

    constructor(props) {
        super(props);

        const {t, role} = this.props;

        this.state = {
            columns: [
                {name: 'id', label: 'Station ID', width: '300'},
                // {name: 'number', label: 'Номер', width: '50'},
                // {name: 'model', label: 'Модель', width: '100'},
                // {name: 'vendor_name', label: 'Производитель', width: '100'},
                {name: 'owner', label: 'Владелец', width: '90'},
                {name: 'location_name', label: 'Название локации', width: '250'},
                {name: 'status', label: 'Статус', width: '90'},
                {name: 'wifi', label: '', width: '24'},
                {name: 'monetized', label: '', width: '24'},
                {name: 'reservations_supported', label: '', width: '78'},
                {name: 'ping_timestamp', label: 'Связь со станцией', width: '90'},
                {name: 'context_menu', label: '', width: '24'},
            ],
            visibleOption: null,
        };

        if (role === 'manager') {
            this.state.columns = this.state.columns.filter(column => column.name !== 'owner');
        }
    }

    componentDidMount() {
        this.setLimitForLoading();

        this.getStations();

        document
            .getElementById('PageBlock')
            .addEventListener('scroll', this.handleScroll);

        window.addEventListener('resize', this.handleWindowResize);

        this.intervalId = setInterval(() => this.updateStations(), this.updateInterval);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        document
            .getElementById('PageBlock')
            .removeEventListener('scroll', this.handleScroll);
        window
            .removeEventListener('resize', this.handleWindowResize);
    }

    setLimitForLoading = () => {
        this.limit = 100;
        // // 80 - шапка, 32 - отступы: снизу от шапки и внизу экрана
        // const wrapHeight = document.body.offsetHeight - 80 - 32 - 32;
        //
        // // Устанавливаем limit - по сколько локаций будем грузить
        // if (this.limit * this.rowHeight < wrapHeight) {
        //     do {
        //         this.limit = this.limit + this.lazyIncrement;
        //     } while ((this.limit * this.rowHeight) < wrapHeight);
        // }
    };

    handleWindowResize = () => {
        this.limit = 0;
        this.offset = 0;
        this.lastLoad = false;

        this.setLimitForLoading();

        // this.getStations({
        //     callback: () => {
        //         this.offset = this.offset + this.limit
        //     },
        // });
    };

    handleScroll = () => {
        const {loadingMore} = this.props;
        const pageBlockHeight = document.getElementById('PageBlock').offsetHeight;
        const pageBlockScrollTop = document.getElementById('PageBlock').scrollTop;
        const pageInnerBlockHeight = document.getElementById('PageInnerBlock').offsetHeight - 1;

        if (pageBlockHeight + pageBlockScrollTop >= pageInnerBlockHeight && !loadingMore && !this.lastLoad) {
            this.offset += this.lazyIncrement;
            this.loadMore();
        }
    };

    loadMore = () => {
        this.getStations({
            callback: (response) => {
                this.lastLoad = response.length < this.limit;
            },
            loadMore: true,
        });
    };

    updateStations = () => {
        this.getStations();
    };

    toggleOptionVisibility = (id) => {
        this.setState({
            visibleOption: this.state.visibleOption === id ? null : id,
        })
    }

    getStations = ({loadMore = false, callback = null, ...options} = {}) => {
        const {
            authorization,
            params,
            getStations,
        } = this.props;

        getStations(
          authorization,
          {
              ...options,
              mode: 'full',
              limit: loadMore ? this.limit : (this.offset + this.limit),
              offset: loadMore ? this.offset : 0,
              search: params?.search || '',
              order_by: 'owner_first_name, owner_last_name, owner_email, location_name:asc',
          },
          callback,
          loadMore,
        );
    }

    render() {
        const {columns} = this.state;
        const {serverTimeOffset, loading, loadingMore, data, error} = this.props;

        if (loading) {
            return (
                <div style={{flexGrow: 1}}>
                    <LoadingSpinner/>
                </div>
            );
        }

        if (error && error.message) {
            return <ErrorAlert>{error.message}</ErrorAlert>;
        }

        const allColumnsWidth = columns.reduce((accumulator, currentValue) => accumulator + +currentValue.width, 0);

        return (
            <>
                <Paper padding="0">
                    <TableWrapper style={{width: `${allColumnsWidth}px`}}>
                        <Table>
                            <colgroup>
                                {columns.map(col => <col key={col.name} width={col.width}/>)}
                            </colgroup>
                            <Thead>
                                <TheadTr>
                                    {columns.map(col => <Th key={col.name}>{col.label}</Th>)}
                                </TheadTr>
                            </Thead>
                            <tbody>
                            {data && data.map((station) =>
                                <StationRow
                                    key={station.id}
                                    serverTimeOffset={serverTimeOffset}
                                    visibleOption={this.state.visibleOption}
                                    toggleOptionVisibility={this.toggleOptionVisibility}
                                    {...station}
                                />
                            )}
                            </tbody>
                        </Table>
                    </TableWrapper>
                </Paper>

                {loadingMore && (
                    <div style={{height: '100px', position: 'relative'}}>
                        <LoadingSpinner/>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({manager, stations: {data, params, loading, loadingMore, error}, app}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    serverTimeOffset: app.serverTimeOffset,
    data,
    params,
    loading,
    loadingMore,
    error,
    role: manager.data?.role,
});

const mapDispatchToProps = dispatch => ({
    getStations: (...params) => dispatch(getStations(...params)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(StationsTable);
