import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import Form, {Title} from '../../common/Form';
import {Close} from '../../common/Modal';
import Button from '../../common/Button';

class DeletePhotoForm extends Component {
  handleCancel = () => {
    const {locationId, history} = this.props;

    history.push(`/locations/${locationId}/photos`);
  };

  handleSubmit = event => {
    event.preventDefault();
    const {authorization, locationId, photoId, deletePhoto,
      getPhotos, history} = this.props;

    deletePhoto(authorization, locationId, photoId, () => {
      getPhotos(locationId);
      history.push(`/locations/${locationId}/photos`);
    });
  };

  render() {
    const {photo, locationId, history} = this.props;

    return (
      <Container>
        <Close onClick={() => history.push(`/locations/${locationId}/photos`)}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Form onSubmit={this.handleSubmit}>
          <Title>Delete this photo?</Title>
          {photo && <Image src={photo.url} />}
          <ButtonContainer>
            <Button theme="white" width="48%" margin="0" float="left"
              onClick={() => history.push(`/locations/${locationId}/photos`)}>
              Cancel
            </Button>
            <Button type="submit" theme="blue" width="48%" margin="0" float="right">
              Delete
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 32px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {

  }
`;

const Image = styled.img`
  min-width: 100%; max-width: 100%;
  min-height: 100%; max-height: 100%;
  margin-bottom: 32px;
  border: 1px solid transparent; border-radius: 8px;
`;

export default withRouter(DeletePhotoForm);
