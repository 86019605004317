import React from 'react';
import styled from 'styled-components';

import {Map, YMaps} from "react-yandex-maps";

function MapWithMarker({center, handleMarkerChange}) {
  return (
    <MapWrapper>
      <YandexMap
        onDragend={(map) => handleMarkerChange && handleMarkerChange(map)}
        mapState={{
            center: [center.lat, center.lng],
            zoom: 16,
        }}
      >
      </YandexMap>
      <Marker className="center-marker" src="/mapMarker.png" />
    </MapWrapper>
  );
}

class YandexMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const onBoundsChange = (event) => this.props.onDragend({
      center: event.get('newCenter'),
    });

    return (
        <YMaps>
          <Map
              state={this.props.mapState}
              width="100%"
              height="100%"
              onBoundsChange={onBoundsChange}
          />
        </YMaps>
    )
  }
}


const MapWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
`;

const Marker = styled.img`
  position: absolute; top: calc(50% - 3rem); left: calc(50% - 1.5rem);
  min-width: 3rem; max-width: 3rem;
  min-height: 3.5rem; max-height: 3.5rem;
  z-index: 2;
`;

export default MapWithMarker;
