import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import * as colors from '../../util/colors';
import bp, {getWindowWidth} from '../common/breakpoints';
import Topbar from '../common/Topbar';

const DataDeletionRequest = ({match}) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Topbar textColor="#202020" textColorOnHover={colors.TEXT_BLUE} />
            <Title size={getWindowWidth() >= 576 ? '32px' : '24px'} weight='bold'>
                {t('dataDeletionRequest')}
            </Title>
            <Text>
                <ul>
                    <li>{t('dataDeletionRequest1')}</li>
                    <li>{t('dataDeletionRequest2')}</li>
                    <li>{t('dataDeletionRequest3')}</li>
                </ul>
            </Text>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%; min-width: 100%;
  @media screen and (max-width: ${bp.phone}) {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 56px;
  }
  @media screen and (min-width: ${bp.phone}) {
    padding-top: calc(56px + 10vh);
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: ${props => props.size || '20px'};
  font-weight: ${props => props.weight || 'normal'};
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: ${bp.phone}) {
    margin-top: 32px;
    margin-bottom: 0;
    margin-left: 16px;
  }
`;

const Text = styled.div`
  font-size: ${props => props.size || '20px'};
  font-weight: ${props => props.weight || 'normal'};
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: ${bp.phone}) {
    margin-left: 16px;
  }
`;


export default DataDeletionRequest;
