import styled from 'styled-components';

import * as colors from '../../../util/colors';
import SectionContainer, {Section} from './SectionContainer';
import TextField from './TextField';
import SelectField from './SelectField';
import RadioField from './RadioField';
import PasswordField from './PasswordField';
import CheckboxField from './CheckboxField';
import UploadField from './UploadField';
import ToggleSwitch from './ToggleSwitch';

const Form = styled.form`
  display: block;
  min-width: ${props => props.width || '100%'};
  max-width: ${props => props.width || '100%'};
  margin: 0 auto;
  padding: 0;
`;
Form.defaultProps = {
  autocomplete: 'off',
};

export const Title = styled.div`
  margin: ${props => props.margin || '0 0 1em 0'};
  text-align: ${props => props.textAlign || 'center'};
  font-size: ${props => props.size || '1.5em'};
  font-weight: bold;
  color: black;
`;

export const Text = styled.div`
  min-width: ${props => props.width || 'auto'};
  max-width: ${props => props.width || 'auto'};
  margin: ${props => props.margin || '24px 0'};
  text-align: ${props => props.textAlign || 'center'};
  font-size: 14px;
  color: ${colors.TEXT_MINOR};
  & > span.link {
    font-weight: bold;
    color: ${colors.BLUE_MAIN};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Submit = styled.button`
  display: block;
  min-width: 95%;
  max-width: 95%;
  min-height: 2.5rem; max-height: 2.5rem;
  margin: 1rem auto;
  font-size: 120%;
  font-weight: bold;
  border: 1px solid ${colors.BUTTON_BLUE}; border-radius: 5px;
  color: white;
  background-color: ${colors.BUTTON_BLUE};
  cursor: ${props => props.disabled ? 'not-allowed' : 'cursor'};
  &:active { color: white; background-color: ${colors.BLUE_DARK}; }
  &:focus { outline: none; }
`;
Submit.defaultProps = {
  type: 'submit'
};

export default Form;
export {SectionContainer, Section, TextField, SelectField, RadioField, PasswordField, CheckboxField, UploadField, ToggleSwitch};
