import {postJSON as post} from '../../api';
import {
  SET_LOCATION_OPENING_HOURS_START, SET_LOCATION_OPENING_HOURS_SUCCESS,
  SET_LOCATION_OPENING_HOURS_FAILURE,
} from './types';

const setLocationOpeningHoursStart = () => ({
  type: SET_LOCATION_OPENING_HOURS_START,
});

const setLocationOpeningHoursSuccess = () => ({
  type: SET_LOCATION_OPENING_HOURS_SUCCESS,
});

const setLocationOpeningHoursFailure = error => ({
  type: SET_LOCATION_OPENING_HOURS_FAILURE,
  error,
});

const setLocationOpeningHours = (authorization, locationId, body, callback) => {
  return dispatch => {
    dispatch(setLocationOpeningHoursStart());
    post(`locations/${locationId}/opening-hours`, {authorization}, body)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(setLocationOpeningHoursSuccess());
          callback && callback();
        } else if ((response.status >= 400) && (response.status < 500)) {
          return response.json();
        } else {
          dispatch(setLocationOpeningHoursFailure(response));
        }
      })
      .then(data => {
        if (data && data.error) {
          dispatch(setLocationOpeningHoursFailure(data));
        }
      })
      .catch(error => {
        dispatch(setLocationOpeningHoursFailure(error));
      });
  };
};

export default setLocationOpeningHours;
