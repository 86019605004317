import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import RoundedLabel from '../common/RoundedLabel';
import {TopBarBlock} from '../common/MainBlocks';
import TabLinkGroup from '../common/TabLinkGroup';
import TabLink from '../common/TabLink';

const Topbar = () => {
  const {t} = useTranslation();

  return (
    <TopBarBlock fixed white>
      <LeftSide>

        <TabLinkGroup>
          <TabLink
            to="/settings/profile"
            active={window.location.pathname === '/settings/profile'}>
            {t('profile')}
          </TabLink>
        </TabLinkGroup>

      </LeftSide>
    </TopBarBlock>
  );
};

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default Topbar;
