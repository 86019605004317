import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import signUpManager from '../../redux/actions/signUpManager';
import logInManager from '../../redux/actions/logInManager';
import getCurrentManager from '../../redux/actions/getCurrentManager';
import LoadingSpinner from '../common/LoadingSpinner';
import {Close} from '../common/Modal';
import Form, {SectionContainer} from '../common/Form';
import {FirstSection} from './Sections';
import validEmail from '../common/emailRegex';

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: { value: '', valid: undefined },
        first_name: { value: '', valid: undefined },
        last_name: { value: '', valid: undefined },
        password: { value: '', valid: undefined },
        confirm_password: { value: '', valid: undefined },
      },
      sectionIndex: 0,
    };
  }

  componentWillMount() {
    if (this.props.error) {
      this.props.error.error = null;
    }

    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
  }

  setData = (name, value, valid) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: { value, valid },
      },
    });
  }

  fieldIsValid = (name, value) => {
    const validPassword = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/;

    switch (name) {
      case 'email':
        return validEmail.test(value);
      case 'password':
        return validPassword.test(value);
      case 'confirm_password':
        return validPassword.test(value) && (value === this.state.data.password.value);
      default:
        return (value.length !== 0);
    }
  }

  handleChange = event => {
    const {name, value} = event.target;
    const valid = this.fieldIsValid(name, value);

    this.setData(name, value, valid);
  };

  handleSectionContinue = () => {
    this.setState({sectionIndex: this.state.sectionIndex + 1});
  }

  handleSectionBack = () => {
    this.setState({sectionIndex: this.state.sectionIndex - 1});
  }

  handleSubmit = event => {
    event.preventDefault();

    const {data} = this.state;
    const formData = {};

    for (let name in data) {
      formData[name] = data[name].value;
    }

    delete formData.confirm_password;
    formData.subscriber = true;

    const {signUpManager, logInManager, getCurrentManager, history} = this.props;

    signUpManager(formData, () => {
      const {email, password} = formData;

      logInManager({email, password}, accessToken => {
        getCurrentManager(`Bearer ${accessToken}`, () => history.push('/dashboard'));
      });
    });
  };

  render() {
    const {loading, history, error} = this.props;
    const {sectionIndex} = this.state;
    const {email, first_name, last_name, password, confirm_password} = this.state.data;

    const sections = [
      <FirstSection
        fields={{ email, first_name, last_name, password, confirm_password }}
        handleChange={this.handleChange}
        handleSectionContinue={this.handleSectionContinue}
        error={error}
      />,
    ];

    if (loading) {
      return (
        <Container>
          <LoadingSpinner/>
        </Container>
      );
    }

    return (
      <Container>
        <Close onClick={() => history.push('/')}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Form onSubmit={this.handleSubmit}>
          <SectionContainer
            sections={sections}
            sectionIndex={sectionIndex}
          />
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 40px 40px;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  padding: 0;
`;

const mapStateToProps = ({manager}) => ({
  ...manager,
});

const mapDispatchToProps = (dispatch) => ({
  signUpManager: (data, callback) => dispatch(signUpManager(data, callback)),
  logInManager: (data, callback) => dispatch(logInManager(data, callback)),
  getCurrentManager: (authorization, callback) =>
    dispatch(getCurrentManager(authorization, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpForm));
