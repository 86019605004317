import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';

// TODO Code reusability.
const ConnectorRow = ({type, status, power, rate}) => {
  let imageUrl;

  switch (type) {
    case 'Type 1':
      imageUrl = '/connectors/type1.svg';
      break;
    case 'Type 2':
      imageUrl = '/connectors/type2.svg';
      break;
    case 'CCS':
      imageUrl = '/connectors/ccs.svg';
      break;
    case 'Schuko':
      imageUrl = '/connectors/schuko.svg';
      break;
    case 'Chademo':
      imageUrl = '/connectors/chademo.svg';
      break;
    case '3-pin':
      imageUrl = '/connectors/3-pin.svg';
      break;
    case 'CCS 1':
      imageUrl = '/connectors/ccs1.svg';
      break;
    case 'CCS 2':
      imageUrl = '/connectors/ccs2.svg';
      break;
    case 'GB/T AC':
      imageUrl = '/connectors/gbt-ac.svg';
      break;
    case 'GB/T DC':
      imageUrl = '/connectors/gbt-dc.svg';
      break;
    case 'Tesla':
      imageUrl = '/connectors/tesla.svg';
      break;
    default:
      imageUrl = '/connectors/type1.svg';
  };

  return (
    <Container>
      <div>
        <ImageContainer className="image-container" status={status}>
          <Image src={imageUrl} />
        </ImageContainer> {type}
      </div>
      <div>
        {power} kW
      </div>
      <div>
        {rate / 100} ₽/min
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  min-height: 50px; max-height: 50px;
  padding: 0 12px;
  border-bottom: 1px solid ${colors.LINE};
  & > div {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 100%; max-height: 100%;
    min-width: 33%; max-width: 33%;
    font-size: 16px;
  }
  & > div:first-child {
    justify-content: flex-start;
  }
  &:first-child div.image-container {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  &:last-child div.image-container {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
`;

const ImageContainer = styled.div`
  min-height: 40px; max-height: 40px;
  min-width: 40px; max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: ${props => {
    switch(props.status) {
      case 'available':
        return colors.ICON_GREEN;
      case 'busy':
        return colors.ICON_BLUE;
      case 'faulted':
        return colors.ERROR;
      case 'offline':
      default:
        return 'gray';
    }
  }};
`;

const Image = styled.img`
  min-width: 28px; max-width: 28px;
  min-height: 28px; max-height: 28px;
  filter: brightness(2);
`;

export default ConnectorRow;
