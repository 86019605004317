import {getJSON as get} from '../../api';
import {GET_LOCATION_PHOTOS_START, GET_LOCATION_PHOTOS_SUCCESS, GET_LOCATION_PHOTOS_FAILURE} from './types';

const getPhotosStart = () => ({
  type: GET_LOCATION_PHOTOS_START,
});

const getPhotosSuccess = data => ({
  type: GET_LOCATION_PHOTOS_SUCCESS,
  data,
});

const getPhotosFailure = error => ({
  type: GET_LOCATION_PHOTOS_FAILURE,
  error,
});

const getPhotos = (locationId, params, callback) => {
  return dispatch => {
    dispatch(getPhotosStart());
    get(`locations/${locationId}/photos`, null, params)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getPhotosSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getPhotosFailure(error));
      });
  };
};

export default getPhotos;
