import {postJSON as post} from '../../api';
import {SIGN_UP_MANAGER_START, SIGN_UP_MANAGER_SUCCESS, SIGN_UP_MANAGER_FAILURE} from './types';

const signUpManagerStart = () => ({
  type: SIGN_UP_MANAGER_START,
});

const signUpManagerSuccess = () => ({
  type: SIGN_UP_MANAGER_SUCCESS,
});

const signUpManagerFailure = error => ({
  type: SIGN_UP_MANAGER_FAILURE,
  error,
});

const signUpManager = (body, callback) => {
  return dispatch => {
    dispatch(signUpManagerStart());
    post('auth/manager', null, body)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(signUpManagerSuccess());
          if (callback) { callback(); }
        } else if ((response.status >= 400) && (response.status < 500)) {
          return response.json();
        } else {
          dispatch(signUpManagerFailure(response));
        }
      })
      .then(data => {
        if (data && data.error) {
          dispatch(signUpManagerFailure(data));
        }
      })
      .catch(error => {
        dispatch(signUpManagerFailure(error));
      });
  };
};

export default signUpManager;
