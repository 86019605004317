import React from 'react';
import { node, bool, string, object } from 'prop-types';

import avatarDefaultImage from './defaultImage.svg';

import { Wrapper, Image, Text } from './Avatar.styled';

const propTypes = {
  children: node,
  rounded: bool,
  sizes: string,
  alt: string,
  src: string,
  other: object,
}

const Avatar = props => {
  let {
    children,
    rounded = false,
    size,
    imageSrc,
    imageAlt,
    ...other
  } = props;
  
  imageSrc = imageSrc ? imageSrc : avatarDefaultImage;

  return (
    <Wrapper
      rounded={rounded}
      size={size}
      {...other}>

      <Image
        alt={imageAlt}
        src={imageSrc}
      />

      {children && 
        <Text>
          {children}
        </Text>
      }

    </Wrapper>
  );
};

Avatar.propTypes = propTypes;

export default Avatar;