import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';

import * as colors from '../../util/colors';
import {fadeIn} from './animations';

const Modal = ({width, minHeight, maxHeight, responsive, children, padding}) => (
  <Backdrop>
    <GlobalStyle />
    <Container width={width} minHeight={minHeight} maxHeight={maxHeight} responsive={responsive} padding={padding}>
      {children}
    </Container>
  </Backdrop>
);

const GlobalStyle = createGlobalStyle`
  html, body {
    min-width: 100vw; max-width: 100vw;
    min-height: 100vh; max-height: 100vh;
    overflow: hidden;
  }
`;

const Backdrop = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  @media screen and (max-width: 576px) {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    padding: 0;
  }
  @media screen and (min-width: 576px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    padding: 3.5rem 0;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background-color: white;
  animation: .2s ${fadeIn()} ease-in-out;
  @media screen and (max-width: 576px) {
    min-width: 100vw; max-width: 100vw;
    min-height: 100vh;
    border: 0 transparent;
  }
  @media screen and (min-width: 576px) {
    margin: 0 auto;
    min-width: ${props => props.width || '480px'};
    max-width: ${props => props.width || '480px'};
    min-height: ${props => props.minHeight || '500px'};
    max-height: ${props => props.maxHeight || '95vh'};
    padding: ${props => props.padding || '0'};
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid ${colors.ICON_NON_ACTIVE}; border-radius: 5px;
  }
`;

const Close = styled.span`
  position: absolute; top: 0; right: 0;
  min-width: 2rem; max-width: 2rem;
  min-height: 2rem; max-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  color: ${colors.ICON_GRAY};
  cursor: pointer;
`;

export default Modal;
export {Close};
