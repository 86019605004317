import React, {Component} from 'react';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';

import * as colors from '../../util/colors';

class NotificationContainer extends Component {
  render() {
    const {t} = this.props;

    return (
      <Container>
        <Title>{t('notifications')}</Title>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-height: 25rem; max-height: 25rem;
  margin-bottom: 20px;
  border: 1px solid ${colors.LINE}; border-radius: 10px;
  background-color: white;
  box-shadow: 2px 2px 2px ${colors.LINE};
  @media screen and (max-width: 1400px) {
    min-width: 100%; max-width: 100%;
  }
  @media screen and (min-width: 1400px) {
    min-width: calc(35% - 15px); max-width: calc(35% - 15px);
    margin-left: 15px;
  }
  /**/display: none;
`;

const Title = styled.h4`
  padding: 1rem;
  color: ${colors.TEXT_MAIN};
  border-bottom: 1px solid ${colors.LINE};
`;

export default withTranslation()(NotificationContainer);
