import {getJSON as get} from '../../api';
import {GET_STATION_START, GET_STATION_SUCCESS, GET_STATION_FAILURE} from './types';
import setServerTime from "./setServerTime";

const getStationstart = () => ({
  type: GET_STATION_START,
});

const getStationsuccess = (data) => ({
  type: GET_STATION_SUCCESS,
  data,
});

const getStationFailure = error => ({
  type: GET_STATION_FAILURE,
  error,
});

const getStation = (authorization, stationId, callback) => {
  return dispatch => {
    dispatch(getStationstart());
    get(`stations/${stationId}`, {authorization})
      .then(async response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(setServerTime(response.headers.get('Date')));
          return response.json();
        }
      })
      .then((data) => {
        dispatch(getStationsuccess(data));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(getStationFailure(error));
      });
  };
};

export default getStation;
