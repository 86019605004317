import styled from "styled-components";
import * as colors from "../../util/colors";

const TitleField = styled.div`
  width: 250px;
  font-size: 14px;
  display: inline-block;
  margin-left: 16px;
  color: ${colors.TEXT_INPUT};
`;

export default TitleField;