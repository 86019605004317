import {postFormData as post} from '../../api';
import {ADD_LOCATION_PHOTO_START, ADD_LOCATION_PHOTO_SUCCESS, ADD_LOCATION_PHOTO_FAILURE} from './types';

const addPhotoStart = () => ({
  type: ADD_LOCATION_PHOTO_START,
});

const addPhotoSuccess = () => ({
  type: ADD_LOCATION_PHOTO_SUCCESS,
});

const addPhotoFailure = error => ({
  type: ADD_LOCATION_PHOTO_FAILURE,
  error,
});

const addPhoto = (authorization, locationId, data, callback) => {
  return dispatch => {
    dispatch(addPhotoStart());
    post(`locations/${locationId}/photos`, {authorization}, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(addPhotoSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(addPhotoFailure(error));
      });
  };
};

export default addPhoto;
