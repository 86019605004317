import {GET_STATISTICS_START, GET_STATISTICS_SUCCESS, GET_STATISTICS_FAILURE} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATISTICS_START:
      return {...state, loading: true};
    case GET_STATISTICS_SUCCESS:
      return {...state, data: action.data, error: null, loading: false};
    case GET_STATISTICS_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default statistics;
