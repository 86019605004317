import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';
import FeedbackCard from './FeedbackCard';

const FeedbackContainer = ({authorization, locationId, data, getUser}) => (
  <Container>
    {feedbackItems(data, authorization, locationId, getUser)}
  </Container>
);

const Container = styled.div`
  position: relative;
  display: block;
  background: white;
  padding: 24px 24px 0;
  border: 1px solid ${colors.LINE}; border-radius: 10px;
  margin-bottom: 24px;
`;

const feedbackItems = (data, authorization, locationId, getUser) => {
  const items = data.map((singleFeedback, i) =>
    <FeedbackCard key={i}
      {...singleFeedback}
      authorization={authorization}
      locationId={locationId}
      getUser={getUser}
    />
  );

  return items;
};

export default FeedbackContainer;
