import React from 'react';

const SuccessPayment = () => {

  return (
      <div>Успешная оплата</div>
  );
};

export default SuccessPayment;
