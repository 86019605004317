import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import getStation from '../../../redux/actions/getStation';
import updateStation from '../../../redux/actions/updateStation';
import changeStationStatus from '../../../redux/actions/changeStationStatus';
import getLocationStations from '../../../redux/actions/getLocationStations';
import Modal from '../../common/Modal';
import StationSettingsForm from './StationSettingsForm';

function StationSettings(props) {
  if (!props.loggedIn) {
    props.history.push('/');
    return false;
  }

  return (
    <Modal minHeight='300px' maxHeight='95vh'>
      <StationSettingsForm
        authorization={props.authorization}
        stationId={props.match.params.stationId} locationId={props.locationId}
        data={props.data} loading={props.loading} error={props.error}
        getStation={props.getStation} updateStation={props.updateStation}
        changeStationStatus={props.changeStationStatus}
        getLocationStations={props.getLocationStations}
      />
    </Modal>
  );
}

const mapStateToProps = ({manager, station}, {match}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  ...station,
});

const mapDispatchToProps = dispatch => ({
  getStation: (authorization, stationId, callback) =>
    dispatch(getStation(authorization, stationId, callback)),
  updateStation: (authorization, stationId, data, callback) =>
    dispatch(updateStation(authorization, stationId, data, callback)),
  changeStationStatus: (authorization, stationId, data, callback) =>
    dispatch(changeStationStatus(authorization, stationId, data, callback)),
  getLocationStations: (authorization, locationId, params, callback) =>
    dispatch(getLocationStations(authorization, locationId, params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StationSettings));
