import React from 'react';

import styled from "styled-components";

export const ReportsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > div {
    & > div {
      margin-left: 4px;
      margin-right: 4px;
      & :last-child {
        margin-right: 0px;
      }
    }
  }
  padding: 24px 24px 16px;
  padding-top: 16px;
  border-bottom: 1px solid #E6EAED;
`;
