import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import * as colors from '../../../util/colors';

function PaymentDetails(props) {
  const bankAccount = props.paymentAccount.bank_account;
  const {card} = props.paymentMethod;
  const {t} = props;

  return (
    <Container>
      <h3 className="title">{t('paymentDetails')}</h3>
      <hr/>
      <div className="payment-details-container">
        {(bankAccount && bankAccount.bank_name &&
          <div className="bank-account-container">
            <h4 className="title">{t('bankAccount')}</h4>
            <img src="/bankIcon.svg" alt="info icon" className="bank-icon" />
            <div className="info-container">
              <div className="bank-name">
                {bankAccount.bank_name} ({bankAccount.currency.toUpperCase()})
              </div>
            </div>
          </div>
        )}
        {(bankAccount && bankAccount.bank_name && <hr/>)}
        {(card &&
            <div className="payment-method-container">
              <h4 className="title">{t('paymentMethod')}</h4>
              <img src={`/${card.brand.toLowerCase()}.svg`} alt={`${card.brand} icon`} className="card-icon" />
              <div className="info-container">
                <div className="card-info">
                  {`${capitalizeFirstLetter(card.brand)} *${card.last4}`}
                </div>
              </div>
            </div>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: block;
  max-width: 740px;
  margin: 0 auto;
  margin-top: 24px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px ${colors.LINE};
  & > h3.title {
    padding: 24px 24px 1px 24px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  & > div.payment-details-container {
    display: inline-block;
    padding: 0 24px 24px 24px;
    width: 100%;
    & > div.bank-account-container {
      & > h4.title {
        margin-top: 4px;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: bold;
      }
      & > img.bank-icon {
        display: inline;
        margin-right: 11px;
        vertical-align: top;
      }
      & > div.info-container {
        display: inline-block;
        & > div.bank-name {
          font-size: 14px;
          margin-top: 6px;
          margin-bottom: 8px;
        }
      }
    }
    & > div.payment-method-container {
      min-height: 70px; max-height: 70px;
      & > h4.title {
        margin-top: 4px;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: bold;
      }
      & > img.card-icon {
        display: inline;
        margin-right: 11px;
        vertical-align: top;
      }
      & > div.info-container {
        display: inline-block;
        & > div.card-info {
          font-size: 14px;
          margin-top: 6px;
        }
      }
    }
  }
`;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default compose(withTranslation(), withRouter)(PaymentDetails);
