import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import * as colors from '../../../util/colors';
import Button from '../../common/Button';
import ChangeLanguage from '../../common/ChangeLanguage';
import Avatar from '../../common/Avatar';

class ProfileContainer extends Component {
  componentWillMount() {
    const {authorization, getCurrentManager} = this.props;
    getCurrentManager(authorization);
  };

  render() {
    const {data, logOutManager, history, t} = this.props;

    return (
      <Container>
        <Section padding="24px 24px 16px 24px">
          <AvatarWrapper>
            <Avatar
              rounded
              size="120px"
              src={data.avatar_url}
            />
          </AvatarWrapper>
          <InfoContainer>
            <h3 className="title">{data.first_name} {data.last_name}</h3>
            <div className="email">{data.email}</div>
            <div className="phone">{data.phone}</div>
          </InfoContainer>
        </Section>
        <Section padding="16px 24px">
          <Password>
            {t('password')}: <span className="hidden-password">*******</span>
            <ChangePassword
              onClick={() => history.push('/settings/change-password')}>
              {t('changePassword')}
            </ChangePassword>
          </Password>
        </Section>
        <Section padding="16px 24px">
          <Language>
            {t('language')}:
            <ChangeLanguageWrap>
              <ChangeLanguage withText bordered />
            </ChangeLanguageWrap>
          </Language>
        </Section>
        <Section height="88px" padding="16px 24px">
          <LogoutButton onClick={() => logOutManager()}>
            {t('logOut')}
          </LogoutButton>
        </Section>
      </Container>
    );
  };
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 740px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px ${colors.LINE};
`;

const Section = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: ${props => props.height || 'auto'};
  max-height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || '24px'};
  border-bottom: 1px solid ${colors.LINE};
  &:last-child {
    border-bottom: 0;
  }
`;

const AvatarWrapper = styled.div`
  margin-right: 24px;
`;

const InfoContainer = styled.div`
  & > .title {
    font-size: 20px;
    font-weight: bold;
  }
`;

const Password = styled.div`
  position: relative;
  min-width: 100%; max-width: 100%;
  & > span.hidden-password {
    color: ${colors.TEXT_MINOR};
  }
`;

const Language = styled.div`
  position: relative;
  min-width: 100%; max-width: 100%;
`;

const ChangePassword = styled.div`
  position: absolute; top: 0; right: 0; bottom: 0;
  color: ${colors.BLUE_MAIN};
  cursor: pointer;
`;

const ChangeLanguageWrap = styled.div`
  position: absolute; top: 0; right: 0; bottom: 0;
  cursor: pointer;
  margin-top: -8px;
`;

const LogoutButton = styled(Button)`
  position: absolute; top: 0; right: 0;
  min-width: 120px; max-width: 120px;
  margin: 24px 24px;
`;

export default compose(withTranslation(), withRouter)(ProfileContainer);
