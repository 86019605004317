import React from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

const TabLink = props => {
  const {
    children,
    active,
    history,
    to,
    marginRight,
    ...other
  } = props;

  const path = window.location.pathname;

  return (
    <Tab
      active={path === to}
      marginRight={marginRight}
      to={to}
      onClick={() => to && history.push(to)}
      {...other}>
      {children}
    </Tab>
  );
};

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  font-weight: bold;
  font-size: 20px;
  color: ${props => props.active ? '#202020;' : '#8f8f8f'};
  padding-top: 4px;
  border-bottom: 4px solid;
  border-bottom-color: ${props => props.active ? '#2E83FF' : 'transparent'};
  cursor: ${props => props.to ? 'pointer' : 'default'};

  &:not(:first-child) {
    margin-left: ${props => props.marginRight ? '32px' : null}
  }

  &:hover {
    color: #202020;
  }
`;

TabLink.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  other: PropTypes.object,
};

export default withRouter(TabLink);