export const BLUE_MAIN   = '#2E83FF';
export const BLUE_DARK   = '#0A4EB3';
export const GREEN_MAIN  = '#51CE16';
export const GREEN_DARK  = '#3E9B13';
export const ORANGE_MAIN = '#F69B0F';
export const ORANGE_DARK = '#B9600F';
export const RED_MAIN    = '#EE290F';
export const ERROR       = '#DF2D14';

export const ICON_YELLOW = '#D5DD0D';
export const ICON_ORANGE = '#F7B93C';
export const ICON_LIGHT_BLUE = '#3CE7F7';
export const ICON_BLUE       = '#3C92F7';
export const ICON_GRAY       = '#83868F';
export const ICON_LIGHT_GREEN = '#C5F73C';
export const ICON_GREEN      = '#62D12F';
export const ICON_NON_ACTIVE = '#CCCED7';

export const MARKER_BLUE  = '#3A86F6';
export const MARKER_GREEN = '#61CF2D';
export const MARKER_GRAY  = '#94969E';

export const TEXT_BLUE  = '#2A79EE';
export const TEXT_GREEN = '#4ABC14';

export const TEXT       = '#1F1F1F';
export const TEXT_TITLE = '#202020';
export const TEXT_MAIN  = '#2F3033';
export const TEXT_MINOR = '#818286';
export const TEXT_INPUT = '#A2A6AB';
export const TEXT_BG    = '#787E87';

export const OPTION_BG        = '#45474D';
export const OPTION_BG_ACTIVE = '#38393D';

export const BLUE_LIGHT    = '#E1EEFF';
export const BG            = '#F5F7F9';
export const BUTTON_STROKE = '#9097A0';
export const INPUT_STROKE  = '#CAD0D9';
export const LINE          = '#E6EAED';

export const BUTTON_BLUE = '#4792FF';

export const PRIMARY_COLOR = '#2A79EE';
