import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import * as colors from "../../../util/colors";
import {connect} from "react-redux";
import changeStationStatus from "../../../redux/actions/changeStationStatus";

import EvseConnector from "./EvseConnector";
import EvseMeterValues from "../../ChargePoints/EvseMeterValues";
import EvseConnectorsList from "../../ChargePoints/EvseConnectorsList";
import ExpandIcon from "../../common/ExpandIcon";
import {FreeSpace} from "../../common/FlexItem";
import OperativeStatus from "../../common/OperativeStatus";
import {ToggleField} from "../../Station/StationInformationTab";

const Evse = ({t, authorization, changeStationStatus, ...props}) => {
    const [isOpened, setOpened] = React.useState(false);
    const toggleOpened = () => setOpened(!isOpened);

    const handleChangeStatus = (e) => {
        const operational_status = e.target.checked ? 'operative' : 'inoperative';

        changeStationStatus(authorization, props.stationId, { operational_status, evses: [props.number] });
    };

    return (
        <>
            <EvseStyled connectors={props.connectors} onClick={toggleOpened}>
                <EvseNumber>EVSE #{props.number}</EvseNumber>
                <EvseConnectorContainer>
                    {evseConnectorItems(props.connectors)}
                </EvseConnectorContainer>
                {props.full && (
                    <OperativeStatus operative={props.operational} male />
                )}
                {props.editable && (
                    <ToggleField
                        style={{marginLeft: '30px'}}
                        value={props.operational}
                        handleChange={handleChangeStatus}
                    />
                )}
                {props.full && (
                    <FreeSpace>
                        <ExpandIcon opened={isOpened}/>
                    </FreeSpace>
                )}
            </EvseStyled>

            {props.full && isOpened && (
                <>
                    <EvseConnectorsList {...props}/>
                    {props.isAdministrator && <EvseMeterValues stationId={props.stationId} number={props.number} />}
                </>
            )}
        </>
    )
};

// TODO Code reusability.
const evseConnectorItems = connectors => {
    const items = connectors.map((connector, i) => {
        return (
            <EvseConnector key={connectors[i].id} {...connector} />
        );
    });
    return items;
};

export const EvseStyled = styled.div`
  display: flex;
  align-items: center;
  min-height: 55px; max-height: 55px;
  border-bottom: 1px solid ${colors.LINE};
  &:last-child {
    border-bottom: 0;
  }
`;

export const EvseNumber = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin: 0 16px;
`;

export const EvseConnectorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const mapStateToProps = ({manager}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    isAdministrator: manager.data?.role === 'administrator',
    isManager: manager.data?.role === 'manager',
});

const mapDispatchToProps = dispatch => ({
    changeStationStatus: (...params) => dispatch(changeStationStatus(...params)),
});

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Evse);
