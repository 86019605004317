import {deleteJSON} from '../../api';
import {
  DELETE_LOCATION_START, DELETE_LOCATION_SUCCESS, DELETE_LOCATION_FAILURE,
} from './types';

const deleteLocationStart = () => ({
  type: DELETE_LOCATION_START,
});

const deleteLocationSuccess = () => ({
  type: DELETE_LOCATION_SUCCESS,
});

const deleteLocationFailure = error => ({
  type: DELETE_LOCATION_FAILURE,
  error,
});

const deleteLocation = (authorization, locationId, callback) => {
  return dispatch => {
    dispatch(deleteLocationStart());
    deleteJSON(`locations/${locationId}`, {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(deleteLocationSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(deleteLocationFailure(error));
      });
  };
};

export default deleteLocation;
