import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import Sidebar from '../common/Sidebar';
import {WrapperBlock, PageBlock, TopBarBlock, PageTitle, PageInnerBlock} from '../common/MainBlocks';
import AntiSanctionProtectionTable from "./AntiSanctionProtectionTable";
import AntiSanctionProtectionTopbar from "./AntiSanctionProtectionTopbar";
import {getAntiSanctionVersions} from "../../api";
import TopbarMobile from "../common/TopbarMobile";

function Dashboard({loggedIn, role, history, authorization}) {
    if (!loggedIn || ['administrator', 'app-developer'].includes(role) === false) {
        history.push('/');
    }

    const [data, setData] = React.useState([]);

    const getVersions = () => {
        getAntiSanctionVersions({authorization})
            .then(response => response.json())
            .then((response) => {
                setData(response);
            });
    };

    return (
        <WrapperBlock>
            <TopbarMobile />
            <Sidebar/>
            <PageBlock>
                <TopBarBlock>
                    <PageTitle>Антисанкции. Версии</PageTitle>
                </TopBarBlock>
                <PageInnerBlock>
                    <AntiSanctionProtectionTopbar
                        getVersions={getVersions}
                        data={data}
                    />
                    <AntiSanctionProtectionTable
                        getVersions={getVersions}
                        data={data}
                    />
                </PageInnerBlock>
            </PageBlock>
        </WrapperBlock>
    );
}

const mapStateToProps = ({manager}) => ({
    loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
    role: manager.data?.role,
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
});

export default compose(connect(mapStateToProps, null), withTranslation(), withRouter)(Dashboard);
