import React from 'react';
import {useTranslation} from 'react-i18next';
import {func, bool} from 'prop-types';

import {Backdrop, Container, LinkContainer, Times} from './Sidebar.styled';
import {DashboardMenuItem, StatisticsByDayMenuItem, ChargePointsMenuItem, LocationsMenuItem, MapMenuItem, AspMenuItem, ProfileMenuItem} from '../SidebarMenuItems';
import {compose} from "redux";
import {connect} from "react-redux";

const propTypes = {
  visible: bool,
  hide: func,
};

const Sidebar = props => {
  const {visible, hide, role} = props;
  const {t} = useTranslation();

  const pathName = window.location.pathname.split('/')[1];

  const isAdministrator = role === 'administrator';
  const isAppDeveloper = role === 'app-developer';
  const isAccounter = role === 'accounter';
  const isManager = role === 'manager';

  return (
    <Backdrop visible={visible}>
      <Container>
        <Times onClick={hide}>&times;</Times>
        <LinkContainer>
          {!isAccounter && (
              <DashboardMenuItem pathName={pathName} />
          )}

          {(isAccounter || isAdministrator) && (
              <StatisticsByDayMenuItem pathName={pathName} />
          )}

          {(isAdministrator || isManager) && (
              <ChargePointsMenuItem pathName={pathName} />
          )}

          {isAdministrator && (
              <LocationsMenuItem pathName={pathName} />
          )}

          <MapMenuItem pathName={pathName} />

          {(isAdministrator || isAppDeveloper) && (
              <AspMenuItem pathName={pathName} />
          )}

          <ProfileMenuItem pathName={pathName} />

        </LinkContainer>
      </Container>
    </Backdrop>
  );
};

Sidebar.propTypes = propTypes;

const mapStateToProps = ({manager}, {match}) => ({
  role: manager.data?.role,
});

export default compose(
    connect(mapStateToProps),
)(Sidebar);
