import React from 'react';
import {withTranslation} from 'react-i18next';

import {
  Container,
  TextField,
  SubmitButton,
} from './Form.styled';

const Form = props => {
  const {t} = props;

  return (
    <Container>
      <input type="hidden" name="u" value="1699304bde6a6574eb3221efb" />
      <input type="hidden" name="id" value="a9893fc825" />
      <TextField
        name="MERGE0"
        id="MERGE0"
        size="25"
        placeholder={t('enterEmailAddress')}
      />
      <SubmitButton>
        <img 
          className="arrow" 
          src="/send.svg" 
          alt="cow" 
          onContextMenu={event => event.preventDefault()}
        />
      </SubmitButton>
    </Container>
  );
};

export default withTranslation()(Form);
