import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getStatistics from '../../redux/actions/getStatistics';
import clearStation from '../../redux/actions/clearStation';
import Sidebar from '../common/Sidebar';
import {WrapperBlock, PageBlock, TopBarBlock, PageTitle} from '../common/MainBlocks';
import StatisticsContainer from './StatisticsContainer';
import NotificationContainer from './NotificationContainer';
import ChargingHistoryContainer from './ChargingHistoryContainer';
import ChargingNowContainer from './ChargingNowContainer';
import TopbarMobile from "../common/TopbarMobile";

class Dashboard extends Component {
  componentWillMount() {
    // document.querySelector('meta[name="viewport"]').setAttribute('content', '');
  }

  componentDidMount() {
    const {loggedIn, history, isAccounter} = this.props;

    this.props.clearStation();

    if (!loggedIn) {
      history.push('/');

      return false;
    }

    if (isAccounter) {
      history.push('/statistics-by-day');
      return false;
    }
  };

  render() {
    const {
      t,
      authorization,
      getStatistics,
      statisticsData,
    } = this.props;

    return (
      <WrapperBlock>
        <TopbarMobile />
        <Sidebar />
        <PageBlock>
          <TopBarBlock>
            <PageTitle>{t('dashboard')}</PageTitle>
          </TopBarBlock>
          <StatisticsContainer
            authorization={authorization}
            getStatistics={getStatistics}
            statistics={statisticsData}
          />
          <NotificationContainer />
          <ChargingNowContainer
            authorization={authorization}
          />
          <ChargingHistoryContainer
            authorization={authorization}
          />
        </PageBlock>
      </WrapperBlock>
    );
  };
}

const mapStateToProps = ({manager, statistics}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  statisticsData: statistics.data,
  statisticsLoading: statistics.loading,
  statisticsError: statistics.error,
  isAccounter: manager.data?.role === 'accounter',
});

const mapDispatchToProps = dispatch => ({
  getStatistics: (authorization, params, callback, excludeLoading = false) =>
    dispatch(getStatistics(authorization, params, callback, excludeLoading)),
  clearStation: () => dispatch(clearStation()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(), withRouter)(Dashboard);
