import React from "react";
import {postLocationPricing, postStartCharging, postStopCharging} from "../../api";
import styled from "styled-components";

import EvseConnectorStatus from "./EvseConnectorStatus";
import TitleField from "../common/TitleField";
import {SelectField, TextField} from "../common/Form";
import Button from "../common/Button";
import {connect} from "react-redux";
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import LoadingSpinner from "../common/LoadingSpinner";
import Alert from "../common/Alerts/Alert";
import * as colors from "../../util/colors";

const EvseConnectorSettings = ({authorization, ...props}) => {
    props.charge_amount = '';
    props.charge_kwh = '';
    props.charge_hours = '';
    props.charge_minutes = '';

    const [formData, setFormData] = React.useState(props);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        if (name === 'charge_amount') {
            setFormData({
                ...formData,
                [name]: value,
                charge_kwh: formData.rate > 0 ? Math.floor(10 * value * 100 / formData.rate)/10 : 100,
                charge_hours: '',
                charge_minutes: '',
            });
        }
        if (name === 'charge_kwh') {
            setFormData({
                ...formData,
                [name]: value,
                charge_amount: Math.ceil(100 * value * formData.rate / 100) / 100,
                charge_hours: '',
                charge_minutes: '',
            });
        }
        if (name === 'charge_hours' || name === 'charge_minutes') {
            setFormData({
                ...formData,
                [name]: value,
                charge_amount: '',
                charge_kwh: '',
            });
        }
    }

    const [statusRelativeDate, statusFullDate] = getRelativeAndFullDatesWithServerOffset(props.status_updated_at, props.serverTimeOffset);

    const handleSave = useSavePricingEffect(props.location_id, formData, authorization);

    const {
        loading,
        response,
        startConnectorHandler,
        stopConnectorHandler,
        clearResponse,
    } = useStartEffect(props.id, formData, authorization);

    return (
        <EvseConnectorSettingsStyled>
            <TitleField>Номер</TitleField>{props.number}
            <br/>

            <EvseStatusStyle>
                <TitleField>Статус</TitleField>
                <EvseConnectorStatus status={props.status} male/>
                {` ${[props.origin_status, props.error_code].join(', ')} `}
                {props.info && `(${props.info})`}
                <EvseStatusTimestamp>
                    {statusRelativeDate}
                    {statusFullDate ? ` (${statusFullDate})` : ''}
                </EvseStatusTimestamp>
            </EvseStatusStyle>
            <br/>

            {props.status === 'available' && props.editable &&
                <EvseFrame>
                    <EvseConnectorTextField
                        label="Сумма зарядки, руб"
                        name="charge_amount"
                        handleChange={handleChange}
                        onClick={(e)=>e.target.select()}
                        {...formData}
                    />
                    <EvseConnectorTextField
                        label="Энергия, кВтч"
                        name="charge_kwh"
                        handleChange={handleChange}
                        onClick={(e)=>e.target.select()}
                        {...formData}
                    />
                    <TitleField>Зарядка по времени, часы:мин.</TitleField>
                    <TextField
                        name="charge_hours"
                        value={formData.charge_hours}
                        width="50px"
                        handleChange={handleChange}
                        onClick={(e)=>e.target.select()}
                    /> :
                    <TextField
                        name="charge_minutes"
                        value={formData.charge_minutes}
                        width="50px"
                        handleChange={handleChange}
                        onClick={(e)=>e.target.select()}
                    />
                    <Button
                        onClick={startConnectorHandler}
                        minWidth="131px"
                        maxWidth="none"
                        height="40px"
                        margin="0"
                    >Старт зарядки</Button>
                </EvseFrame>
            }
            {props.status === 'busy' && props.editable &&
                <EvseNoFrame>
                <Button
                    onClick={stopConnectorHandler}
                    minWidth="131px"
                    maxWidth="none"
                    height="40px"
                    margin="0"
                >Отменить зарядку</Button>
                </EvseNoFrame>
            }
            <br/>


            <TitleField>Ток</TitleField>{props.current}
            <br/>

            <TitleField>Тип коннектора</TitleField>{props.type}
            <br/>

            <TitleField>Мощность, кВт/ч</TitleField>{props.power}
            <br/>

            {props.monetized
                ? <EvseFrame>
                    <EvseConnectorTextField
                        label="Комиссия за начало зарядки, коп."
                        name="start_fee"
                        handleChange={handleChange}
                        {...formData}
                    />

                    <EvseConnectorSelectField
                        label="Тариф"
                        name="tariff"
                        options={[
                            // {id: 'minute', name: 'За минуту'},
                            {id: 'kwh', name: 'За кВтч'},
                        ]}
                        selectedOption={{id: 'kwh', name: 'За кВтч'}}
                        handleChange={handleChange}
                        {...formData}
                    />

                    <EvseConnectorTextField
                        label="Прайс, копеек за кВт"
                        name="rate"
                        handleChange={handleChange}
                        {...formData}
                    />
                    {props.editable && (
                        <Button
                            onClick={handleSave}
                            minWidth="131px"
                            maxWidth="none"
                            height="40px"
                            margin="0"
                        >
                            Изменить тариф
                        </Button>
                    )}

                </EvseFrame>
                : <span style={{display: 'inline-block', verticalAlign: 'top', color:'coral', marginLeft: '16px'}}>Монетизация выключена!</span>
            }

            {loading && (
                <LoadingSpinner/>
            )}
            {response && (
                <Alert
                    onClose={clearResponse}
                    title={response}
                />
            )}
        </EvseConnectorSettingsStyled>
    );
};

const EvseConnectorTextField = (props) => {
    const value = props[props.name];

    return (
        <>
            <TitleField>{props.label}</TitleField>
            {!props.editable && (
                value
            )}
            {props.editable && (
                <TextField
                    name={props.name}
                    value={value}
                    width="150"
                    handleChange={props.handleChange}
                    disabled={props.name === 'start_fee'}
                    onClick={props.onClick}
                />
            )}
            <br/>
        </>
    )
}

const EvseConnectorSelectField = ({name, options, ...props}) => {
    const value = props[name];
    const findOptionByValue = value => options.find(option => option.id === value);
    const [selectedOption, selectOption] = React.useState(findOptionByValue(value) || options[0]);

    React.useEffect(() => {
        selectOption(findOptionByValue(value) || options[0])
    }, [options, value]);

    return (
        <>
            <TitleField>{props.label}</TitleField>
            {!props.editable && (
                value
            )}
            {props.editable && (
                <SelectField
                    noDot
                    selectedFieldStyle={{border: '1px solid #CAD0D9'}}
                    optionHoverColor="#E1EEFF"
                    name={name}
                    width='150px'
                    selectedOption={selectedOption}
                    options={options}
                    handleChange={props.handleChange}
                />
            )}
            <br/>
        </>
    )
}

const useSavePricingEffect = (location_id, formData, authorization) => React.useCallback(() => {
    const VALUES = ['location_id', 'type', 'current', 'power', 'tariff'];
    const INTEGERS = ['rate', 'start_fee'];
    const sendData = {};

    VALUES.forEach(key => sendData[key] = formData[key]);
    INTEGERS.forEach(key => sendData[key] = parseInt(formData[key]));

    postLocationPricing(location_id, sendData, {authorization})
        .then(response => response.text())
        .then(() => {
            window.location.reload();
        });
}, [location_id, formData, authorization])


const useStartEffect = (id, formData, authorization) => {
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState('');

    const startConnectorHandler = React.useCallback(() => {
        setLoading(true);
        const sendData = {
            limit_amount: parseFloat(formData.charge_amount) * 100 || undefined,
            limit_kwh: parseFloat(formData.charge_kwh) || undefined,
            limit_hours: parseInt(formData.charge_hours) || undefined,
            limit_minutes: parseInt(formData.charge_minutes) || undefined,
        };

        postStartCharging(id, sendData, {authorization})
            .then(response => response.text())
            .then(data => {
                let message = (data === 'Accepted') ? 'Запрос принят' : data;
                try {
                    const order = JSON.parse(data);
                    if (order && order.error) { message = `Ошибка: ${order.error}`; }
                    if (order && order.id) { message = `Запрос принят, ID: ${order.id}`; }
                } catch (e) {}
                setResponse(message);
                setLoading(false);
            });
    }, [id, formData, authorization]);

    const stopConnectorHandler = React.useCallback(() => {
        setLoading(true);
        postStopCharging(id, {authorization})
            .then(response => response.text())
            .then(data => {
                let message = (data === 'Accepted') ? 'Запрос принят' : data;
                try {
                    const order = JSON.parse(data);
                    if (order && order.error) { message = `Ошибка: ${order.error}`; }
                    if (order && order.id) { message = `Запрос принят, ID: ${order.id}`; }
                } catch (e) {}
                setResponse(message);
                setLoading(false);
            });
    }, [id, formData, authorization]);

    const clearResponse = () => {
        setResponse('');
        window.location.reload();
    };

    return {loading, response, clearResponse, startConnectorHandler, stopConnectorHandler}
};


export const EvseStatusTimestamp = styled.span`
  display: none;
  color: ${colors.MARKER_GRAY};
`;

const EvseConnectorSettingsStyled = styled.div`
  margin-left: 24px;
  padding-bottom: 24px;
`;

const EvseStatusStyle = styled.span`
  &:hover ${EvseStatusTimestamp} {
    display: inline;
  }
`;

const EvseFrame = styled.div`
    border: 10px solid #eff1f3;
    border-radius: 5px;
    padding: 10px 20px 10px 0;
    display: inline-block;
    margin: 10px 0;
    button {
        margin-left: 266px;
    }
`;

const EvseNoFrame = styled.div`
    padding: 10px 20px 10px 0;
    display: inline-block;
    margin: 10px 0;
    button {
        margin-left: 266px;
    }
`;

const mapStateToProps = ({manager, app}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    isAdministrator: manager.data?.role === 'administrator',
    serverTimeOffset: app.serverTimeOffset,
});

export default connect(mapStateToProps)(EvseConnectorSettings);
