import {getJSON as get} from '../../api';
import {GET_CURRENT_MANAGER_START, GET_CURRENT_MANAGER_SUCCESS, GET_CURRENT_MANAGER_FAILURE} from './types';

const getCurrentManagerStart = () => ({
  type: GET_CURRENT_MANAGER_START,
});

const getCurrentManagerSuccess = data => ({
  type: GET_CURRENT_MANAGER_SUCCESS,
  data,
});

const getCurrentManagerFailure = error => ({
  type: GET_CURRENT_MANAGER_FAILURE,
  error,
});

const getCurrentManager = (authorization, callback) => {
  return dispatch => {
    dispatch(getCurrentManagerStart());
    get('auth/manager', {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getCurrentManagerSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getCurrentManagerFailure(error));
      });
  };
};

export default getCurrentManager;
