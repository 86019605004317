import React from 'react';

import {SelectField} from "../common/Form";
import {IntervalItem} from "../Dashboard/StatisticsContainer.styled";

export default function PaymentTypeFilter({ onChange, defaultType }) {
  const items = [
    {name: 'card', id: 'card,account'},
    {name: 'terminal', id: 'terminal'},
    {name: 'cash', id: 'cash'},
  ];

  const defaultSelectedType = items.find(({id}) => id === defaultType) || items[0];
  const [selectedType, setSelectedType] = React.useState(defaultSelectedType);

  const handleChange = (type) => {
    setSelectedType(items.find(({id}) => id === type));
    onChange(type);
  }

  return (
    <IntervalItem>
      <SelectField
        noDot
        optionHoverColor="#E1EEFF"
        name="paymentTypeFilter"
        selectedFieldStyle={{border: '1px solid #CAD0D9'}}
        width='132px'
        selectedOption={selectedType}
        handleChange={option => handleChange(option.target.value)}
        options={items}
      />
    </IntervalItem>
  )
}
