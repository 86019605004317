import React from 'react';
import {Row} from 'reactstrap';
import styled from 'styled-components';

import Greeting from './Greeting';
import Illustration from './Illustration';
import Footer from '../common/Footer';
import {Wrapper} from '../common/MainBlocks';
import Topbar from '../common/Topbar';

const Home = () => {
  return (
    <>
      <Topbar />

      <Wrapper direction="column">
          <RowContainer>
            <Greeting />
            <Illustration />
          </RowContainer>
      </Wrapper>

      <Footer />
    </>
  );
};

const RowContainer = styled(Row)`
  min-height: 100vh;
  width: 100vw;
`;

export default Home;
