export default {
  phone: '576px',
  tablet: '768px',
  laptop: '992px',
  desktop: '1224px',
  largeDesktop: '1824px',
};

export const getWindowWidth = () => window.innerWidth;
export const getWindowHeight = () => window.innerHeight;
