import styled from 'styled-components';

import * as colors from '../../util/colors';
import bp from '../common/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  border-bottom: 1px solid ${colors.LINE};

  @media screen and (max-width: ${bp.tablet}) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  display: block;
  min-width: 50%;
  max-width: 50%;

  @media screen and (max-width: ${bp.tablet}) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const Title = styled.div`
  display: block;
  margin: 6px 0;
  font-size: 20px;
  font-weight: bold;
`;

export const Rating = styled.span`
  margin-left: 12px;
  font-size: 18px;
  color: ${colors.ORANGE_MAIN};
`;

export const Street = styled.div`
  font-size: 16px;
  color: ${colors.TEXT_MINOR};
`;

export const OpeningHoursContainer = styled.div`
  display: block;
  min-width: 100%; max-width: 100%;
  font-size: 16px;
`;

export const OpeningHours = styled.div`
  display: inline-block;
  min-width: 145px; max-width: 145px;
  margin-bottom: 3px;
  color: ${colors.TEXT_MINOR};
  & > span.hours {
    color: black;
    float: right;
    padding-right: 14px;
  }
`;

export const OptionsToggle = styled.span`
  position: absolute; top: 24px; right: 24px;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.TEXT_MINOR};
  cursor: pointer;
  z-index: 2;
`;

export const OptionContainer = styled.div`
  position: absolute; top: 62px; right: 15px;
  display: ${props => props.visible ? 'block' : 'none'};
  transform: translateX(12px) translateY(-6px);
  overflow: hidden;
  border-radius: 10px;
  background-color: ${colors.OPTION_BG};
  z-index: 1;
`;

export const Triangle = styled.div`
  position: absolute; top: -20px; right: 12px;
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  border-top: 0 solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${colors.OPTION_BG};
`;

export const Option = styled.span`
  display: block;
  padding: 12px; padding-right: 32px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  &:nth-child(2) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:hover { background-color: ${colors.OPTION_BG_ACTIVE}; }
`;