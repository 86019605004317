import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Modal from '../common/Modal';
import LogInForm from './LogInForm';
import SendPasswordForm from './SendPasswordForm';
import InfoRecoveryForm from './InfoRecoveryForm';

const LogIn = ({loggedIn, history}) => {
  // const [form, setForm] = useState('login');
  const [form, setForm] = useState('login');

  if (loggedIn) {
    history.push('/dashboard');
    return false;
  }

  return (
    <Modal minHeight="376px">
      {form === 'login' && <LogInForm setForm={setForm} />}
      {form === 'reset_password' && <SendPasswordForm setForm={setForm} />}
      {form === 'info_recovery' && <InfoRecoveryForm setForm={setForm} />}
    </Modal>
  );
};

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
});

export default connect(mapStateToProps, null)(withRouter(LogIn));
