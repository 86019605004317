import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import * as colors from '../../../util/colors';

class PhotoCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsVisible: false,
    };
  }

  handleOptionsToggle = () => {
    this.setState({optionsVisible: !this.state.optionsVisible});
  };

  handleDeletePhoto = () => {
    const {locationId, id, history} = this.props;

    history.push(`/locations/${locationId}/photos/${id}/delete`);

    this.handleOptionsToggle();

    return false;
  };

  render() {
    const {url, t} = this.props;
    const {optionsVisible} = this.state;

    return (
      <Container>
        <TopContainer>
          <OptionsToggle onClick={this.handleOptionsToggle}>
            <i className="fa fa-ellipsis-v" />
          </OptionsToggle>
          <OptionContainer visible={optionsVisible}>
            <Triangle />
            <Option onClick={this.handleDeletePhoto}>
              <i className="fa fa-trash" />{t('delete')}
            </Option>
          </OptionContainer>
        </TopContainer>
        <MiddleContainer>
          <Photo src={url} />
        </MiddleContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  min-height: 250px; max-height: 250px;
  margin-bottom: 30px;
  padding: 12px;
  border: 1px solid ${colors.LINE}; border-radius: 10px;
  background-size: cover;
  background-color: white;
  box-shadow: 2px 2px 2px ${colors.LINE};
`;

const TopContainer = styled.div`
  position: relative;
  min-height: 24px; max-height: 24px;
  display: flex;
  align-items: center;
`;

const OptionsToggle = styled.span`
  position: absolute; top: 0; right: 12px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  z-index: 2;
`;

const OptionContainer = styled.div`
  position: absolute; top: 40px; right: 0;
  display: ${props => props.visible ? 'block' : 'none'};
  transform: translateX(12px) translateY(-6px);
  border-radius: 10px;
  background-color: ${colors.OPTION_BG};
  z-index: 1;
`;

const Triangle = styled.div`
  position: absolute; top: -20px; right: 15px;
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  border-top: 0 solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${colors.OPTION_BG};
`;

const Option = styled.span`
  display: block;
  padding: 12px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  &:nth-child(2) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:hover { background-color: ${colors.OPTION_BG_ACTIVE}; }
  & > i {
    width: 1em;
    height: 1em;
    text-align: center;
    margin-right: 12px;
  }
`;

const MiddleContainer = styled.div`
  position: absolute; top: 0; right: 0; bottom: 0; left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%; max-width: 100%;
  min-height: 100%; max-height: 100%;
  border-radius: 8px;
`;

const Photo = styled.img`
  display: block;
  min-width: 100%; max-width: 100%;
`;

export default compose(withTranslation(), withRouter)(PhotoCard);
