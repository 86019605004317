import {postJSON as post} from '../../api';
import {
  CREATE_PAYMENT_ACCOUNT_START, CREATE_PAYMENT_ACCOUNT_SUCCESS,
  CREATE_PAYMENT_ACCOUNT_FAILURE,
} from './types';

const updatePaymentAccountStart = () => ({
  type: CREATE_PAYMENT_ACCOUNT_START,
});

const updatePaymentAccountSuccess = () => ({
  type: CREATE_PAYMENT_ACCOUNT_SUCCESS,
});

const updatePaymentAccountFailure = error => ({
  type: CREATE_PAYMENT_ACCOUNT_FAILURE,
  error,
});

const updatePaymentAccount = (authorization, managerId, data, callback) => {
  return dispatch => {
    dispatch(updatePaymentAccountStart());
    post(`managers/${managerId}/account`, {authorization}, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(updatePaymentAccountSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(updatePaymentAccountFailure(error));
      });
  };
};

export default updatePaymentAccount;
