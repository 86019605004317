import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer, createMigrate} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {rootReducer} from './reducers';
import migrations from './migrations';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const middlewares = [ thunk ];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  migrate: createMigrate(migrations),
  blacklist: ['app'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeEnhancers(
   applyMiddleware(...middlewares),
  )
);

let persistor = persistStore(store)

export {store, persistor};
