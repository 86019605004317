import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import EvseCard from './EvseCard';

const EvseContainer = ({evses, handleChange}) => {
  let totalConnectorNumber = 0;
  let busyConnectorsNumber = 0;
  let outOfUseConnectorsNumber = 0;

  for (const i in evses) {
    const evse = evses[i];

    totalConnectorNumber += evse.connectors.length;

    busyConnectorsNumber += evse.connectors
      .filter(connector => connector.status === 'busy').length;

    outOfUseConnectorsNumber += evse.connectors
      .filter(connector => connector.status === 'out_of_use').length;
  }

  const {t} = useTranslation();

  return (
    <Container>
      <StatusContainer>
        <TotalEvseNumber>
          EVSE <span className="number">({evses.length})</span>
        </TotalEvseNumber>
        <BusyConnectorNumber>
          <span>{busyConnectorsNumber}</span> {t('inUse')}
        </BusyConnectorNumber>
        <TotalConnectorNumber>
          <span>{totalConnectorNumber}</span> {t('connectors')}
        </TotalConnectorNumber>
        <OutOfUseConnectorNumber>
          <span>{outOfUseConnectorsNumber}</span> {t('broken')}
        </OutOfUseConnectorNumber>
      </StatusContainer>
      <CardContainer>
        {evseItems(evses, handleChange)}
      </CardContainer>
    </Container>
  );
};

const evseItems = (evses, handleChange) => {
  const items = evses.map((evse, i) => {
    return <EvseCard key={i} index={i} {...evse} handleChange={handleChange} />;
  });

  return items;
};

const Container = styled.div`
  margin-bottom: 64px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px; max-height: 24px;
  margin: 12px 0;
`;

const Status = styled.div`
  position: relative;
  font-size: 16px;
  margin-right: 24px;
  color: ${colors.TEXT_INPUT};
`;

const TotalEvseNumber = styled(Status)`
  padding-right: 6px;
  font-weight: bold;
  color: black;
  & > span.number {
    font-weight: normal;
    color: ${colors.TEXT_INPUT};
  }
  & > span.question {
    position: absolute; top: 0; right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 14px; max-width: 14px;
    min-height: 14px; max-height: 14px;
    font-size: 12px;
    font-weight: normal;
    border-radius: 50%;
    color: white; background-color: ${colors.TEXT_INPUT};
  }
`;

const TotalConnectorNumber = styled(Status)`
  font-weight: normal;
  & > span {
    font-weight: bold;
    color: black;
  }
`;

const BusyConnectorNumber = styled(Status)`
  font-weight: normal;
  & > span {
    font-weight: bold;
    color: ${colors.BLUE_MAIN};
  }
`;

const OutOfUseConnectorNumber = styled(Status)`
  font-weight: normal;
  & > span {
    font-weight: bold;
    color: ${colors.RED_MAIN};
  }
`;

const CardContainer = styled.div`
  position: relative;
  overflow-y: auto;
  min-height: 20vh; max-height: 30vh;
`;

export default EvseContainer;
