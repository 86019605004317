import React from 'react';
import styled from 'styled-components';

const SidebarIcon = ({type}) => (
  <Image
    src={type ? `/sidebarIcons/${type}.svg` : '/sidebarIcons/home.svg'}
    className="icon"
  />
);

const Image = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px; max-width: 20px;
  min-height: 20px; max-height: 20px;
`;

export default SidebarIcon;
