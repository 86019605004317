import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {TextField, ToggleSwitch} from '../../common/Form';

const ForAllDays = ({data, handleChange}) => {
  const {t} = useTranslation();

  return (
    <Container>
      <Title>{t('forAllDays')}</Title>
      <SwitchContainer>
        <ToggleSwitch
          name="for_all_days.active"
          value={data.active.value} valid={data.active.valid}
          handleChange={handleChange}
        />
      </SwitchContainer>
      <FieldContainer>
        <TextField
          name="for_all_days.opens_at"
          value={data.opens_at.value} valid={data.opens_at.valid}
          disabled={!data.active.value}
          handleChange={handleChange} width="48%" margin="0 4px 0 0"
        />
        -
        <TextField
          name="for_all_days.closes_at"
          value={data.closes_at.value} valid={data.closes_at.valid}
          disabled={!data.active.value}
          handleChange={handleChange} width="48%" margin="0 0 0 4px"
        />
      </FieldContainer>
    </Container>
  );
};


const Container = styled.div`
  position: relative;
  display: inline-block;
  min-width: 140px; max-width: 140px;
  min-height: 100%; max-height: 100%;
  margin-right: 24px;
`;

const Title = styled.h5`
  display: block;
  font-size: 16px;
`;

const SwitchContainer = styled.div`
  position: absolute; top: 0; right: 0;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ForAllDays;
