import {getJSON as get} from '../../api';
import {GET_STATIONS_FAILURE, GET_STATIONS_START, GET_STATIONS_SUCCESS} from './types';
import setServerTime from "./setServerTime";

const getStationsStart = () => ({
  type: GET_STATIONS_START,
});

const getStationsSuccess = (data, params, loadMore) => ({
  type: GET_STATIONS_SUCCESS,
  data,
  params,
  loadMore,
});

const getStationsFailure = error => ({
  type: GET_STATIONS_FAILURE,
  error,
});

const getStations = (authorization, params, callback, loadMore = false) => {
  return dispatch => {
    dispatch(getStationsStart());
    get('stations', {authorization}, params)
      .then(async response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(setServerTime(response.headers.get('Date')));
          return response.json();
        }
      })
      .then((data) => {
        dispatch(getStationsSuccess(data, params, loadMore));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(getStationsFailure(error));
      });
  };
};

export default getStations;
