import React from 'react';

import Button from '../common/Button';
import {TopBarBlock, LeftSide, RightSide} from '../common/MainBlocks';
import {TextField} from "../common/Form";
import {postAntiSanctionVersion, postStationMessage} from "../../api";
import {connect} from "react-redux";
import Alert from "../common/Alerts/Alert";

const AntiSanctionProtectionTopbar = ({authorization, getVersions, data=[]}) => {
    const [androidVersion, setAndroidVersion] = React.useState('');
    const [iOSVersion, setIOSVersion] = React.useState('');
    const [response, setResponse] = React.useState('');

    const addAntiSactionVersion = () => {
        postAntiSanctionVersion({
            android_v: androidVersion,
            ios_v: iOSVersion,
        }, {authorization})
            .then(response => response.text())
            .then((data) => {
                setResponse(data);
                setAndroidVersion('');
                setIOSVersion('');
                getVersions();
            });
    }

    useLastVersionsInInputEffect(setAndroidVersion, setIOSVersion, data);

    return (
        <>
            <TopBarBlock fixed white>
                <LeftSide>
                    <TextField
                        name="android_v"
                        value={androidVersion}
                        label="Android"
                        warning="This field is required."
                        type="number"
                        handleChange={(e) => setAndroidVersion(e.target.value)}
                        width="calc(50% - 10px)"
                        height="30px"
                    />
                    <TextField
                        name="ios_v"
                        value={iOSVersion}
                        label="iOS"
                        warning="This field is required."
                        type="text"
                        handleChange={(e) => setIOSVersion(e.target.value)}
                        width="calc(50% - 10px)"
                        height="30px"
                        margin="0 0 0 20px"
                    />
                </LeftSide>
                <RightSide>
                    <Button
                        onClick={addAntiSactionVersion}
                        minWidth="131px"
                        maxWidth="none"
                        height="40px"
                        margin="0"
                    >
                        Сохранить
                    </Button>
                </RightSide>
            </TopBarBlock>

            {response && (
                <Alert
                    onClose={()=>setResponse('')}
                    title={response}
                />
            )}
        </>
    );
};

const useLastVersionsInInputEffect = (setAndroidVersion, setIOSVersion, data) => {
    React.useEffect(() => {
        setAndroidVersion(data[0]?.android_v || '');
        setIOSVersion(data[0]?.ios_v || '')
    }, [data]);
}

const mapStateToProps = ({manager}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
});

export default connect(mapStateToProps)(AntiSanctionProtectionTopbar);
