import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {postAuthEmailVerify} from '../../api';

import * as colors from '../../util/colors';
import bp, {getWindowWidth} from '../common/breakpoints';
import LoadingSpinner from '../common/LoadingSpinner';
import Topbar from '../common/Topbar';

const EmailVerification = ({match}) => {
    const token = match.params.token;
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (token) {
            const tokenBody = {
                token
            };

            postAuthEmailVerify(tokenBody)
                .then(response => response.status)
                .then(status => {
                    setLoading(false);
                    if (status === 200) { setVerified(true); };
                });
        }
    }, [token]);

    if (loading) {
        return (
            <Container>
                <LoadingSpinner />
            </Container>
        );
    }

    if (verified) {
        return (
            <Container>
                <Topbar textColor="#202020" textColorOnHover={colors.TEXT_BLUE} />
                <Title size={getWindowWidth() >= 576 ? '32px' : '24px'} weight='bold'>
                    Поздравляем!
                </Title>
                <Text>
                    Ваш адрес электронной почты был подтвержден.
                </Text>
                <Image>
                    <img src="/emailInboxSuccess.svg" title="Email verification" alt="email icon success" />
                </Image>
            </Container>
        );
    }

    return (
        <Container>
            <Topbar textColor="#202020" textColorOnHover={colors.TEXT_BLUE} />
            <Title size={getWindowWidth() >= 576 ? '32px' : '24px'} weight='bold'>
                Ой
            </Title>
            <Text>
                Что-то пошло не так.
            </Text>
            <Image>
                <img src="/emailInboxFailure.svg" title="Email verification" alt="email icon failure" />
            </Image>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%; min-width: 100%;
  @media screen and (max-width: ${bp.phone}) {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 56px;
  }
  @media screen and (min-width: ${bp.phone}) {
    padding-top: calc(56px + 10vh);
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: ${props => props.size || '20px'};
  font-weight: ${props => props.weight || 'normal'};
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: ${bp.phone}) {
    margin-top: 32px;
    margin-bottom: 0;
    margin-left: 16px;
  }
`;

const Text = styled.div`
  font-size: ${props => props.size || '20px'};
  font-weight: ${props => props.weight || 'normal'};
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: ${bp.phone}) {
    margin-left: 16px;
  }
`;

const Image = styled.div`
  margin-top: 50px;
  @media screen and (max-width: ${bp.phone}) {
    margin-left: auto; margin-right: auto;
  }
`;

export default EmailVerification;
