import {postJSON as post} from '../../api';
import {
  GET_MANAGER_ACCESS_TOKEN_START, GET_MANAGER_ACCESS_TOKEN_SUCCESS,
  GET_MANAGER_ACCESS_TOKEN_FAILURE,
} from './types';

const getManagerAccessTokenStart = () => ({
  type: GET_MANAGER_ACCESS_TOKEN_START,
});

const getManagerAccessTokenSuccess = accessToken => ({
  type: GET_MANAGER_ACCESS_TOKEN_SUCCESS,
  accessToken,
});

const getManagerAccessTokenFailure = error => ({
  type: GET_MANAGER_ACCESS_TOKEN_FAILURE,
  error,
});

const getManagerAccessToken = (email, refreshToken, callback, onError) => {
  return dispatch => {
    dispatch(getManagerAccessTokenStart());
    post('auth/manager/create-token', null, {email, refresh_token: refreshToken})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getManagerAccessTokenSuccess(data.access_token));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getManagerAccessTokenFailure(error));
        if (onError) { onError(); }
      });
  };
};

export default getManagerAccessToken;
