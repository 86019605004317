import moment from "moment";
import _ from "lodash";

export const getRelativeAndFullDatesWithServerOffset = (date, serverTimeOffset) => {
    const fullDate = date && moment(date).add(serverTimeOffset).format('YYYY-MM-DD HH:mm:ss');
    const relativeDate = fullDate ? moment(date).add(serverTimeOffset).from(Date.now()) : '';

    return [relativeDate, fullDate];
}

export const getDays = (month, year) => {
    month++;
    if (month <= 9) {
        month = ['0', month].join('');
    }

    const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth() ;

    return _.range(1, daysInMonth + 1)
        .map(day => Object.assign({}, {id: day, name: day}));
};

export const MONTHS = moment.months().map((month, index) => Object.assign({}, {id: index, name: month}));

export const YEARS = _.range(new Date().getFullYear() - 5, new Date().getFullYear() + 1).map(year => Object.assign({}, {
    id: year,
    name: year
}));

export const WEEK_BEFORE = moment().subtract(7, 'days');

export const MONTH_BEFORE = moment().subtract(1, 'months');
