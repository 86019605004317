import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import { Wrapper, Text } from './Burger.styled';

const Burger = props => {
  const { theme = 'black', children, ...other } = props;

  return (
    <Wrapper {...other}>
      <Icon theme={theme} />
      {children && 
        <Text>{children}</Text>
      }
    </Wrapper>
  );
};

Burger.propTypes = {
  theme: PropTypes.oneOf(['black', 'white']),
  children: PropTypes.node,
  other: PropTypes.object,
};

export default Burger;
