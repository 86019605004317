import React from 'react';
import styled from 'styled-components';

const ToggleSwitch = ({name, value, readonly, valid, handleChange}) => (
  <Container>
    <Input name={name} checked={value} valid={valid} onChange={handleChange} style={{cursor: readonly ? 'default' : undefined}} />
    <Slider checked={value}>
      <Switch checked={value} />
    </Slider>
  </Container>
);

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 32px; max-width: 32px;
  min-height: 24px; max-height: 24px;
`;

const Slider = styled.div`
  min-width: 100%; max-width: 100%;
  min-height: 16px; max-height: 16px;
  background-color:
  ${props => props.checked ? 'rgba(46, 131, 255, 50%)' : 'rgba(204, 206, 215, 50%)'};
  border-radius: 12px;
`;

const Input = styled.input`
  position: absolute; top: 0; left: 0;
  min-width: 100%; max-width: 100%;
  min-height: 100%; max-height: 100%;
  transform: scale(2.5, 1.25);
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  outline: none;
`;
Input.defaultProps = { type: 'checkbox' };

const Switch = styled.span`
  position: absolute; top: 0;
  ${props => props.checked ? 'right: -12px;' : 'left: -12px;'};
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  background-color:
  ${props => props.checked ? 'rgba(46, 131, 255)' : 'rgba(204, 206, 215)'};
  box-shadow: 0 2px 0.12vw rgba(0, 0, 0, 24px);

  border-radius: 50%;
`;

export default ToggleSwitch;
