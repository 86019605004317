import React from 'react';
import moment from 'moment';
import {Location, Price} from "../Dashboard/ChargingHistoryContainer";
import {connectorType, Data} from "../Dashboard/ChargingHistoryColumns";

const isRowExpanded = (rowData) => !rowData.payments_count && !rowData.title

export const renderFirstColumn = (rowData) => {
  if (rowData.title) {
    return <TitleCell {...rowData} />
  }

  if (isRowExpanded(rowData)) {
    return <PaymentTimeColumn {...rowData} />
  }

  return <LocationCell {...rowData} />
};

export const render4dColumn = (rowData) => {
  if (isRowExpanded(rowData)) {
    return connectorType(rowData.connector)
  }

  return rowData.payments_count;
};

export const renderKwh = rowData => {
  if (rowData.title) {
    return null;
  }

  return (
    <Data>
      <div>{toKWh(rowData)}</div>
    </Data>
  );
}

export const capturedAmount = (amount, rowData) => {
  if (rowData.title) {
    return null;
  }

  return (
    <Data><Price>{toCapturedAmount(rowData)}₽</Price></Data>
  );
}

export const toKWh = (rowData) => parseFloat(((rowData.meter_stop - rowData.meter_start) / 1000).toFixed(3))
export const toCapturedAmount = (rowData) => {
  if (rowData.payment_method_type === 'cash') { return 0; }
  return rowData.captured_amount / 100;
}

const TitleCell = ({ title }) => (
  <Location>
    <div className="name">{title}</div>
  </Location>
);

const LocationCell = ({ location }) => (
  <Data>
    <Location>
      <div className="name">{location.name}</div>
      <div className="address">
        {location.street === undefined
          ? 'Loading...'
          : `${location.street}, ${location.city}, ${location.country}`}
      </div>
    </Location>
  </Data>
);

const PaymentTimeColumn = ({ charging_start, charging_finish }) => (
  <Data>
    <Location>
      <div className="address" style={{paddingLeft: '16px'}}>
        {charging_start ? moment(charging_start).format('HH:mm:ss') : '? : ? : ?'}
        {' - '}
        {charging_finish ? moment(charging_finish).format('HH:mm:ss') : '? : ? : ?'}
      </div>
    </Location>
  </Data>
);
