import React, {Component} from 'react';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {connectorImageByType} from "../../Dashboard/ChargingHistoryContainer";

import * as colors from '../../../util/colors';

class StationCard extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  handleClick(e) {
    if (!this.props.disableClick) {
      if (this.node.contains(e.target)) {
        this.props.changeStationCard(this.props.currentIndex);
        this.props.saveStation(this.props);
      }
    }
  };

  render() {
    const {props} = this;
    const {t} = props;

    return (
      <Container ref={node => this.node = node} active={props.active} disabled={this.props.disableClick}>
        <Image src={validImageUrl(props.image) ? props.image : '/defaultStationPicture.jpg'} />
        <InfoContainer>
          <Info>
            <label>{t('manufacturer')}</label>
            <div>{props.vendor_name}</div>
          </Info>
          <Info>
            <label>{t('model')}</label>
            <div>{props.model}</div>
          </Info>
          <Info>
            <label>EVSE</label>
            {props.evses.map((evse, i) =>
              <Evse key={i}>
                <div className="number">#{evse.number}</div>
                {evse.connectors.map((connector, j) =>
                  <Connector key={j}>
                    <ConnectorIcon src={connectorImageByType(connector.type)}
                      alt='Connector type' />
                    <div>{`${connector.type} (${connector.power} ${t('kW')})`}</div>
                  </Connector>
                )}
              </Evse>
            )}
          </Info>
        </InfoContainer>
      </Container>
    );
  };
}

const validImageUrl = url => {
  if (!url) {
    return false;
  }
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
  return url.match(re) !== null;
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 200px;
  margin-bottom: 24px;
  border: 2px solid ${props => props.active ? colors.BUTTON_BLUE : 'transparent'}; border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 0.4vw rgba(37, 99, 160, 15%);
  &:hover {
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
  }
  margin-top: 24px
`;

const Image = styled.img`
  display: inline-block;
  float: left;
  min-width: 160px; max-width: 160px;
  min-height: 160px; max-height: 160px;
  margin-left: 12px;
  margin-right: 12px;
  object-fit: contain;
  object-position: center;
`;

const InfoContainer = styled.div`
  float: right;
  display: inline-block;
  min-width: calc(100% - 180px); max-width: calc(100% - 180px);
`;

const Info = styled.div`
  margin: 12px 0;
  & > label {
    display: block;
    color: ${colors.TEXT_MINOR};
    font-size: 14px;
    margin: 0;
  }
  & > div {
    color: black;
    font-size: 14px;
    font-weight: bold;
  }
`;

const Evse = styled.div`
  display: flex;
  align-items: center;
  & > div {
    display: inline-block;
  }
  & > div.number {
    margin-right: 6px;
    color: ${colors.BLUE_MAIN};
  }
`;

const Connector = styled.div`
  margin-top: 2px;
  margin-right: 6px;
  font-size: 14px;
  font-weight: bold;
  color: black;
  
  display: flex !important;
  flex-direction: row;
  align-items: center;
`;

const ConnectorIcon = styled.img`
  width: 20px;
  margin: 2px 5px;
`;


export default compose(withTranslation())(StationCard);
