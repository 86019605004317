import React from 'react';
import {connect} from 'react-redux';
import {getOcppLogs} from "../../api";

import {SelectField} from "../common/Form";
import Paper from "../common/Paper";
import Button from "../common/Button";
import styled from "styled-components";

function StationLogsTab({ stationId, connectorId, authorization }) {
  const [logMessages, reloadLogs] = useLogMessagesEffect(stationId, connectorId, authorization);
  return (
    <>
      <LogMessages
        logMessages={logMessages}
        reloadLogs={reloadLogs}
        stationId={stationId}
        connectorId={connectorId}
      />
    </>
  );
}

const LogMessages = ({logMessages, reloadLogs, stationId, connectorId}) => {
  const [isFullscreen, setFullscreen] = React.useState(false);
  const toggleFullscreen = function () {
      setFullscreen(!isFullscreen);
  };
  const ref = useScrollToBottomEffect(logMessages);

  const consoleLogsReversed = [...logMessages].reverse();

  let lastMessageId = null;

    /**
     *
     * @param message_id
     * @param connector_id
     * @param payload
     * @param created_at
     * @returns {JSX.Element}
     */
  const formatLog = ({
        message_id,
        connector_id,
        payload,
        created_at,
  }) => {
      let isMissed = false;
      if (lastMessageId && lastMessageId + 1 < message_id && lastMessageId < message_id) {
          // isMissed = true; // turn off logic for missed messages
      }
      lastMessageId = message_id;
      return (
          <div>
              {isMissed ? (<Missed>... пропуск ...</Missed>) : ''}
              <div>
                  <CreatedAt>{created_at}; </CreatedAt>
                  {connectorId ? '' : (<ConnectorId>{connector_id}; </ConnectorId>)}
                  <MessageId><b>{message_id}</b>; </MessageId>
                  <Payload>{payload}</Payload>
              </div>
          </div>
      );
    }

  const options = [
      { id: 0, name: 'Все'},
      { id: 1, name: '1'},
      { id: 2, name: '2'},
      { id: 3, name: '3'},
      { id: 4, name: '4'},
      { id: 5, name: '5'},
  ];
  const findOptionByValue = connectorId => {
      return options.find(option => option.id === +connectorId);
  };
  const [selectedOption, selectOption] = React.useState(findOptionByValue(connectorId) || options[0]);

  const onSelectChange = e => {
      window.location.href = `/charge-points/${encodeURIComponent(stationId)}/logs` + (e.target.value ? `/${e.target.value}` : '');
      return selectOption(findOptionByValue(e.target.value))
  };

  return (
    <Paper className={isFullscreen ? 'fullscreen' : ''}>
      <div ref={ref}
        className="model_creation_text_field default-scrollbar"
        style={{
          padding: '8px',
          height: '250px',
          overflowY: 'scroll',
          opacity: 0.7,
          flex: 'auto',
        }}
      >
        {consoleLogsReversed.map((log) => (
            <>
              {formatLog(log)}
            </>
          )
        )}
      </div>
      <ButtonsRow>
          <Button onClick={reloadLogs}>
              Обновить
          </Button>
          <div style={{paddingLeft: '26px'}}>
              <SelectField
                  noDot
                  selectedFieldStyle={{border: '1px solid #CAD0D9'}}
                  optionHoverColor="#E1EEFF"
                  name='connectorId'
                  width='150px'
                  selectedOption={selectedOption}
                  options={options}
                  handleChange={onSelectChange}
              />
          </div>
          <Button onClick={toggleFullscreen} style={{marginLeft: 'auto', minWidth: 'auto', width: '20px'}}>⤢</Button>
      </ButtonsRow>
    </Paper>
  )
}

const useScrollToBottomEffect = (consoleLogs) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [consoleLogs])

  return ref;
}

const useLogMessagesEffect = (stationId, connectorId, authorization) => {
  const [logs, setLogs] = React.useState([]);

  const reloadLogs = React.useCallback(() => {
      getOcppLogs(stationId, connectorId, {authorization})
      .then(response => {
          return response.json();
      })
      .then((data) => {
        setLogs(data); // Turn off sort by message_id.
        // setLogs(data.sort((a, b) => b.message_id - a.message_id));
      });
  }, [stationId, connectorId, authorization]);

  React.useEffect(() => {
    reloadLogs();
  }, []);

  return [logs, reloadLogs];
};

const mapStateToProps = ({manager}, {match}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  stationId: match.params.stationId,
  connectorId: match.params.connectorId,
});

export default connect(mapStateToProps)(StationLogsTab);

export const CreatedAt = styled.span`
  color: #9acaff;
`;

export const ConnectorId = styled.span`
  color: #9acaff;
`;

export const MessageId = styled.span`
  color: #9acaff;
`;

export const Payload = styled.span`
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
`;

const Missed = styled.div`
  color: #dc75a5;
  display: flex;
  justify-content: space-around;
`;
