import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';

class ServiceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsVisible: false,
    };
  }

  handleOptionsToggle = () => {
    this.setState({optionsVisible: !this.state.optionsVisible});
  };

  handleEditService = () => {
    const {locationId, id, history} = this.props;

    history.push(`/locations/${locationId}/services/${id}/edit`);

    this.handleOptionsToggle();
  };

  handleDeleteService = () => {
    const {authorization, id, locationId, deleteService, getServices} = this.props;

    deleteService(authorization, id, () => getServices(locationId));

    this.handleOptionsToggle();
  };

  render() {
    const {type_id, name, price, description, t} = this.props;
    const {optionsVisible} = this.state;

    return (
      <Container>
        <TopContainer>
          <img src={`/serviceTypes/${type_id}.svg`} alt={`${name} icon`} className="service-icon" />
          <Price>{price / 100}₽</Price>
          <OptionsToggle onClick={this.handleOptionsToggle}>
            <i className="fa fa-ellipsis-v" />
          </OptionsToggle>
          <OptionContainer visible={optionsVisible}>
            <Triangle />
            <Option onClick={this.handleEditService}>
              <i className="fa fa-pen" /> {t('edit')}
            </Option>
            <Option onClick={this.handleDeleteService}>
              <i className="fa fa-trash" /> {t('delete')}
            </Option>
          </OptionContainer>
        </TopContainer>
        <MiddleContainer>
          <Name>{name}</Name>
          <Description>
            {description}
          </Description>
        </MiddleContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  min-height: 250px; max-height: 250px;
  margin-bottom: 30px;
  padding: 16px;
  border: 1px solid ${colors.LINE}; border-radius: 10px;
  background-color: white;
  box-shadow: 2px 2px 2px ${colors.LINE};
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & > img.service-icon {
    width: 32px;
    height: 32px;
  }
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const Price = styled.span`
  font-size: 18px;
  font-style: bold;
  margin-left: 8px;
`;

const OptionsToggle = styled.span`
  position: absolute; right: 0; right: 0;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.TEXT_MINOR};
  cursor: pointer;
  z-index: 2;
`;

const OptionContainer = styled.div`
  position: absolute; top: 40px; right: -12px;
  display: ${props => props.visible ? 'block' : 'none'};
  transform: translateX(12px) translateY(-6px);
  border-radius: 10px;
  background-color: ${colors.OPTION_BG};
  z-index: 1;
`;

const Triangle = styled.div`
  position: absolute; top: -20px; right: 15px;
  min-width: 24px; max-width: 24px;
  min-height: 24px; max-height: 24px;
  border-top: 0 solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${colors.OPTION_BG};
`;

const Option = styled.div`
  display: block;
  padding: 12px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  &:nth-child(2) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:hover { background-color: ${colors.OPTION_BG_ACTIVE}; }
  & > i {
    width: 1em;
    height: 1em;
    text-align: center;
    margin-right: 12px;
  }
`;

const MiddleContainer = styled.div`
  position: relative;
  min-height: 170px;
  margin-top: 12px;
  padding: 6px;
`;

const Description = styled.div`
  margin-top: 8px;
  color: ${colors.TEXT_MINOR};
`;

export default compose(withTranslation(), withRouter)(ServiceCard);
