import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import * as colors from '../../../util/colors';

import Avatar from '../../common/Avatar';

class FeedbackCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };

    // Prevent memory leaks.
    this.mounted = false;
  }

  componentDidMount() {
    const {props} = this;

    this.mounted = true;

    props.getUser(props.user_id, user => {
      if (this.mounted) {
        this.setState({user});
      }
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {props} = this;
    const {user} = this.state;

    return (
      <Container>
        <AvatarImage
          size="40px"
          rounded
          imageSrc={(user && user.avatar_url) ? user.avatar_url : null}
        />
        <InfoContainer>
          <Name>
            {(user.first_name && user.last_name) ? [user.first_name, user.last_name].join(' ') : 'Unknown'}
          </Name>
          <Rating>
            <img src={(props.rating > 0) ? '/star.svg' : '/starDisabled.svg'} alt="star icon" className="star" />
            <img src={(props.rating > 1) ? '/star.svg' : '/starDisabled.svg'} alt="star icon" className="star" />
            <img src={(props.rating > 2) ? '/star.svg' : '/starDisabled.svg'} alt="star icon" className="star" />
            <img src={(props.rating > 3) ? '/star.svg' : '/starDisabled.svg'} alt="star icon" className="star" />
            <img src={(props.rating > 4) ? '/star.svg' : '/starDisabled.svg'} alt="star icon" className="star" />
          </Rating>
          <Date>
            {moment(props.created_at).format('DD.MM.YYYY')}
          </Date>
        </InfoContainer>
        <LikesContainer>
          <div className="number">
            {props.likes}
          </div>
          <img src="/thumbUp.svg" alt="thumb up" className="thumb-up" />
        </LikesContainer>
        <Comment>
          {props.comment}
        </Comment>
      </Container>
    );
  };
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
  & > img.avatar {
    float: left;
    margin-top: 4px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`;

const AvatarImage = styled(Avatar)`
  float: left;
  margin-top: 4px;
`;

const InfoContainer = styled.div`
  display: inline-block;
  width: 500px;
  margin-left: 16px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.TEXT_TITLE};
`;

const Rating = styled.div`
  display: inline-block;
  & > img.star {
    padding-bottom: 2px;
  }
`;

const Date = styled.div`
  display: inline-block;
  font-size: 14px;
  color: ${colors.TEXT_MINOR};
  margin-left: 8px;
`;

const LikesContainer = styled.div`
  position: absolute; top: 0; right: 0;
  & > div.number {
    display: inline-block;
    font-size: 14px;
    color: ${colors.TEXT_MINOR};
    vertical-align: sub;
    height: 30px;
  }
  & > img.thumb-up {
    margin-left: 8px;
    display: inline-block;
  }
`;

const Comment = styled.div`
  color: ${colors.TEXT_MAIN};
  font-size: 16px;
  margin: 16px 28px 0 56px;
`;

export default withRouter(FeedbackCard);
