import React from 'react';
import styled from 'styled-components';

const SectionContainer = ({sections, sectionIndex}) => {
  return (
    <Container>
      {sections && sections[sectionIndex]}
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%; max-width: 100%;
`;

const Section = styled.div`
  display: block;
`;

export default SectionContainer;
export {Section};
