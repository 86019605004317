import styled from "styled-components";
import * as colors from "../../util/colors";

const StatusBlock = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.marginLeft || '0'};
  min-width: 80px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 24px;
  color: #fff;
  padding: 1px;
  background-color: ${props => ({
    unconfigured: colors.TEXT,
    available: colors.GREEN_MAIN,
    offline: colors.MARKER_GRAY,
    faulted: colors.ERROR,
    busy: colors.ORANGE_MAIN,
}[props.status])};
`;

export default StatusBlock;
