import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import LoadingSpinner from '../../common/LoadingSpinner';
import {ErrorAlert} from '../../common/Alerts';
import Form, {Title, Text, TextField, SelectField, CheckboxField} from '../../common/Form';
import {Close} from '../../common/Modal';
import Button from '../../common/Button';

class AddServiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        type_id: { value: undefined, valid: true },
        price: { value: 0.5, valid: true },
        privacy: { value: false, valid: undefined },
      },
    };
  }

  componentWillMount() {
    const {serviceTypes, locationServices, getServiceTypes} = this.props;

    getServiceTypes(() => {
      const options = serviceTypes.filter(serviceType => {
        for (const i in locationServices) {
          const locationService = locationServices[i];

          if (locationService.type_id === serviceType.id) {
            return false;
          }
        }

        return true;
      });

      if (options.length !== 0) {
        this.setFieldData('type_id', options[0].id, true);
      }
    });
  }

  fieldIsValid = (name, value) => {
    const {serviceTypes, locationServices} = this.props;

    switch (name) {
      case 'type_id':
        const serviceTypeExists = serviceTypes.find(service => service.id === value);
        const locationServiceExists = locationServices.find(service => service.id === value);

        return Number.isInteger(value) && serviceTypeExists && !locationServiceExists;
      case 'price':
        return !Number.isNaN(parseFloat(value));
      case 'privacy':
        return value;
      default:
        return false;
    }
  };

  setFieldData = (name, value, valid) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [name]: { value, valid },
      },
    });
  };

  handleChange = event => {
    const {name, value, type} = event.target;
    const {data} = this.state;

    if (type === 'checkbox') {
      this.setFieldData(name, !data[name].value, this.fieldIsValid(name, !data[name].value));
      return true;
    }

    if (name === 'price') {
      if (parseFloat(value)) {
        if (value[value.length-1] === '.') {
          this.setFieldData(name, value, false);
          return true;
        }
        this.setFieldData(name, parseFloat(value), this.fieldIsValid(name, value));
        return true;
      }
      this.setFieldData(name, value, this.fieldIsValid(name, value));
      return true;
    }
    this.setFieldData(name, value, this.fieldIsValid(name, value));
  };

  handleSubmit = event => {
    event.preventDefault();

    const {authorization, locationId, addService, getServices, history} = this.props;
    const {data} = this.state;
    const formData = {};

    for (let name in data) {
      formData[name] = data[name].value;
    }

    formData.price *= 100;

    delete formData.privacy;

    addService(authorization, locationId, formData, () => {
      getServices(locationId, {}, () => {
        history.push(`/locations/${locationId}/services`);
      });
    });
  };

  render() {
    const {serviceTypes, locationServices, loading, error,
      locationId, history, t} = this.props;
    const {data} = this.state;

    const allFieldsValid = Object.values(data)
      .filter(field => !field.valid).length === 0;

    if (loading) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      );
    }

    const options = serviceTypes.filter(serviceType => {
      for (let i in locationServices) {
        let locationService = locationServices[i];
        if (locationService.type_id === serviceType.id) {
          return false;
        }
      }
      return true;
    });

    if (options.length === 0) {
      return (
        <Container>
          <Close onClick={() => history.push(`/locations/${locationId}/services`)}>
            <img src="/cross.svg" alt="Close" />
          </Close>
          <Form>
            <Text margin="50% auto">
              {t('noService')}
            </Text>
          </Form>
        </Container>
      );
    }

    const selectedOption =
      options.find(option => option.id === data.type_id.value) || options[0];

    return (
      <Container>
        {error && error.message && <ErrorAlert>{error.message}</ErrorAlert>}
        <Close onClick={() => history.push(`/locations/${locationId}/services`)}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Form onSubmit={this.handleSubmit}>
          <Title>{t('addService')}</Title>
          <SelectField
            name="type_id" selectedOption={selectedOption} options={options}
            label="Service"
            handleChange={this.handleChange}
          />
          <TextField
            name="price" value={data.price.value} valid={data.price.valid}
            label={<span>Price ₽</span>} required
            warning="This field is required"
            handleChange={this.handleChange}
            width="100%"
          />
          <CheckboxField
            name="privacy" value={data.privacy.value} valid={data.privacy.valid}
            text={<Text>I have read <a href="https://ms.drivechargers.ru/privacy">Privacy Policy</a>.</Text>}
            warning="This field is required"
            handleChange={this.handleChange}
            width="100%"
          />
          <ButtonContainer>
            <Button disabled={!allFieldsValid}
              type="submit" theme="blue" width="100%" margin="0" height="40px">
              {t('addService')}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 32px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {
    position: absolute; right: 0; bottom: 0; left: 0;
  }
`;

export default compose(withTranslation() ,withRouter)(AddServiceForm);
