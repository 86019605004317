import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import LoadingSpinner from '../../common/LoadingSpinner';
import {ErrorAlert} from '../../common/Alerts';
import Form, {Title, TextField} from '../../common/Form';
import {Close} from '../../common/Modal';
import Button from '../../common/Button';
import ParkingContainer from './ParkingContainer';
import EvseContainer from './EvseContainer';

function isBool(value) {
  return ((value === true) || (value === false));
}

class StationSettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        number: {value: '', valid: undefined},
        parking: {
          car: {value: false, valid: true},
          bike: {value: false, valid: true},
        },
        evses: [],
      },
    };
  }

  componentWillMount() {
    const {authorization, stationId, getStation} = this.props;

    getStation(authorization, stationId, () => {
      const {data} = this.props;

      this.setFieldData('number', data.number, true);
      this.setFieldData('parking.car', data.parking.car, true);
      this.setFieldData('parking.bike', data.parking.bike, true);
      this.setFieldData('evses', data.evses.map(evse => {
        return {
          ...evse,
          protocol: data.protocol,
          operational: {value: evse.operational, valid: evse.operational}
        };
      }));
    });
  }

  fieldByPath = (path, data = {...this.state.data}) => {
    return path.split('.')
      .map(prop => parseInt(prop) || prop)
      .reduce((prop1, prop2) => prop1 && prop1[prop2], data);
  };

  setFieldData = (name, value, valid) => {
    const data = {...this.state.data};
    const field = this.fieldByPath(name);

    if (Array.isArray(field)) {
      if (Array.isArray(value)) {
        field.splice(0, field.length);
        field.push(...value);
      }
      else {
        field.push(value);
      }
    }
    else {
      if (['parking.car', 'parking.bike'].includes(name) && !isBool(value)) {
        return;
      }

      field.value = value;
      field.valid = valid || this.fieldIsValid(name, value);
    }
    this.setState({data});
  };

  allFieldsValid = () => {
    return true;
  };

  fieldIsValid = (name, value) => {
    if (name.split('.').slice(-1)[0] === 'operational') {
      return true;
    }

    switch (name) {
      case 'number':
        return value.length > 0 && parseInt(value) > 0 && Number.isInteger(parseFloat(value));
      case 'parking.car':
        return isBool(value);
      case 'parking.bike':
        return isBool(value);
      default:
        return false;
    }
  };

  handleChange = event => {
    event.preventDefault();

    const {name, value, type} = event.target;
    const field = this.fieldByPath(name);

    if (type === 'checkbox' && this.fieldIsValid(name, !field.value)) {
      this.setFieldData(name, !field.value, true);
      return true;
    }

    if (type === 'number') {
      this.setFieldData(name, parseInt(value));
      return true;
    }

    this.setFieldData(name, value);
  };

  handleSubmit = event => {
    event.preventDefault();

    if (!this.allFieldsValid()) {
      return false;
    }

    const {authorization, stationId, locationId} = this.props;
    const {data} = this.state;

    const formData = {
      number: parseInt(data.number.value),
      parking: {
        car: data.parking.car.value,
        bike: data.parking.bike.value,
      },
    };

    const {updateStation, changeStationStatus, getLocationStations, history} = this.props;

    updateStation(authorization, stationId, formData, () => {
      if (!this.props.data.protocol) {
        getLocationStations(authorization, locationId, {}, () => {
          history.push(`/locations/${locationId}/stations`);
        });
        return true;
      }

      const operativeEvseFormData = {operational_status: 'operative', evses: []};
      const inoperativeEvseFormData = {operational_status: 'inoperative', evses: []};

      for (let i in data.evses) {
        let evse = data.evses[i];
        if (evse.operational.value) {
          operativeEvseFormData.evses.push(evse.number);
          continue;
        }
        inoperativeEvseFormData.evses.push(evse.number);
      }

      changeStationStatus(authorization, stationId, operativeEvseFormData, () => {
        changeStationStatus(authorization, stationId, inoperativeEvseFormData, () => {
          getLocationStations(authorization, locationId, {}, () => {
            history.push(`/locations/${locationId}/stations`);
          });
        });
      });
    });
  };

  render() {
    const {loading, error, locationId, history, t} = this.props;
    const {data} = this.state;


    if (loading) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      );
    }

    return (
      <Container>
        {error && error.message && <ErrorAlert>{error.message}</ErrorAlert>}
        <Close onClick={() => history.push(`/locations/${locationId}/stations`)}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Form onSubmit={this.handleSubmit}>
          <Title>{t('stationSettings')}</Title>
          {/* station number */}
          <TextField
            name="number" value={data.number.value} valid={data.number.valid}
            label={t('stationNumber')}
            warning="This field is required."
            type="number"
            handleChange={this.handleChange} width="100%" height="40px"
          />
          {/* parking container */}
          <ParkingContainer
            car={data.parking.car}
            bike={data.parking.bike}
            handleChange={this.handleChange}
          />
          {/* evse container */}
          <EvseContainer evses={data.evses} handleChange={this.handleChange} />
          {/* save or cancel changes */}
          <ButtonContainer>
            <Button onClick={() => history.push(`/locations/${locationId}/stations`)}
              theme="white" width="48%" height="40px" margin="0" float="left">
              {t('cancel')}
            </Button>
            <Button disabled={!this.allFieldsValid()}
              type="submit" theme="blue" width="48%" height="40px" margin="0" float="right">
              {t('confirm')}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 32px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {
    position: absolute; right: 0; bottom: 0; left: 0;
  }
`;

export default compose(withTranslation(), withRouter)(StationSettingsForm);
