import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import {ToggleSwitch} from '../../common/Form';

function ParkingContainer({car, bike, handleChange}) {
  const {t} = useTranslation();

  return (
    <Container>
      <h4 className="title">{t('parking')}</h4>
      <div className="parking">
        <div className="text-container">
          <i className="fa fa-car" /> {t('carParking')}
        </div>
        <div className="switch-container">
          <ToggleSwitch
            name="parking.car" value={car.value}
            handleChange={handleChange}
            className="toggle-switch"
          />
        </div>
      </div>
      <div className="parking">
        <div className="text-container">
          <i className="fa fa-bicycle" /> {t('bikeParking')}
        </div>
        <div className="switch-container">
          <ToggleSwitch
            name="parking.bike" value={bike.value}
            handleChange={handleChange}
            className="toggle-switch"
          />
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  /* title */
  & > h4.title {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }
  /* parking */
  & > div.parking {
    position: relative;
    display: block;
    min-height: 45px; max-height: 45px;
    border-bottom: 1px solid ${colors.LINE};
  }
  & > div.parking:last-child {
    border-bottom: 0 solid transparent;
  }
  /* text container */
  & > div.parking > div.text-container {
    position: absolute; top: 0; bottom: 0; left: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
  }
  & > div.parking > div.text-container > i.fa {
    font-size: 24px;
    margin-right: 12px;
    color: ${colors.ICON_GRAY};
  }
  /* switch container */
  & > div.parking > div.switch-container {
    position: absolute; top: 0; bottom: 0; right: 12px;
    display: flex;
    align-items: center;
  }
`;

export default ParkingContainer;
