import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import Form, {Title, TextField} from '../../common/Form';
import Button from '../../common/Button';
import {Close} from '../../common/Modal';

class EditServiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        price: {value: '', valid: undefined},
      },
    };
  }

  componentWillMount() {
    const {price} = this.props.data;

    if (!price) { return false; }

    const data = { ...this.state.data };

    data.price.value = price / 100;
    data.price.valid = !Number.isNaN(data.price.value);

    this.setState({data});
  }

  fieldIsValid = (name, value) => {
    switch (name) {
      case 'price':
        return !Number.isNaN(parseFloat(value));
      case 'privacy':
        return value;
      default:
        return false;
    }
  };

  setFieldData = (name, value, valid) => {
    const data = {...this.state.data};

    if (!data[name]) { return false; }

    data[name].value = value;
    data[name].valid = valid;

    this.setState({data});
  };

  handleChange = event => {
    const {name, value} = event.target;

    if (name === 'price') {
      if (parseFloat(value)) {
        if (value[value.length-1] === '.') {
          this.setFieldData(name, value, false);
          return true;
        }
        this.setFieldData(name, parseFloat(value), this.fieldIsValid(name, value));
        return true;
      }
      this.setFieldData(name, value, this.fieldIsValid(name, value));
      return true;
    }
    this.setFieldData(name, value, this.fieldIsValid(name, value));
  };

  handleSubmit = event => {
    event.preventDefault();

    const {authorization, locationId, serviceId,
      editService, getServices, history} = this.props;
    const {data} = this.state;
    const formData = {};

    for (const name in data) {
      formData[name] = data[name].value;
    }

    formData.price *= 100;

    editService(authorization, serviceId, formData, () => {
      getServices(locationId, {}, () => {
        history.push(`/locations/${locationId}/services`);
      });
    });
  };

  render() {
    const {locationId, history, t} = this.props;
    const {data} = this.state;
    const allFieldsValid = Object.values(data).filter(field => !field.valid).length === 0;

    return (
      <Container>
        <Close onClick={() => history.push(`/locations/${locationId}/services`)}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Form onSubmit={this.handleSubmit}>
          <Title>{t('editService')}</Title>
          <ServiceTypeContainer>
            <div className="label">{t('serviceType')}</div>
            <div className="name">
              <ServiceTypeIcon src={serviceTypeIconLink(this.props.data.type_id)} />
              {this.props.data.name}
            </div>
          </ServiceTypeContainer>
          <TextField
            name="price" value={data.price.value} valid={data.price.valid}
            label={<span>Price ₽</span>} required
            warning="This field is required"
            handleChange={this.handleChange}
            width="100%"
          />
          <ButtonContainer>
            <Button disabled={!allFieldsValid}
              type="submit" theme="blue" width="100%" margin="0" height="40px">
              {t('editService')}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const serviceTypeIconLink = typeId => `/serviceTypes/${typeId}.svg`;

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 32px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {
    position: absolute; right: 0; bottom: 0; left: 0;
  }
`;

const ServiceTypeContainer = styled.div`
  margin-bottom: 12px;
  & > div.label {
    display: block;
    margin: 0.25rem 0;
    font-size: 14px;
    color: ${colors.TEXT_INPUT};
  }
  & > div.name {
    display: flex;
    align-items: center;
    min-height: 48px; max-height: 48px;
    font-size: 18px;
    font-weight: bold;
  }
`;

const ServiceTypeIcon = styled.img`
  min-width: 36px; max-width: 36px;
  min-height: 36px; max-height: 36px;
  margin-right: 12px;
  border-radius: 50%;
`;

export default compose(withTranslation(), withRouter)(EditServiceForm);
