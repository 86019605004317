import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import ReactTooltip from 'react-tooltip';
import {withTranslation} from 'react-i18next';

import Evses from './Evses';
import CarIcon from '../../icons/CarIcon';
import BikeIcon from '../../icons/BikeIcon';

import {
  Wrapper,
  Inner,
  Top,
  LeftSide,
  Number,
  Status,
  RightSide,
  Parking,
  ParkingLabel,
  OptionsToggle,
  OptionContainer,
  Triangle,
  Option,
  Middle,
  Image,
  Info,
  InfoBox,
  Bottom,
} from './StationCard.styled';
import OperativeStatus from "../../common/OperativeStatus";
import StationMonetizedIcon from "./StationMonetizedIcon";

class StationCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsVisible: false,
    };
  }

  handleOptionsToggle = () => {
    this.setState({
      optionsVisible: !this.state.optionsVisible,
    });
  };

  handleDeleteStation = () => {
    const {authorization, id, location_id, getLocationStations, deleteStation} = this.props;

    deleteStation(authorization, id, () => getLocationStations(authorization, location_id));
  };

  handleConnectToBackend = () => {
    const {authorization, id, location_id, history, getStation} = this.props;

    getStation(authorization, id, () => history.push(`/locations/${location_id}/stations/add`))
  }

  render() {
    const {
      id,
      location_id,
      number,
      parking,
      model,
      vendor_name,
      evses,
      image,
      history,
      t,
      monetized,
    } = this.props;

    const {
      optionsVisible,
    } = this.state;

    const operative = (evses.filter(evse => evse.operational).length !== 0);

    return (
      <Wrapper>
        <Inner>

          <Top>
            <LeftSide>
              <Number>#{number}</Number>
              <StationMonetizedIcon monetized={monetized} />
              <ReactTooltip effect='solid' />
              <OperativeStatus operative={operative} />
            </LeftSide>

            <RightSide>
              <Parking>
                <ParkingLabel>{t('parking')}: {' '}</ParkingLabel>
                {parking.car && <CarIcon/>}
                {parking.bike && <BikeIcon/>}
              </Parking>

              <OptionsToggle onClick={this.handleOptionsToggle}>
                <i className="fa fa-ellipsis-v" />
              </OptionsToggle>
              <OptionContainer visible={optionsVisible}>
                <Triangle />
                <Option onClick={() => {
                  history.push(`/locations/${location_id}/stations/${encodeURIComponent(id)}/settings`);
                  this.handleOptionsToggle();
                }}>
                  <i className="fa fa-pen" /> {t('stationSettings')}
                </Option>
                <Option onClick={this.handleConnectToBackend}>
                  <i className="fa fa-wifi" /> {t('connectToBackend')}
                </Option>
                <Option onClick={this.handleDeleteStation}>
                  <i className="fa fa-trash" /> {t('delete')}
                </Option>
              </OptionContainer>
            </RightSide>
          </Top>

          <Middle>
            <Image
              src={validImageUrl(image) ? image : '/defaultStationPicture.jpg'}
              />
            <Info>
              <InfoBox>
                <label>{t('model')}</label>
                <div>{model}</div>
              </InfoBox>
              <InfoBox>
                <label>{t('manufacturer')}</label>
                <div>{vendor_name}</div>
              </InfoBox>
              <InfoBox>
                <label>{t('stationId')}</label>
                <div className="id"
                     onClick={() => history.push(`/charge-points/${encodeURIComponent(id)}/information`)}
                > {id}
                </div>
              </InfoBox>
            </Info>
          </Middle>

          <Bottom>
            <Evses evses={evses} />
          </Bottom>

        </Inner>
      </Wrapper>
    );
  }
}

const validImageUrl = url => {
  if (!url) {
    return false;
  }
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
  return url.match(re) !== null;
};

export default compose(withTranslation(), withRouter)(StationCard);
