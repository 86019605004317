import {postJSON as post} from '../../api';
import {LOG_IN_MANAGER_START, LOG_IN_MANAGER_SUCCESS, LOG_IN_MANAGER_FAILURE} from './types';

const logInManagerStart = () => ({
  type: LOG_IN_MANAGER_START,
});

const logInManagerSuccess = (accessToken, refreshToken) => ({
  type: LOG_IN_MANAGER_SUCCESS,
  accessToken,
  refreshToken,
});

const logInManagerFailure = error => ({
  type: LOG_IN_MANAGER_FAILURE,
  error,
});

const logInManager = (formData, callback) => {
  return dispatch => {
    dispatch(logInManagerStart());
    post('auth/manager/log-in', null, formData)
      .then(response => {
        if (((response.status >= 200) && (response.status < 300)) || ((response.status >= 400) && (response.status < 500))) {
          return response.json();
        }
      })
      .then(data => {
        if (data && data.access_token && data.refresh_token) {
          dispatch(logInManagerSuccess(data.access_token, data.refresh_token));
          if (callback) { callback(data.access_token); }
        } else if (data && data.error) {
          dispatch(logInManagerFailure(data));
        }
      })
      .catch(error => {
        dispatch(logInManagerFailure(error));
      });
  };
};

export default logInManager;
