import {getJSON as get} from '../../api';
import {GET_SERVICE_TYPES_START, GET_SERVICE_TYPES_SUCCESS, GET_SERVICE_TYPES_FAILURE} from './types';

const getServiceTypesStart = () => ({
  type: GET_SERVICE_TYPES_START,
});

const getServiceTypesSuccess = data => ({
  type: GET_SERVICE_TYPES_SUCCESS,
  data,
});

const getServiceTypesFailure = error => ({
  type: GET_SERVICE_TYPES_FAILURE,
  error,
});

const getServiceTypes = callback => {
  return dispatch => {
    dispatch(getServiceTypesStart());
    get('types/services')
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getServiceTypesSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getServiceTypesFailure(error));
      });
  };
};

export default getServiceTypes;
