import styled from 'styled-components';

import * as colors from '../../../util/colors';

const setTheme = theme => {
  switch (theme) {
    case 'blue':
      return `
        color: white; background-color: ${colors.BUTTON_BLUE};
        border: 1px solid ${colors.BUTTON_BLUE}; border-radius: 5px;
        &:active { color: ${colors.BUTTON_BLUE}; background-color: white; }
      `;
    case 'white':
      return `
        color: ${colors.BUTTON_BLUE}; background-color: white;
        border: 1px solid ${colors.BUTTON_BLUE}; border-radius: 5px;
        &:active { color: white; background-color: ${colors.BUTTON_BLUE}; }
      `;
    case 'link':
      return `
        margin: 0;
        color: ${colors.BLUE_MAIN}; background-color: white;
        border: 0 solid transparent;
      `;
    case 'transparent':
      return `
        margin: 0;
        color: white; background-color: transparent;
        border: 3px solid white; border-radius: 5px;
      `;
    case 'gray':
      return `
        color: ${colors.TEXT_INPUT}
        border: 1px solid ${colors.TEXT_INPUT}; border-radius: 5px;
        background: none;
      `;
    case 'white-blue':
      return `
        color: ${colors.BUTTON_BLUE}
        border: 1px solid ${colors.BUTTON_BLUE}; border-radius: 5px;
        background-color: white;
      `;
    default:
      return `
        color: white; background-color: ${colors.BUTTON_BLUE};
        border: 1px solid ${colors.BUTTON_BLUE}; border-radius: 3px;
      `;
  }
};

export const StyledButton = styled.button`
  display: ${props => props.width ? 'inline-flex' : 'flex'};
  min-width: ${props => props.minWidth || props.width || '232px'};
  max-width: ${props => props.maxWidth || props.width || '232px'};
  min-height: ${props => props.height || '40px'};
  max-height: ${props => props.height || '40px'};
  margin: 1rem 0;
  margin: ${props => props.margin};
  float: ${props => props.float};
  padding: ${props => props.padding || '0 26px'};
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  outline: none;
  &:focus { outline: none; };
  @media screen and (max-width: 768px) {
    margin: 1rem auto;
  }
  ${props => setTheme(props.theme)};
  background-color: ${props => props.disabled && colors.ICON_NON_ACTIVE };
  border: ${props => props.disabled && '1px solid transparent'};
`;
StyledButton.defaultProps = {
  type: 'button'
};