import {getJSON as get} from './http';

export const geocode = (authorization, params) => {
  return get('maps/geocode', {authorization}, params)
    .then(response => {
      if ((response.status >= 200) && (response.status < 300)) {
        return response.json();
      }

      return null;
    });
};

export default {geocode};
