import {
  CLEAR_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_SUCCESS,
} from '../actions/types';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const orderDetails = (state = initialState, action) => {
  switch (action.type) {
    // get station
    case GET_ORDER_DETAILS_START:
      return {...state, loading: true};
    case GET_ORDER_DETAILS_SUCCESS:
      return {...state, data: action.data, date: action.date, error: null, loading: false};
    case GET_ORDER_DETAILS_FAILURE:
      return {...state, error: action.error, loading: false};
    // update station
    case CLEAR_ORDER_DETAILS_REQUEST: {
      return {...state, data: null, loading: false, error: null};
    }
    default:
      return state;
  }
};

export default orderDetails;
