import styled from 'styled-components';

import bp from '../breakpoints';

export const Container = styled.span`
  text-decoration: none;
  cursor: pointer;
  
  @media screen and (max-width: ${bp.phone}) {
    display: flex;
    align-items: center;
    min-height: 56px; max-height: 56px;
    padding-left: 24px;
    color: black; background-color: white;
    cursor: pointer;
  }
  @media screen and (min-width: ${bp.phone}) {
    &:focus { text-decoration: none; }
    &:hover {
      color: ${props => props.textColorOnHover};
      border-bottom: 2px solid ${props => props.textColorOnHover};
    }
    margin: 1.04vw;
    color: inherit;
  }
`;