import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

const StationMonetizedIcon = ({t, monetized}) =>
    (
        <img
            src={monetized ? '/monetizationOn.svg' : '/monetizationOff.svg'}
            alt={monetized ? 'monetization on' : 'monetization off'}
            data-tip={t(monetized ? 'monetized' : 'noMonetization')}
        />
    )

export default compose(withTranslation())(StationMonetizedIcon);
