import React from 'react';
import styled from 'styled-components';

import * as colors from '../../util/colors';
import {getAgreementsContents} from '../../api';
import {fadeIn} from './animations';
import { Title } from './Form';
import Topbar from './Topbar';
import Footer from './Footer';
import Link from './Footer/Link';
import bp from './breakpoints';

class Agreement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agreements: ''
    };
  }

  componentDidMount() {
    getAgreementsContents(this.props.url)
      .then(response => {return response.text()})
      .then(agreement => this.setState({ agreements: agreement.replace(/"/g, '') }));
  }

  render() {
    const {links, title} = this.props
    return (
      <Container>
        <Topbar fixed />
        <PolicyContainer>
          <QuickLinksContainer linksAreVisible={links && links.length > 0}>
            <Links>
              <h3>Quick links</h3>
              {links.map((link, index) => <QuickLink
                sectionId={'\\' + link.id + '\\'}
                to={'/terms/#' + link.id || '#'} key={index}>{link.title}</QuickLink>)}
            </Links>
          </QuickLinksContainer>
          <InfoContainer>
            <PolicyTitle color={colors.PRIMARY_COLOR} size='32px'>
              {title}
            </PolicyTitle>
            <PolicyText>
              <Section>
                <InlinePolicy dangerouslySetInnerHTML={{__html: this.state.agreements}}/>
              </Section>
            </PolicyText>
          </InfoContainer>
        </PolicyContainer>
        <FooterContainer>
          <Footer/>
        </FooterContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  min-width: 100%;
  max-width: 100%;
  @media screen and (max-width: ${bp.phone}) {
    position: relative;
    display: block;
    min-height: 1032px;
  }
  @media screen and (min-width: ${bp.phone}) {
    display: flex;
    flex-direction: column;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: 1s ${fadeIn()} ease-in-out;
  @media screen and (max-width: ${bp.phone}) {
    min-height: 472px;
    margin-top: 56px;
  }
  @media screen and (min-width: ${bp.phone}) {
    font-size: 1.25vw;
    margin-left: 10%;
  }
`;

const FooterContainer = styled.div`
  @media screen and (max-width: ${bp.phone}) {
    position: relative;
    display: block;
  }
  @media screen and (min-width: ${bp.phone}) {
    margin-top: 181px
  }
`;

const QuickLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  animation: 1s ${fadeIn()} ease-in-out;
  @media screen and (max-width: ${bp.phone}) {
    display: none;
  }
  @media screen and (min-width: ${bp.phone}) {
    font-size: 1.25vw;
    margin-left: 10%;
    margin-top: 76px;
    visibility: ${props => props.linksAreVisible ? 'visible' : 'hidden'};
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const QuickLink = styled(Link)`
  margin-top: 10px;
  cursor: pointer;
  color: black;
`;

const PolicyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  animation: 1s ${fadeIn()} ease-in-out;
  @media screen and (max-width: ${bp.phone}) {
    width: 100%;
  }
  @media screen and (min-width: ${bp.phone}) {
    padding-top: 64px;
    font-size: 1.25vw;
  }
`;

const PolicyText = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${bp.phone}) {
    width: 90%;
    margin: auto;
  }
`;

const PolicyTitle = styled(Title)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  color: #2A79EE;
  margin-bottom: 32px;
  @media screen and (max-width: ${bp.phone}) {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 24px;
  }
`;

const Section = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  @media screen and (max-width: ${bp.phone}) {
    align-text: center;
    width: 100%;
  }
  @media screen and (min-width: ${bp.phone}) {
    max-width: 50vw;
  }
`;

const InlinePolicy = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #2F3033;
  @media screen and (max-width: ${bp.phone}) {
    font-size: 18px;
    padding-left: 16px;
    padding-right: 16px;
  }
  & > a {
    color: #2A79EE;
    text-decoration: none;
  }
`;

export default Agreement;
