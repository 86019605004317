import React from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import LoadingSpinner from "../common/LoadingSpinner";
import Alert from "../common/Alerts/Alert";
import {TextField} from "../common/Form";
import Button from "../common/Button";
import styled from "styled-components";
import {postStationDescription} from "../../api";

function DescriptionField(props) {
    const {
        authorization,
        value,
        vendor_name,
        model,
        connectors,
        station_id,
        isAdministrator,
    } = props;

    const {
        sendMessage,
        description,
        handleChange,
        error,
        response,
        clearResponse,
        loading,
    } = useSendDescriptionEffect(value, vendor_name, model, connectors, station_id, authorization);

    return (
        <DescForm>
            <TextField
                name="payload"
                value={description}
                handleChange={handleChange}
                width="360px"
                margin="0 12px 0 0"
                height="125px"
                multiline
            />
            <ActionBlock>
                <Button style={{marginLeft: 'auto', marginRight: '12px'}} onClick={sendMessage}>
                    Сохранить
                </Button>
                {vendor_name && !model && (<Info>ⓘ общая часть описания для всех станций данного производителя</Info>)}
                {model && !connectors && (<Info>ⓘ общая часть описания для всех станций данной модели</Info>)}
                {connectors && !station_id && (<Info>ⓘ общая часть описания для всех станций с такой же конфигурацией коннекторов</Info>)}
                {station_id && (<Info>ⓘ часть описания только для данной станции</Info>)}
            </ActionBlock>
            {loading && (
                <LoadingSpinner/>
            )}
            {response && (
                <Alert
                    onClose={clearResponse}
                    title={response}
                />
            )}
        </DescForm>
    );
}


const useSendDescriptionEffect = (value, vendor, model, connectors, stationId, authorization) => {
    const [description, setDescription] = React.useState(value);
    const [error, setError] = React.useState('');
    const [response, setResponse] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    console.log('useSendMessageEffect');
    const sendMessage = React.useCallback(() => {
        setLoading(true);
        postStationDescription({
            vendor_name: vendor,
            model,
            connectors,
            station_id: stationId,
            description,
        }, {authorization})
            .then(response => response.text())
            .then((data) => {
                setLoading(false);
                if (data.includes('error')) {
                    setError(JSON.parse(data).error);
                } else {
                    setResponse(data);
                }
            });
    }, [stationId, authorization, description]);

    const clearResponse = () => setResponse('');

    const handleChange = (e) => {
        setDescription(e.target.value);
        setError(false);
    };

    return {sendMessage, description, error, response, handleChange, clearResponse, loading};
}

const mapStateToProps = ({manager, station, app}, {match}) => ({
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    loading: station.stationsLoading,
    data: station.data,
    serverTimeOffset: app.serverTimeOffset,
    isAdministrator: manager.data?.role === 'administrator',
});

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(DescriptionField);

const DescForm = styled.div`
  display: flex !important;
  width: 100%;
  align-items: flex-start;
`;

const ActionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Info = styled.div`
  color: #A2A6AB;
  font-size: 14px;
  font-weight: normal;
`;
