import styled from "styled-components";
import * as colors from "../../util/colors";

const ExpandIcon = styled.div`
  transform: rotate(${props => props.opened ? '180deg' : '0deg'});
  position: relative;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 12px;
    left: 7px;
    top: 6px;
    transform: rotate(-45deg);
    background: ${colors.OPTION_BG};
  }
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 12px;
    right: 7px;
    top: 6px;
    transform: rotate(45deg);
    background: ${colors.OPTION_BG};
  }
`;

export default ExpandIcon;
