import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Modal from '../common/Modal';
import SignUpForm from './SignUpForm';

const SignUp = ({loggedIn, history}) => {
  if (loggedIn) {
    history.push('/dashboard');
    return false;
  }

  return (
    <Modal minHeight="609px">
      <SignUpForm />
    </Modal>
  );
};

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
});

export default connect(mapStateToProps, null)(withRouter(SignUp));
