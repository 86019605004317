import React from 'react';
import styled from 'styled-components';

const RoundedLabel = props => <Label {...props}/>

const setTheme = theme => {
  switch (theme) {
    case 'orange':
      return `
        background-color: #FFF4E3;
        color: #E89516;
      `;
    case 'lightblue':
      return `
        background-color: #E1EEFF;
        color: #2A79EE;
      `;
    default:
      return `
        background-color: #EBFAE4;
        color: #3CC01B;
      `;
  }
};

const Label = styled.div`
  ${props => setTheme(props.theme)};
  display: block;
  border-radius: 16px;
  padding: ${props => props.padding || '9px 16px'};
  font-weight: 500;
  font-size: ${props => props.fontSize || '14px'};
  text-align: center;
`;

export default RoundedLabel;
