import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  height: ${props => props.height ? props.height : 'auto'};
  padding: ${props => props.padding ? props.padding : '24px'};
  box-shadow: 0 1px 15px rgba(217,226,232,0.8);
  border-radius: 10px;
  background-color: #fff;
  overflow: auto;
  margin-bottom: 24px;
  position: relative;
`;