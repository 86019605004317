import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import Button from '../../common/Button';
import {ErrorAlert} from '../../common/Alerts';
import ForAllDays from './ForAllDays';
import Period from './Period';

class OpeningHoursContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        for_all_days: {
          active: {value: false, valid: false},
          opens_at: {value: '08:00', valid: undefined},
          closes_at: {value: '20:00', valid: undefined},
        },
        periods: [],
      },
    };
  }

  componentWillMount() {
    const {data} = this.props;

    for (let i in data.periods) {
      let {day, opens_at, closes_at} = data.periods[i];
      this.setPeriod(`periods.${day}`);
      this.setPeriod(
        `periods.${day}.opens_at`,
        `${opens_at.substring(0,2)}:${opens_at.substring(2)}`
      );
      this.setPeriod(
        `periods.${day}.closes_at`,
        `${closes_at.substring(0,2)}:${closes_at.substring(2)}`
      );
    }
  }

  componentDidMount() {
    if (this.props.error) {
      this.props.error.error = null;
    }
  }

  handleSubmit = event => {
    this.props.handleSubmit(event, () => {
      const { periods } = this.state.data;
      let formData = [];

      for (let day in periods) {
        let {opens_at, closes_at} = periods[day];
        formData.push({
          day,
          opens_at: opens_at.value.replace(':', '').padStart(4, '0'),
          closes_at: closes_at.value.replace(':', '').padStart(4, '0'),
        });
      }

      const {authorization, locationId,
        setLocationOpeningHours, getLocation, history} = this.props;

      setLocationOpeningHours(authorization, locationId, formData, () => {
        getLocation(locationId, () => {
          history.push(`/locations/${locationId}/stations`);
        });
      });
    });
  };

  handleChange = event => {
    const {name, value} = event.target;

    switch (name.split('.')[0]) {
      case 'for_all_days':
        this.setForAllDays(name, value);
        return true;
      case 'periods':
        this.setPeriod(name, value);
        return true;
      default:
        return false;
    }
  };

  setForAllDays = (name, value) => {
    const {data} = this.state;
    const fieldName = name.split('.')[1];

    if (!data.for_all_days[fieldName]) {
      return false;
    }

    if (fieldName === 'active') {
      data.for_all_days.active.value = !data.for_all_days.active.value;
      data.for_all_days.active.valid = !data.for_all_days.active.valid;

      for (let day in data.periods) {
        this.setPeriod(`periods.${day}.opens_at`, data.for_all_days.opens_at.value);
        this.setPeriod(`periods.${day}.closes_at`, data.for_all_days.closes_at.value);
      }

      this.setState({data});

      return true;
    }

    data.for_all_days[fieldName].value = value;
    data.for_all_days[fieldName].valid = this.fieldIsValid(fieldName, value);

    for (let day in data.periods) {
      this.setPeriod(`periods.${day}.${fieldName}`, data.for_all_days[fieldName].value);
    }

    this.setState({data});
  };

  setPeriod = (name, value) => {
    const {data} = this.state;
    const day = name.split('.')[1];
    let period = data.periods[day];

    if (!period) {
      data.periods.push({
        opens_at: { value: '08:00', valid: undefined },
        closes_at: { value: '20:00', valid: undefined },
      });
      period = data.periods[data.periods.length-1];
      this.setState({ data });
      return true;
    }

    period[name.split('.')[2]].value = value;
    period[name.split('.')[2]].valid = this.fieldIsValid(name.split('.')[2], value);

    this.setState({data});
  };

  fieldIsValid = (name, value) => {
    switch (name) {
      case 'active':
        return value;
      case 'opens_at':
      case 'closes_at':
        const time = value.split(':');

        if (time.length !== 2 || !time[0] || !time[1]) {
          return false;
        }

        const [hours, minutes] = value.split(':');
        const hoursValid = hours && parseInt(hours) && parseInt(hours) < 24;
        const minutesValid = minutes && parseInt(minutes) && parseInt(minutes) < 60;
        return (hoursValid && minutesValid);
      default:
        return false;
    }
  };

  render() {
    const {locationId, allFieldsValid, history, error, t} = this.props;
    const {data} = this.state;
    const periodFieldsDisabled = data.for_all_days.active.value;

    return (
      <Container>
        <Title>{t('openingHours')}</Title>
        <PeriodContainer>
          <ForAllDays
            data={data.for_all_days}
            handleChange={this.handleChange}
          />
          {periodItems(data.periods, this.handleChange, periodFieldsDisabled)}
        </PeriodContainer>
        <ButtonContainer>
          <Button onClick={() => history.push(`/locations/${locationId}/stations`)}
            theme="white" width="232px" margin="0 6px 0 0" float="left">
            {t('cancel')}
          </Button>
          <Button onClick={this.handleSubmit}
            theme="blue" width="232px" margin="0 0 0 6px" float="right"
            disabled={!allFieldsValid}>
            {t('confirm')}
          </Button>
          {error && error.error && <div className="error-alert"><ErrorAlert>{error.error}</ErrorAlert></div>}
        </ButtonContainer>
      </Container>
    );
  }
}

const periodItems = (periods, handleChange, disabled=false) => {
  const items = periods.map((period, i) => {
    return <Period key={i}
      day={i} {...period}
      disabled={disabled}
      handleChange={handleChange}
    />;
  });

  return items;
};

const Container = styled.div`
  min-height: 120px; max-height: 120px;
`;

const Title = styled.h1`
font-size: 24px;
font-weight: bold;
color: black;
`;

const PeriodContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%; max-width: 100%;
  min-height: 100px; max-height: 100px;
  overflow-x: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  & > div.error-alert {
    width: auto;
    margin-left: 9px;
  }
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {
    position: absolute; right: 0; bottom: 0; left: 0;
  }
`;

export default compose(withTranslation() ,withRouter)(OpeningHoursContainer);
