import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import deletePhoto from '../../../redux/actions/deletePhoto';
import getPhotos from '../../../redux/actions/getPhotos';
import Modal from '../../common/Modal';
import DeletePhotoForm from './DeletePhotoForm';

class DeletePhoto extends Component {
  componentDidMount() {
    const {loggedIn, photo, history} = this.props;

    if (!loggedIn || !photo) {
      history.push('/');
      return false;
    }
  }

  render() {
    const {authorization, locationId, photoId, photo,
       deletePhoto, getPhotos} = this.props;

    return (
      <Modal minHeight="auto">
        <DeletePhotoForm
          authorization={authorization}
          locationId={locationId}
          photoId={photoId}
          photo={photo}
          deletePhoto={deletePhoto}
          getPhotos={getPhotos}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({manager, location}, {match}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  photoId: match.params.photoId,
  photo: location.photos.find(photo => photo.id === match.params.photoId),
});

const mapDispatchToProps = dispatch => ({
  deletePhoto: (authorization, locationId, data, callback) =>
    dispatch(deletePhoto(authorization, locationId, data, callback)),
  getPhotos: (locationId, params, callback) =>
    dispatch(getPhotos(locationId, params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeletePhoto));
