import {postJSON as post} from '../../api';
import {EDIT_MANAGER_START, EDIT_MANAGER_SUCCESS, EDIT_MANAGER_FAILURE} from './types';

const editManagerStart = () => ({
  type: EDIT_MANAGER_START,
});

const editManagerSuccess = () => ({
  type: EDIT_MANAGER_SUCCESS,
});

const editManagerFailure = error => ({
  type: EDIT_MANAGER_FAILURE,
  error,
});

const editManager = (authorization, managerId, data, callback) => {
  return dispatch => {
    dispatch(editManagerStart());
    post(`managers/${managerId}`, {authorization}, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(editManagerSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(editManagerFailure(error));
      });
  };
};

export default editManager;
