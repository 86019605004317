import {postJSON as post} from '../../api';
import {UPDATE_LOCATION_START, UPDATE_LOCATION_SUCCESS, UPDATE_LOCATION_FAILURE} from './types';

const updateLocationStart = () => ({
  type: UPDATE_LOCATION_START,
});

const updateLocationSuccess = () => ({
  type: UPDATE_LOCATION_SUCCESS,
});

const updateLocationFailure = error => ({
  type: UPDATE_LOCATION_FAILURE,
  error,
});

const updateLocation = (authorization, locationId, body, callback) => {
  return dispatch => {
    dispatch(updateLocationStart());
    post(`locations/${locationId}`, {authorization}, body)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(updateLocationSuccess());
          if (callback) { callback(); }
        } else if ((response.status >= 400) && (response.status < 500)) {
          return response.json();
        } else {
          dispatch(updateLocationFailure(response));
        }
      })
      .then(data => {
        if (data && data.error) {
          dispatch(updateLocationFailure(data));
        }
      })
      .catch(error => {
        dispatch(updateLocationFailure(error));
      });
  };
};

export default updateLocation;
