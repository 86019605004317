import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import getServiceTypes from '../../../redux/actions/getServiceTypes';
import addService from '../../../redux/actions/addService';
import getServices from '../../../redux/actions/getServices';
import Modal from '../../common/Modal';
import AddServiceForm from './AddServiceForm';

class AddService extends Component {
  componentWillMount() {
    const {loggedIn, history} = this.props;

    if (!loggedIn) {
      history.push(`/`);
      return false;
    }
  }

  render() {
    const {props} = this;

    return (
      <Modal>
        <AddServiceForm
          authorization={props.authorization}
          locationId={props.locationId}
          loading={props.loading} error={props.error}
          serviceTypes={props.serviceTypes} locationServices={props.locationServices}
          getServiceTypes={props.getServiceTypes}
          addService={props.addService} getServices={props.getServices}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({manager, location, types}, {match}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  locationServices: location.services,
  serviceTypes: types.services,
  loading: types.loading || location.servicesLoading,
  error: location.error || types.error,
});

const mapDispatchToProps = dispatch => ({
  getServiceTypes: callback => dispatch(getServiceTypes(callback)),
  addService: (authorization, locationId, data, callback) =>
    dispatch(addService(authorization, locationId, data, callback)),
  getServices: (locationId, params, callback) =>
    dispatch(getServices(locationId, params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddService));
