import React, {useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Sidebar from '../common/Sidebar';
import TabLink from '../common/TabLink';
import LocationCard from './LocationCard';
import MapComponent from './Map';

import {WrapperBlock, PageBlock, TopBarBlock} from '../common/MainBlocks';
import TopbarMobile from "../common/TopbarMobile";

const LocationsMap = props => {
  const {loggedIn, history} = props;
  const {t} = useTranslation();

  if (!loggedIn) {
    history.push('/');
  }

  const [description, setDescription] = useState();

  return (
    <WrapperBlock>
      <TopbarMobile />
      <Sidebar />
      <PageBlockStyled>
        <TopBarBlock white fixed noMargin>
          <TabLink to="/map">{t('allLocations')}</TabLink>
        </TopBarBlock>
        {description &&
          <LocationCard description={description}/>
        }
        <MapComponent setDescription={setDescription}/>
      </PageBlockStyled>
    </WrapperBlock>
  );
};

const PageBlockStyled = styled(PageBlock)`
  padding: 0 !important;
  overflow: hidden;
`;

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
});

export default connect(mapStateToProps)(LocationsMap);
