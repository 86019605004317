import {SET_MAP_POSITION, SET_MAP_ADDRESS} from '../actions/types';

const initialState = {
  position: {},
};

const map = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_POSITION:
      return {...state, position: action.position};
    case SET_MAP_ADDRESS:
      return {...state, address: action.address};
    default:
      return state;
  }
};

export default map;
