import Modal, {Close} from "../Modal";
import {Title} from "../Form";
import React from "react";

export default function Alert({onClose, title}) {
  return (
    <Modal minHeight="40px" padding="30px">
      <Close onClick={onClose}>
        <img src="/cross.svg" alt="Close" />
      </Close>
      <Title>{title}</Title>
    </Modal>
  )
}
