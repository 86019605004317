import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {getStatisticsReport} from "../../api";

import {DownloadItem, IntervalItem} from "../Dashboard/StatisticsContainer.styled";
import LoadingSpinner from '../common/LoadingSpinner';


function ExportExcel({ authorization, title, mode, from, to, locations, history }) {
  const [loading, setLoading] = React.useState(false);
  const onExportExcelClick = async () => {
      setLoading(true);
      getStatisticsReport({authorization}, mode, from, to, locations)
          .then(response => response.blob())
          .then(blob => {
              setLoading(false);
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `report-${mode}-${from.substr(0, 10).replaceAll('-', '')}-${to.substr(0, 10).replaceAll('-', '')}.xlsx`;
              document.body.appendChild(a);
              a.click();
              a.remove();
          });
  }

  return (
      <>
          {loading && <LoadingSpinner />}
          <IntervalItem onClick={onExportExcelClick}>
              <DownloadItem>
                  <span>{title}</span>
                  <img src="/downloadIcon.svg" alt="download icon" height='15'/>
              </DownloadItem>
          </IntervalItem>
      </>
  )
};


const mapStateToProps = ({manager}) => ({
    loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
    role: manager.data?.role,
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
});

export default compose(connect(mapStateToProps, null), withTranslation(), withRouter)(ExportExcel);
