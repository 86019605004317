import React from 'react';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';
import {compose} from 'redux';
import getStation from "../../redux/actions/getStation";

import {WrapperBlock, PageBlock, PageInnerBlock, TopBarBlock} from '../common/MainBlocks';
import Sidebar from '../common/Sidebar';
import TopbarBackButton from '../common/TopbarBackButton';
import StationTabs from './StationTabs';
import StationInformationTab from './StationInformationTab';
import StationEvsesTab from './StationEvsesTab';
import StationConsoleTab from "./StationConsoleTab";
import StationLogsTab from "./StationLogsTab";
import StationDescriptionTab from './StationDescriptionTab';
import TopbarMobile from "../common/TopbarMobile";
import TitleField from "../common/TitleField";

function Station(props) {
    const {stationId} = props.match.params;
    const {loggedIn, history, authorization, data, loading, getStation} = props;

    if (!loggedIn) {
        history.push('/');
    }

    React.useEffect(() => {
        getStation(authorization, stationId);
    }, [authorization, stationId]);

    let stationName = data && `${data.id} (${data.location_name}, ${data.location_street})`;

    return (
        <WrapperBlock>
            <TopbarMobile />
            <Sidebar/>
            <PageBlock>
                <PageInnerBlock>
                    {/* {error && error.message && <ErrorAlert>Something went wrong</ErrorAlert>} */}
                    <TopBarBlock white fixed>
                        <TopbarBackButton
                            to="/charge-points"
                            label="Зарядные станции"
                        />
                        <TitleField>{stationName}</TitleField>
                    </TopBarBlock>

                    <StationTabs
                        authorization={authorization}
                        stationId={stationId}
                        loading={loading}
                    />

                    <Route path="/charge-points/:stationId/information" component={StationInformationTab}/>
                    <Route path="/charge-points/:stationId/controllers" component={StationEvsesTab}/>
                    <Route path="/charge-points/:stationId/console" component={StationConsoleTab}/>
                    <Route path="/charge-points/:stationId/logs/:connectorId?" component={StationLogsTab}/>
                    <Route path="/charge-points/:stationId/description" component={StationDescriptionTab}/>
                </PageInnerBlock>
            </PageBlock>
        </WrapperBlock>
    );
}

const mapStateToProps = ({manager, station}) => ({
    loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
    authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
    ...station,
});

const mapDispatchToProps = dispatch => ({
    getStation: (...params) => dispatch(getStation(...params)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Station);
