import {getJSON as get} from '../../api';
import {GET_LOCATION_FEEDBACK_START, GET_LOCATION_FEEDBACK_SUCCESS, GET_LOCATION_FEEDBACK_FAILURE} from './types';

const getFeedbackStart = () => ({
  type: GET_LOCATION_FEEDBACK_START,
});

const getFeedbackSuccess = data => ({
  type: GET_LOCATION_FEEDBACK_SUCCESS,
  data,
});

const getFeedbackFailure = error => ({
  type: GET_LOCATION_FEEDBACK_FAILURE,
  error,
});

const getFeedback = (locationId, params, callback) => {
  return dispatch => {
    dispatch(getFeedbackStart());
    get(`locations/${locationId}/feedback`, null, params)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getFeedbackSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getFeedbackFailure(error));
      });
  };
};

export default getFeedback;
