import {CLEAR_STATION_REQUEST} from './types';

const clearStationRequest = () => ({
  type: CLEAR_STATION_REQUEST
});

const clearStation = () => {
  return dispatch => {
    dispatch(clearStationRequest());
  };
};

export default clearStation;
