import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import Button from '../common/Button';

const MiddleBar = props => {
  const {
    status,
    buttonText,
    buttonLink,
    buttonOnClick,
    children,
    tooltipText = '',
    disabled,
  } = props;

  return (
    <Container>

      <LeftSide>
        {status}
      </LeftSide>

      {buttonText && (buttonLink || buttonOnClick) &&
        <RightSide>
          {(tooltipText.length > 0 && disabled) ? (
            <span data-tip={tooltipText} data-place="left">
              <Button
                to={buttonLink}
                minWidth="131px"
                maxWidth="none"
                height="40px"
                margin="0"
                disabled>
                {buttonText}
              </Button>
            </span>
          ) : (
            <Button
              to={buttonLink}
              onClick={buttonOnClick}
              minWidth="131px"
              maxWidth="none"
              height="40px"
              margin="0">
              {buttonText}
            </Button>
          )}
          <ReactTooltip effect='solid' />
        </RightSide>
      }

      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

const LeftSide = styled.div`
  flex-grow: 1;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  padding-left: 3px;
  font-size: 20px;
  font-weight: bold;
  color: #202020;
`;

const Counter = styled.span`
  color: #8f8f8f;
`;

export default MiddleBar;

export {
  Title,
  Counter,
};
