import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {TextField} from '../../common/Form';
import Divider from '../../common/Divider';
import StationCard from './StationCard';

export const ChooseStationForm = props => {
  const {
    stations,
    changeStep,
    selectModelBtnClick,
    saveStation,
    handleSearch,
    search,
  } = props;

  const {t} = useTranslation();

  const [selectedIndex, setSelectedStation] = useState(0);

  const renderStation = (station, index) => {
    return (
      <StationCard {...station} key={index}
        currentIndex={selectedIndex}
        active={selectedIndex === index}
        changeStationCard={() => setSelectedStation(index)}
        saveStation={saveStation}
      />
    );
  };

  // REVIEW
  const handleChange = event => {
    setSelectedStation(0);

    saveStation(stations[0]);

    return handleSearch(event);
  };

  return (
    <Wrapper>
      <SearchPanel>
        <div>
          <Text size="24px" color="#202020">{t('addNewStation')}</Text>
        </div>
        <div style={{marginTop: '16px'}}>
          <Text>{t('selectStationModel')}</Text>
        </div>

        <InputForm>
          <TextField
            label={t('searchByManufacturerOrModel')}
            handleChange={handleChange}
            placeholder={search}
          />
        </InputForm>

        <Buttons>
          <Button
            style={{marginRight: '5px'}}
            onClick={() => changeStep(1)}>
            {t('createNewModel')}
          </Button>
          <Button
            disabled={!stations || stations.length === 0}
            border="transparent"
            style={{marginLeft: '5px'}}
            color="#4792FF"
            onClick={selectModelBtnClick}
            textColor="#FFFFFF">
            {t('selectModel')}
          </Button>
        </Buttons>
        <Divider marginBottom={'0px'}/>
      </SearchPanel>

      <StationContainer>
        {stations.map((station, index) => renderStation(station, index))}
      </StationContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 15px rgba(217,226,232,0.8);
  max-width: 700px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const SearchPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 62px;
  z-index: 2;
  background-color: #fff;
  padding: 32px 0 0;
`;

const Text = styled.span`
  font-size: ${props => props.size || '16px'}
  color: ${props => props.color || '#818286'}
  margin-top: ${props => props.paddingTop || '0px'}
`;

const InputForm = styled.div`
  display: flex;
  flex-direction: column
  align-items: center;
  margin-top: 32px;
  width: 100%;
  padding-left: 5.55vw;
  padding-right: 5.55vw;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-bottom: 24px;
  padding-top: 16px;
  width: 100%;
  padding-left: 5.55vw;
  padding-right: 5.55vw;
`;

const Button = styled.button`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${props => props.border || '#4792FF'};
  font-size: 14px;
  background-color: ${props => props.color || '#FFFFFF'};
  color: ${props => props.textColor || '#4792FF'};
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: #CCCED7;
  }
  outline: none;
  &:focus { outline: none; };
`;

// const Border = styled.div`
//   height: 1px;
//   background-color: #E6EAED;
//   width: 100%;
//   margin-top: 24px;
// `;

const StationContainer = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 5.55vw;
  padding-right: 5.55vw;
`;
