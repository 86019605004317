import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import getPaymentAccount from '../../../redux/actions/getPaymentAccount';
import updatePaymentAccount from '../../../redux/actions/updatePaymentAccount';
import LoadingSpinner from '../../common/LoadingSpinner';
import {TopBarBlock} from '../../common/MainBlocks';
import TopbarBackButton from '../../common/TopbarBackButton';
import PaymentAccountForm from './PaymentAccountForm';

class PaymentAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      authorization,
      data,
      loading,
      updatePaymentAccount,
      getPaymentAccount,
      t,
    } = this.props;

    if (loading) {
      return (
        <>
          <TopBarBlock fixed white>
            <TopbarBackButton
              to="/settings/payments"
              label={t('payments')}
            />
          </TopBarBlock>
          <LoadingSpinner />
        </>
      );
    }

    return (
      <>
        <TopBarBlock fixed white>
          <TopbarBackButton
            to="/settings/payments"
            label={t('payments')}
          />
        </TopBarBlock>

        <PaymentAccountForm
          authorization={authorization}
          managerId={data.id}
          managerEmail={data.email}
          updatePaymentAccount={updatePaymentAccount}
          getPaymentAccount={getPaymentAccount}
          loading={loading}
          error={{}}
        />
      </>
    );
  }
}

const mapStateToProps = ({manager, plans}) => ({
  loggedIn: manager.accessToken && Object.values(manager.data).length > 0,
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  plans,
  ...manager,
});

const mapDispatchToProps = dispatch => ({
  getPaymentAccount: (authorization, managerId, callback) =>
    dispatch(getPaymentAccount(authorization, managerId, callback)),
  updatePaymentAccount: (authorization, managerId, data, callback) =>
    dispatch(updatePaymentAccount(authorization, managerId, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withTranslation(), withRouter)(PaymentAccount));
