import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getPhotos from '../../../redux/actions/getPhotos';
import LoadingSpinner from '../../common/LoadingSpinner';

import MiddleBar, { Title, Counter } from '../MiddleBar';
import PhotoContainer from './PhotoContainer';

class Photos extends Component {
  componentDidMount() {
    const {locationId, getPhotos} = this.props;

    getPhotos(locationId);
  }

  render() {
    const {
      authorization,
      locationId,
      data,
      loading,
      getPhotos,
      t,
    } = this.props;

    if (loading) {
      return (
          <LoadingSpinner />
      );
    }

    const status = (
      <>
        <Title>
          {t('photos')}
          {' '}
          <Counter>({data.length})</Counter>
        </Title>
      </>
    );

    return (
      <>
        <MiddleBar
          status={status}
          buttonText={t('addPhoto')} 
          buttonLink={`/locations/${locationId}/photos/add`}
        />

        <PhotoContainer
          authorization={authorization}
          locationId={locationId}
          data={data}
          getPhotos={getPhotos}
        />
      </>
    );
  }
}

const mapStateToProps = ({manager, location}, {match}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  data: location.photos,
  loading: location.photosLoading,
});

const mapDispatchToProps = dispatch => ({
  getPhotos: locationId => dispatch(getPhotos(locationId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Photos);
