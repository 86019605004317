import React from 'react';
import {convertJsonToSheetData, exportToXLSX} from "../../util/xlsx";

import {DownloadItem, IntervalItem} from "../Dashboard/StatisticsContainer.styled";

export default function ExportExcel({ getCsvData, fileName }) {
  const onExportExcelClick = async () => {
    const csvData = await getCsvData();
    const sheetData = convertJsonToSheetData(csvData);

    exportToXLSX({ Sheets: { data: sheetData }, fileName });
  }

  return (
    <IntervalItem onClick={onExportExcelClick}>
      <DownloadItem>
        <span>Excel</span>
        <img src="/downloadIcon.svg" alt="download icon" height='15'/>
      </DownloadItem>
    </IntervalItem>
  )
};
