import React from 'react';

const FailPayment = () => {
  const query = new URLSearchParams(window.location.search);
  return (
      <div>Оплата не прошла</div>
  );
};

export default FailPayment;
