import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getFeedback from '../../../redux/actions/getFeedback';
import getUser from '../../../redux/actions/getUser';
import LoadingSpinner from '../../common/LoadingSpinner';

import MiddleBar, { Title, Counter } from '../MiddleBar';
import FeedbackContainer from './FeedbackContainer';

class Feedback extends Component {
  componentDidMount() {
    const {locationId, getFeedback} = this.props;

    getFeedback(locationId);
  }

  render() {
    const {authorization, locationId, data, loading, getFeedback, getUser, t} = this.props;

    if (loading) {
      return (
        <LoadingSpinner />
      );
    }

    const status = (
      <>
        <Title>
          {t('feedback')}
          {' '}
          <Counter>({data.length})</Counter>
        </Title>
      </>
    );

    return (
      <>
        <MiddleBar status={status}/>

        {data.length > 0 && 
          <FeedbackContainer
            authorization={authorization}
            locationId={locationId}
            data={data}
            getFeedback={getFeedback}
            getUser={getUser}
          />
        }
      </>
    );
  }
}

const mapStateToProps = ({manager, location}, {match}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  locationId: match.params.locationId,
  data: location.feedback,
  loading: location.feedbackLoading,
  manager: manager.data,
});

const mapDispatchToProps = dispatch => ({
  getFeedback: locationId => dispatch(getFeedback(locationId)),
  getUser: (userId, callback) => dispatch(getUser(userId, callback)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Feedback);
