import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Divider = props => {
  const {
    children,
    textProps,
    height,
    ...other
  } = props;

  return (
    <DividerWrap {...other}>
      <Line height={height}/>

      {children &&
        <Text {...textProps}>
          {children}
        </Text>
      }

      <Line height={height}/>
    </DividerWrap>
  );
};

const DividerWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${props => props.marginTop || '16px'};
  margin-bottom: ${props => props.marginBottom || '16px'};
  position: relative;
`;

const Line = styled.div`
  flex-grow: 1;
  height: ${props => props.height || '1px'};
  background-color: #E6EAED;
`;

const Text = styled.div`
  padding: 0 1rem;
`;

Divider.propTypes = {
  children: PropTypes.node,
  textProps: PropTypes.object,
  other: PropTypes.object,
};

export default Divider;
