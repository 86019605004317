import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import updatePaymentAccount from '../../../redux/actions/updatePaymentAccount';
import Form, {Title, Text} from '../../common/Form';
import Button from '../../common/Button';
import LoadingSpinner from '../../common/LoadingSpinner';
import {ErrorAlert} from '../../common/Alerts';
import {TopBarBlock} from '../../common/MainBlocks';
import TopbarBackButton from '../../common/TopbarBackButton';
import {Bank} from './sections';

const COUNTRY_OPTIONS = [
  {id: 'DE', name: 'Germany'},
  {id: 'RU', name: 'Russia'},
];

class PaymentBank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        bank_account_id: {value: '', valid: undefined},
        bank_name: {value: '', valid: undefined},
        bank_id: {value: '', valid: undefined},
        bank_country: {
          value: COUNTRY_OPTIONS[0].id, valid: true,
          options: COUNTRY_OPTIONS,
          selectedOptionIndex: 0,
        },
        bank_city: {value: '', valid: undefined},
        bank_postal_code: {value: '', valid: undefined},
        bank_address: {value: '', valid: undefined},
        name_on_account: {value: '', valid: undefined},
      }
    };
  }

  fieldIsPermissible = (name, value) => {
    switch (name) {
      case 'birth_date':
        return value.length <= 10;
      default:
        return true;
    }
  };

  fieldIsValid = (name, value) => {
    switch (name) {
      case 'birth_date':
        return value.length === 10;
      default:
        return value !== '';
    }
  };

  fieldsAreValid = () => {
    return !Object.values(this.state.data).find(value => !value.valid);
  }

  handleChange = event => {
    const {name, value} = event.target;

    if (!this.fieldIsPermissible(name, value)) { return false; }

    const data = {...this.state.data};

    // for select fields
    if (data[name].options) {
      const selectedOption = data[name].options.find(option => option.id === value);
      data[name].selectedOptionIndex = data[name].options.indexOf(selectedOption);
    }

    data[name].valid = this.fieldIsValid(name, value);

    if (name === 'birth_date') {
      const previousValue = data[name].value;
      const dashIsNeeded =
        previousValue.length < value.length &&
        (value.length === 2 || value.length === 5);

      if (dashIsNeeded) {
        data[name].value = value + '-';
      }
      else {
        data[name].value = value;
      }
    }
    else {
      data[name].value = value;
    }

    this.setState({data});
  };

  handleSubmit = event => {
    event.preventDefault();

    const {authorization} = this.props;
    const managerId = this.props.data.id;
    const {data} = this.state;

    const body = {
      payoutInfo: [{
        payoutType: 'WIRE',
        baseCurrency: 'EUR',
        bankName: data.bank_name.value,
        country: data.bank_country.value,
        city: data.bank_city.value,
        address: data.bank_address.value,
        zip: data.bank_postal_code.value,
        nameOnAccount: data.name_on_account.value,
        bankAccountClass: 'CORPORATE',
        bankAccountType: 'CHECKING',
        bankId: data.bank_id.value,
        bankAccountId: data.bank_account_id.value,
        // `iban` is required if payoutType value is CHAPS or SEPA. Value is International Bank Account Number.
        // `swiftBic` is optional. Relevant if payoutType value is CHAPS or SEPA. Value is Bank Identifier Code.
      }],
    };

    this.props.updatePaymentAccount(authorization, managerId, body, () => this.props.history.push('/settings/payments/subscription'));
  }

  render() {
    const {loading, error, t} = this.props;

    if (loading) {
      return (
        <>
          <TopBarBlock fixed white>
            <TopbarBackButton
              to="/settings/payments"
              label={t('payments')}
            />
          </TopBarBlock>
          <LoadingSpinner />
        </>
      );
    }

    return (
      <>
        <TopBarBlock fixed white>
          <TopbarBackButton
            to="/settings/payments"
            label={t('payments')}
          />
        </TopBarBlock>
        <Container>
          <img src="/paymentsProgressBar/progressBar2.svg" alt="progress-bar" className="progress-bar-icon" />
          <Form onSubmit={this.handleSubmit}>
            <Title size="24px">{t('bankInfo')}</Title>
            <Text margin="16px 0">{t('addBankInfoTo')}</Text>

            <Bank data={this.state.data} handleChange={this.handleChange} />

            <Info>
              <img src="/infoIcon.svg" alt="info icon" className="info-icon"/>
              <Text color="#818286" size="14px">{t('deDoesNotStoreBankInfo')}</Text>
            </Info>

            <ButtonContainer>
              <div className="button-container">
                <Button
                  disabled={!this.fieldsAreValid()}
                  type='submit'
                  margin='0'
                  width='auto'
                >
                  {t('addBankInfo')}
                </Button>
                {error && error.message && <div className="error-alert"><ErrorAlert>{error.message}</ErrorAlert></div>}
              </div>
            </ButtonContainer>
          </Form>
        </Container>
      </>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: 582px; max-width: 582px;
  min-height: 120px;
  margin: 0 auto; margin-bottom: 32px;
  padding: 24px 100px; padding-bottom: 68px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px ${colors.LINE};
  & > h3.title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: bold;
  }
  & > img.progress-bar-icon {
    display: block;
    margin: 0 auto 24px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; min-width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  & > span {
    padding-bottom: 5px;
  }
  & > img {
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute; right: 0; bottom: 0; left: 0;
  min-height: 68px; max-height: 68px;
  & > div.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%; max-width: 100%;
    position: relative;
    & > div.error-alert {
      margin-left: 9px;
    }
  }
`;

const mapStateToProps = ({manager}) => ({
  loggedIn: manager.accessToken && Object.values(manager.data).length > 0,
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  ...manager,
});

const mapDispatchToProps = dispatch => ({
  updatePaymentAccount: (authorization, managerId, data, callback) =>
    dispatch(updatePaymentAccount(authorization, managerId, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withTranslation(), withRouter)(PaymentBank));
