import { deleteJSON } from '../../api';
import {
  DELETE_LOCATION_SERVICE_START, DELETE_LOCATION_SERVICE_SUCCESS, DELETE_LOCATION_SERVICE_FAILURE,
} from './types';

const deleteServiceStart = () => ({
  type: DELETE_LOCATION_SERVICE_START,
});

const deleteServiceSuccess = () => ({
  type: DELETE_LOCATION_SERVICE_SUCCESS,
});

const deleteServiceFailure = error => ({
  type: DELETE_LOCATION_SERVICE_FAILURE,
  error,
});

const deleteService = (authorization, serviceId, callback) => {
  return dispatch => {
    dispatch(deleteServiceStart());
    deleteJSON(`services/${serviceId}`, {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(deleteServiceSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(deleteServiceFailure(error));
      });
  };
};

export default deleteService;
