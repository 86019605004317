import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import getLocation from '../../redux/actions/getLocation';
import deleteLocation from '../../redux/actions/deleteLocation';
import {WrapperBlock, PageBlock, PageInnerBlock, TopBarBlock} from '../common/MainBlocks';
import Sidebar from '../common/Sidebar';
import TopbarBackButton from '../common/TopbarBackButton';

import LocationCard from './LocationCard';
import LocationSettings from './LocationSettings';
// stations
import Stations from './Stations';
import StationSettings from './StationSettings';
import AddStation from './AddStation';
// services
import Services from './Services';
import AddService from './AddService';
import EditService from './EditService';
// photos
import Photos from './Photos';
import AddPhoto from './AddPhoto';
import DeletePhoto from './DeletePhoto';
// feedback
import Feedback from './Feedback';
import TopbarMobile from "../common/TopbarMobile";

class Locations extends Component {
  componentWillMount() {
    const {locationId} = this.props.match.params;
    const {loggedIn, getLocation, history} = this.props;

    if (!loggedIn) {
      history.push('/');
    }

    getLocation(locationId);
  }

  render() {
    const {locationId} = this.props.match.params;
    const {authorization, data, loading, deleteLocation, t} = this.props;
    const tobapAndLocationCardVisible = !window.location.pathname.includes('/stations/add');

    return (
      <WrapperBlock>
        <TopbarMobile />
        <Sidebar/>
        <PageBlock>
          <PageInnerBlock>
            {/* {error && error.message && <ErrorAlert>Something went wrong</ErrorAlert>} */}

            {tobapAndLocationCardVisible &&
              <>
                <TopBarBlock white fixed>
                  <TopbarBackButton
                    to="/locations"
                    label={t('locations')}
                  />
                </TopBarBlock>

                <LocationCard
                  authorization={authorization}
                  locationId={locationId}
                  loading={loading}
                  data={data}
                  deleteLocation={deleteLocation}
                />
              </>
            }
            {/* edit location */}
            <Route path="/locations/:locationId/settings" component={LocationSettings}/>

            {/* stations section */}
            <Route exact path="/locations/:locationId/stations" component={Stations}/>
            <Route path="/locations/:locationId/stations/add" component={AddStation}/>
            <Route path="/locations/:locationId/stations/:stationId/settings" component={StationSettings}/>

            {/* services section */}
            <Route path="/locations/:locationId/services" component={Services}/>
            <Route path="/locations/:locationId/services/add" component={AddService}/>
            <Route path="/locations/:locationId/services/:serviceId/edit" component={EditService}/>

            {/* photos section */}
            <Route path="/locations/:locationId/photos" component={Photos}/>
            <Route path="/locations/:locationId/photos/add" component={AddPhoto}/>
            <Route path="/locations/:locationId/photos/:photoId/delete" component={DeletePhoto}/>

            {/* feedback section */}
            <Route path="/locations/:locationId/feedback" component={Feedback}/>
          </PageInnerBlock>
        </PageBlock>
      </WrapperBlock>
    );
  }
}

const mapStateToProps = ({manager, location}) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  ...location,
});

const mapDispatchToProps = dispatch => ({
  getLocation: (locationId, callback) =>
    dispatch(getLocation(locationId, callback)),
  deleteLocation: (authorization, locationId, callback) =>
    dispatch(deleteLocation(authorization, locationId, callback)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter
)(Locations);
