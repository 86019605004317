import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';

import getManagerBalance from '../../../redux/actions/getManagerBalance';
import Profile from './Profile';

const mapStateToProps = ({manager, balance}) => ({
  authorization: `Bearer ${manager.accessToken}`,
  balance: balance.data,
  ...manager.data,
});

const mapDispatchToProps = dispatch => ({
  getManagerBalance: (authorization, managerId, callback) => {
    return dispatch(getManagerBalance(authorization, managerId, callback));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Profile);