import React from 'react';
import {useTranslation} from 'react-i18next';

import {Title, TextField, SelectField, RadioField} from '../../common/Form';

export const BusinessType = ({data, handleChange}) => {
  const {t} = useTranslation();

  return (
    <div>
      <RadioField
        name="business_type"
        options={data.business_type.options}
        selectedOptionIndex={data.business_type.selectedOptionIndex}
        handleChange={handleChange}
        label={t('businessType')} required
        width="100%"
      />
    </div>
  );
};

export const Individual = ({data, handleChange}) => {
  const {t} = useTranslation();

  return (
    <div>
      <TextField
        name="individual_first_name"
        value={data.individual_first_name.value}
        valid={data.individual_first_name.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('firstName')} required
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="individual_last_name"
        value={data.individual_last_name.value}
        valid={data.individual_last_name.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('lastName')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <TextField
        name="individual_email"
        value={data.individual_email.value}
        valid={data.individual_email.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('email')} required
        width="100%"
      />
      <TextField
        name="individual_phone"
        value={data.individual_phone.value}
        valid={data.individual_phone.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('phone')} required
        width="100%"
      />
      <TextField
        name="individual_birth_date"
        value={data.individual_birth_date.value}
        valid={data.individual_birth_date.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('dateOfBirth')} required
        placeholder="DD-MM-YYYY"
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="individual_tax_id"
        value={data.individual_tax_id.value}
        valid={data.individual_tax_id.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('pinOrTaxId')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <SelectField
        name="individual_country"
        selectedOption={
          data.individual_country.options[data.individual_country.selectedOptionIndex]
        }
        options={data.individual_country.options}
        handleChange={handleChange}
        label={t('country')} required
        width="100%"
      />
      <TextField
        name="individual_city"
        value={data.individual_city.value}
        valid={data.individual_city.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('city')} required
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="individual_postal_code"
        value={data.individual_postal_code.value}
        valid={data.individual_postal_code.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('postalCode')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <TextField
        name="individual_address"
        value={data.individual_address.value}
        valid={data.individual_address.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('address')} required
        width="100%"
      />
      <TextField
        name="principal_passport_number"
        value={data.principal_passport_number.value}
        valid={data.principal_passport_number.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('passportNumber')} required
        width="100%"
      />
    </div>
  );
};

export const Company = ({data, handleChange}) => {
  const {t} = useTranslation();

  return (
    <div>
      <hr />

      <Title margin="32px 0 16px 0" size="18px">{t('companyInformation')}</Title>
      <TextField
        name="company_name"
        value={data.company_name.value}
        valid={data.company_name.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('companyName')} required
        width="100%"
      />
      <TextField
        name="company_email"
        value={data.company_email.value}
        valid={data.company_email.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('email')} required
        width="100%"
      />
      <TextField
        name="company_phone"
        value={data.company_phone.value}
        valid={data.company_phone.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('phone')} required
        width="100%"
      />
      <TextField
        name="company_tax_id"
        value={data.company_tax_id.value}
        valid={data.company_tax_id.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('taxId')} required
        width="100%"
      />
      <SelectField
        name="company_country"
        selectedOption={
          data.company_country.options[data.company_country.selectedOptionIndex]
        }
        options={data.company_country.options}
        handleChange={handleChange}
        label={t('country')} required
        width="100%"
      />
      <TextField
        name="company_city"
        value={data.company_city.value}
        valid={data.company_city.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('city')} required
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="company_postal_code"
        value={data.company_postal_code.value}
        valid={data.company_postal_code.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('postalCode')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <TextField
        name="company_address"
        value={data.company_address.value}
        valid={data.company_address.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('address')} required
        width="100%" margin="0 0 24px 0"
      />

      <hr />

      <Title margin="32px 0 16px 0" size="18px">{t('principalInformation')}</Title>
      <TextField
        name="principal_first_name"
        value={data.principal_first_name.value}
        valid={data.principal_first_name.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('firstName')} required
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="principal_last_name"
        value={data.principal_last_name.value}
        valid={data.principal_last_name.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('lastName')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <TextField
        name="principal_email"
        value={data.principal_email.value}
        valid={data.principal_email.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('email')} required
        width="100%"
      />
      <TextField
        name="principal_birth_date"
        value={data.principal_birth_date.value}
        valid={data.principal_birth_date.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('dateOfBirth')} required
        placeholder="DD-MM-YYYY"
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="principal_tax_id"
        value={data.principal_tax_id.value}
        valid={data.principal_tax_id.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('pinOrTaxId')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <SelectField
        name="principal_country"
        selectedOption={
          data.principal_country.options[data.principal_country.selectedOptionIndex]
        }
        options={data.principal_country.options}
        handleChange={handleChange}
        label={t('country')} required
        width="100%"
      />
      <TextField
        name="principal_city"
        value={data.principal_city.value}
        valid={data.principal_city.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('city')} required
        width="calc(50% - 9px)" margin="0 9px 0 0"
      />
      <TextField
        name="principal_postal_code"
        value={data.principal_postal_code.value}
        valid={data.principal_postal_code.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('postalCode')} required
        width="calc(50% - 9px)" margin="0 0 0 9px"
      />
      <TextField
        name="principal_address"
        value={data.principal_address.value}
        valid={data.principal_address.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('address')} required
        width="100%"
      />
      <TextField
        name="principal_passport_number"
        value={data.principal_passport_number.value}
        valid={data.principal_passport_number.valid}
        handleChange={handleChange} handleBlur={() => false}
        label={t('passportNumber')} required
        width="100%"
      />
    </div>
  );
};
