import {postJSON as post} from '../../api';
import {EDIT_LOCATION_SERVICE_START, EDIT_LOCATION_SERVICE_SUCCESS, EDIT_LOCATION_SERVICE_FAILURE} from './types';

const editServiceStart = () => ({
  type: EDIT_LOCATION_SERVICE_START,
});

const editServiceSuccess = () => ({
  type: EDIT_LOCATION_SERVICE_SUCCESS,
});

const editServiceFailure = error => ({
  type: EDIT_LOCATION_SERVICE_FAILURE,
  error,
});

const editService = (authorization, serviceId, data, callback) => {
  return dispatch => {
    dispatch(editServiceStart());
    post(`services/${serviceId}`, {authorization}, data)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(editServiceSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(editServiceFailure(error));
      });
  };
};

export default editService;
