import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';
import {ToggleSwitch} from '../../common/Form';
import ConnectorRow from './ConnectorRow';

const EvseCard = ({index, ocpp_version, operational, number, connectors, handleChange}) => (
  <Container>
    <TopContainer>
      <div className="number">#{number} EVSE</div>
      <div className="switch-container">
        <ToggleSwitch
          name={`evses.${index}.operational`}
          value={ocpp_version === null ? false : operational.value}
          valid={ocpp_version === null ? false : operational.valid}
          handleChange={handleChange}
        />
      </div>
    </TopContainer>
    <ConnectorContainer>
      {connectorItems(connectors)}
    </ConnectorContainer>
  </Container>
);

const Container = styled.div`
  position: relative;
  display: block;
  min-width: calc(100% - 5px); max-width: calc(100% - 5px);
  margin-bottom: 8px;
  border: 1px solid ${colors.LINE}; border-radius: 8px;
  background-color: ${colors.BG};
`;

const TopContainer = styled.div`
  position: relative;
  display: block;
  min-height: 40px; max-height: 40px;
  border-bottom: 1px solid ${colors.LINE};
  & > div {
    position: absolute;
    display: flex;
    align-items: center;
    min-height: 100%; max-height: 100%;
  }
  & > div.number {
    top: 0; left: 12px;
    font-size: 14px;
    font-weight: bold;
  }
  & > div.switch-container {
    top: 0; right: 24px;
  }
`;

const ConnectorContainer = styled.div`
`;

const connectorItems = connectors => (
  connectors.map((connector, i) =>
    <ConnectorRow key={i} {...connector} />
  )
);

export default EvseCard;
