import {SET_SERVER_TIME_OFFSET} from "./types";
import moment from "moment";

const setServerTimeOffset = (timeOffset) => ({
    type: SET_SERVER_TIME_OFFSET,
    payload: timeOffset,
});

const setServerTime = (date) => {
    return dispatch => {
        if (!date) {
            return
        }

        const timeOffset = moment(date).diff(Date.now());

        dispatch(setServerTimeOffset(timeOffset));
    }
};

export default setServerTime;
