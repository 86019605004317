import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${props => props.size ? props.size : '80px'};
  height: ${props => props.size ? props.size : '80px'};
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #F5F7F9;
  color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: ${props => props.rounded ? '50%' : '0px'}
`;

export const Image = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Text = styled.div`
  display: block;
`;