/**
 * Subscription fee calculator.
 */

const FIRST_RANGE_MULTIPLIER  = 1;
const SECOND_RANGE_MULTIPLIER = 0.9;
const THIRD_RANGE_MULTIPLIER  = 0.8;
const FOURTH_RANGE_MULTIPLIER = 0.7;

/**
 * Calculate subscription fee for one charging station.
 *
 * @param  {Number} originalFee
 * @param  {Number} quantity
 * @param  {Number} country
 * @return {Number}             Monthly fee per station.
 */
function calculateFee(originalFee, quantity, country) {
  let quantityMultiplier;

  switch (true) {
    case (quantity < 1): {
      return 0;
    }
    case ((quantity > 1) && (quantity <= 10)): {
      quantityMultiplier = SECOND_RANGE_MULTIPLIER;
      break;
    }
    case ((quantity > 10) && (quantity <= 100)): {
      quantityMultiplier = THIRD_RANGE_MULTIPLIER;
      break;
    }
    case (quantity > 100): {
      quantityMultiplier = FOURTH_RANGE_MULTIPLIER;
      break;
    }
    default: {
      quantityMultiplier = FIRST_RANGE_MULTIPLIER;
    }
  }

  let countryMultiplier;

  switch (true) {
    case (country === 'Germany'): {
      countryMultiplier = 1;
      break;
    }
    default: {
      countryMultiplier = 0.6;
    }
  }

  return originalFee * quantityMultiplier * countryMultiplier;
}

/**
 * Calculated subscription discount.
 *
 * @param  {Number} baseFee
 * @param  {Number} calculatedFee
 * @return {Number}               Discount.
 */
function calculateDiscount(baseFee, calculatedFee) {
  return calculatedFee
    && (parseFloat((100 - (calculatedFee / baseFee) * 100).toFixed(2)))
    || 0;
}

export {calculateFee, calculateDiscount};
