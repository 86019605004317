import React from 'react';

const FlagUs = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
        <rect width="24" height="18" rx="2" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0)">
        <rect width="24" height="18" rx="2" fill="#F9F9F9"/>
        <rect x="12" y="4" width="12" height="2" fill="#DB0F4C"/>
        <rect x="12" y="8" width="12" height="2" fill="#DB0F4C"/>
        <rect y="12" width="24" height="2" fill="#DB0F4C"/>
        <rect y="16" width="24" height="2" fill="#DB0F4C"/>
        <rect x="12" width="12" height="2" fill="#DB0F4C"/>
        <rect width="12" height="10" fill="#002765"/>
        <rect x="9" y="3" width="1" height="1" fill="white"/>
        <rect x="5" y="7" width="1" height="1" fill="white"/>
        <rect x="9" y="7" width="1" height="1" fill="white"/>
        <rect x="1" y="3" width="1" height="1" fill="white"/>
        <rect x="3" y="5" width="1" height="1" fill="white"/>
        <rect x="7" y="5" width="1" height="1" fill="white"/>
        <rect x="5" y="3" width="1" height="1" fill="white"/>
        <rect x="7" y="1" width="1" height="1" fill="white"/>
        <rect x="3" y="1" width="1" height="1" fill="white"/>
        <rect x="1" y="7" width="1" height="1" fill="white"/>
      </g>
    </svg>
  );
};

export default FlagUs;

