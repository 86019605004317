import {
  // log in manager action types
  LOG_IN_MANAGER_START, LOG_IN_MANAGER_SUCCESS, LOG_IN_MANAGER_FAILURE,
  // sign up manager action types
  SIGN_UP_MANAGER_START, SIGN_UP_MANAGER_SUCCESS, SIGN_UP_MANAGER_FAILURE,
  // get current manager action types
  GET_CURRENT_MANAGER_START, GET_CURRENT_MANAGER_SUCCESS, GET_CURRENT_MANAGER_FAILURE,
  // log out manager action type
  LOG_OUT_MANAGER,
  // get manager access token action types
  GET_MANAGER_ACCESS_TOKEN_START, GET_MANAGER_ACCESS_TOKEN_SUCCESS,
  GET_MANAGER_ACCESS_TOKEN_FAILURE,
  // create manager payment account action types
  CREATE_PAYMENT_ACCOUNT_START, CREATE_PAYMENT_ACCOUNT_SUCCESS,
  CREATE_PAYMENT_ACCOUNT_FAILURE,
  // get manager payment account action types
  GET_PAYMENT_ACCOUNT_START, GET_PAYMENT_ACCOUNT_SUCCESS,
  GET_PAYMENT_ACCOUNT_FAILURE,
  // create payment method action types
  CREATE_PAYMENT_METHOD_START, CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  // get manager payment method action types
  GET_PAYMENT_METHOD_START, GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAILURE,
  // create manager plan subscription action types
  CREATE_MANAGER_SUBSCRIPTION_START, CREATE_MANAGER_SUBSCRIPTION_SUCCESS,
  CREATE_MANAGER_SUBSCRIPTION_FAILURE,
  // get manager plan subscription action types
  GET_MANAGER_SUBSCRIPTION_START, GET_MANAGER_SUBSCRIPTION_SUCCESS,
  GET_MANAGER_SUBSCRIPTION_FAILURE,
  // manager settings action types
  SET_MANAGER_SETTINGS_START, SET_MANAGER_SETTINGS_SUCCESS, SET_MANAGER_SETTINGS_FAILURE,
  SET_MANAGER_PASSWORD_START, SET_MANAGER_PASSWORD_SUCCESS, SET_MANAGER_PASSWORD_FAILURE,
  SET_MANAGER_AVATAR_START, SET_MANAGER_AVATAR_SUCCESS, SET_MANAGER_AVATAR_FAILURE,
} from '../actions/types';

const initialState = {
  accessToken: '',
  refreshToken: '',
  data: {},
  paymentAccount: {},
  paymentMethod: {},
  subscription: {},
  loading: false,
  error: null,
};

const manager = (state = initialState, action) => {
  switch(action.type) {
    // register manager
    case SIGN_UP_MANAGER_START:
      return {...state, loading: true};
    case SIGN_UP_MANAGER_SUCCESS:
      return {...state, error: null, loading: false};
    case SIGN_UP_MANAGER_FAILURE:
      return {...state, error: action.error, loading: false};
    // log in manager
    case LOG_IN_MANAGER_START:
      return {...state};
    case LOG_IN_MANAGER_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        accessTokenLastUpdatedAt: new Date(),
        refreshToken: action.refreshToken,
        error: null
      };
    case LOG_IN_MANAGER_FAILURE:
      return {...state, error: action.error};
    // get current manager
    case GET_CURRENT_MANAGER_START:
      return {...state, loading: true};
    case GET_CURRENT_MANAGER_SUCCESS:
      return {...state, data: action.data, error: null, loading: false};
    case GET_CURRENT_MANAGER_FAILURE:
      return {...state, error: action.error, loading: false};
    // get manager new access token
    case GET_MANAGER_ACCESS_TOKEN_START:
      return {...state, loading: true};
    case GET_MANAGER_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        accessTokenLastUpdatedAt: new Date(),
        error: null,
        loading: false,
      };
    case GET_MANAGER_ACCESS_TOKEN_FAILURE:
      return {...state, error: action.error, loading: false};
    // create manager payment account
    case CREATE_PAYMENT_ACCOUNT_START:
      return {...state, loading: true};
    case CREATE_PAYMENT_ACCOUNT_SUCCESS:
      return {...state, loading: false};
    case CREATE_PAYMENT_ACCOUNT_FAILURE:
      return {...state, error: action.error, loading: false};
    // get manager payment account
    case GET_PAYMENT_ACCOUNT_START:
      return {...state, loading: true};
    case GET_PAYMENT_ACCOUNT_SUCCESS:
      return {...state, paymentAccount: action.data, error: null, loading: false};
    case GET_PAYMENT_ACCOUNT_FAILURE:
      return {...state, error: action.error, loading: false};
    // create manager payment account
    case CREATE_PAYMENT_METHOD_START:
      return {...state, loading: true};
    case CREATE_PAYMENT_METHOD_SUCCESS:
      return {...state, loading: false};
    case CREATE_PAYMENT_METHOD_FAILURE:
      return {...state, error: action.error, loading: false};
    // get manager payment method
    case GET_PAYMENT_METHOD_START:
      return {...state, loading: true};
    case GET_PAYMENT_METHOD_SUCCESS:
      return {...state, paymentMethod: action.data, error: null, loading: false};
    case GET_PAYMENT_METHOD_FAILURE:
      return {...state, error: action.error, loading: false};
    // create manager subscription
    case CREATE_MANAGER_SUBSCRIPTION_START:
      return {...state, loading: true};
    case CREATE_MANAGER_SUBSCRIPTION_SUCCESS:
      return {...state, error: null, loading: false};
    case CREATE_MANAGER_SUBSCRIPTION_FAILURE:
      return {...state, error: action.error, loading: false};
    // get manager subscription
    case GET_MANAGER_SUBSCRIPTION_START:
      return {...state, loading: true};
    case GET_MANAGER_SUBSCRIPTION_SUCCESS:
      return {...state, subscription: action.data, error: null, loading: false};
    case GET_MANAGER_SUBSCRIPTION_FAILURE:
      return {...state, error: action.error, loading: false};
    // set manager settings
    case SET_MANAGER_SETTINGS_START:
      return {...state, loading: true};
    case SET_MANAGER_SETTINGS_SUCCESS:
      return {...state, loading: false};
    case SET_MANAGER_SETTINGS_FAILURE:
      return {...state, error: action.error, loading: false};
    // set manager password
    case SET_MANAGER_PASSWORD_START:
      return {...state, loading: true};
    case SET_MANAGER_PASSWORD_SUCCESS:
      return {...state, loading: false};
    case SET_MANAGER_PASSWORD_FAILURE:
      return {...state, error: action.error, loading: false};
    // set manager avatar
    case SET_MANAGER_AVATAR_START:
      return {...state, loading: true};
    case SET_MANAGER_AVATAR_SUCCESS:
      return {...state, loading: false};
    case SET_MANAGER_AVATAR_FAILURE:
      return {...state, error: action.error, loading: false};
      // log out manager
    case LOG_OUT_MANAGER:
      return initialState;
    default:
      return state;
  }
};

export default manager;
