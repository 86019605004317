import React from 'react';
import {withRouter} from 'react-router-dom';

import scrollToSection from '../scrollToSection';

import {Container} from './Link.styled';

const Link = props => {
  const onClick = () => {
    if (props.onClick) { props.onClick(); }

    if (props.to && window.location.pathname === props.to.split('#')[0]) {
      scrollToSection(props.sectionId, props.block);
    }
    else {
      window.open(props.to, props.target || '_self');
    }
  };

  return (
    <Container {...props} onClick={onClick}>
      {props.children}
    </Container>
  );
};

export default withRouter(Link);
