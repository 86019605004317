import React from 'react';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import {TextField} from '../../common/Form';

const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function Period(props) {
  const {day, opens_at, closes_at, handleChange, disabled, t} = props;

  return (
    <Container>
      <Title>{t(WEEKDAYS[day])}</Title>
      <FieldContainer>
        <TextField
          name={`periods.${day}.opens_at`}
          value={opens_at.value} valid={opens_at.valid}
          handleChange={handleChange} handleBlur={() => false}
          disabled={disabled}
          width="48%" margin="0 4px 0 0"
        />
        -
        <TextField
          name={`periods.${day}.closes_at`}
          value={closes_at.value} valid={closes_at.valid}
          handleChange={handleChange} handleBlur={() => false}
          disabled={disabled}
          width="48%" margin="0 0 0 4px"
        />
      </FieldContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  min-width: 120px; max-width: 120px;
  min-height: 100%; max-height: 100%;
  margin-right: 24px;
`;

const Title = styled.h5`
  display: block;
  font-size: 16px;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default compose(withTranslation())(Period);
