import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Button from '../common/Button';
import {Container, Title, ButtonContainer} from './Greeting.styled';

const GreetingContainer = ({loggedIn}) => {
  useEffect(() => {
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
  });

  const { t } = useTranslation();

  return (
    <Container id="greeting">
      <Title>{t('shareYourStation')} <br/> {t('itsFreeAndSimple')}</Title>
      <ButtonContainer>
        {loggedIn ? (
          <Button to="/dashboard" size="medium" theme="blue">
            {t('dashboard')}
          </Button>
        ) : (
          <>
            <Button to="/login" size="medium" theme="blue">
              {t("login")}
            </Button>
          </>
        )}
      </ButtonContainer>
    </Container>
  );
};

const mapStateToProps = ({ manager }) => ({
  loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
});

export default connect(mapStateToProps, null)(GreetingContainer);
