import styled from 'styled-components';

import bp from '../../common/breakpoints';

export const StationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;

  @media screen and (max-width: ${bp.tablet}) {
    flex-direction: column;
  }
`;