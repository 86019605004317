import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';

import {store, persistor} from './redux/store';
import App from './components/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './i18n';

import moment from 'moment';
import 'moment/locale/ru';

moment().locale('ru');

window.persistor = persistor;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
