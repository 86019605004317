import React from 'react';
import styled from 'styled-components';

import ConnectorItem from './ConnectorItem';

const Connectors = ({connectors}) => (
  <Container>
    <ConnectorsList>
      {connectors.map(connector => {
        return <ConnectorItem connector={connector} />;
      })}
    </ConnectorsList>
  </Container>
);

const Container = styled.div`
  border-top: 1px solid #e6eaed;
  padding: 24px 32px;
  overflow-y: auto;
`;

const ConnectorsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Connectors;
