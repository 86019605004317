import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import * as colors from '../../../util/colors';
import bp from '../breakpoints';

const Link = props => {
  const onClick = () => {
    props.onClick && props.onClick();
    window.open(props.to, props.target || '_self');
  };
  return (
    <Container {...props} onClick={onClick}>
      {props.children}
    </Container>
  );
};

const Container = styled.span`
  display: inline-block;
  margin-bottom: 14px;
  color: ${colors.TEXT};
  cursor: pointer;
  font-size: 14px;
  &:hover {
    color: ${colors.TEXT_BLUE}
  }
  @media screen and (max-width: ${bp.tablet}) {
    margin: 6px 0;
    font-size: 16px;
  }
`;

export default withRouter(Link);
