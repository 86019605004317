import React, {Component} from 'react';

import Profile from './Profile.connect';
import Logo from '../Logo';
import Icon from './SidebarIcon';

import {SidebarBlock} from '../MainBlocks';
import {LogoWrapper, LinkContainer, Link} from './Sidebar.styled';
import {DashboardMenuItem, StatisticsByDayMenuItem, StatisticsReportsMenuItem, ChargePointsMenuItem, LocationsMenuItem, MapMenuItem, AspMenuItem, ProfileMenuItem} from '../SidebarMenuItems';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  handleToggle = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const {loggedIn, t} = this.props;
    const {visible} = this.state;
    const pathName = window.location.pathname.split('/')[1];

    const isAdministrator = this.props.role === 'administrator';
    const isAppDeveloper = this.props.role === 'app-developer';
    const isAccounter = this.props.role === 'accounter';
    const isManager = this.props.role === 'manager';

    return (
      <SidebarBlock visible={visible}>

        <LogoWrapper>
          <Logo width="132" height="46" fillColor="blue" />
        </LogoWrapper>

        <Profile />

        <LinkContainer>
          {!isAccounter && (
            <DashboardMenuItem pathName={pathName} />
          )}

          {(isAccounter || isAdministrator) && (
            <StatisticsByDayMenuItem pathName={pathName} />
          )}

          {(isAccounter || isAdministrator) && (
            <StatisticsReportsMenuItem pathName={pathName} />
          )}

          {(isAdministrator || isManager) && (
              <ChargePointsMenuItem pathName={pathName} />
          )}

          {isAdministrator && (
            <LocationsMenuItem pathName={pathName} />
          )}

          <MapMenuItem pathName={pathName} />

          {(isAdministrator || isAppDeveloper) && (
            <AspMenuItem pathName={pathName} />
          )}

          <ProfileMenuItem pathName={pathName} />

          {!loggedIn && (
            <>
              <Link to="/login"><Icon /> {t('login')}</Link>
              <Link to="/signup"><Icon /> {t('signup')}</Link>
            </>
          )}
        </LinkContainer>

      </SidebarBlock>
    );
  }
}

export default Sidebar;
