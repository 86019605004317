import {getJSON as get} from '../../api';
import {GET_STATISTICS_START, GET_STATISTICS_SUCCESS, GET_STATISTICS_FAILURE} from './types';

const getStatisticsStart = () => ({
  type: GET_STATISTICS_START,
});

const getStatisticsSuccess = data => ({
  type: GET_STATISTICS_SUCCESS,
  data,
});

const getStatisticsFailure = error => ({
  type: GET_STATISTICS_FAILURE,
  error,
});

const getStatistics = (authorization, params, callback, excludeLoading = false) => {
  return dispatch => {
    if (!excludeLoading) {
      dispatch(getStatisticsStart());
    }
    get('statistics', {authorization}, params)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getStatisticsSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getStatisticsFailure(error));
      });
  };
};

export default getStatistics;
