import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {geocode} from '../../api';
import setMapPosition from '../../redux/actions/setMapPosition';
import setMapAddress from '../../redux/actions/setMapAddress';
import LoadingSpinner from '../common/LoadingSpinner';
import MapWithMarker from '../common/MapWithMarker';

class MapContainer extends Component {
  componentDidMount() {
    navigator.geolocation.getCurrentPosition((position, error) => {
      if (error) { return false; }

      const {latitude, longitude} = position.coords;

      this.props.setMapPosition({
        lat: latitude,
        lng: longitude,
      });

      geocode(this.props.authorization, {latlng: [latitude, longitude].join()})
        .then(address => {
          this.props.setMapAddress(address);
        });
    });
  }

  handleMarkerChange = map => {
    const [lat, lng] = map.center;

    this.props.setMapPosition({lat, lng});

    geocode(this.props.authorization, {latlng: [lat, lng].join()})
      .then(address => {
        this.props.setMapAddress(address);
      });
  };

  render() {
    const {position} = this.props;

    return (
      <Container>
        {position && position.lat && position.lng
          ? <MapWithMarker
            center={position}
            handleMarkerChange={this.handleMarkerChange}
          />
          : <LoadingSpinner />}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  height: auto;
  min-height: 100vh; max-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    display: block;
    min-width: 50%; max-width: 50%;
  }
  @media screen and (min-width: 768px) {
    display: inline-block;
    min-width: 50%; max-width: 50%;
  }
`;

const mapStateToProps = ({manager, map}) => ({
  authorization: `Bearer ${manager.accessToken}`,
  position: map.position,
});

const mapDispatchToProps = dispatch => ({
  setMapPosition: position => dispatch(setMapPosition(position)),
  setMapAddress: position => dispatch(setMapAddress(position)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
