import React from 'react';
import styled from 'styled-components';

import * as colors from '../../../../../util/colors';

const ConnectorItem = ({connector}) => {
  const {status, type, power, rate, parking} = connector || {};

  return (
    <Container>
      <TypeIcon status={status}>
        <img src={getConnectorTypeIcon(type)} styles={{color: 'white'}} alt={type} />
      </TypeIcon>

      <Content>
        <Type>{type}</Type>
        <ElectricPower>{`${power} kW`}</ElectricPower>
        <Price>{parseFloat((rate / 100).toFixed(3))} ₽/min</Price>
        <Parking visible={parking}>
          {<img src="/carsParkingIcon.svg" alt="cars parking" className="car" />}
          {<img src="/bikesParkingIcon.svg" alt="bikes parking" className="bike" />}
        </Parking>
      </Content>
    </Container>
  );
};

// TODO Code reusability.
function getConnectorTypeIcon(type) {
  let imageUrl;
  switch (type) {
    case 'Type 1':
      imageUrl = '/connectors/type1.svg';
      break;
    case 'Type 2':
      imageUrl = '/connectors/type2.svg';
      break;
    case 'CCS':
      imageUrl = '/connectors/ccs.svg';
      break;
    case 'Schuko':
      imageUrl = '/connectors/schuko.svg';
      break;
    case 'Chademo':
      imageUrl = '/connectors/chademo.svg';
      break;
    case '3-pin':
      imageUrl = '/connectors/3-pin.svg';
      break;
    case 'CCS 1':
      imageUrl = '/connectors/ccs1.svg';
      break;
    case 'CCS 2':
      imageUrl = '/connectors/ccs2.svg';
      break;
    case 'GB/T AC':
      imageUrl = '/connectors/gbt-ac.svg';
      break;
    case 'GB/T DC':
      imageUrl = '/connectors/gbt-dc.svg';
      break;
    case 'Tesla':
      imageUrl = '/connectors/tesla.svg';
      break;
    default:
      imageUrl = '/connectors/type1.svg';
  };

  return imageUrl;
}

const Container = styled.div`
  display: flex;
  height: 40px;
  box-shadow: 0px 2px 6px rgba(37, 99, 160, 0.16);
  margin-bottom: 8px;
  border-radius: 4px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 8px;
  font-size: 0.875em;
`;

const TypeIcon = styled.div`
  width: 40px;
  background-color: ${props => {
    switch (props.status) {
      case 'available':
        return colors.ICON_GREEN;
      case 'busy':
        return colors.ICON_BLUE;
      case 'faulted':
        return colors.ERROR;
      case 'offline':
      default:
        return 'gray';
    }
  }};
  border-radius: 4px 0 0 4px;
  & > img {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    display: flex;
    filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(1960%) hue-rotate(62deg) brightness(118%) contrast(100%);
  }
`;

const Type = styled.span`
  width: 80px;
`;

const ElectricPower = styled.span`
  width: 72px;
`;

const Price = styled.span`
  width: 80px;
  float: right;
`;

const Parking = styled.div`
  display: flex;
  float: right;
  & > img.car {
    margin-right: 8px;
    visibility: ${props => props.visible.car ? 'visible' : 'hidden'}
  }
  & > img.bike {
    width: 16px;
    visibility: ${props => props.visible.bike ? 'visible' : 'hidden'}
  }
`;

export default ConnectorItem;
