import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';

import {WrapperBlock, PageBlock, PageInnerBlock} from '../common/MainBlocks';
import StationsTopBar from './Topbar';
import Sidebar from '../common/Sidebar';
import StationsTable from './StationsTable';
import TopbarMobile from "../common/TopbarMobile";

class Locations extends Component {
    componentWillMount() {
        const {loggedIn, role, history} = this.props;

        if (!loggedIn || (role !== 'administrator' && role !== 'manager')) {
          history.push('/');
        }
    }

    render() {
        return (
            <WrapperBlock>
                <TopbarMobile />
                <Sidebar />
                <PageBlock>
                    <PageInnerBlock>
                        <StationsTopBar />
                        <StationsTable/>
                    </PageInnerBlock>
                </PageBlock>
            </WrapperBlock>
        );
    }
}

const mapStateToProps = ({manager}) => ({
    loggedIn: manager.accessToken && manager.data && Object.keys(manager.data).length > 0,
    role: manager.data?.role,
});

export default compose(connect(mapStateToProps), withRouter)(Locations);
