import React from "react";

import EvseConnector from "../Location/Stations/EvseConnector";
import EvseConnectorSettings from "./EvseConnectorSettings";
import ExpandIcon from "../common/ExpandIcon";
import {EvseConnectorContainer, EvseNumber, EvseStyled} from "../Location/Stations/Evse";
import {FreeSpace} from "../common/FlexItem";

const EvseConnectorsList = ({connectors, ...props}) => {
    return (
        <>
            {connectors.map((connector, i) => (
                <EvseConnectorItem key={connector.id} connector={connector} {...props}/>
            ))}

        </>
    );
}

const EvseConnectorItem = ({connector, ...props}) => {

    return (
        <>
            <EvseConnectorSettings {...connector} {...props} />
        </>
    )

}

export default EvseConnectorsList;
