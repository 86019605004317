import React from 'react';
import {compose} from "redux";
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import ReactTooltip from 'react-tooltip';
import {getRelativeAndFullDatesWithServerOffset} from "../../util/time";
import {getOwnerNameByData} from "../../util/owner";
import * as colors from '../../util/colors';

import {Tr, Td} from './StationsTable.styled';
import Evses from "../Location/Stations/Evses";
import {Option} from "../Location/Stations/StationCard.styled";
import styled from "styled-components";
import StationStatus from "./StationStatus";
import StationMonetizedIcon from "../Location/Stations/StationMonetizedIcon";
import StationReservationSupportIcon from "./StationReservationSupportIcon";
import OptionsMenu from "../common/OptionsMenu";
import {connect} from "react-redux";
import {Location} from "../Dashboard/ChargingHistoryContainer";

const StationRow = ({t, history, ...props}) => {
    const [isDetailsVisible, setDetailsVisibility] = React.useState(false);

    const {
        id,
        // number,
        // model,
        // vendor_name,
        location_id,
        location_name,
        location_city,
        location_street,
        evses,
        ping_timestamp,
        serverTimeOffset,
        monetized,
        reservations_supported,
        data_transfer,
        role,
    } = props;

    const owner = getOwnerNameByData(props);
    const rssi = data_transfer?.autoenterprise?.miscInfo?.rssi;

    const [pingRelativeDate, pingFullDate] = getRelativeAndFullDatesWithServerOffset(ping_timestamp, serverTimeOffset);

    const toggleDetailsVisibility = () => setDetailsVisibility(!isDetailsVisible);

    const toggleOptionVisibility = (id, e) => {
        props.toggleOptionVisibility(id);

        e.stopPropagation();
    }

    return (
        <>
            <Tr onClick={() => history.push(`/charge-points/${encodeURIComponent(id)}/information`)}>
                <Td>
                  <Warning data_transfer={data_transfer} /> {id}
                </Td>

                {role !== 'manager'
                    ? <Td>{owner}</Td>
                    : ''
                }

                <Td>
                    <Location>
                        <div className="name">{location_name}</div>
                        {role === 'administrator'
                            ? <a className="address" href={`/locations/${encodeURIComponent(location_id)}/stations`}>{location_city}, {location_street}</a>
                            : <a className="address">{location_city}, {location_street}</a>
                        }
                    </Location>
                </Td>

                <Td>
                    <StationStatus {...props} noMargin />
                </Td>

                <Td data-tip={rssi && `Уровень сигнала: ${rssi} (из 32)`}>
                    <Wifi signal={rssi}/>
                </Td>

                <Td>
                    <StationMonetizedIcon monetized={monetized} />
                </Td>

                <Td>
                    <StationReservationSupportIcon reservations_supported={reservations_supported} />
                </Td>

                <Td data-tip={pingFullDate} data-place="left">
                    <ReactTooltip effect='solid' />
                    {pingRelativeDate}
                </Td>

                <Td data-tip={t('stationSettings')} data-place="left">
                  <i
                    onClick={() => history.push(`/charge-points/${encodeURIComponent(id)}/information`)}
                    className="fa fa-pen"
                  />
                </Td>
            </Tr>
        </>
    );
};

const Wifi = ({ signal }) => {
  if (!signal) {
    return null;
  }

  let color;

  if (signal <= 12) color = colors.ERROR;
  if (signal >= 13 && signal <= 20) color = colors.ORANGE_MAIN;
  if (signal > 20) color = colors.ICON_GREEN;

  return (
    <>
      <i className="fa fa-wifi" style={{ color }}/>
    </>
  );
}

const Warning = ({data_transfer}) => {
  let error;

  if (data_transfer?.autoenterprise?.miscInfo?.tamper === 'open') {
    error = 'Tamper open!';
  }

  if (!error) {
    return null;
  }

  return (
    <i
      className="fas fa-exclamation-circle"
      style={{ color: colors.ERROR }}
      data-tip={error}
    />
  )
}

export const OptionsContainer = styled.div`
  position: relative;
  min-height: 24px;
`;

const mapStateToProps = ({manager}) => ({
    role: manager.data?.role,
});

export default compose(connect(mapStateToProps), withTranslation(), withRouter)(StationRow);
