import {postJSON as post} from '../../api';
import {UPDATE_STATION_START, UPDATE_STATION_SUCCESS, UPDATE_STATION_FAILURE} from './types';

const updateStationStart = () => ({
  type: UPDATE_STATION_START,
});

const updateStationSuccess = () => ({
  type: UPDATE_STATION_SUCCESS,
});

const updateStationFailure = error => ({
  type: UPDATE_STATION_FAILURE,
  error,
});

const updateStation = (authorization, stationId, body, callback) => {
  return dispatch => {
    dispatch(updateStationStart());
    post(`stations/${stationId}`, {authorization}, body)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          dispatch(updateStationSuccess());
          if (callback) { callback(); }
        }
      })
      .catch(error => {
        dispatch(updateStationFailure(error));
      });
  };
};

export default updateStation;
