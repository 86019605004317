import {
  CHANGE_STATION_STATUS_FAILURE,
  CHANGE_STATION_STATUS_START,
  CHANGE_STATION_STATUS_SUCCESS,
  CHANGE_MONETIZE_FAILURE,
  CHANGE_MONETIZE_START,
  CHANGE_MONETIZE_SUCCESS,
  CLEAR_STATION_REQUEST,
  GET_STATION_FAILURE,
  GET_STATION_START,
  GET_STATION_SUCCESS,
  UPDATE_STATION_FAILURE,
  UPDATE_STATION_START,
  UPDATE_STATION_SUCCESS,
} from '../actions/types';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const station = (state = initialState, action) => {
  switch (action.type) {
    // get station
    case GET_STATION_START:
      return {...state, loading: true};
    case GET_STATION_SUCCESS:
      return {...state, data: action.data, date: action.date, error: null, loading: false};
    case GET_STATION_FAILURE:
      return {...state, error: action.error, loading: false};
    // update station
    case UPDATE_STATION_START:
      return {...state, loading: true};
    case UPDATE_STATION_SUCCESS:
      return {...state, error: null, loading: false};
    case UPDATE_STATION_FAILURE:
      return {...state, error: action.error, loading: false};
    // change station status
    case CHANGE_STATION_STATUS_START:
      return {...state, loading: true};
    case CHANGE_STATION_STATUS_SUCCESS:
      return {...state, data: action.data, date: action.date, error: null, loading: false};
    case CHANGE_STATION_STATUS_FAILURE:
      return {...state, error: action.error, loading: false};
    case CHANGE_MONETIZE_START:
      return {...state, loading: true};
    case CHANGE_MONETIZE_SUCCESS:
      return {...state, data: action.data, date: action.date, error: null, loading: false};
    case CHANGE_MONETIZE_FAILURE:
      return {...state, error: action.error, loading: false};
    case CLEAR_STATION_REQUEST: {
      return {...state, data: null, loading: false, error: null};
    }
    default:
      return state;
  }
};

export default station;
