import {GET_PLANS_START, GET_PLANS_SUCCESS, GET_PLANS_FAILURE} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const plans = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS_START:
      return {...state, loading: false};
    case GET_PLANS_SUCCESS:
      return {...state, data: action.data, error: null, loading: false};
    case GET_PLANS_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default plans;
