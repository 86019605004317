import React from 'react';
import {Loading} from "./ChargingNowContainer";
import styled from "styled-components";

export const connectorType = connector => connector ? (
  <Data>
    <div className="row" style={{justifyContent: 'center'}}>
      <ConnectorIcon src={connectorImageByType(connector.type)} />
      {connector.type}
    </div>
  </Data>
): <Loading width={this.randomWidth}/>;

const ConnectorIcon = styled.img`
  min-width: 1.438em; max-width: 1.438em;
  min-height: 1.438em; max-height: 1.438em;
  margin-right: 8px;
`;

export const Data = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: start;
  // & > div {
  //   min-height: 53px; max-height: 63px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-start;
  //   font-size: 14px;
  //   min-width: 100%;
  // }
`;


// TODO Code reusability.
export const connectorImageByType = connectorType => {
  if (!connectorType || typeof connectorType !== 'string') {
    return '/connectors/type1.svg';
  }
  switch (connectorType.toLowerCase()) {
    case 'type 1':
      return '/connectors/type1.svg';
    case 'type 2':
      return '/connectors/type2.svg';
    case 'ccs':
      return '/connectors/ccs.svg';
    case 'schuko':
      return '/connectors/schuko.svg';
    case 'chademo':
      return '/connectors/chademo.svg';
    case '3-pin':
      return '/connectors/3-pin.svg';
    case 'ccs 1':
      return '/connectors/ccs1.svg';
    case 'ccs 2':
      return '/connectors/ccs2.svg';
    case 'gb/t ac':
      return '/connectors/gbt-ac.svg';
    case 'gb/t dc':
      return '/connectors/gbt-dc.svg';
    case 'tesla':
      return '/connectors/tesla.svg';
    default:
      return '/connectors/type1.svg';
  }
};
