import React from 'react';
import {withRouter} from 'react-router-dom';

import Avatar from '../common/Avatar';
import {
  AvatarAndNameCell,
  OpeningHours,
  Name,
  Address,
  Rating,
  Services,
  Available,
  Busy,
} from './LocationRow.styled';

import { Tr, Td } from './LocationTable.styled';

const currentDayOpeningHours = periods => {
  const currentDay = new Date().getDay();

  const period = periods.find(el => el.day === currentDay);

  if (period) {
    const {opens_at, closes_at} = period;

    const opensAt  = `${opens_at.substring(0, 2)}:${opens_at.substring(2)}`;
    const closesAt = `${closes_at.substring(0, 2)}:${closes_at.substring(2)}`;

    return `${opensAt}-${closesAt}`;
  }

  return 'closed';
};

const imageUrl = photos => {
  if (!photos || !photos[0] || !photos[0].url) {
    return '/defaultLocationPicture.png';
  }
  return photos[0].url;
};

const availableConnectorsData = connectors => {
  return connectors.filter(connector => connector.status === 'available').length;
};

const busyConnectorsData = connectors => {
  return connectors.filter(connector => connector.status === 'busy').length;
};

const LocationRow = props => {
  const {
    id,
    history,
    photos,
    name,
    street,
    opening_hours,
    rating,
    services,
    number_of_stations,
    connectors,
  } = props;

  return (
    <Tr onClick={() => history.push(`/locations/${id}/stations`)}>
  
      <Td>
        <AvatarAndNameCell>
          <Avatar
            size="40px"
            rounded
            imageSrc={imageUrl(photos)}
            style={{ marginRight: '16px' }}
          />
          <Name>{name}</Name>
        </AvatarAndNameCell>
      </Td>
  
      <Td>
        <Address>{street}</Address>
      </Td>
  
      <Td>
        {opening_hours && opening_hours.periods &&
          <OpeningHours>
            {currentDayOpeningHours(props.opening_hours.periods)}
          </OpeningHours>
        }
      </Td>
  
      <Td>
        <Rating>
          <i className="fa fa-star" /> 
          {(rating % 1 > 0) ? rating.toFixed(1) : rating}
        </Rating>
      </Td>
  
      <Td>
        <Services>{services && services.length}</Services>
      </Td>
  
      <Td>
        {number_of_stations || 0}
      </Td>
  
      <Td>
        <Available>
          {connectors && availableConnectorsData(connectors)}
        </Available>
      </Td>
  
      <Td>
        <Busy>
          {connectors && busyConnectorsData(connectors)}
        </Busy>
      </Td>
    </Tr>
  );
};

export default withRouter(LocationRow);
