import React, {Component} from 'react';
import {bool, object, func} from 'prop-types';

import {
  Wrapper,
  Option,
  Icon,
  Text,
  ActiveIcon,
} from './SwitchDesktop.styled';

import CircleIcon from './icons/Circle';

const propTypes = {
  isVisible: bool,
  selectedOption: object,
  options: object,
  changeLanguage: func,
};

class SwitchDesktop extends Component {
  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  setInnerRef = node => {
    this.innerRef = node;
  }

  handleClickOutside = event => {
    const { closeLangSwitch } = this.props;

    if (!document.getElementById('selectLangNode').contains(event.target) && 
        this.innerRef && !this.innerRef.contains(event.target)) {
      closeLangSwitch(this.setInnerRef);
    }
  }

  render() {
    const {
      isVisible,
      selectedOption,
      options,
      changeLanguage,
    } = this.props;
  
    if (!isVisible) return null;

    return (
      <Wrapper ref={this.setInnerRef}>
        {options.map((lang, i) => {
          const isActive = lang.id === selectedOption.id;
  
          return (
            <Option
              key={i}
              active={isActive}
              onClick={() => changeLanguage(lang.shorthand)}
            >
              <Icon>
                {lang.icon}
              </Icon>
              <Text>
                {lang.shorthand.toUpperCase()}
              </Text>

              {/* 
                Используем SVG кружочек (CircleIcon), чтобы при измененном масштабе в браузере 
                он не превращался в квадратик
              */}
              {isActive && (
                <ActiveIcon>
                  <CircleIcon />
                </ActiveIcon>
              )}
            </Option>
          );
        })}
      </Wrapper>
    );
  }
};

SwitchDesktop.propTypes = propTypes;

export default SwitchDesktop;
