import {GET_SERVICE_TYPES_START, GET_SERVICE_TYPES_SUCCESS, GET_SERVICE_TYPES_FAILURE} from '../actions/types';

const initialState = {
  services: [],
  loading: false,
  error: null,
};

const types = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_TYPES_START:
      return {...state, loading: true};
    case GET_SERVICE_TYPES_SUCCESS:
      return {...state, error: null, services: action.data, loading: false};
    case GET_SERVICE_TYPES_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default types;
