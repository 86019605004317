import React from "react";
import _ from "lodash";

import {
  Button,
  Buttons,
  DataTypeContainer,
  DataTypes,
  ExportContainer,
  Header, ModalTitle,
  RowTitle
} from "./../Dashboard/StatisticsContainer.styled";
import {Checkbox} from "../common/Checkbox";
import {Modal} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
import LoadingSpinner from "./LoadingSpinner";

function SelectItemsModal({title, items, isOpen, onChange, onCancelClick, defaultSelectedIds, loading}) {
  const [selectedItems, setSelectedItems] = React.useState([]);

  const exportBtnDisabled = selectedItems.length === 0;

  React.useEffect(() => {
    if (defaultSelectedIds) {
      setSelectedItems(defaultSelectedIds);
    }
  }, [defaultSelectedIds])

  return (
    <Modal isOpen={isOpen} centered>
      <Header>
        <ModalTitle>{title}</ModalTitle>
      </Header>
      <ModalBody style={{paddingTop: 0, paddingLeft: '32px', paddingRight: '32px', paddingBottom: '32px'}}>
        {loading && <LoadingSpinner/>}
        <ExportContainer>
          <DataTypeContainer>
            <RowTitle align="flex-start"></RowTitle>
            <DataTypes>
              <CheckboxGroup
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                items={items}
                label="Выбрать все"
                labelStyled={`
                  margin-bottom: 20px;
                `}
              />
            </DataTypes>
          </DataTypeContainer>

          <Buttons>
            <Button color="white"
                    onClick={onCancelClick}><span>Cancel</span></Button>
            <Button textColor="white" onClick={() => onChange(selectedItems)}
                    disabled={exportBtnDisabled}>
              <span>Ок</span>
            </Button>
          </Buttons>
        </ExportContainer>
      </ModalBody>
    </Modal>
  )
}

const CheckboxGroup = ({items, selectedItems, setSelectedItems, label, labelStyled}) => {
  const groupItems = items.reduce((previousValue, currentValue) => [...previousValue, ...(currentValue.items || [currentValue])], []);
  const groupSelectedItems = groupItems.filter(({id}) => selectedItems.find((selectedId) => selectedId === id));
  const noSelectedItems = !groupSelectedItems.length;
  const allSelectedItems = groupSelectedItems.length === groupItems.length;

  const toggleItems = () => {
    const groupItemsIds = groupItems.map(({id}) => id);

    if (noSelectedItems || !allSelectedItems) {
      const unicalSelectedItems = [...new Set([...selectedItems, ...groupItemsIds])];

      setSelectedItems(unicalSelectedItems);
    } else {
      setSelectedItems(selectedItems.filter((id) => !groupItemsIds.includes(id)));
    }
  };

  const handleDataType = (checked, id) => {
    if (checked) {
      setSelectedItems(selectedItems.concat(id))
    } else {
      const locations = [...selectedItems];

      _.remove(locations, value => value === id);

      setSelectedItems([...locations]);
    }
  };

  return (
    <>
      <Checkbox
        italic={true}
        styled={`
          font-style: italic;
          ${labelStyled || ''}
        `}
        checkboxStyle={{opacity: noSelectedItems || allSelectedItems ? 1 : 0.5}}
        checked={!noSelectedItems}
        model={{label}}
        onChange={toggleItems}
      />
      <DataTypes>
        {items.map(({name, id, items: subItems}) => (
          subItems ?
            <CheckboxGroup
              key={id}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              label={name}
              items={subItems}
            />
            :
            <Checkbox
              key={id}
              checked={selectedItems.includes(id)}
              model={{label: name}}
              onChange={(checked) => handleDataType(checked, id)}
            />
        ))}
      </DataTypes>
    </>
  )
}

export default SelectItemsModal;
