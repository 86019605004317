import styled from 'styled-components';

import * as colors from '../../util/colors';


export const TableWrapper = styled.div`
  min-width: 100%;
  overflow: hidden;
`;

export const Table = styled.table`
  min-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const Thead = styled.thead`
  & > tr {
    height: 65px;
  }
`;

export const TheadTr = styled.tr`
  cursor: default;
`;

export const Tr = styled.tr`
  border-top: 1px solid ${colors.LINE};
  cursor: pointer;
  transition: .218s background-color ease;

  &:hover {
    background-color: #F3F5F6;
  }

  &:active {
    background-color: #E6EAED;
  }
`;

export const Td = styled.td`
  padding: 12px;
  font-size: 14px;

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;

export const Th = styled.td`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.TEXT_MINOR};
  text-oveflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    padding-left: 24px;
  }
  &:last-child {
    padding-right: 24px;
  }
`;