import {getJSON as get} from '../../api';
import {GET_LOCATION_STATIONS_START, GET_LOCATION_STATIONS_START_MORE, GET_LOCATION_STATIONS_SUCCESS, GET_LOCATION_STATIONS_FAILURE} from './types';

const getLocationStationsStart = () => ({
  type: GET_LOCATION_STATIONS_START,
});

const getLocationStationsStartMore = () => ({
  type: GET_LOCATION_STATIONS_START_MORE,
});

const getLocationStationsSuccess = (data, totalCount, loadMore) => ({
  type: GET_LOCATION_STATIONS_SUCCESS,
  data,
  totalCount,
  loadMore,
});

const getLocationStationsFailure = error => ({
  type: GET_LOCATION_STATIONS_FAILURE,
  error,
});

const getLocationStations = (
  authorization,
  locationId,
  params,
  callback,
  excludeLoading = false,
  loadMore = false
) => {
  return dispatch => {
    
    if (!excludeLoading) {
      dispatch(getLocationStationsStart());
    }

    if (loadMore) {
      dispatch(getLocationStationsStartMore());
    }

    let totalCount;

    get(`locations/${locationId}/stations`, {authorization}, params)
      .then(response => {
        if (response.ok) {
          totalCount = response.headers.get('x-total-count');
          return response.json();
        }
      })
      .then(data => {
        dispatch(getLocationStationsSuccess(data, totalCount, loadMore));
        if (callback) { callback(data) }
      })
      .catch(error => {
        dispatch(getLocationStationsFailure(error));
      });
  };
};

export default getLocationStations;
