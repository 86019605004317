import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';

import {
  LangTitle,
  LangChooser,
  LangOption,
  FilledDot,
  Dot,
  Icon,
  Name,
} from './SwitchMobile.styled';

const SwitchMobile = props => {
  const {
    isVisible,
    closeLangSwitch,
    options,
    selectedOption,
    changeLanguage,
  } = props;

  return (
    <Modal isOpen={isVisible} centered>
      <ModalHeader toggle={() => closeLangSwitch()}>
        <LangTitle>Select language</LangTitle>
      </ModalHeader>
      <LangChooser>
        {options.map(lang => {
          return (
            <LangOption
              key={lang.id}
              selected={selectedOption === lang}
              onClick={() => changeLanguage(lang.shorthand)}
            >
              {selectedOption === lang ? <FilledDot /> : <Dot/>}
              <Icon>{lang.icon}</Icon>
              <Name>{lang.name}</Name>
            </LangOption>
          );
        })}
      </LangChooser>
    </Modal>
  );
};

export default SwitchMobile;