import React from 'react';
import {withRouter} from 'react-router-dom';
import {object, string, func} from 'prop-types';

import {StyledButton} from './Button.styled';

const propTypes = {
  history: object,
  to: string,
  onClick: func,
  other: object,
};

const Button = props => {
  const {
    history,
    to,
    onClick,
    ...other
  } = props;

  return (
    <StyledButton
      onClick={(e) => {
        to && history.push(to);
        onClick && onClick(e);
      }}
      {...other}
    />
  );
};

Button.propTypes = propTypes;

export default withRouter(Button);
