import React from "react";

import StatusBlock from "../common/StatusBlock";
import OperativeStatus from "../common/OperativeStatus";
import EvseConnectorStatus from "./EvseConnectorStatus";

const StationStatus = ({configured, evses, marginLeft}) => {
    if (!configured) {
        return (
            <StatusBlock status="unconfigured">
                Ненастроена
            </StatusBlock>
        )
    }

    const operative = (evses.filter(evse => evse.operational).length !== 0);

    if (!operative) {
        return (
            <OperativeStatus operative={false} noMargin />
        )
    }

    const connectors = evses.reduce((connectors, evse) => [...connectors, ...evse.connectors], []);
    const availableCount = connectors.filter(connector => connector.status === 'available').length;
    const busyCount = connectors.filter(connector => connector.status === 'busy').length;
    const offlineCount = connectors.filter(connector => connector.status === 'offline').length;
    const status = availableCount && 'available' || busyCount && 'busy' || offlineCount && 'offline' || 'faulted';

    return (
        <>
            <EvseConnectorStatus status={status} />
        </>
    );
}

export default StationStatus;
