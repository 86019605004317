import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
`;

export const Name = styled.div`
  display: block;
  min-width: 100%; max-width: 100%;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
`;

export const Balance = styled.div`
  display: block;
  min-width: 100%; max-width: 100%;
  margin: 0.5rem auto; margin-bottom: 1.5rem;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;