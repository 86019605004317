import {getJSON as get} from '../../api';
import {GET_LOCATION_SERVICES_START, GET_LOCATION_SERVICES_SUCCESS, GET_LOCATION_SERVICES_FAILURE} from './types';

const getServicesStart = () => ({
  type: GET_LOCATION_SERVICES_START,
});

const getServicesSuccess = data => ({
  type: GET_LOCATION_SERVICES_SUCCESS,
  data,
});

const getServicesFailure = error => ({
  type: GET_LOCATION_SERVICES_FAILURE,
  error,
});

const getServices = (locationId, params, callback) => {
  return dispatch => {
    dispatch(getServicesStart());
    get(`locations/${locationId}/services`, null, params)
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getServicesSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getServicesFailure(error));
      });
  };
};

export default getServices;
