import React, {Component} from 'react';
import styled from 'styled-components';

import * as colors from '../../../util/colors';
import {fadeIn} from '../animations';

class UploadField extends Component {
  state = {
    file: null,
    valid: true,
  };

  fieldRef = React.createRef();

  fileFormatIsValid = (file) => {
    const nameParts = file.name.split('.');
    const format = nameParts[nameParts.length-1].toLowerCase();
    const allowedFormats = this.props.allowedFormats || ['jpg', 'jpeg', 'png'];

    if (allowedFormats.includes(format)) {
      return true;
    }
    return false;
  };

  handleDelete = () => {
    const input = this.fieldRef.current;
    input.value = '';

    this.setState({file: null, valid: true});
  };

  handleChooseFile = event => {
    const {name, files} = event.target;
    const {handleChange} = this.props;

    const fileIsValid = this.fileFormatIsValid(files[0]);
    this.setState({ file: files[0], valid: fileIsValid });
    if (fileIsValid) {
      if (handleChange) {
        handleChange({
          target: {
            name,
            value: files[0]
          },
        });
      }

      return true;
    }
  };

  shortenFileName = fileName => {
    if (!fileName) { return false; }

    const splitFileName = fileName.split('.');
    const [extension]   = splitFileName.splice(-1);
    const name          = splitFileName.join('.');

    if (name.length < 25) { return `${name}.${extension}`; }

    return `${name.slice(0, 13)}...${name.slice(-9)}.${extension}`;
  };

  render() {
    const {type, label, buttonText, warning, height} = this.props;
    const {file, valid} = this.state;

    return (
      <Container height={height} {...this.props}>
        <Label>{label}</Label>
        <Button theme="blue" width="100%" {...this.props}>
          {file && <Delete onClick={this.handleDelete}>&times;</Delete>}
          <Field
            ref={this.fieldRef}
            name="file"
            onChange={this.handleChooseFile}
          />
          <img src="/clipIcon.svg" alt="Clip icon" height={20} width={20} style={{margin: '0 6.45px 0 11.82px'}} /><span style={{color: "#818286"}}>{file ? this.shortenFileName(file.name) : buttonText}</span>
        </Button>
        {warning && !valid && <Warning>{warning}</Warning>}
        {file && type === 'image' && <Image src={URL.createObjectURL(file)} />}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  align-self: center;
  margin-bottom: 32px;
  margin: ${props => props.margin};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  &:hover { cursor: pointer; }
`;

const Label = styled.label`
  display: block;
  margin: 0.25rem 0;
  font-size: 14px;
  color: ${colors.TEXT_INPUT};
`;

const Button = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%; max-width: 100%;
  min-height: 40px;
  max-height: 40px;
  margin: 0.5rem 0;
  margin: ${props => props.buttonMargin};
  font-size: 16px;
  border: 1px solid #CAD0D9; border-radius: 4px;
  color: black; background-color: white;
  outline: none;
  box-shadow: 0 0 3px ${props => props.valid === false ? 'red' : 'transparent'};
  background-color: #F5F7F9;
  & > input:focus {
    box-shadow: 0 0 3px ${props => props.valid === false ? 'red' : colors.BUTTON_BLUE};
  }
`;

const Delete = styled.span`
  position: absolute; top: 6px; right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px; max-height: 24px;
  font-size: 24px;
  color: ${colors.ICON_GRAY};
  &:hover { cursor: pointer; }
  z-index: 2;
`;

const Field = styled.input`
  position: absolute; top: 0; right: 0; bottom: 0; left: 0;
  min-width: 100%; max-width: 100%;
  min-height: 100%; max-height: 100%;
  opacity: 0;
  &::-webkit-file-upload-button {
    min-width: 100%; max-width: 100%;
    min-height: 100%; max-height: 100%;
    &:hover { cursor: pointer; }
  }
`;
Field.defaultProps = {
  type: 'file',
};

const Warning = styled.div`
  text-align: right;
  font-size: 12px;
  color: red;
`;

const Image = styled.img`
  min-width: 100%; max-width: 100%;
  min-height: 100%; max-height: 100%;
  animation: .5s ${fadeIn()} ease-in-out;
  border: 1px solid transparent; border-radius: 8px;
  &:hover { cursor: default; }
`;

export default UploadField;
