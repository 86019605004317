import {getJSON as get} from '../../api';
import {GET_PAYMENT_ACCOUNT_START, GET_PAYMENT_ACCOUNT_SUCCESS, GET_PAYMENT_ACCOUNT_FAILURE} from './types';

const getPaymentAccountStart = () => ({
  type: GET_PAYMENT_ACCOUNT_START,
});

const getPaymentAccountSuccess = data => ({
  type: GET_PAYMENT_ACCOUNT_SUCCESS,
  data,
});

const getPaymentAccountFailure = error => ({
  type: GET_PAYMENT_ACCOUNT_FAILURE,
  error,
});

const getPaymentAccount = (authorization, managerId, callback) => {
  return dispatch => {
    dispatch(getPaymentAccountStart());
    get(`managers/${managerId}/account`, {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getPaymentAccountSuccess(data));
        if (callback) { callback(); }
      })
      .catch(error => {
        dispatch(getPaymentAccountFailure(error));
      });
  };
};

export default getPaymentAccount;
