import {randomBytes as rb} from 'crypto';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

import * as colors from '../../../util/colors';
import SubmitForm, {TextField} from '../../common/Form';
import Button from '../../common/Button';
import {ErrorAlert} from '../../common/Alerts';
import StationCard from './StationCard';

class ModelSelectedForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: {value: '', valid: undefined},
      },
      editing: false,
    };
  }

  componentWillMount() {
    if (this.props.error) {
      this.props.error.error = null;
    }

    this.setState({data: {id: { value: rb(10).toString('hex'), valid: undefined }}});
  }

    handleChange = event => {
      let {name, value} = event.target;

      value = value.trim().split(' ').join('');

      this.setField(name, value);
    };

    setField = (name, value) => {
      const data = { ...this.state.data };

      data[name].value = value;
      data[name].valid = (value.length > 0);

      this.setState({ data });
    };

    handleSubmit = event => {
      event.preventDefault();

      const {modelSelectedNextClick} = this.props;
      const selectedStation = this.props.station;
      const {data} = this.state;

      const station = {
        id:          data.id.value,
        vendor_name: selectedStation.vendor_name,
        model:       selectedStation.model,
        image:       selectedStation.image,
        connectors:  [],
      };

      for (const evse of selectedStation.evses) {
        for (const connector of evse.connectors) {
          const formattedConnector = {
            evse_number: connector.evse_number,
            number:      connector.number,
            type:        connector.type,
            current:     connector.current,
            power:       connector.power,
          };

          station.connectors.push(formattedConnector);
        }
      }

      modelSelectedNextClick(station);
    };

    allFieldsValid() {
      const {data} = this.state;

      return (data.id.value.length > 0);
    };

    render() {
      const {id} = this.state.data;
      const {error, t} = this.props;

      return (
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Title bold>{t('newStation')}</Title>

            <StationContainer>
              <StationCard {...this.props.station} disableClick={true} />
            </StationContainer>

            <InputContainer>
              {this.state.editing ? <TextField
                name="id"
                value={id.value}
                label={t('stationId')}
                valid={id.valid}
                required
                handleChange={this.handleChange} handleBlur={() => false}
                removeIconVisible
              /> :
                <div>
                  <div>
                    <StationIdLabel>{t('stationId')}</StationIdLabel>
                  </div>
                  <StationIdEdit>
                    <span>{id.value}</span>
                    <span className="edit" onClick={() => this.setState({editing: true})}>{t('edit')}</span>
                  </StationIdEdit>
                </div>
              }
            </InputContainer>
            <ButtonContainer>
              <Button type="submit" disabled={!this.allFieldsValid()}
                theme="blue" width="100%" margin="24px 0">
                {t('continue')}
              </Button>
              {(error && error.message && <div className="error-alert">
                <ErrorAlert bottom='5px' noTriangle>
                  {Object.entries(error).length > 1 ? error.message || error.error : 'Something went wrong'}
                </ErrorAlert>
              </div>
              )}
            </ButtonContainer>
          </Form>
        </Container>
      );
    };
}

const Container = styled.div`
  height: calc(auto - 32px);
  padding-bottom: 10px;
  min-width: 700px; max-width: 50%;
  background-color: white;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 8px;
`;

const Form = styled(SubmitForm)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  width: 300px;
  margin: ${props => props.margin || '32px auto 0'} ;
  font-size: ${props => props.size || '24px'};
  font-weight: ${props => props.bold ? 'bold' : 'none'};
  text-align: center;
  color: ${props => props.color || colors.TEXT_TITLE};
  align-self: center;
`;

const StationContainer = styled.div`
  margin: 8px 0;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
`;

const InputContainer = styled.div`
  display: flex
  flex-direction: column
  justify-content: space-around
  padding: 0 100px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  padding: 0 100px;
  width: 100%;
  & > div.error-alert {
    display: inline;
    position: relative;
    width: 200px;
  }
`;

const StationIdEdit = styled.div`
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%;

  & > .edit {
    color: ${colors.BUTTON_BLUE}
    &:hover {
      cursor: pointer
    }
  }
`;
const StationIdLabel = styled.span`
  display: block;
  margin: 0.25rem 0;
  font-size: 14px;
  color: ${colors.TEXT_INPUT};
`;

export default compose(withTranslation(), withRouter)(ModelSelectedForm);
