import React from 'react';

const FlagGe = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
        <rect width="24" height="18" rx="2" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0)">
        <rect y="6" width="24" height="6" fill="#FF0000"/>
        <rect y="12" width="24" height="6" fill="#F9C41D"/>
        <rect width="24" height="6" fill="#202020"/>
      </g>
    </svg>
  );
};

export default FlagGe;
