import React from "react";

import StatusBlock from "./StatusBlock";

const OperativeStatus = ({operative, male, noMargin}) => (
    <StatusBlock status={operative ? 'available' : 'faulted'} marginLeft={noMargin || '24px'}>
        {operative && (
            male ? 'Рабочий' : 'Рабочая'
        )}
        {!operative && (
            male ? 'Нерабочий' : 'Нерабочая'
        )}
    </StatusBlock>
)

export default OperativeStatus;
