import styled from 'styled-components';

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #202020;
  cursor: pointer;

  & > img {
    margin-right: 12px;
  }
`;