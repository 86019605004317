import React, {Component} from 'react';
import 'chart.js';
import moment from 'moment';
import {withTranslation} from 'react-i18next';

import Paper from '../common/Paper';

import {Container, Title} from './StatisticsContainer.styled';
import StatisticsDates from "./StatisticsDates";
import StatisticsChart from "./StatisticsChart";
import StatisticsInterval, {MONTHS_INTERVAL} from "./StatisticsIntervals";
import StatisticsSummary from "./StatisticsSummary";

class StatisticsContainer extends Component {
  intervalId = 0;

  constructor(props) {
    super(props);

    this.state = {
      chartData: 'charging_revenue',
      interval: 'month',
      from: moment().subtract(30, 'days').toISOString(),
      to: moment().toISOString(),
      statistics: {
        current_period: {
          charging_revenue: 0,
          charging_sessions: 0,
          energy_usage: 0,
        },
        data: [],
      },
      exportModalVisible: false,

      startDay: MONTHS_INTERVAL.startDay,
      startMonth: MONTHS_INTERVAL.startMonth,
      startYear: MONTHS_INTERVAL.startYear,

      endDay: MONTHS_INTERVAL.endDay,
      endMonth: MONTHS_INTERVAL.endMonth,
      endYear: MONTHS_INTERVAL.endYear,

      isExportLoading: false,
      locationIds: [],
    };
  }

  componentWillMount() {
    this.reloadStatistics();
  }

  componentDidMount() {
    this.intervalId = setInterval(() => this.reloadStatistics(), 5 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getFromDate() {
    const {
      startDay,
      startMonth,
      startYear,
    } = this.state;

    const MM = moment().month(startMonth.id).format('MM');

    return moment(`${startDay.id}-${MM}-${startYear.id} 00:00:00`, 'DD-MM-YYYY hh:mm:ss');
  }

  getToDate() {
    const {
      endDay,
      endMonth,
      endYear
    } = this.state;

    const MM = moment().month(endMonth.id).format('MM');

    return moment(`${endDay.id}-${MM}-${endYear.id} 23:59:59`, 'DD-MM-YYYY hh:mm:ss');
  }

  setStateAndReloadStatistics(state) {
    this.setState(state, () => {
      this.reloadStatistics();
    });
  }

  async reloadStatistics() {
    const {authorization, getStatistics} = this.props;

    getStatistics(authorization, {
      from: this.getFromDate().toISOString(),
      to: this.getToDate().toISOString(),
      mode: 'full',
      location_ids: this.state.locationIds,
    }, () => this.setState({ statistics: this.props.statistics }));
  }

  render() {
    const data = {};
    const {t} = this.props;

    let chargingRevenue = 0;
    let numberOfCharges = 0;
    let energyUsage = 0;

    if (!this.state.statistics?.data) {
      return null;
    }

    for (const dayData of this.state.statistics.data) {
      switch (true) {
        case (this.state.chartData === 'charging_revenue'): {
          const
          dateAsString = moment(dayData.date).format('YYYY MMM DD');

          data[dateAsString] = dayData.charging_revenue / 100;
          break;
        }
        case (this.state.chartData === 'charges'): {
          const
          dateObject = moment(dayData.date);
          const
          dateAsString = moment(`${dateObject.year()}-${(dateObject.month() + 1).toString().padStart(2, '0')}-${dateObject.date()}`).format('YYYY MMM DD');

          data[dateAsString] = dayData.charging_sessions;

          break;
        }
        case (this.state.chartData === 'energy_usage'): {
          const
          dateAsString = moment(dayData.date).format('YYYY MMM DD');

          data[dateAsString] = parseFloat((dayData.energy_usage / 1000).toFixed(3));
          break;
        }
        default:
      }

      chargingRevenue += dayData.charging_revenue / 100;
      numberOfCharges += dayData.charging_sessions;
      energyUsage += dayData.energy_usage;
    }

    return (
      <Paper padding="0">
        <Container>
          <Title>{t('statistics')}</Title>

          <StatisticsInterval
            interval={this.state.interval}
            onStaticsIntervalChange={(state) => this.setStateAndReloadStatistics(state)}
            onLocationsFilterChange={(locationIds) => this.setStateAndReloadStatistics({ locationIds })}
            getFromDate={() => this.getFromDate()}
            getToDate={() => this.getToDate()}
            authorization={this.props.authorization}
            locationIds={this.state.locationIds}
          />

          <StatisticsDates
            data={this.state}
            handleChange={(state) => this.setStateAndReloadStatistics(state)}
          />

          <StatisticsSummary
            chargingRevenue={chargingRevenue}
            numberOfCharges={numberOfCharges}
            energyUsage={energyUsage}
            chartData={this.state.chartData}
            onChange={(state) => this.setState(state)}
          />

          <StatisticsChart
            data={data}
            type={this.state.chartData}
          />
        </Container>
      </Paper>
    );
  }
}

export default withTranslation()(StatisticsContainer);
