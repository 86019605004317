import React from 'react';
import {connect} from 'react-redux';
import getStations from "../../redux/actions/getStations";
import SearchForm from "../common/Form/SearchForm";

function ChargeSearchForm({getStations}) {
  return (
    <SearchForm onChange={getStations}/>
  )
}

const mapDispatchToProps = dispatch => ({
  getStations: (authorization, params) => dispatch(getStations(authorization, params)),
});

export default connect(null, mapDispatchToProps)(ChargeSearchForm);
