import React from "react";

const StationReservationSupportIcon = ({reservations_supported}) => (
    <img
        src={reservations_supported ? '/clock-reservation-supported.svg' : '/clock-reservation.svg'}
        style={{width: '20px'}}
    />
)

export default StationReservationSupportIcon;
