import {getJSON as get} from '../../api';
import {
  GET_MANAGER_BALANCE_START, GET_MANAGER_BALANCE_SUCCESS, GET_MANAGER_BALANCE_FAILURE
} from './types';

const getManagerBalanceStart = () => ({
  type: GET_MANAGER_BALANCE_START,
});

const getManagerBalanceSuccess = data => ({
  type: GET_MANAGER_BALANCE_SUCCESS,
  data,
});

const getManagerBalanceFailure = error => ({
  type: GET_MANAGER_BALANCE_FAILURE,
  error,
});

const getManagerBalance = (authorization, managerId, callback) => {
  return dispatch => {
    dispatch(getManagerBalanceStart());
    get(`managers/${managerId}/balance`, {authorization})
      .then(response => {
        if ((response.status >= 200) && (response.status < 300)) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(getManagerBalanceSuccess(data));
        if (callback) { callback(data); }
      })
      .catch(error => {
        dispatch(getManagerBalanceFailure(error));
      });
  };
};

export default getManagerBalance;
