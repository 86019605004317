import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import ReactTooltip from 'react-tooltip';

import * as colors from '../../../util/colors';
import {calculateFee, calculateDiscount} from '../../../util/fee-calculator';
import Button from '../../common/Button';
import Form, {Title, Text} from '../../common/Form';
import {Checkbox} from '../../common/Checkbox';
import LoadingSpinner from '../../common/LoadingSpinner';
import {ErrorAlert} from '../../common/Alerts';
import RadioButton from '../../common/RadioButton';

// TODO Get from the /help/constants endpoint.
const BASE_MONTHLY_FEE = 16;
const BASE_ANNUALLY_FEE = 15;

class SubscriptionConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        frequency: {
          value: 'monthly', valid: true,
          options: [
            {id: 'monthly', name: 'Monthly'},
            {id: 'annually', name: 'Annually'}
          ],
          selectedOptionIndex: 0,
        },
        selectedStations: {},
      },
    };
  }

  handleChange = event => {
    const {name, value} = event.target;
    const data = {...this.state.data};

    if (name === 'selectedStations') {
      if (data.selectedStations[value.id]) {
        delete data.selectedStations[value.id];
      } else {
        data.selectedStations[value.id] = value;
      }

      return this.setState({data});
    }

    // for select fields
    if (data[name] && data[name].options) {
      const selectedOption = data[name].options.find(option => option.id === value);
      data[name].selectedOptionIndex = data[name].options.indexOf(selectedOption);
    }

    data[name].value = value;

    this.setState({data});
  };

  handleSubmit = event => {
    event.preventDefault();

    const {locations} = this.props;
    const {data} = this.state;
    const {selectedStations} = data;
    const frequency = data.frequency.value;
    const quantity = Object.keys(data.selectedStations).length;

    const stations = [];

    for (const location of locations) {
      for (const station of location.stations) {
        stations.push({id: station.id, monetized: !!selectedStations[station.id]});
      }
    }

    const body = {
      frequency,
      quantity,
      stations
    };

    this.props.createManagerSubscription(this.props.authorization, this.props.manager.id, body, () => {
      this.props.history.push('/settings/payments');
    });
  }

  render() {
    const {history, loading, error, t, locations} = this.props;
    const {data} = this.state;
    const nStations = Object.keys(data.selectedStations).length;
    const nTotalStations = locations.reduce((a, b) => b.stations.length + a, 0);

    if (loading) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      );
    }

    let baseFee = BASE_MONTHLY_FEE;

    if (data.frequency.value === 'annually') {
      baseFee = BASE_ANNUALLY_FEE;
    }

    const calculatedFee = calculateFee(baseFee, nStations, 'Germany');
    const calculatedDiscount = calculateDiscount(BASE_MONTHLY_FEE, calculatedFee);
    const totalFee = calculatedFee * nStations;

    return (
      <Container>
        <img src="/paymentsProgressBar/progressBar3.svg" alt="progress-bar" className="progress-bar-icon" />
        <Form onSubmit={this.handleSubmit}>
          <Title size="24px">{t('subscriptionConfiguration')}</Title>
          <Text margin="16px 0">{t('subscriptionPriceIsCalculated')}</Text>

          <RadioButton
            name="frequency"
            items={[
              {
                value: 'monthly',
                label: t('monthly'),
                checked: data.frequency.value === 'monthly',
                onChange: this.handleChange,
              },
              {
                value: 'annually',
                label: t('annually'),
                checked: data.frequency.value === 'annually',
                onChange: this.handleChange,
              },
            ]}
          />

          <Fee><span className='amount'>{totalFee}</span> ₽ /{t('month')}</Fee>

          <InfoContainer>
            <div className='stations-selected'>
              <span className='amount'>
                {nStations}
              </span>
              {t('stationsSelected')}
            </div>
            <div className='discount'>
              {calculatedDiscount}% {t('discount')}
            </div>
          </InfoContainer>

          <ButtonContainer>
            <Button
              onClick={() => history.push('/settings/payments')}
              margin='0 8px 0 0'
              theme='white-blue'
              width='182px'
            >
              {t('configureLater')}
            </Button>
            <Button
              disabled={(nStations === 0)}
              type="submit"
              margin="0 0 0 8px"
              width='182px'
            >
              {t('confirm')}
            </Button>
            {error && error.message && <div className="error-alert"><ErrorAlert>{error.message}</ErrorAlert></div>}
          </ButtonContainer>

          <hr />

          <SelectedStationsTitle>
            {t('selectedStationsForMonetization')}
          </SelectedStationsTitle>
          <NumberOfSelectedStations>
            <span className='amount'>{nStations}/{nTotalStations}</span>
          </NumberOfSelectedStations>

          <LocationsContainer>
            {locations.map(({name, stations}) => (
              <LocationCard key={name}>
                <LocationTitle>{name}</LocationTitle>

                {(stations.length === 0) && <NoStations>{t('noStations')}</NoStations>}

                {stations.map(station => (
                  <StationCard key={station.id}>
                    <StationImage src={station.image || '/defaultStationPicture.jpg'} />
                    <StationInfo>
                      <StationNumber>#{station.number}</StationNumber>
                      <StationModel>{station.model}</StationModel>

                      {station.evses.map(evse => evse.connectors.map(connector => (
                        <ConnectorIcon key={connector.id}>
                          <img
                            data-tip={`${connector.type} (${connector.power} ${t('kW')})`}
                            src={`/connectors/${connector.type.replace(' ', '').toLowerCase()}.svg`}
                            alt='Connector type'
                          />
                          <ReactTooltip effect='solid' />
                        </ConnectorIcon>
                      )))}

                      <StationCheckbox>
                        <Checkbox
                          model={{}}
                          onChange={() => this.handleChange({target: {name: 'selectedStations', value: station}})}
                          name={station.id}
                          value={station}
                          checked={this.state.data.selectedStations[station.id]}
                        />
                      </StationCheckbox>
                    </StationInfo>
                  </StationCard>
                ))}

              </LocationCard>
            ))}

          </LocationsContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-width: 582px; max-width: 582px;
  min-height: 120px;
  margin: 0 auto; margin-bottom: 32px;
  padding: 24px 100px;
  border: 1px solid transparent; border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px ${colors.LINE};
  & > h3.title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: bold;
  }
  & > img.progress-bar-icon {
    display: block;
    margin: 0 auto 24px;
  }
`;

const Fee = styled.div`
  margin: auto;
  font-size: 16px;
  text-align: center;
  & > span.amount {
    font-size: 24px;
    font-weight: bold;
  }
`;

const InfoContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
  & > div.stations-selected {
    display: inline-block;
    margin-right: 16px;
    font-size: 14px;
    color: ${colors.TEXT_MINOR}
    & > span.amount {
      background-color: ${colors.BLUE_LIGHT};
      color: ${colors.BLUE_MAIN};
      border-radius: 12px;
      padding: 6px 10px;
      font-size: 12px;
      margin-right: 6px;
    }
  }
  & > div.discount {
    background-color: #EBFAE4;
    color: ${colors.TEXT_GREEN}
    display: inline-block;
    border-radius: 12px;
    padding: 6px 12px;
    font-size: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%; max-width: 100%;
  position: relative;
  & > div.error-alert {
    margin-left: 9px;
  }
`;

const SelectedStationsTitle = styled.div`
  color: ${colors.TEXT_MAIN};
  font-size: 14px;
  text-align: center;
`;

const NumberOfSelectedStations = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  margin-top: 8px;
  & > span.amount {
    background-color: ${colors.BLUE_LIGHT};
    color: ${colors.BLUE_MAIN};
    border-radius: 12px;
    padding: 6px 10px;
    font-size: 12px;
  }
`;

const LocationsContainer = styled.div`

`;

const LocationCard = styled.div`
  margin-top: 16px;
  padding: 16px;
  border: 1px solid ${colors.LINE};
  border-radius: 8px;
`;

const LocationTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const NoStations = styled.div`
  font-size: 14px;
  color: ${colors.TEXT_MINOR};
`;

const StationCard = styled.div`
  position: relative;
  width: 348px;
  height: 86px;
  display: inline-block;
  padding: 8px;
  box-shadow: 0 2px 0.4vw rgba(37, 99, 100, 16%);
  border-radius: 6px;
  margin-top: 8px;
`;

const StationImage = styled.img`
  width: 70px;
  height: 70px;
  background-color: gray;
  display: inline-block;
`;

const StationInfo = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 16px;
  width: calc(100% - 102px);
`;

const StationNumber = styled.div`
  font-size: 14px;
  font-weight: medium;
`;

const StationModel = styled.div`
  margin-top: 4px;
  font-size: 14px;
`;

const StationCheckbox = styled.div`
  position: absolute; top: 31px; right: 10px;
`;

const ConnectorIcon = styled.span`
  margin-right: 8px;
`;

export default compose(withTranslation(), withRouter)(SubscriptionConfiguration);
