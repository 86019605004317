import {GET_CONNECTOR_START, GET_CONNECTOR_SUCCESS, GET_CONNECTOR_FAILURE} from '../actions/types';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const connector = (state = initialState, action) => {
  switch (action.type) {
    // get connector
    case GET_CONNECTOR_START:
      return {...state, loading: true};
    case GET_CONNECTOR_SUCCESS:
      return {...state, data: action.data, error: null, loading: false};
    case GET_CONNECTOR_FAILURE:
      return {...state, error: action.error, loading: false};
    default:
      return state;
  }
};

export default connector;
