import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';

import Form, {Title, Text} from '../common/Form';
import {Close} from '../common/Modal';
import Button from '../common/Button';

const InfoRecoveryForm = ({history, setForm}) => {
  const handleSubmit = event => {
    event.preventDefault();
    setForm('login');
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Close onClick={() => history.push('/')}>
          <img src="/cross.svg" alt="Close" />
        </Close>
        <Title>Email was sent</Title>
        <Text textAlign="left" position="absolute">
          {"Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder."}
        </Text>
        <MailboxImage><img src="/mailboxSend.svg" alt="Mailbox send" height={172} /></MailboxImage>
        <ButtonContainer>
          <Button type="submit" width="100%" margin="0" theme="blue" warning="hi">
          Return to Sign In
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-width: calc(100% - 80px); max-width: calc(100% - 80px);
  margin: 40px 40px;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  @media screen and (max-width: 576px) {
    margin-top: 2rem;
    position: relative;
  }
  @media screen and (min-width: 576px) {

  }
  & > div.error-alert {
    display: inline;
    position: absolute;
    width: 200px;
    margin-top: -3px;
    margin-left: 9px;
  }
`;

const MailboxImage = styled.div`
  display: flex;
  justify-content: center;
  margin: 43px auto 24px;
`;

export default withRouter(InfoRecoveryForm);
