import moment from "moment";
import {postGraphql} from '../../api';
import {convertJsonToSheetData} from "../../util/xlsx";

export const loadOrders = async ({ authorization, ...queryOpts }) => {
  const getOrdersQuery = createGetOrdersQuery({
    search: '',
    offset: 0,
    locationIds: '',
    ...queryOpts
  });

  const result = await postGraphql({authorization}, getOrdersQuery).then(res => res.json());
  const edges = result.data.orders?.edges || [];

  return edges.map(edge => edge.node);
}

export const getOrdersForExportToXlsx = (orders) => {
  return orders.map((order) => {
    const {
      transaction_start,
      transaction_finish,
      meter_start,
      meter_stop,
      meter_soc_start,
      meter_soc_stop,
    } = order;

    const notNull = (value) => value !== null;
    const transactionSuccess = transaction_start && transaction_finish;
    const transactionDurationMin = Math.round((moment(transaction_finish) - moment(transaction_start)) / 1000 / 60);
    const meterSummary = notNull(meter_start) && notNull(meter_stop) ? Math.round(meter_stop - meter_start) / 1000 : '-';
    const formatTime = (time) => time ? moment(time).format('DD.MM.YY, HH:mm:ss') : '-';
    const capturedAmount = notNull(order.captured_amount) ? Math.round(order.captured_amount)/100 : '-';

    return {
      'Номер заказа': order.id,
      'Локация': order.location.name,
      'Станция': order.station.id,
      'Статус сессии': order.status,
      'Старт транзакции (время по МСК)': formatTime(order.charging_start),
      'Окончание транзакции (время по МСК)': formatTime(order.charging_finish),
      'Старт заряда (время по МСК)': formatTime(transaction_start),
      'Окончание заряда (время по МСК)': formatTime(transaction_finish),
      'Время зарядки (в минутах)': transactionSuccess ? transactionDurationMin : '-',
      'Тип коннектора': order.connector.type,
      'SoC Старт': notNull(meter_soc_start) ? meter_soc_start : '-',
      'SoC Финиш': notNull(meter_soc_stop) ? meter_soc_stop : '-',
      'Счетчик, кВт Начало': notNull(meter_start) ? Math.round(meter_start)/1000 : '-',
      'Счетчик, кВт Финиш': notNull(meter_stop) ? Math.round(meter_stop)/1000 : '-',
      'Заряжено, кВт': meterSummary,
      'Тариф, руб.': notNull(order.rate) ? Math.round(order.rate)/100 : '-',
      'Сумма, в руб': capturedAmount,
      'Тип оплаты': order.payment_method_type,
      'Оплачено через приложение': order.payment_method_type !== 'cash' ? capturedAmount : '-',
      'Оплачено через кассу': order.payment_method_type === 'cash' ? capturedAmount : '-',
    }
  });
}

export const covertOrdersToStyledSheetData = (csvData) => {
  const headCellStyle = {
    fill: {
      fgColor: { rgb: "d2dae4" },
      bgColor: { indexed: 64},
    },
    border: {
      top: {style: 'thin', color: { rgb: "000000" }},
      bottom: {style: 'thin', color: { rgb: "000000" }},
      right: {style: 'thin', color: { rgb: "000000" }},
      left: {style: 'thin', color: { rgb: "000000" }},
    },
    alignment: {
      wrapText: true,
      vertical: 'center',
    }
  };

  return convertJsonToSheetData(csvData, { headCellStyle });
}

const createGetOrdersQuery = (args) => {
  return {
    query: `{
       orders(from: "${args.from}", to: "${args.to}", locationIds: "${args.locationIds}", limit: ${args.limit}, offset: ${args.offset}, search: "${args.search}") {
         total_count
         edges {
           node {
             id
             location {
               id
               name
               country
               city
               street
             }
             station {
               id
               number
             }
             connector {
               id
               type
               evse_number
             }
             user {
               id
               first_name
               last_name
               avatar_url
               phone
             }
             status
             reservation_start
             reservation_finish
             charging_start
             charging_finish
             meter_start
             meter_stop
             service_orders {
               id
             }
             charging_price
             services_price
             captured_amount
             transaction_start
             transaction_finish
             ev_connected
             payment_method_type
             meter_soc_start
             meter_soc_stop
             rate
             rfid
             parking_time
             parking_amount
             parking_captured_amount
             reservation_price
          }
        }
      }
    }`
  };
};
